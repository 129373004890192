import { Component, OnInit, Input, Injector, ViewChild, EventEmitter, Output, ChangeDetectorRef, ElementRef, ViewEncapsulation, ContentChild, NgZone } from '@angular/core';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { RequestModel } from '../../../models/request/request-model';
import { Observable, Subject } from "rxjs";
import { Router, ActivatedRoute, NavigationExtras, NavigationStart, NavigationEnd, CanDeactivate } from '../../../../../node_modules/@angular/router';
import { Location, formatDate, DatePipe } from '@angular/common';
import { SemBaseComponent } from '../../../components/common/sem-base/sem-base.component';
import { SemLoaderComponent } from '../sem-loader/sem-loader.component';
import { SemDateFormatPipePipe } from '../../helpers/pipes/sem-date-format-pipe.pipe';
import { DynamicLoadingUtil } from '../../helpers/dynamic-loading-util';
import { NavigationNodeSerializer } from '../../../models/serializing/navigation-node-serializer';
import { NavigationResponseModel } from '../../../models/navigation/navigation-response-model';
import { NavigationRequestModel } from '../../../models/navigation/navigation-request-model';
import { FileUploadService } from 'src/app/services/file-upload-service/file-upload.service';
import { FileUploadObservableState } from '../../helpers/file-util';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';
import { StateModel } from '../../../models/navigation/state-model';
import { filter, map } from '../../../../../node_modules/rxjs/operators';
import { SemConfirmDialogComponent } from 'src/app/components/controls/dialogs/sem-confirm-dialog/sem-confirm-dialog.component';
import { SemDialogComponent } from 'src/app/components/controls/dialogs/sem-dialog/sem-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ExportService } from 'src/app/services/export/export.service';
import { SearchService } from 'src/app/services/search/search.service';
import { Form, NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { ActionsService } from 'src/app/services/actions-service/actions.service';
import { SemVersionDialogComponent } from 'src/app/components/controls/dialogs/sem-version-dialog/sem-version-dialog.component';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { UserSearchSettingModel } from 'src/app/models/search/user-search-setting-model';
import { SemParameterDialogComponent } from 'src/app/components/controls/dialogs/sem-parameter-dialog/sem-parameter-dialog.component';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { SemCompareDialogComponent } from 'src/app/components/controls/dialogs/sem-compare-dialog/sem-compare-dialog.component';
import { LoaderServiceService } from 'src/app/services/loader-service/loader-service.service';
import { SemGenerateDialogComponent } from 'src/app/components/controls/dialogs/sem-generate-dialog/sem-generate-dialog.component';
import { LocalStorageService } from '../../../services/local-storage-service/local-storage.service';
import { FormService } from '../../../services/form-service/form.service';
import { UserService } from '../../../services/user/user-provider.service';
import { FileUploadStandaloneService } from '../../../services/file-upload-standalone/file-upload-standalone.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sem-dynamic',
  templateUrl: './sem-dynamic.component.html',
  styleUrls: ['./sem-dynamic.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [FileUploadStandaloneService]
})
export class SemDynamicComponent extends SemBaseComponent implements OnInit {

  public SerializationObjectFormLink: string;
  params: any;
  model: any = {};
  mainQuery: any;
  @ViewChild("loaderComp", { read: SemLoaderComponent, static: true }) loader: SemLoaderComponent;

  //stringReportQuery: string = "{ \"ReportId\": \"4\", \"Query\": { \"MainQuery\": null, \"FieldQueries\": null, \"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"Id\", \"FieldValue\": \"*\", \"FieldValueFrom\": null, \"FieldValueTo\": null, \"Path\": null, \"Exclude\": false, \"Subqueries\": null, \"BoostFactor\": null, \"Negate\": false }, \"Facets\": null, \"Projection\": [ \"Id\", \"OffenderProfileId\", \"ClientId\", \"Name\", \"Person.FirstName\", \"Person.LastName\", \"PlannedDateOfRelease\",\"DateOfIncarceration\" ], \"RequestedFacets\": null, \"Page\": 1, \"PageSize\": 10, \"ClassType\": null, \"ClassTypes\": [ \"Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Penitentiary.OffenderProfileModel\" ], \"SortFields\": null, \"UseCache\": false, \"RepositoryName\": \"lucene\", \"ParseMode\": 0, \"IncludeParents\": false }, \"ResultType\": 0 }";
  //stringReportQueryJudgement: string = "{ \"ReportId\": \"8\", \"Query\": { \"MainQuery\": null, \"FieldQueries\": null, \"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"Id\", \"FieldValue\": \"*\", \"FieldValueFrom\": null, \"FieldValueTo\": null, \"Path\": null, \"Exclude\": false, \"Subqueries\": null, \"BoostFactor\": null, \"Negate\": false }, \"Facets\": null, \"Projection\": [ \"ClientId\", \"Title\", \"EntryDateString\", \"JudgeFullName\", \"MainRegulationItem\", \"CaseDecisionDateString\", \"DurationOfTerm\",\"OffenderProfile.Person.LastName\",\"OffenderProfile.Person.FirstName\",\"OffenderProfile.Person.MiddleName\",\"ProbationOfficerFullName\" ], \"RequestedFacets\": null, \"Page\": 1, \"PageSize\": 10, \"ClassType\": null, \"ClassTypes\": [ \"Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Penitentiary.CourtJudgementInformationModel\" ], \"SortFields\": null, \"UseCache\": false, \"RepositoryName\": \"lucene\", \"ParseMode\": 0, \"IncludeParents\": false }, \"ResultType\": 0 }";
  //stringReportQueryJudgement2: string = "{ \"ReportId\": \"9\", \"Query\": { \"MainQuery\": null, \"FieldQueries\": null, \"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"Id\", \"FieldValue\": \"*\", \"FieldValueFrom\": null, \"FieldValueTo\": null, \"Path\": null, \"Exclude\": false, \"Subqueries\": null, \"BoostFactor\": null, \"Negate\": false }, \"Facets\": null, \"Projection\": [ \"Id\", \"PenaltyData.PenaltyType.Name\", \"PenaltyData.MainPenaltyForCourtJudgementInformation.ProbationOfficerFullName\", \"PenaltyData.MainPenaltyForCourtJudgementInformation.MainRegulationItem\", \"PenaltyData.MainPenaltyForCourtJudgementInformation.CaseDecisionDateString\", \"PenaltyData.MainPenaltyForCourtJudgementInformation.DurationOfTerm\", \"PenaltyData.MainPenaltyForCourtJudgementInformation.OffenderProfile.OffenderFullName\",\"ActualDateString\",\"OfficerFullName\",\"EntryDateString\",\"OfficerReport\",\"PlannedDateString\",\"ProbationVisitationProtocolType.Name\",\"OfficerFullName\" ], \"RequestedFacets\": null, \"Page\": 1, \"PageSize\": 10, \"ClassType\": null, \"ClassTypes\": [ \"Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Probation.ProbationVisitationProtocolModel\" ], \"SortFields\": null, \"UseCache\": false, \"RepositoryName\": \"lucene\", \"ParseMode\": 0, \"IncludeParents\": false }, \"ResultType\": 0 }";
  collapse: boolean = false;
  formActions: any[] = [];
  searchViews: any[] = [];
  currentSearchViewName: string = "";

  formName: string = this.translationsProviderService.translations["AddNew"].toUpperCase();
  reportQuery: any;
  reportQueryJudgement: any;
  reportQueryJudgement2: any;

  nExtra: NavigationExtras;

  alwaysShow: boolean = true;
  disable: boolean = true;

  @Output() titleChanged = new EventEmitter();
  public submitFilesRequestEvent: Subject<void> = new Subject<void>();
  public submitFilesEndRequestEvent: Subject<void> = new Subject<void>();
  public modelChangeEvent: Subject<void> = new Subject<void>();

  cd: ChangeDetectorRef;
  stateModel: StateModel;
  mandatoryFields: any[] = [];
  domainModelTypeFullName: string;

  clearedObjects: any[] = [];

  @ViewChild('f', { read: NgForm, static: true }) mainForm: NgForm;
  private exportServiceSubscriber = null;
  actionFinishedSubscription: any;

  valid: boolean = true;
  tab: any = null;
  selectedTabIndex: any = null;
  currentId: number = 0;
  showPopup: boolean = false;
  isSetAsReadOnly: boolean = true;
  skipFormValidationCheck: boolean = false;
  isSplitView: boolean = false;
  private currentEnviromentSettings;

  constructor(
    public injector: Injector,
    public restProviderService: RestProviderService,
    public requestModel: RequestModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public location: Location,
    public datePipe: SemDateFormatPipePipe,
    public fileUploadService: FileUploadService,
    public dialog: MatDialog,
    public exportService: ExportService,
    public searchService: SearchService,
    public translationsProviderService: TranslationsProviderService,
    public changeDet: ChangeDetectorRef,
    public loaderService: LoaderServiceService,
    public actionsService: ActionsService,
    public localStorageService: LocalStorageService,
    public formService: FormService,
    public fileUploadStandalone: FileUploadStandaloneService) {
    super(injector, router, activatedRoute, injector.get(DynamicFieldsManagerService), injector.get(UtilityService), formService);
  }

  ngOnInit() {
    this.currentEnviromentSettings = environment;
  }

  ngAfterViewInit() {

    if (window.location.href.includes('cms/galisweb/statistics')) {
      this.formName = "";
    }

    this.dfms.metaModel = [];
    this.searchService.splitSearchEnabled = false;
    this.searchService.splitSearchKeywords = [];

    if (!this.isSplitView) {
      this.dfms.wasInEditMode = false;
      this.dfms.isFormReadOnly = false;
    } else {
      this.dfms.wasInEditMode = false;
      this.dfms.isFormReadOnly = true;
    }

    this.activatedRoute.queryParams.subscribe(params => {
      //console.log("Parameters: ");
      //console.log(params);
      this.params = params;

      if (this.params != null && typeof this.params != "undefined") {
        if (this.params["id"] != null && typeof this.params["id"] != "undefined") {

          this.currentId = parseInt(this.params["id"]);
          if (typeof DynamicLoadingUtil.loader != "undefined") {
            DynamicLoadingUtil.loader.showLoader = true;
          }

          //this.restProviderService.getDataGET("api/documents/" + this.params["id"]).subscribe(data => {
          this.restProviderService.getDataGET("api/data/form/" + this.SerializationObjectFormLink + "/" + this.params["id"]).subscribe(data => {
            let jsonData = JSON.parse(data);
            let prepModel = jsonData["Model"];

            if (jsonData.Metadata) {
              if (jsonData.Metadata.SecurityResult) {
                //jsonData.Metadata.SecurityResult.Items[45]["W"] = 0;
                this.dfms.metaModel = jsonData.Metadata.SecurityResult.Items;

                if (jsonData.Metadata.SecurityResult.Items.filter(x => x.W == 1).length == 0) {
                  this.dfms.isFormReadOnly = true;
                  this.dfms.editModeEnabled = false;
                }
              }
            }

            prepModel = this.dfms.setInitValues(prepModel);

            this.model = prepModel;
            this.model["SerializationObjectFormLink"] = this.SerializationObjectFormLink;
            this.domainModelTypeFullName = jsonData.DomainModelTypeFullName;
            //Fire observable to the child components(controls...), that the model has changed

            this.formName = this.model.Title;

            this.titleChanged.emit(this.formName);

            //console.log("LOADED MODEL");

            //console.log(this.model);
            if (typeof DynamicLoadingUtil.loader != "undefined") {
              DynamicLoadingUtil.loader.showLoader = false;
            }
            this.loadFormActions("top-form-action", null);


            this.dfms.globalModel = this.model;
            //this.checkMetamodel();

            this.modelChangeEvent.next(this.model);
            this.model["LOADEDFROMAPI"] = true;
            this.restProviderService._modelValueChanged.next(this.model);
            this.changeDet.detectChanges();

            if (typeof this.stateModel != "undefined" && this.stateModel != null) {
              //this.model[this.stateModel.Prop] = this.stateModel.Model;
              if (typeof this.stateModel["tabInformations"] != "undefined") {
                //this.model = this.stateModel;
                this.model["tabInformations"] = this.stateModel["tabInformations"];
                let tabInfo = this.stateModel["tabInformations"];
                this.tab = tabInfo["tabId"];
                this.changeTab(parseInt(this.tab));
              }
            }

            if (this.utility.isFormExpicitEditMode()) {
              this.dfms.isFormReadOnly = true;
            }

            this.changeDet.detectChanges();

            this.mainForm.form.markAsPristine();

          }, error => {
            if (typeof DynamicLoadingUtil.loader != "undefined") {
              DynamicLoadingUtil.loader.showLoader = false;
            }
          });

        }
        else if (this.params["model"] != null && typeof this.params["model"] != "undefined") {
          this.dfms.globalModel = null;
          this.formName = "";
          let UserSearchSetting = new UserSearchSettingModel;
          UserSearchSetting.View = { "Id": this.params["view"] };
          UserSearchSetting.ModelName = this.params["model"];

          this.loadFormActions("top-list-action", UserSearchSetting);
          
          if(environment.useOldSearchViewFeature === false){
            // also load all search views
            this.loadUserSearchViews(this.params["model"]);

          }
          
        } else {
          this.dfms.globalModel = null;
          this.loadFormActions("top-form-action", null);

          this.restProviderService.getDataGET("api/data/form/" + this.SerializationObjectFormLink).subscribe(data => {
            let jsonData = JSON.parse(data);

            if (jsonData.Metadata) {
              if (jsonData.Metadata.SecurityResult) {
                //jsonData.Metadata.SecurityResult.Items[45]["W"] = 0;
                this.dfms.metaModel = jsonData.Metadata.SecurityResult.Items;
                this.changeDet.detectChanges();
              }
            }
          },
            error => {

            });

        }

      } else {
        this.dfms.globalModel = null;
      }

      if (this.router.url.includes("/report")) {
        this.formName = this.translationsProviderService.translations["WebGalis_Module_PPMSCIS_Reports"];
      }

      let cr = this.utility.getCurrentRoute();

      if (typeof cr.labels == "undefined" || cr.labels == null) {
        this.restProviderService.getDataGET("api/dynamicforms/labels").subscribe((data: any) => {
          cr.labels = JSON.parse(data);

          let routes = this.localStorageService.get("api/navigation/getRoutes");

          if (routes != null) {

            let routesJSON = JSON.parse(routes);
            for (var i = 0; i < routesJSON.length; i++) {
              if (routesJSON[i].Title == cr.Title) {
                routesJSON[i] = cr;

                this.localStorageService.remove("api/navigation/getRoutes");
                this.localStorageService.add("api/navigation/getRoutes", JSON.stringify(routesJSON));

                break;
              }
            }

          }
        });
      }

    });

    this.activatedRoute.paramMap.subscribe(data => {
      this.stateModel = window.history.state;
      this.stateModel = this.utility.checkStateForValidity(window.location.href, this.stateModel);

      //We moved commented code below to the utility class, but just for case we leave this in until we test all cases
      /*if ((typeof this.stateModel == "undefined" || this.stateModel == null) || (Object.keys(this.stateModel).length == 1 && Object.keys(this.stateModel)[0] == "navigationId")) {
        var sm = this.utility.getStateFromHistory(window.location.href);

        if (sm != null) {
          this.stateModel = sm.StateObject;
        }
      } else {
        let stModel = { Url: window.location.href, StateObject: this.stateModel };
        this.utility.modifyStateFromHistory(stModel)
      }*/
      let tabClicked = false;
      if (typeof this.stateModel != "undefined" && this.stateModel != null) {
        //this.model[this.stateModel.Prop] = this.stateModel.Model;
        if (typeof this.stateModel["tabInformations"] != "undefined") {
          this.model = this.stateModel;
          let tabInfo = this.stateModel["tabInformations"];
          this.tab = tabInfo["tabId"];
          tabClicked = true;
          this.changeTab(parseInt(this.tab));
        }
        else if (typeof this.stateModel["Action"] != "undefined" && this.stateModel["Action"]) {
          if (typeof this.stateModel.Prop != "undefined" && typeof this.stateModel.Model != "undefined") {

            this.actionFinishedSubscription = this.actionsService.currentActionFinished.subscribe(data => {
              if (typeof data != "undefined" && data != null) {
                if (typeof data != "string") {

                  // this is support so you can send multiple object via: processNavigationUrl call
                  // example:
                  //this.utility.processNavigationUrl("url", {Model: data, "Prop":"SportGamesPerson", "Root":true, "Props":["Person","Result"]});
                  // this will bind properties "Person" and "Result" values from "Model:data"
                  if (this.stateModel.Root == true) {
                    if (this.stateModel.Props != null && typeof this.stateModel.Props != "undefined") {
                      // go through each prop and map
                      for (var prop of this.stateModel.Props) {
                        var value = this.resolvePropertyBinding(data["Model"], prop);
                        this.dfms.assign(this.model, prop, value, true);
                        this.dfms.assign(this.model, prop, value, true);
                      }
                      this.restProviderService._modelValueChanged.next(this.model);
                      this.dfms.globalModel = this.model;
                    }
                  } else {
                    this.dfms.assign(this.model, this.stateModel.Prop, data["Model"], true);
                    this.restProviderService._modelValueChanged.next(this.model);
                    this.dfms.globalModel = this.model;
                  }
                }
              }
              DynamicLoadingUtil.loader.showLoader = false;
            });

            DynamicLoadingUtil.loader.showLoader = true;
            this.actionsService.executeAction({ ActionUrl: this.stateModel["Action"] }, null, this.stateModel["Model"], null, null, this, null);
          }
        }
        else if (typeof this.stateModel.Prop != "undefined" && typeof this.stateModel.Model != "undefined") {

          // this is support so you can send multiple object via: processNavigationUrl call
          // example:
          //this.utility.processNavigationUrl("url", {Model: data, "Prop":"SportGamesPerson", "Root":true, "Props":["Person","Result"]});
          // this will bind properties "Person" and "Result" values from "Model:data"
          if (this.stateModel.Root == true) {
            if (this.stateModel.Props != null && typeof this.stateModel.Props != "undefined") {
              // go through each prop and map
              for (var prop of this.stateModel.Props) {
                var value = this.resolvePropertyBinding(this.stateModel.Model, prop);
                this.dfms.assign(this.model, prop, value, true);
              }
              this.restProviderService._modelValueChanged.next(this.model);
              this.dfms.globalModel = this.model;
            }
          } else {
            this.dfms.assign(this.model, this.stateModel.Prop, this.stateModel.Model, true);
            this.restProviderService._modelValueChanged.next(this.model);
            this.dfms.globalModel = this.model;
          }
        }
        else if (this.stateModel['IsFormCopy']) {
          this.model = this.stateModel;
        }
      }

      /*if (!tabClicked) {
        try {
          this.changeTab(0);
        } catch (e) {

        }
      }*/
    });

    this.restProviderService._modelValueChanged.next(this.model);
    this.cd = this.injector.get(ChangeDetectorRef);
    this.cd.detectChanges();
    this.submitFilesEndRequestEvent.subscribe((res) => {
      console.log("Submit file finished.")
    }, error => {
      console.log("Submit file error.")
    });

    var btn = document.getElementsByName("Geri qayıt");
    if (btn.length == 1) {
      btn[0].style.display = "none";
    }

    //this.tab.click();


    /*setTimeout(() => {
      var evt = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });

      //this.changeTab(parseInt("4"));

    }, 3000);*/

    this.dfms._modelValueReset.subscribe(data => {
      if (Object.keys(data).length > 0) {
        this.model = data;
        this.changeDet.detectChanges();
      }
    });

    if (this.mainForm) {
      this.mainForm.valueChanges.subscribe(val => {

        //fire up validator checks, for o2m controls. Hacky solution
        //this.dfms._modelValueSubmitValidator.next(this.model);

        if (this.mainForm.submitted) {
          this.checkFormValidation();
        }
      });
    }

    this.dfms.globalForm = this.mainForm;

    this.actionsService.formActionsReceived.subscribe(data => {

      if (data) {
        if (this.formService.preLoadEvent) {

          this.actionsService.executeAction({ "IsFileAction": false, "ActionUrl": this.formService.preLoadEvent.Action, "ActionName": "/" }, {}, {}, this.actionExecuted.bind(this), null, this, null);
          //this.actionsService.callAction({ "IsFileAction":false,"ActionUrl": this.formService.preLoadEvent.Action, "ActionName":"/" }, null, null, this.actionExecuted.bind(this), this, false, null, false, false);

          this.formService.preLoadEvent = null;
        }
      }
    });

  }

  public actionExecuted(data) {
    try {
      this.model = data;
      this.restProviderService._modelValueChanged.next(data);
    }
    catch (e) {

    }
  }

  public callReport(data) {
    const dialogRef = this.dialog.open(SemGenerateDialogComponent, {
      data: data
    });
  }


  canDeactivate() {
    console.log('i am navigating away');

    if (this.dfms.wasInEditMode) {
      // if the editName !== this.user.name
      const dialogRef = this.dialog.open(SemConfirmDialogComponent, { data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          if (result == true) {
            // Call Back button programmatically as per user confirmation.
            history.back();
            // Uncomment below line to redirect to the previous page instead.
            // window.location = document.referrer // Note: IE11 is not supporting this.
          } else {
            // Stay on the current page.
            history.pushState(null, null, window.location.pathname);
          }
        } else {
          history.pushState(null, null, window.location.pathname);
        }

      });
    } else {
      history.back();
    }

    return false;
  }

  /*@HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (event.restoredState) {
      const dialogRef = this.dialog.open(SemConfirmDialogComponent, { data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          if (result == true) {
            // Call Back button programmatically as per user confirmation.
            history.back();
            // Uncomment below line to redirect to the previous page instead.
            // window.location = document.referrer // Note: IE11 is not supporting this.
          } else {
            // Stay on the current page.
            history.pushState(null, null, window.location.pathname);
          }
        } else {
          history.pushState(null, null, window.location.pathname);
        }

      });
    }
  }*/

  stateActionEvaluated(data) {

  }

  checkMetamodel() {

    //Leave this commented code in, cause you never know :)
    /*let fg = this.mainForm.form;
    for (var i = 0; i < this.dfms.metaModel.length; i++) {
      if(this.mainForm.controls["model." + this.dfms.metaModel[i].Path]){
        if(this.dfms.metaModel[i].W == 0){
          
          let fc = this.mainForm.controls["model." + this.dfms.metaModel[i].Path];
          let controlValue = fc.value;
          fg.removeControl("model." + this.dfms.metaModel[i].Path);
          fg.addControl("model." + this.dfms.metaModel[i].Path, new FormControl({value: controlValue, disabled: true}));
          fg.updateValueAndValidity();

        }
      }
    }*/
    //this.mainForm.form.get("model.OffenderProfile.Person.FirstName").disable();
    //this.mainForm.form.controls["model.OffenderProfile.Person.FirstName"].disable();
    //this.mainForm.form.controls["model.OffenderProfile.Person.FirstName"].setValidators(null);

    //this.dfms.metaModel[45]["W"] = 0;
    try {
      for (var i = 0; i < this.dfms.metaModel.length; i++) {
        if (this.mainForm.controls["model." + this.dfms.metaModel[i].Path]) {
          if (this.dfms.metaModel[i].R == 0) {

            this.mainForm.controls["model." + this.dfms.metaModel[i].Path].setValue("");

          }
        }
      }
    } catch (e) {
      console.log(e);
    }

  }

  ngOnDestroy(): void {
    if (this.exportServiceSubscriber != null) {
      this.exportServiceSubscriber.unsubscribe();
    }

    if (this.actionFinishedSubscription != null) {
      this.actionFinishedSubscription.unsubscribe();
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    var tabs = document.getElementsByClassName("mat-tab-label");
    var firstSelected = false;
    var lastSelected = false;

    if (event.index == 0) {
      var btn = document.getElementsByName("Geri qayıt");
      if (btn.length == 1) {
        btn[0].style.display = "none";
        firstSelected = true;
      }
    } else if (event.index == tabs.length - 1) {
      var btn = document.getElementsByName("Növbəti");
      if (btn.length == 1) {
        btn[0].style.display = "none";
        lastSelected = true;
      }
    }

    if (!firstSelected) {
      var btn = document.getElementsByName("Geri qayıt");
      if (btn.length == 1) {
        btn[0].style.display = "block";
      }
    }
    if (!lastSelected) {
      var btn = document.getElementsByName("Növbəti");
      if (btn.length == 1) {
        btn[0].style.display = "block";
      }
    }

  }

  formTabChanged(event) {
    try {

      let tabContentElement = null;
      if (event.target.parentNode.id.length > 0) {
        tabContentElement = document.querySelectorAll('[aria-labelledby="' + event.target.parentNode.id + '"]')[0];
      } else {
        tabContentElement = document.querySelectorAll('[aria-labelledby="' + event.target.id + '"]')[0];
      }

      if (tabContentElement) {
        tabContentElement = tabContentElement.children[0];
        if (tabContentElement) {
          tabContentElement = tabContentElement.children[0];
        }
      }

      if (typeof tabContentElement != "undefined" && tabContentElement.hasAttribute("aria-form-url") && tabContentElement.getAttribute("aria-form-url").length > 0) {

        let formUrl = tabContentElement.getAttribute("aria-form-url");
        let tabId = tabContentElement.getAttribute("tab-id");

        this.model["tabInformations"] = { tabId: tabId, tabName: event.target.innerText };

        this.utility.processNavigationUrl(formUrl + "?id=" + this.currentId, this.model);
        return;

      } else {

        let mainElement = null;
        if (event.target.classList[0] == "mat-tab-label-content") {
          mainElement = event.target.parentElement;
        } else if (event.target.classList[0] == "mat-tab-label") {
          mainElement = event.target;
        }

        if (mainElement && !mainElement.innerText.contains("-")) {

          let val = mainElement.getAttribute("aria-label");
          let disabled = mainElement.getAttribute("aria-disabled");

          if (val.contains("parent")) {
            val = val.substring(7, val.length);
          }

          if (disabled && disabled == "true") {

            let query = "[aria-label=" + val + "]";
            let elements = document.querySelectorAll(query);
            let arrelements = Array.prototype.slice.call(elements);

            if (arrelements.length > 0) {
              //arrelements.shift();

              let displayValue = "none";

              if (arrelements[0]["style"]["display"] == "none" || arrelements[0]["style"]["display"] == "") {
                displayValue = "inline-flex";
              }

              for (var i = 0; i < arrelements.length; i++) {
                arrelements[i]["style"]["display"] = displayValue;
              }
            }
          }
        }
      }
    } catch (e) {
      //console.log(e);
    }

    var result = document.getElementsByClassName("mat-tab-label");
    let selected = 0;
    let nextSelected = 0;

    // get current selected tab
    Object.entries(result).forEach(([key, value]) => {
      if (value.classList.contains("mat-tab-label-active")) {
        selected = parseInt(key);
        this.selectedTabIndex = selected;
        this.utility.tabInformations = {};
        this.utility.tabInformations["tabId"] = selected;
      }
    });

  }

  changeTab(tabNumber) {

    if (this.model["tabInformations"]["tabId"] != 0) {
      var result = document.getElementsByClassName("mat-tab-label") as HTMLCollection;

      var evt = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });

      for (var i = 0; i < result.length; i++) {
        let cTabId = result[i]["innerText"];
        if (this.model["tabInformations"]["tabName"] == cTabId) {
          result[i].dispatchEvent(evt);
          return;
        }
      }

      result[0].dispatchEvent(evt);
    }
    /*
    if ((result.length - 1) > tabNumber) {
      result[tabNumber].dispatchEvent(evt);
    } else if (result.length > 0) {
      result[0].dispatchEvent(evt);
    }*/
  }

  goBack() {
    //if (this.mainForm.dirty) {

    if (this.dfms.wasInEditMode) {
      const dialogRef = this.dialog.open(SemConfirmDialogComponent, { data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.location.back();
        }

      });
    } else {
      this.location.back();
    }

    /*} else {
      this.location.back();
    }*/
  }

  /*@HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.mainForm.dirty) {

      const dialogRef = this.dialog.open(SemConfirmDialogComponent, { data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          return result;
        }

        return false;

      });

    } else {
      return true;
    }
  }*/

  isCyclic = (obj => {
    const keys = []
    const stack = []
    const stackSet = new Set()
    let detected = false

    const detect = ((object, key) => {
      if (!(object instanceof Object))
        return

      if (stackSet.has(object)) { // it's cyclic! Print the object and its locations.
        const oldindex = stack.indexOf(object)
        const l1 = `${keys.join('.')}.${key}`
        const l2 = keys.slice(0, oldindex + 1).join('.')
        //console.log(`CIRCULAR: ${l1} = ${l2} = ${object}`)
        //console.log(object)
        detected = true
        return
      }

      keys.push(key)
      stack.push(object)
      stackSet.add(object)
      Object.keys(object).forEach(k => { // dive on the object's children
        if (k && Object.prototype.hasOwnProperty.call(object, k))
          detect(object[k], k)
      })

      keys.pop()
      stack.pop()
      stackSet.delete(object)
    })

    detect(obj, 'obj')
    return detected
  })

  submit<T>(model): Observable<T> {

    this.loading = true;
    var clonedObject = _.cloneDeep(model);
    JsonUtil.isCyclic(clonedObject);

    //var stringify = require('json-stringify-safe');

    /*clonedObject = JSON.stringify( clonedObject, function( key, value) {
      if(clonedObject.isCyclic(value)){
        return null;
      }
      else {return value;}
    })*/

    //JsonUtil.isCyclic(clonedObject);

    // for now, we should find solution for cases like this 
    // (where clonedObject is very big object and has alot of properties or has property array with alot of items). In this case this code can break JS
    let skip: boolean = false;
    if (model["SerializationObjectFormLink"] == "3418-CompetitionSystemProposalForm" || model["SerializationObjectFormLink"] == "3407-CompetitionSystemForm") {
      skip = true;
    }

    if (!skip) {
      this.clearEmpties(clonedObject);
    }

    this.requestModel.url = "api/data";
    this.requestModel.data = { Envelope: clonedObject, Projection: null, ApplicationModelFullName: null };
    return this.restProviderService.getDataPOST(this.requestModel);
  }
  openVersion() {
    this.dialog.open(SemVersionDialogComponent, { width: '1135px', height: '883px', data: { DomainModelTypeFullName: this.domainModelTypeFullName, EntityId: this.model.Id } });
  }


  formSubmit() {

    this.showPopup = false;
    this.valid = true;

    // THIS IS ALREADY CALLED IN formSubmit() SO WE DONT NEED TO CALL AGAIN HERE
    // check if pre submit js	
    //if (this.utility.preFormSubmitJS != null && typeof this.utility.preFormSubmitJS != "undefined") {	
    //  // execute pre form submit logic      	
    //  this.utility.preFormSubmitJS = this.utility.preFormSubmitJS.replace(/\\/g, "");	
    //  let preResult = function (str) {	
    //    return eval(str);	
    //  }.call(this, this.utility.preFormSubmitJS);	
    //}

    // check if we need popup before save
    if (this.utility.openPopupBeforeSave) {

      this.showPopup = true;
      if (this.utility.openPopupBeforeSaveCondition != null && typeof this.utility.openPopupBeforeSaveCondition != "undefined" && this.utility.openPopupBeforeSaveCondition != "") {
        this.utility.openPopupBeforeSaveCondition = this.utility.openPopupBeforeSaveCondition.replace(/\\/g, "");
        eval(this.utility.openPopupBeforeSaveCondition);
      }

      if (this.showPopup) {
        const dialogRef = this.dialog.open(SemConfirmDialogComponent, { data: { type: DialogType.Confirmation, text: this.utility.openPopupBeforeSaveMessage } });
        dialogRef.afterClosed().subscribe(result => {

          if (result) {
            this.submitCall();
          }
        });
      } else {
        this.submitCall();
      }
    } else {
      this.submitCall();
    }

  }

  submitCall() {

    for (var i = 0; i < this.dfms.controlValuesForValidation.length; i++) {
      let propVal = this.dfms.controlValuesForValidation[i]["Value"];

      if (typeof propVal != "undefined" && propVal) {
        this.dfms.globalForm.controls[this.dfms.controlValuesForValidation[i]["Name"]]?.setValue(this.dfms.controlValuesForValidation[i]["Value"]);
        this.dfms.globalForm.controls[this.dfms.controlValuesForValidation[i]["Name"]]?.updateValueAndValidity();
      }
    }

    // check if pre submit js
    if (this.utility.preFormSubmitJS != null && typeof this.utility.preFormSubmitJS != "undefined") {
      // execute pre form submit logic      
      this.utility.preFormSubmitJS = this.utility.preFormSubmitJS.replace(/\\/g, "");
      let preResult = function (str) {
        return eval(str);
      }.call(this, this.utility.preFormSubmitJS);
    }

    // if this.valid already false (from preFormSubmitJS) then dont check form validity
    if (this.valid !== false) {
      this.valid = this.mainForm.valid;
    }

    this.mandatoryFields = [];

    if (this.valid) {
      this.mainForm.ngSubmit.emit();
    } else {

      // skipFormValidationCheck property is used so you can show custom error in preFormSubmitJS
      if (this.skipFormValidationCheck === true) {
        //console.log("error on pre submit JS logic");
        this.utility.reqStatus = ResponseStatus.Error;
        // this.utility.reqMessage should be set in preFormSubmitJS
      } else {
        this.checkFormValidation();
        this.utility.reqStatus = ResponseStatus.ErrorMandatoryFields;
      }



      this.utility.reqResponse = true;



      setTimeout(() => {
        this.utility.reqResponse = false;
      }, 5000);


    }
  }

  checkFormValidation() {
    this.utility.reqMessage = null;
    this.mandatoryFields = [];
    let cr = this.utility.getCurrentRoute();

    for (const name in this.dfms.globalForm.controls) {
      if (this.dfms.globalForm.controls[name].invalid) {
        this.mandatoryFields.push(cr.labels[name]);
      }
    }
  }

  clearEmpties(o) {
    for (var k in o) {
      if (!o[k] || typeof o[k] !== "object") {
        continue // If null or not an object, skip to the next iteration
      }

      for (var i = 0; i < this.clearedObjects.length; i++) {
        if (this.clearedObjects[i] == i) {
          continue;
        }
      }

      this.clearedObjects.push(o);
      // The property is an object
      this.clearEmpties(o[k]); // <-- Make a recursive call on the nested object
      if (Object.keys(o[k]).length === 0) {
        if (!(o[k].getMonth && typeof o[k].getMonth === "function")) {
          delete o[k]; // The object had no properties, so delete that property
        }

      }
    }

    return o;
  }

  fileUpload(): Observable<number> {
    this.fileUploadStandalone.files = this.fileUploadService.files;
    this.fileUploadService.files = [];
    return this.fileUploadStandalone.uploadFiles(this.model);
  }

  fileDelete(): Promise<any> {
    return this.fileUploadService.removeFiles();
  }

  getPrompt() {

    const dialogRef = this.dialog.open(SemConfirmDialogComponent, { width: '350px', height: '185px', data: { type: DialogType.Delete, text: this.translationsProviderService.translate('AreYouSureYouWantToDeleteThisRecord') } });
    return dialogRef.afterClosed();

  }

  delete(model) {
    this.requestModel.url = "api/data/delete/" + this.SerializationObjectFormLink + "/" + model["Id"];
    return this.restProviderService.getDataDELETE(this.requestModel);
  }

  resolvePropertyBinding(object, propertyPath) {

    if (typeof propertyPath != "undefined" && propertyPath != null) {
      let split = propertyPath.split(".");
      let obj = object;

      for (let i = 0; i < split.length; i++) {
        if (obj != null && typeof obj != "undefined") {
          obj = obj[split[i]];
        }
      }

      if (obj != null && typeof obj != "undefined") {
        return obj;
      }
    }

    return "";
  }

  formCopy() {
    this.model['IsFormCopy'] = true;
    this.utility.processNavigationUrl(window.location.pathname, this.model)
  }

  printWithQuery(reqModel: any) {
    console.log("Print init.");
    this.loading = true;

    var fModel = new RequestModel;
    fModel.data = JSON.parse(reqModel);

    //set additional model data
    console.log(fModel);
    fModel.data["Query"]["PageSize"] = 2000;
    //fModel.data["Query"]["RepositoryName"] = "nhibernate";
    fModel.data["Query"]["Projection"] = null;

    //lets call the report api
    fModel.contentType = "application/json";
    fModel.responseType = "application/octet-stream;base64 ";
    fModel.url = "api/report/generate";

    this.restProviderService.getDataPOST<string>(fModel).subscribe(data => {
      console.log(data);

      const linkSource = 'data:application/pdf;base64,' + data["Data"];
      const downloadLink = document.createElement("a");
      const fileName = this.datePipe.transform(Date.now(), "dd_MM_yyyy_hh_mm_ss") + "_report.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.loading = false;

    }, error => {
      console.log(error);
      this.loading = false;
    });
  }

  print(query: any, propertyPathForValue: any = null) {
    console.log("Print init.");
    this.loading = true;
    DynamicLoadingUtil.loader.showLoader = true;

    var fModel = new RequestModel;
    fModel.data = JSON.parse(query);
    if (propertyPathForValue != null && typeof propertyPathForValue != "undefined" && this.model != null && typeof this.model != "undefined" && this.model.Id != null && typeof this.model.Id != "undefined" && this.model.Id > 0) {

      fModel.data["Query"]["TreeQuery"]["FieldValue"] = this.resolvePropertyBinding(this.model, propertyPathForValue);
    } else {
      if (this.model != null && typeof this.model != "undefined" && this.model.Id != null && typeof this.model.Id != "undefined" && this.model.Id > 0) {
        fModel.data["Query"]["TreeQuery"]["FieldValue"] = this.model["Id"];
      } else {

        fModel.data["Query"]["TreeQuery"]["FieldValue"] = this.params.Id;
      }
    }



    fModel.contentType = "application/json";
    fModel.responseType = "application/octet-stream;base64 ";
    fModel.url = "api/report/generate";

    this.restProviderService.getDataPOST<string>(fModel).subscribe(data => {
      console.log(data);

      const linkSource = 'data:application/pdf;base64,' + data["Data"];
      const downloadLink = document.createElement("a");
      const fileName = this.datePipe.transform(Date.now(), "dd_MM_yyyy_hh_mm_ss") + "_report.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      DynamicLoadingUtil.loader.showLoader = false;
      this.loading = false;

    }, error => {
      console.log(error);
      DynamicLoadingUtil.loader.showLoader = false;
      this.loading = false;
    });

  }

  export(exportType: string) {

    this.loading = true;
    DynamicLoadingUtil.loader.showLoader = true;
    // get current search query from search service
    let searchQuery = this.searchService.getCurrentSearchQuery();
    // set projection same as current data grid display fields, we don't want to export any other additional data
    searchQuery["Projection"] = this.searchService.getCurrentGridDisplayFields();
    // execute export based on exportType and search query    
    this.exportServiceSubscriber = this.exportService.export(exportType, searchQuery).subscribe(data => {

      if (data != null && typeof data != "undefined") {

        const linkSource = "data:" + data["MimeType"] + ";base64," + data["Content"];
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = data["FileName"];
        downloadLink.click();
      }

      DynamicLoadingUtil.loader.showLoader = false;
      this.loading = false;
    }, error => {
      DynamicLoadingUtil.loader.showLoader = false;
      this.loading = false;
    });
  }

  addNew() {
    this.formName = this.translationsProviderService.translations["WebGalis_AddNew"];
  }

  isMenuOpened() {
  }

  loadFormActions(context, userSearchSetting: UserSearchSettingModel) {

    let request = new NavigationRequestModel;
    let rModel = new RequestModel

    rModel.url = "/api/navigation/getNodes";
    request.context = context;
    request.depth = 0;
    request.rootNode = null;

    if (typeof userSearchSetting != "undefined" && userSearchSetting != null) {
      request.UserSearchSetting = userSearchSetting;
    }

    if (typeof (this.SerializationObjectFormLink) != "undefined" && this.SerializationObjectFormLink != null && this.SerializationObjectFormLink.length > 0) {
      request.dynamicFormId = parseInt(this.SerializationObjectFormLink.split("-")[0]);

      rModel.data = request;

      this.restProviderService.serializer = new NavigationNodeSerializer;

      this.restProviderService.getDataPOST<NavigationResponseModel[]>(rModel).subscribe(
        data => {
          //console.log("POST Request is successful, FormActions ", data);
          /*if (this.utility.isFormExpicitEditMode() && this.dfms.isFormReadOnly) {
            this.formActions.push({ Title: this.translationsProviderService.translate("WebGalis_Edit"), Children: [], NavigationNodeRule: { ActionBody: "this.dfms.setFormAsReadOnly();", Condition: "this.dfms.isFormReadOnly == true && !this.checkIfInUserGroup(6) && (this.restProviderService.currentUser.Id == this.model.DocumentCreator.Id || this.checkIfInUserGroup(1))", RuleType: 1 } });
          }*/
          this.formActions = this.formActions.concat(data);
          this.formActions.sort((a, b) => a.Order > b.Order ? 1 : -1);

          // check if any of formaction is "bulk"checkIfInUserGroup
          // for now check this only on listing page
          if (userSearchSetting != null && typeof userSearchSetting != "undefined" && this.formActions != null && typeof this.formActions != "undefined") {
            for (let i = 0; i < this.formActions.length; i++) {
              if (this.findBulkAction(this.formActions[i]) == true) {
                // stop loop, only 1 "bulk" action needed
                break;
              }
            }
          }

        },
        error => {
          console.log("Error", error);
        }
      );
    }
  }


  redirectToSearchView(searchViewItem: any){
    if(searchViewItem && searchViewItem.ViewId){
      let currentUrl = this.router.url;
      const newViewUrl = this.utility.updateURLQueryParam(currentUrl, 'view', searchViewItem.ViewId);
      this.utility.processNavigationUrl(newViewUrl, null, true, true); 
    }
  }

  loadUserSearchViews(modelName: string) {

    let userService = this.injector.get(UserService);    

    userService.getUserSearchViews(modelName).subscribe({
      next: data => {
        this.searchViews = data;
        let currentViewIdInUrl = this.params["view"];

        if(currentViewIdInUrl){
          let indx = this.searchViews.findIndex(x => x.ViewId == currentViewIdInUrl);
          if(indx >= 0){
            this.currentSearchViewName = ": " + this.searchViews[indx].SearchViewName;
          }else{
            this.currentSearchViewName = "";
          }
        }else{
          this.currentSearchViewName = "";
        }        
      },
      error: error => { 
        this.searchViews = [];
        this.currentSearchViewName = "";
        console.log("error getting search views");
      }
    });
  }

  findBulkAction(navigationNode) {

    if (typeof navigationNode != "undefined" && navigationNode != null) {
      if (navigationNode.BulkAction == true) {
        // check if condition is true (otherwise bulk action will not be shown)
        var shown = this.getCondition(navigationNode);
        if (shown) {
          let actionService = this.injector.get(ActionsService);
          actionService.setCurrentBulkActionPresent(true);
          // stop loop, only 1 "bulk" action needed
          return true;
        }
      }

      if (navigationNode.Children != null && typeof navigationNode.Children != "undefined" && navigationNode.Children.length > 0) {
        for (var i = 0; i < navigationNode.Children.length; i++) {
          var val = this.findBulkAction(navigationNode.Children[i]);
          if (val) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getFilteredChildren(children) {

    var newFiltered = [];
    if (children != null && typeof children != "undefined") {
      for (var i = 0; i < children.length; i++) {
        var show = this.getCondition(children[i]);
        if (show) {
          newFiltered.push(children[i]);
        }
      }
    }

    return newFiltered;
  }

  getCondition(action) {
    try {
      if (action.NavigationNodeRule) {
        if (action.NavigationNodeRule.Condition != null && typeof action.NavigationNodeRule.Condition != "undefined" && action.NavigationNodeRule.Condition.length > 0) {
          return eval(action.NavigationNodeRule.Condition);
        }
      }

      return this.alwaysShow;
    } catch (e) {
      return false;
    }
  }

  // this method is for purposes of action with children (but in some cases children are hiden based on condition and in that case action is shown without children
  //- that action is useless in that case, so we dont need to show it)
  getActionFilteredChildrenCount(action) {

    let children = 0;

    if (action.Children == null || typeof action.Children == "undefined") {
      children = 0;
    } else {
      children = action.Children.length;
    }

    // if action is only set as parent and has no children then return 0
    if (action.IsOnlyParent == true && children == 0) {
      return 0;
    }

    // if action has no children show on UI
    if (children < 1) {
      return 1;
    }

    // if action has children show on UI only if at least on children can be shown (based on children condition)
    if (action.Children != null && typeof action.Children != "undefined" && action.Children.length > 0) {
      for (var i = 0; i < action.Children.length; i++) {
        var child = action.Children[i];
        if (child != null && typeof child != "undefined") {
          var show = this.getCondition(child);
          if (show == true) {
            return 1;
          }
        }
      }
    }

    return 0;
  }


  getActionBody(action) {

    let actionService = this.injector.get(ActionsService);

    if (action.NavigationNodeRule) {
      if ((typeof action.NavigationNodeRule.FormAction == "undefined" || !action.NavigationNodeRule.FormAction) && action.NavigationNodeRule.ActionBody) {
        eval(action.NavigationNodeRule.ActionBody);
        return;
      }
      //eval(action.NavigationNodeRule.ActionBody);
      // if form actions has parameter form linked then we should first show popup with parameter form
      if (action.NavigationNodeRule.FormAction.ParameterForm != null && typeof action.NavigationNodeRule.FormAction.ParameterForm != "undefined" && action.NavigationNodeRule.FormAction.ParameterForm.Id > 0) {
        var reportId = null;
        if (action.NavigationNodeRule.FormAction.ParameterForm.Report != null && typeof action.NavigationNodeRule.FormAction.ParameterForm.Report != "undefined") {
          reportId = action.NavigationNodeRule.FormAction.ParameterForm.Report.Id;
        }

        const dialogRef = this.dialog.open(SemParameterDialogComponent, {
          data: {
            parameterFormId: action.NavigationNodeRule.FormAction.ParameterForm.Id,
            reportId: reportId,
            customHeaderTitle: action.NavigationNodeRule.FormAction.ParameterForm.CustomHeaderTitle,
            customCssClass: action.NavigationNodeRule.FormAction.ParameterForm.CustomCssClass
          }
        });

        dialogRef.afterClosed().subscribe(returnData => {
          if (returnData != null && typeof returnData != "undefined") {
            // only call action if parameter popup was confirmed (not cancled - close button)
            if (returnData.Canceled == false) {
              var inputParametersModel = returnData["Model"];
              actionService.callAction(action.NavigationNodeRule.FormAction, this.model, null, null, this, action.BulkAction, inputParametersModel, action.NavigationNodeRule.FormAction.OnlyClientLogic, false, action.BulkExport, action.NavigationNodeRule.FormAction.SkipBulkActionApiMethod);
            }
          }
        });
      } else {
        // just call action without parameters form popup
        actionService.callAction(action.NavigationNodeRule.FormAction, this.model, null, null, this, action.BulkAction, null, action.NavigationNodeRule.FormAction.OnlyClientLogic, false, action.BulkExport, action.NavigationNodeRule.FormAction.SkipBulkActionApiMethod);
      }
    }

    return "";
  }

  setCollapsed(p_collapse) {
    this.collapse = p_collapse;
  }

  invokeAction(url, propertyName) {
    let stateModel = new StateModel;
    stateModel.Model = this.model;
    stateModel.Prop = propertyName;

    if (this.mainForm.dirty) {

      const dialogRef = this.dialog.open(SemConfirmDialogComponent, { width: '350px', height: '185px', data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.utility.processNavigationUrl(url, stateModel);
        }
      });

    } else {
      this.utility.processNavigationUrl(url, stateModel);
    }
  }

  getClass(condition) {
    if (eval("this." + condition) == true) {
      return "hide-tab";
    }

    return "";
  }

  checkIfInArray(prop: [], id: any) {
    return prop.some(el => el["Id"] === id);
  }

  /*checkIfInUserGroup(prop: [], id: any) {
    if (typeof prop != "undefined" && prop) {
      //return prop.some(el => el === id);
      for (var i = 0; i < prop.length; i++) {
        if (prop[i] == id) {
          return true;
        }
      }
    }
    return false;
  }*/

  checkIfInUserGroup(id: any) {
    let userService = this.injector.get(UserService);
    return userService.checkIfInUserGroup(id);
  }

  openCompareDialog(data: any) {
    let obj = { items: data, object: this.model };
    const dialogRef = this.dialog.open(SemCompareDialogComponent, { data: obj });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {

        let reqModel = new RequestModel;
        reqModel.data = { Envelope: this.model, InputParameters: { "IsModified": result["IsModified"] } };
        reqModel.url = "api/competitionsystem/confirmcsp";
        DynamicLoadingUtil.loader.showLoader = true;

        this.restProviderService.getDataPOST(reqModel).subscribe(data => {

          if (data["Metadata"] != null && typeof data["Metadata"] != "undefined" && data["Metadata"].ServerMessageMetadata != null && typeof data["Metadata"].ServerMessageMetadata != "undefined" && data["Metadata"].ServerMessageMetadata.MessageType == 2) {
            // ok envelope response but error type                                  
            this.loaderService.subject.next(1);
            DynamicLoadingUtil.loader.showLoader = false;
            this.utility.reqMessage = data["Metadata"].ServerMessageMetadata.Message;
            this.utility.reqStatus = ResponseStatus.Error;
            this.utility.reqResponse = true;
            this.loading = false;
          } else {
            this.utility.reqMessage = "Uspešno";
            this.utility.reqStatus = ResponseStatus.Ok;
            this.utility.reqResponse = true;
            this.loading = false;
            this.loaderService.subject.next(1);
            DynamicLoadingUtil.loader.showLoader = false;
            location.reload();
          }
        }, err => {
          this.loaderService.subject.next(2);
          this.utility.reqMessage = "Napaka. Preverite podatke.";
          this.utility.reqStatus = ResponseStatus.Error;
          this.utility.reqResponse = true;
          this.loading = false;
          DynamicLoadingUtil.loader.showLoader = false;
        });

      }
    });
  }

  /*getProp(prop, expression) {
     let value = "";
 
     if (expression) {
       let midValue = eval(expression);
       //let midValue = new Function(expression).call(this);
       value = midValue;
     }
     else {
       value = this.dfms.getValue(this.model, prop);
     }
 
     return value;
   }
 
   setProp(prop, value) {
     this.dfms.assign(this.model, prop, value, true);
   }*/

}

// must be same as serverside ServerMessageMetadataType
export enum ResponseStatus {
  Ok = 1,
  Error = 2,
  ErrorMandatoryFields = 3,
  Warning = 4
}

export enum DialogType {
  Delete = 1,
  Notification = 2,
  Confirmation = 3
}

export class JsonUtil {

  static isCyclic(json) {
    const keys = [];
    const stack = [];
    const stackSet = new Set();
    let detected = false;

    function detect(obj, key) {
      if (typeof obj !== 'object') {
        return;
      }

      if (stackSet.has(obj)) { // it's cyclic! Print the object and its locations.
        const oldIndex = stack.indexOf(obj);
        const l1 = keys.join('.') + '.' + key;
        const l2 = keys.slice(0, oldIndex + 1).join('.');
        //console.log('CIRCULAR: ' + l1 + ' = ' + l2 + ' = ' + obj);
        //console.log(obj);
        detected = true;
        obj = null;
        return;
      }

      keys.push(key);
      stack.push(obj);
      stackSet.add(obj);
      for (const k in obj) { // dive on the object's children
        if (obj.hasOwnProperty(k)) {
          detect(obj[k], k);
        }
      }

      keys.pop();
      stack.pop();
      stackSet.delete(obj);
      return;
    }

    function clearEmpties(obj, key) {
      if (typeof obj !== 'object') {
        return;
      }

      if (stackSet.has(obj)) { // it's cyclic! Print the object and its locations.
        const oldIndex = stack.indexOf(obj);
        const l1 = keys.join('.') + '.' + key;
        const l2 = keys.slice(0, oldIndex + 1).join('.');
        //console.log('CIRCULAR: ' + l1 + ' = ' + l2 + ' = ' + obj);
        //console.log(obj);
        detected = true;
        obj = null;
        return -1;
      }

      keys.push(key);
      stack.push(obj);
      stackSet.add(obj);
      for (const k in obj) { // dive on the object's children
        if (obj.hasOwnProperty(k)) {
          if (Object.keys(k).length === 0) {
            delete obj[k];
          } else {
            let res = clearEmpties(obj[k], k);
            if (res == -1) {
              /*obj[k] = _.cloneDeep(obj[k]);
              obj[k]["PenaltyExecution"] = _.cloneDeep(obj[k]["PenaltyExecution"]);
              obj[k]["PenaltyExecution"]["PenaltyData"] = null;*/
              obj[k] = null;
            }
          }
        }
      }

      keys.pop();
      stack.pop();
      stackSet.delete(obj);
      return;
    }

    //detect(json, 'obj');
    clearEmpties(json, 'obj');
    return detected;
  }

}
