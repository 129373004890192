import { Component, OnInit, Input, AfterContentInit, AfterViewInit, SimpleChanges } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';

@Component({
  selector: 'sem-multi-fields',
  templateUrl: './sem-multi-fields.component.html',
  styleUrls: ['./sem-multi-fields.component.css']
})
export class SemMultiFieldsComponent extends SemControlComponent implements OnInit {  

  _multiFields: any[] = [];    
  previousMultiFields: any[] = [];
  @Input() minWidth:string = "auto";
  @Input() multiFieldRootPropertyBinding: string;
  @Input() isReadOnly: boolean = false;

  @Input() set multiFields(value: any[]) {  
      this.previousMultiFields = this._multiFields;
      this._multiFields = value;
      this.clearProperties();    
  }

  get multiFields(): any[] {
    return this._multiFields;
  }
 
  super() {
  }

  getReadOnly(){
    if(this.isReadOnly != null && typeof this.isReadOnly != "undefined"){
      return this.isReadOnly;
    }
    return false;
  }
  
  clearProperties(){
    if(this.previousMultiFields != null && typeof this.previousMultiFields != "undefined"){
      for(let i = 0; i < this.previousMultiFields.length; i++){
        let fullPropertyPath = null;
        if(this.multiFieldRootPropertyBinding != null && typeof this.multiFieldRootPropertyBinding != "undefined" && this.multiFieldRootPropertyBinding.length > 0){
          fullPropertyPath = this.multiFieldRootPropertyBinding + "." + this.previousMultiFields[i].Binding;
        }else{
          fullPropertyPath = this.previousMultiFields[i].Binding;
        }

        this.dfms.assign(this.model, fullPropertyPath, null, true);    

        if(fullPropertyPath.endsWith("Linked")){
          this.dfms.assign(this.model, fullPropertyPath.replace("Linked","Numeric"), null, true);    
          this.dfms.assign(this.model, fullPropertyPath.replace("Linked","String"), null, true);    
        }
      }        
    }      
  }

  ngOnInit() {              
  }

  getProp(propertyPath) {       

    let fullPropertyPath = null;
    if(this.multiFieldRootPropertyBinding != null && typeof this.multiFieldRootPropertyBinding != "undefined" && this.multiFieldRootPropertyBinding.length > 0){
      fullPropertyPath = this.multiFieldRootPropertyBinding + "." + propertyPath;
    }else{
      fullPropertyPath = propertyPath;
    }

    return this.dfms.getValue(this.model, fullPropertyPath);
  }

  setProp(propertyPath, value) {            

    let fullPropertyPath = null;
    if(this.multiFieldRootPropertyBinding != null && typeof this.multiFieldRootPropertyBinding != "undefined" && this.multiFieldRootPropertyBinding.length > 0){
      fullPropertyPath = this.multiFieldRootPropertyBinding + "." + propertyPath;
    }else{
      fullPropertyPath = propertyPath;
    }

    this.dfms.assign(this.model, fullPropertyPath, value, true);    
  }

}
