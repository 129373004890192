import { Component, Input, OnInit } from '@angular/core';
import { GalleryModel } from '../../../../models/gallery-model/galleryModel';
import { RequestModel } from '../../../../models/request/request-model';
import { ActionsService } from '../../../../services/actions-service/actions.service';
import { RestProviderService } from '../../../../services/rest-provider/rest-provider.service';
import { ValueAccessorBase } from '../../shared/value-accessor/value-accessor-base';

@Component({
  selector: 'sem-gallery-control',
  templateUrl: './sem-gallery-control.component.html',
  styleUrls: ['./sem-gallery-control.component.css']
})
export class SemGalleryControlComponent extends ValueAccessorBase<string> implements OnInit {

  @Input() apiUrl:string = "";
  @Input() pagesize:any = 10;
  @Input() bindings:string[] = [];
  @Input() data:GalleryModel[] = [];

  super() { }
  
  ngOnInit() {
    this.sidebarMenuOpened = true;
    this.actionsService = this.injector.get(ActionsService);
    this.restProviderService = this.injector.get(RestProviderService);
  }

  ngAfterViewInit() {
  }

  ngOnChanges() {

    let reqModel:RequestModel = {
      url: this.apiUrl,
      data: {Envelope:{"PageSize":this.pagesize,"Bindings":this.bindings}},
      contentType: '',
      responseType: '',
      etag: null
    };

    this.restProviderService.getDataPOST(reqModel).subscribe((data:any)=>{
      this.data = data;
    },error=>{

    });
  }

  itemClick(gridItem){

  }

}
