import { Injectable, Input } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  @Input() preLoadEvent:any;
  
  constructor() { }
}
