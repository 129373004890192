import { Component, OnInit, Input, NgZone, ChangeDetectorRef, Injector, NgModuleRef, Compiler, Renderer2, ComponentFactoryResolver, ElementRef, KeyValueDiffers, ViewChild } from '@angular/core';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { SemBaseComponent } from '../../common/sem-base/sem-base.component';
import { RouteNavigationService } from '../../../services/dynamic-routes-loader/route-navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { FileUploadService } from 'src/app/services/file-upload-service/file-upload.service';
import { Observable } from 'rxjs';
import { MetaModelService } from '../../../services/metamodel/metamodel-provider.service';
import { UserService } from '../../../services/user/user-provider.service';
import { SearchService } from '../../../services/search/search.service';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';
import { NgForm, FormBuilder } from '@angular/forms';
import { ActionsService } from 'src/app/services/actions-service/actions.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { ParameterFormsService } from 'src/app/services/parameter-forms-service/parameter-forms.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { FormService } from '../../../services/form-service/form.service';
import { NgxPhotoEditorService } from 'ngx-photo-editor';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-sem-control',
  templateUrl: './sem-control.component.html',
  styleUrls: ['./sem-control.component.css']
})
export class SemControlComponent extends SemBaseComponent implements OnInit {

  //constructor(private _compiler: Compiler, private _injector: Injector, private _m: NgModuleRef<any>) { }
  constructor(
    public restProviderService: RestProviderService,
    public zone: NgZone,
    public changeDetection: ChangeDetectorRef,
    public _injector: Injector,
    public _m: NgModuleRef<any>,
    public _compiler: Compiler,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public renderer: Renderer2,
    public translationsService: TranslationsProviderService,
    public fileUploadService: FileUploadService,
    public metaModelService: MetaModelService,
    public userService: UserService,
    public searchService: SearchService,
    public componentFactoryResolver: ComponentFactoryResolver,
    public control: ElementRef,
    public formBuilder: FormBuilder,
    public actionsService: ActionsService,
    public dfms: DynamicFieldsManagerService,
    public utility: UtilityService,
    public parameterFormService: ParameterFormsService,
    public formService: FormService,
    public photoEditorService: NgxPhotoEditorService,
    public anglarHttpClient: HttpClient,
    public angLocation:Location) {

    super(_injector, router, route, dfms, utility, formService);
  }

  @Input() public title: string = "";
  @Input() model: any;
  @Input() fields: any;
  params: any;
  @Input() modelChangeNotifier: Observable<void>;
  @Input() form: NgForm;
  @Input() name: any;
  @Input() events: any;
  @Input() cssClass: any;
  @Input() isRequired: any;
  @Input() isReadOnly: any;
  @Input() condition: any;
  @Input() bindingProperty: string;
  @Input() showTooltip: any;
  @Input() tooltipTextMessage: string;
  @Input() readOnlyCondition: any;
  @Input() parentBindingProp: any;
  @Input() min: any;
  @Input() max: any;
  @Input() preJS: any;
  @Input() postJS: any;
  @Input() onValueChangeEval: any;
  @Input() placeholder: string = "";
  loading: boolean = false;

  @ViewChild('f', { read: NgForm, static: true }) mainViewForm: NgForm;

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.params = params;
      });

    this.isRequired = this.getAttribute('required');
  }

  checkTranslation(value: string) {
    return this.translationsService.translate(value);
  }

  getAttribute(atribute: string) {
    return this.control.nativeElement.getAttribute(atribute);
  }

  checkPlaceholder(initialValue = "") {
    /*if (this.checkForDisabled(this.getAttribute('name'))) {
      this.placeholder = 'WebGalis_FillOutAuto';
    }*/

    if (initialValue.length > 0 && this.placeholder.length == 0) {
      this.placeholder = initialValue;
    }

    return this.translationsService.translate(this.placeholder);
  }

  checkForDisabled(name: string) {

    if (typeof this.readOnlyCondition != "undefined" && this.readOnlyCondition) {
      if (this.readOnlyCondition.length > 0) {
        return eval(this.readOnlyCondition);
      }
    }

    /*if (typeof this.readOnlyCondition != "undefined" && this.readOnlyCondition) {
      this.readOnlyCondition = this.readOnlyCondition + " && this.dfms?.isFormReadOnly == true";
    }else{
      this.readOnlyCondition = "this.dfms?.isFormReadOnly == true";
    }*/

    if (name != null) {
      if (this.isReadOnly) {
        return this.isReadOnly;
      }

      for (var i = 0; i < this.dfms.manyToOneReadOnly.length; i++) {
        if (name.includes(this.dfms.manyToOneReadOnly[i])) {
          return true;
        }
      }

      if (name != null) {

        if (name.startsWith("model.")) {
          name = name.replace("model.", "");
        }
        if (name.startsWith("Title.")) {
          name = name.replace("Title.", "");
        }

        if (typeof this.parentBindingProp != "undefined" && this.parentBindingProp) {
          if (this.parentBindingProp != "Title") {

            if (this.parentBindingProp.startsWith("Title.")) {
              this.parentBindingProp = this.parentBindingProp.replace("Title.", "");
            }

            name = this.parentBindingProp + "." + name;
          }
        }

        for (var i = 0; i < this.dfms.metaModel.length; i++) {
          if (this.dfms.metaModel[i].W == 0 && this.dfms.metaModel[i].Path == name) {
            return true;
          }
        }

      }
    }

    /*let roCondition = "";

    if(this.dfms?.isFormReadOnly){
      roCondition = "dfms?.isFormReadOnly == true";
    }*/

  }

  getBindingPropertyName() {
    let name = this.getAttribute("name");
    if (name != null) {

      if (name.startsWith("model.")) {
        name = name.replace("model.", "");
      }
      if (name.startsWith("Title.")) {
        name = name.replace("Title.", "");
      }

      if (typeof this.parentBindingProp != "undefined" && this.parentBindingProp) {
        if (this.parentBindingProp != "Title") {

          if (this.parentBindingProp.startsWith("Title.")) {
            this.parentBindingProp = this.parentBindingProp.replace("Title.", "");
          }

          name = this.parentBindingProp + "." + name;
        }
      }

      return name;

    }
    return "";
  }

  getName() {
    try {
      if (typeof this.getAttribute('name') != "undefined" && this.getAttribute('name') != null) {
        return eval("this.form.controls['" + this.getAttribute('name') + "'].errors");
      }
      else if (typeof this.getAttribute('ng-reflect-name') != "undefined" && this.getAttribute('ng-reflect-name') != null) {
        return eval("this.form.controls['" + this.getAttribute('ng-reflect-name') + "'].errors");
      }
    } catch (e) {
      return null;
    }

    return null;
  }

  click() {
    if (this.events) {
      let clickEvent = this.events[0];
      eval(clickEvent.PreJs);
      if (typeof (clickEvent.Action) != "undefined" && clickEvent.Action != null && clickEvent.Action != "") {
        this.actionsService.executeAction(clickEvent.Action, this.model, this.model, null, null, this, null);
      }
      eval(clickEvent.PostJs);

    }
  }

  checkIfInArray(prop: [], id: any) {
    return prop.some(el => el["Id"] === id);
  }

  checkIfInUserGroup(id: any) {
    return this.userService.checkIfInUserGroup(id);
  }

  addToInlineSearchProps(item) {

    item.bindingProperty = this.getBindingPropertyName();

    if (this.searchService.splitSearchKeywords.filter(i => i.bindingProperty == item.bindingProperty).length == 0) {

      item.name = this.utility.getCurrentRoute().labels[this.getAttribute('name')];
      this.searchService.splitSearchKeywords.push(item);
      this.searchService.splitSearchEnabled = true;

      this.searchService.splitSearchS.next(undefined);
    }
  }

}
