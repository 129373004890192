import { Component, OnInit, Input } from '@angular/core';
import { SemTextInputComponent } from '../sem-text-input/sem-text-input.component';

import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';

@Component({
    selector: 'sem-rich-text-editor',
    templateUrl: './sem-rich-text-editor.component.html',
    styleUrls: ['./sem-rich-text-editor.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: SemRichTextEditorComponent, multi: true }
      ]
})
export class SemRichTextEditorComponent extends ValueAccessorBase<string> implements OnInit {
    public textFormat : any = "";

    super() {
    }

    ngOnInit() {
    }
    
}
