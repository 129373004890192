import { Component, OnInit, Input, AfterContentInit, NgZone, ChangeDetectorRef, AfterViewInit, AfterContentChecked, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { NG_VALUE_ACCESSOR, FormControl } from '../../../../../node_modules/@angular/forms';
import { RequestModel } from 'src/app/models/request/request-model';
import * as _ from "lodash";
import { Observable, of, Subject, Subscription } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';


@Component({
  selector: 'sem-drop-down-select',
  templateUrl: './sem-select.component.html',
  styleUrls: ['./sem-select.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemSelectComponent, multi: true }
  ]
})
export class SemSelectComponent extends ValueAccessorBase<any> implements OnInit, AfterViewInit {

  ngAfterViewInit() {

  }

  @Input() list: any[] = [];
  @Input() galregistryname: string;
  @Input() subBindingPropertyName: string;
  @Input() titleField: string;
  @Input() searchQueryFilter: any = {};
  @Input() type: any = 1;
  @Input() filterField: any = "Title";
  @Input() preLoad: any;
  @Input() useAsMultiField: boolean = false;
  @Input() autoSelectFirstEntry: boolean = false;
  @Input() clientUIControl: boolean = false;
  @Input() multiFieldRootPropertyBinding: string = "";
  @Input() multiFieldPropertyName: string = "";
  @Input() waitForFilteredValue: boolean = false;
  @Input() useNhibernate: boolean = false;
  @Input() filteredValueBinding: string = "";
  @Input() additionalProjection: any[] = [];
  @Input() customPageSize: any;
  @Input() defaultValue: any;
  @Input() onSelectPostJS: string = null;
  @Input() customDataApi: string = null;
  @Input() minWidth: string = "auto";
  @Input() autoSelectCondition: string = "";
  @Input() autoSelectConditionValue: string = "";
  @Input() isReadOnly: any;
  @Input() useGlobalModel: boolean = false;
  @Input() customDataApiInputParameters: any = null;
  @Input() autoSelectFirstEntryCondition: any = null;
  @Input() loadItemsOnModelLoad: any = null;
  @Input() placeholder: string = "";
  @Input() pageSize: any = 40;
  @Input() readOnlyCondition: any;
  @Input() useCustomApiCondition: string = "";

  itemsLoaded = false;
  _isDisabled: boolean;

  private additionalProjectionOriginal: any[] = [];

  @Output() itemSelected: EventEmitter<MatOption> = new EventEmitter<MatOption>();
  @ViewChild(MatSelect, { read: MatSelect, static: true }) matSelect: MatSelect;
  initialSet: boolean = false;
  selectedValueId: string;

  formModel: any;
  private loadedOnModelLoad = false;
  myControl = new FormControl();

  multiFields: any[] = [];
  filteredOptions: Observable<any[]>;
  loadingResults: boolean = false;
  req: Subject<void> = new Subject<void>();
  query = JSON.parse("{ \"MainQuery\": null, \"FieldQueries\": { }, \"FacetQueries\": { }, \"RequestedFacets\": null, \"Projection\": [], \"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"*\", \"FieldValue\": \"*\" }, \"ClassTypes\": [], \"Page\": \"1\", \"PageSize\": " + this.pageSize + ", \"RepositoryName\": \"lucene\", \"Form\": null, \"UseCache\": false, \"Facets\": [], \"SortFields\": [] }");
  //private subscription: Subscription;
  bindingPropertyName: string = "";
  private filteredValueSubs: any = null;
  private newSectionAddedObsrvProp: Subscription = null;
  id = 0;
  preSelectedEntity = null;
  private dropdownOptionsLoaded: boolean = false;
  autoselect = true;

  conditionApplied: boolean = false;
  conditionValue: any = null;

  valueRemoved: boolean = false;

  super() { }

  ngOnDestroy(): void {

    this.utility.newDropdownValueSelected(undefined, undefined);
    if (typeof this.filteredValueSubs != "undefined" && this.filteredValueSubs != null) {
      this.filteredValueSubs.unsubscribe();
    }

    if (this.newSectionAddedObsrvProp) {
      this.newSectionAddedObsrvProp.unsubscribe();
    }
  }

   getReadOnly() {
    if (this.isReadOnly != null && typeof this.isReadOnly != "undefined") {
      return this.isReadOnly;
    }    

    if(this.readOnlyCondition != null && typeof this.readOnlyCondition != "undefined" && this.readOnlyCondition.length > 0){

      let resultOfEval = eval(this.readOnlyCondition);

      if(resultOfEval == true){
        this.isReadOnly = true;
        return true;
      }else{
        this.isReadOnly = false;
      }
    }

    return false;
  }

  ngOnInit() {

    if (this.useNhibernate) {
      this.query = JSON.parse("{ \"MainQuery\": null, \"FieldQueries\": { }, \"FacetQueries\": { }, \"RequestedFacets\": null, \"Projection\": [], \"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"*\", \"FieldValue\": \"*\" }, \"ClassTypes\": [], \"Page\": \"1\", \"PageSize\": " + this.pageSize + ", \"RepositoryName\": \"nhibernate\", \"Form\": null, \"UseCache\": false, \"Facets\": [], \"SortFields\": [] }");
    }

    if (Object.prototype.toString.call(this.additionalProjection) === '[object Array]') {
      this.additionalProjectionOriginal = Object.assign([], this.additionalProjection);
    } else if (Object.prototype.toString.call(this.additionalProjection) === '[object String]') {
      var res = this.additionalProjection.toString().split(",");
      var array: any[] = [];
      for (var i = 0; i < res.length; i++) {
        array.push(res[i]);
      }
      this.additionalProjectionOriginal = Object.assign([], array);
    }


    if (typeof (this.type) == "undefined" || this.type == 0) {
      this.type = 1;
    }

    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.params = params;
      });

    if (typeof (this.filterField) == "undefined" || this.filterField == null || this.filterField == "") {
      this.filterField = "Title";
    }

    let name = this.getAttribute("name");
    if (name != null && typeof name != "undefined") {
      this.bindingPropertyName = name.replace("model.", "");
    }


    if (this.type == 1 || this.type == 3) {
      if (this.waitForFilteredValue == false) {
        // if default value specified load only this value
        // after first click on dropdown loadItems() will execute
        // also load default value on empty details page (if id specified we load actual value from db - based on response)        
        if (this.defaultValue != null && typeof this.defaultValue != "undefined" && this.defaultValue != "" && (this.params["id"] == null || typeof this.params["id"] == "undefined" || this.params["id"] == 0)) {
          this.loadItems(parseInt(this.defaultValue));
        } else {
          this.dropdownOptionsLoaded = true;
          this.loadItems();
        }
      }
    } else {
      // if default value specified on autocomplete load default value            
      // also load default value on empty details page (if id specified we load actual value from db - based on response)                
      if (this.defaultValue != null && typeof this.defaultValue != "undefined" && this.defaultValue != "" && (this.params["id"] == null || typeof this.params["id"] == "undefined" || this.params["id"] == 0)) {
        this.loadItems(parseInt(this.defaultValue));
      }

      if (this.checkForDisabled(this.getAttribute('name')) == true) {
        this.myControl.disable();
      }
    }

    // register listener 
    if (this.waitForFilteredValue != null && typeof this.waitForFilteredValue != "undefined" && this.waitForFilteredValue == true) {
      this.filteredValueSubs = this.utility.currentDropdownSelectedValue.subscribe(value => {

        if (typeof value != "undefined" && value != null
          && typeof value["SelectedDropDownBinding"] != "undefined" && value["SelectedDropDownBinding"] != null
          && typeof value["SelectedValue"] != "undefined" && value["SelectedValue"] != null) {

          // check if correct dropdown
          if (this.filteredValueBinding == value["SelectedDropDownBinding"]) {
            // if standard dropdown, fire search
            // if autocomplete we dont need to fire search because it will be fired when user clicks on dropdown
            if (this.type == 1 || this.type == 3) {
              if (this.defaultValue != null && typeof this.defaultValue != "undefined" && this.defaultValue != "" && (this.params["id"] == null || typeof this.params["id"] == "undefined" || this.params["id"] == 0)) {
                this.loadItems(parseInt(this.defaultValue));
              } else {
                this.loadItems();
              }
              //this.loadItems();
            }
          }
        }
      });
    }

    this.newSectionAddedObsrvProp = this.utility.newSectionAddedObsr.subscribe((val) => {
      if (typeof this.autoSelectCondition != "undefined" && this.autoSelectCondition && this.autoSelectCondition.length > 0) {
        try {
          var evalVal = eval("function doIt(model){" + this.autoSelectCondition + "} doIt(val);");

          if (typeof evalVal != "undefined" && evalVal && evalVal > 0) {
            this.id = evalVal;
            this.value = this.list[this.id];
          } else {
            this.id = 0;
            this.value = null;
          }
          this.changeDetection.detectChanges();
        }
        catch (e) {
          console.log("Error setting default value from autoselect condition", e);
        }
      }
    });

    this.restProviderService.modelValueChanged.subscribe(model => {
      if (typeof model != "undefined" && model && Object.keys(model).length > 0) {
        if (this.preSelectedEntity) {
          let currentVal = this.dfms.getValue(model, this.bindingPropertyName);
          if (typeof currentVal == "undefined" || !currentVal) {
            this.value = this.preSelectedEntity;
            this.changeDetection.detectChanges();
          }
        }

        if (this.type == 3) {

          let selectedVal = this.dfms.getValue(model, this.bindingPropertyName);

          if (typeof selectedVal != "undefined" && selectedVal) {
            for (var i = 0; i < this.list.length; i++) {
              if (this.list[i]["Id"] == selectedVal["Id"]) {
                this.list[i] = selectedVal;
              }
            }

            this.changeDetection.detectChanges();
          }
        } else if (this.type == 1 && model["Id"] > 0 && this.loadedOnModelLoad == false) {
          this.loadedOnModelLoad = true;

          // model data has been loaded 
          // if loadItemsOnModelLoad is true -> load items again
          if (this.loadItemsOnModelLoad != null && typeof this.loadItemsOnModelLoad != "undefined" && this.loadItemsOnModelLoad == true) {
            this.loadItems();
          }
        }


        if (typeof this.autoSelectCondition != "undefined" && this.autoSelectCondition && this.autoSelectCondition.length > 0) {
          try {
            this.conditionValue = eval("function doIt(model){" + this.autoSelectCondition + "} doIt(model);");
            if (this.type == 2) {
              this.loadAutoComplete("");
              this.loadedOnModelLoad = true;
            }
          }
          catch (e) {
            console.log("Error setting default value from autoselect condition", e);
          }

        }

        /*if (this.defaultValue != null && typeof this.defaultValue != "undefined" && this.defaultValue != "" && (this.params["id"] == null || typeof this.params["id"] == "undefined" || this.params["id"] == 0)) {
          this.loadItems(parseInt(this.defaultValue));
        } else {
          this.loadItems();
        }*/

        //this.loadItems();
        if (typeof this.autoSelectCondition != "undefined" && this.autoSelectCondition && this.autoSelectCondition.length > 0) {
          let currentVal = this.dfms.getValue(model, this.bindingPropertyName);
          if (typeof currentVal != "undefined" && currentVal) {
            this.value = currentVal;
            this.id = currentVal["Id"];
            this.changeDetection.detectChanges();
          }
        }
      }
      //This causes problems when large number of dropdowns in present on the form... since we do not have the scenario with initial load needed for autocomplete dropdown, we commented that out
      /*l["Id"] > 0 && this.loadedOnModelLoad == false){
        this.loadedOnModelLoad = true;
        this.loadAutoComplete("");
      }*/

    });

  }

  ngModelChangeEvent(ev) {

  }

  ordinalChange(ev) {

  }

  addSorting(query) {

    if (query != null) {
      var sorting = [];

      var classType = query["ClassType"];
      if (classType == null || typeof classType == "undefined" || classType == "") {
        classType = query["ClassTypes"][0];
      }

      sorting.push({
        "FieldName": "Id",
        "Direction": 1,
        "RootClassName": classType
      });

      query["SortFields"] = sorting;
    }

    return query;
  }

  loadItems(defaultValueId: number = 0) {

    if (this.predefinedValueEvent != "" && this.predefinedValueEvent != null && typeof this.predefinedValueEvent != "undefined") {
      this.id = parseInt(this.predefinedValueEvent);
    } else {
      this.id = 0;
    }

    if (defaultValueId > 0) {
      this.id = defaultValueId;
    } else {
      this.id = 0;
    }

    if (this.galregistryname.length > 0) {

      this.loadingResults = true;
      let defaultValueTreeQuery = null;

      if (defaultValueId > 0) {
        defaultValueTreeQuery = {
          "Type": "FieldValue",
          "FieldName": "Id",
          "FieldValue": "" + this.defaultValue
        };
      }

      // use custom delimiter ${ }
      _.templateSettings.interpolate = /\${([\s\S]+?)}/g;
      let formModel = {};

      if (this.model != null && typeof this.model != "undefined") {
        formModel = this.model;
      }

      if (Object.keys(formModel).length == 0 || this.useGlobalModel) {
        if (this.dfms.globalModel != null && typeof this.dfms.globalModel != "undefined") {
          formModel = this.dfms.globalModel;
        }
      }

      if (this.searchQueryFilter != null && typeof this.searchQueryFilter != "undefined" && Object.entries(this.searchQueryFilter).length !== 0 && this.searchQueryFilter.constructor !== Object) {

        try {

          let requestModel: RequestModel = new RequestModel();
          requestModel.url = "/api/registry/dropdown";
          requestModel.contentType = "application/json"
          let searchQ = Object.assign({}, this.query);//JSON.parse(this.searchQueryFilter);
          let interpolateError: boolean = false;
          // no need for inerpolate since we have default value tree query
          if (defaultValueTreeQuery == null) {
            try {
              // interpolate
              let compiled = _.template(this.searchQueryFilter);
              let compiledSearchQuery = compiled(formModel);
              if (compiledSearchQuery != null && typeof compiledSearchQuery != "undefined") {
                let interpolateMainSearchQueryObject = JSON.parse(compiledSearchQuery);
                searchQ["TreeQuery"] = interpolateMainSearchQueryObject;
              }
            } catch {
              interpolateError = true;
              //let searchQ = Object.assign({}, this.query);
            }

          } else {
            searchQ["TreeQuery"] = defaultValueTreeQuery;
          }

          if (!interpolateError) {
            searchQ["ClassType"] = this.galregistryname;
            searchQ["Projection"] = this.getProjection();

            if (this.customPageSize != null && typeof this.customPageSize != "undefined" && this.customPageSize > 0) {
              searchQ["PageSize"] = this.customPageSize;
            }


            searchQ = this.addSorting(searchQ);
            requestModel.data = searchQ;
            this.restProviderService.getDataPOST<any>(requestModel).subscribe(data => {
              this.zone.run(() => {
                let result = data["Models"];

                this.preSelectedEntity = null;

                // clear before setting new list from response
                this.list = [];
                for (var i = 0; i < result.length; i++) {
                  this.list = [...this.list, result[i]["Model"]];

                  if (this.id > 0) {
                    if (result[i]["Model"]["Id"] == this.id) {
                      this.preSelectedEntity = result[i]["Model"];
                    }
                  }
                }

                if (this.titleField == "" || this.titleField == null || typeof this.titleField == "undefined") {
                  this.titleField = "Title";
                  this.changeDetection.detectChanges();
                } else {
                  this.changeDetection.detectChanges();
                }
                this.itemsLoaded = true;

                // also check that we're not on details form (then value is based on entity)
                if (this.autoSelectFirstEntry && (this.clientUIControl || (this.params["id"] == null || typeof this.params["id"] == "undefined"))) {
                  if (this.list != null && typeof this.list != "undefined" && this.list.length > 0) {

                    this.autoselect = true;

                    if (this.autoSelectFirstEntryCondition != null && typeof this.autoSelectFirstEntryCondition != "undefined") {
                      // autoSelectFirstEntryCondition should set value of this.autoselect to true/false
                      let preResult = function (str) {
                        return eval(str);
                      }.call(this, this.autoSelectFirstEntryCondition);
                    }

                    if (this.autoselect === true) {
                      var value = this.list[0];
                      value["ObjectType"] = this.galregistryname;
                      this.value = value;
                    }

                  }
                }

                if (this.preSelectedEntity) {
                  this.value = this.preSelectedEntity;
                }

                if (this.value && this.type == 3) {
                  for (var i = 0; i < this.list.length; i++) {
                    if (this.list[i]["Id"] == this.value["Id"]) {
                      this.list[i] = this.value;
                    }
                  }
                }

                if (this.conditionValue != null) {
                  this.conditionApplied = true;
                  this.defaultValue = this.conditionValue;
                  //this.value = this.list[this.conditionValue];
                  this.value = this.list.filter(x=>x.Id == this.conditionValue)[0];
                  this.changeDetection.detectChanges();
                }
                this.loadingResults = false;
              });
            },
              error => {
                //console.log("Error", error);
                this.loadingResults = false;
              }
            );
          }else{	
            this.loadingResults = false;	
          }
        } catch (e) {
          this.loadingResults = false;	
        }
      } else {

        let requestModel: RequestModel = new RequestModel();
        requestModel.contentType = "application/json"

        let useCustomApi = true;
        if(this.useCustomApiCondition != null && typeof this.useCustomApiCondition != "undefined" && this.useCustomApiCondition.length > 0){
          useCustomApi = eval(this.useCustomApiCondition);
        }

        if (useCustomApi === true && this.customDataApi != null && typeof this.customDataApi != "undefined" && this.customDataApi.length > 0) {
          requestModel.url = this.customDataApi;

          if (this.customDataApiInputParameters != null && typeof this.customDataApiInputParameters != "undefined" && Object.keys(this.customDataApiInputParameters).length > 0) {
            try {
              // interpolate
              let compiled = _.template(this.customDataApiInputParameters);
              let compiledInputParams = compiled(formModel);
              if (compiledInputParams != null && typeof compiledInputParams != "undefined") {
                let compiledInputParamsObject = JSON.parse(compiledInputParams);
                requestModel.data = { "InputParameters": compiledInputParamsObject };
              }
            } catch {
            }
          }

        } else {
          requestModel.url = "/api/registry/dropdown";
          let searchQ = Object.assign({}, this.query);//JSON.parse(this.searchQueryFilter);
          searchQ["ClassType"] = this.galregistryname;

          searchQ["Projection"] = this.getProjection();

          if (this.customPageSize != null && typeof this.customPageSize != "undefined" && this.customPageSize > 0) {
            searchQ["PageSize"] = this.customPageSize;
          }


          if (defaultValueTreeQuery != null) {
            searchQ["TreeQuery"] = defaultValueTreeQuery;
          }

          searchQ = this.addSorting(searchQ);
          requestModel.data = searchQ;
        }

        this.restProviderService.getDataPOST<any>(requestModel).subscribe(data => {
          this.zone.run(() => {
            let result = data.Models;
            this.preSelectedEntity = null;
            // clear before setting new list from response
            this.list = [];
            for (var i = 0; i < result.length; i++) {
              this.list = [...this.list, result[i]["Model"]];

              if (this.id > 0) {
                if (result[i]["Model"]["Id"] == this.id) {
                  this.preSelectedEntity = result[i]["Model"];
                }
              }
            }

            if (this.titleField == "" || this.titleField == null || typeof this.titleField == "undefined") {
              this.titleField = "Title";
              this.changeDetection.detectChanges();
            } else {
              this.changeDetection.detectChanges();
            }
            this.itemsLoaded = true;

            // also check that we're not on details form (then value is based on entity)
            if (this.autoSelectFirstEntry && (this.clientUIControl || (this.params["id"] == null || typeof this.params["id"] == "undefined"))) {
              if (this.list != null && typeof this.list != "undefined" && this.list.length > 0) {


                this.autoselect = true;

                if (this.autoSelectFirstEntryCondition != null && typeof this.autoSelectFirstEntryCondition != "undefined") {
                  // autoSelectFirstEntryCondition should set value of this.autoselect to true/false
                  let preResult = function (str) {
                    return eval(str);
                  }.call(this, this.autoSelectFirstEntryCondition);
                }

                if (this.autoselect === true) {
                  var value = this.list[0];
                  value["ObjectType"] = this.galregistryname;
                  this.value = value;
                }


              }
            }

            if (this.preSelectedEntity) {
              this.value = this.preSelectedEntity;
            }

            if (this.value && this.type == 3) {
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i]["Id"] == this.value["Id"]) {
                  this.list[i] = this.value;
                }
              }
            }


            if (this.conditionValue != null) {
              this.conditionApplied = true;
              this.defaultValue = this.conditionValue;
              this.value = this.list.filter(x=>x.Id == this.conditionValue)[0];
              this.changeDetection.detectChanges();
            }

            this.loadingResults = false;
            this.changeDetection.detectChanges();

          });
        }, error => {
          this.list.push("Error retrieving data for dropdown");
          this.loadingResults = false;
        });
      }
    }
  }

  getProjection() {
    let projections = [];
    if (typeof this.filterField != undefined && this.filterField != null && this.filterField != "") {
      projections.push(this.filterField);
    }
    if (this.additionalProjectionOriginal != null && typeof this.additionalProjectionOriginal != "undefined" && this.additionalProjectionOriginal.length > 0) {
      var array = Object.assign([], this.additionalProjectionOriginal);
      for (var i = 0; i < array.length; i++) {
        if (typeof array[i] === 'string' || array[i] instanceof String) {
          projections.push(array[i]);
        }
      }
    }
    return projections;
  }

  clearValue(event) {

    let attName = this.getAttribute("name");

    let control = this.dfms.globalForm.controls[attName];

    if (control !== null && typeof control !== "undefined") {
      this.dfms.globalForm.controls[attName].setValue(null);
      this.dfms.globalForm.controls[attName].updateValueAndValidity();
    }    

    this.dfms.updateControlsValidationArray(attName,null);

    event.stopPropagation();
    this.valueRemoved = true;
    this.value = null;    
  }

  loadAutoComplete(searchTerm) {

    if (searchTerm.length == 0) {
      searchTerm = "*";
    }
    else {
      searchTerm = searchTerm + "*";
    }

    // use custom delimiter ${ }
    _.templateSettings.interpolate = /\${([\s\S]+?)}/g;

    let formModel = {};

    if (this.model != null && typeof this.model != "undefined") {
      formModel = this.model;
    }

    if (Object.keys(formModel).length == 0 || this.useGlobalModel) {
      if (this.dfms.globalModel != null && typeof this.dfms.globalModel != "undefined") {
        formModel = this.dfms.globalModel;
      }
    }

    let autocompleteTreeQuery = { Type: "FieldValue", FieldName: this.filterField, FieldValue: searchTerm }

    let requestModel: RequestModel = new RequestModel();
    requestModel.contentType = "application/json"
    let interpolateError: boolean = false;
    requestModel.url = "/api/registry/dropdown";
    let searchQ = Object.assign({}, this.query);
    searchQ["ClassType"] = this.galregistryname;
    searchQ["ClassTypes"] = [this.galregistryname];
    searchQ["Projection"] = this.getProjection();

    if (this.searchQueryFilter != null && typeof this.searchQueryFilter != "undefined" && Object.entries(this.searchQueryFilter).length !== 0 && this.searchQueryFilter.constructor !== Object) {
      try {
        // interpolate
        let compiled = _.template(this.searchQueryFilter);
        let compiledSearchQuery = compiled(formModel);

        if (compiledSearchQuery != null && typeof compiledSearchQuery != "undefined") {
          let interpolateMainSearchQueryObject = JSON.parse(compiledSearchQuery);

          var andQuery = {
            "Type": "AND",
            "SubQueries": []
          };

          andQuery["SubQueries"].push(interpolateMainSearchQueryObject);
          andQuery["SubQueries"].push(autocompleteTreeQuery);

          searchQ["TreeQuery"] = andQuery;
        }
      } catch {
        interpolateError = true;
        //searchQ["TreeQuery"] = autocompleteTreeQuery;
      }
    } else {
      searchQ["TreeQuery"] = autocompleteTreeQuery;
    }

    if (!interpolateError) {
      if (this.customPageSize != null && typeof this.customPageSize != "undefined" && this.customPageSize > 0) {
        searchQ["PageSize"] = this.customPageSize;
      }

      let useCustomApi = true;
      if(this.useCustomApiCondition != null && typeof this.useCustomApiCondition != "undefined" && this.useCustomApiCondition.length > 0){
        useCustomApi = eval(this.useCustomApiCondition);
      }

      if (useCustomApi === true && this.customDataApi != null && typeof this.customDataApi != "undefined" && this.customDataApi.length > 0) {
        requestModel.url = this.customDataApi;
        let inputParams = null;
        if (this.customDataApiInputParameters != null && typeof this.customDataApiInputParameters != "undefined" && Object.keys(this.customDataApiInputParameters).length > 0) {
          try {
            // interpolate
            let compiled = _.template(this.customDataApiInputParameters);
            let compiledInputParams = compiled(formModel);
            if (compiledInputParams != null && typeof compiledInputParams != "undefined") {
              let compiledInputParamsObject = JSON.parse(compiledInputParams);
              inputParams = compiledInputParamsObject;
            }
          } catch {
          }
        }

        requestModel.data = { "Envelope": { "SearchTerm": searchTerm, "Projection": this.getProjection(), "PageSize": this.customPageSize }, "InputParameters": inputParams };
      } else {
        searchQ = this.addSorting(searchQ);
        requestModel.data = searchQ;
      }

      this.restProviderService.getDataPOST<any>(requestModel).pipe(takeUntil(this.req)).subscribe(data => {
        this.zone.run(() => {
          let result = data["Models"];

          this.list = [];
          for (var i = 0; i < result.length; i++) {
            this.list = [...this.list, result[i]["Model"]];
          }

          //this.filteredOptions = of(this.list.map(value => this._filter(value)));

          if (this.titleField == "" || this.titleField == null || typeof this.titleField == "undefined") {
            this.titleField = "Title";
            this.changeDetection.detectChanges();
          } else {
            this.changeDetection.detectChanges();
          }

          /*if (searchTerm == "*") {
            this.filteredOptions = of(this.list);
          }*/
          this.filteredOptions = of(this.list);
          this.loadingResults = false;       

          if (this.conditionValue != null && (typeof this.value == "undefined" || !this.value)) {
            this.conditionApplied = true;
            this.defaultValue = this.conditionValue;
            //this.value = this.list[this.conditionValue];
            this.value = this.list.filter(x=>x.Id == this.conditionValue)[0];
            this.changeDetection.detectChanges();
          }

        });
      },
        error => {
          //console.log("Error", error);
          this.loadingResults = false;
        }
      );
    } else {
      this.loadingResults = false;
    }
  }

  compareFn(c1: any, c2: any): boolean {
    var res = c1 && c2 ? c1.Id === c2.Id : c1 === c2;

    return res;
  }

  compareFnR(c1: any, c2: any): boolean {
    var res = c1 && c2 ? c1.Id === c2.Id : c1 === c2;
    return res;
  }

  radioButtonChange(event) {
    let attName = this.getAttribute("name");

    let control = this.dfms.globalForm.controls[attName];

    if (control !== null && typeof control !== "undefined") {
      this.dfms.globalForm.controls[attName].setValue(event.source.value);
      this.dfms.globalForm.controls[attName].updateValueAndValidity();
    }        

    this.dfms.updateControlsValidationArray(attName,event.source.value);

    if(typeof this.onValueChangeEval != "undefined" && this.onValueChangeEval){
      eval(this.onValueChangeEval);
    }
  }

  radioChange(event) {

  }

  valueChanged() {
  }

  valueModified() {
    //console.log("Value modified: ", this.value);
  }

  //TODO: Leave this commented out, need to check if it is required for hiding of controls, or for seting them as readonly
  selectionChange(event: MatOptionSelectionChange) {

    /*this.autoSelectCondition = null;
    this.conditionValue = null;
    this.defaultValue = null;
    this.conditionApplied = false;*/

    let value = event.source.value;
    // set value to model
    //this.dfms.assign(this.model, this.bindingPropertyName, value, true);  
    let attName = this.getAttribute("name");

    let control = this.dfms.globalForm.controls[attName];

    if (control !== null && typeof control !== "undefined") {
      this.dfms.globalForm.controls[attName].setValue(event.source.value);
      this.dfms.globalForm.controls[attName].updateValueAndValidity();
    }            

    this.dfms.updateControlsValidationArray(attName,event.source.value);

    this.changeDetection.detectChanges();

    if (typeof value != "number" && typeof value != "string") {
      value["ObjectType"] = this.galregistryname;
    }

    this.itemSelected.emit(value);

    if (this.useAsMultiField) {
      this.processMultiField(value);
    }

    this.utility.newDropdownValueSelected(value, this.bindingPropertyName);

    // execute code after dropdown option is selected 
    if (this.onSelectPostJS != null && typeof this.onSelectPostJS != "undefined" && this.onSelectPostJS.length > 0) {
      let preResult = function (str) {
        return eval(str);
      }.call(this, this.onSelectPostJS);
    }

    setTimeout(() => {
      if (this.galregistryname.includes("PenaltyType")) {

        var result = document.getElementsByClassName("mat-tab-label");

        for (var i = 0; i <= result.length; i++) {
          // continue from selected on (ignore previous tabs)
          // check if not disabled      
          if (result[i]["innerText"].includes("-")) {
            result[i]["style"]["display"] = "none";
          };
        }
      }
    }, 50);
  }

  processMultiField(value) {
    // get fields based on selected item
    // send them to another control via event

    let requestModel: RequestModel = new RequestModel();
    requestModel.url = "/api/dynamicforms/multifields/definition";
    requestModel.contentType = "application/json"
    let reqDataModel = {
      "Id": value["Id"],
      "FullName": this.galregistryname,
      "MultiFieldPropertyName": this.multiFieldPropertyName
    };
    requestModel.data = reqDataModel;

    this.restProviderService.getDataPOST<any>(requestModel).subscribe(data => {
      this.zone.run(() => {
        if (data != null && typeof data != "undefined" && data.length > 0) {
          this.multiFields = data;
        }
      });
    },
      error => {
        //console.log("Error", error);
      }
    );
  }

  dropdownSelectClick() {
    // if defaultValue is specified then all items for dropdown are not loaded onInit (only default value)
    // here we load all dropdown options (only once)

    if (this.defaultValue != null && typeof this.defaultValue != "undefined" && this.defaultValue != "" && this.dropdownOptionsLoaded == false) {
      this.dropdownOptionsLoaded = true;
      this.loadItems();
    }
  }

  ddInitialSet(event: MatOptionSelectionChange) {

    if (!this.initialSet) {
      this.initialSet = true;
      if (event.source != null && typeof event.source != "undefined" && event.source.value != null && typeof event.source.value != "undefined" && this.useAsMultiField) {
        this.processMultiField(event.source.value);
      }
      //this.selectionChange(event);
    }

    for (var i = 0; i < this.dfms.metaModel.length; i++) {
      if (this.dfms.metaModel[i].W == 0 && "model." + this.dfms.metaModel[i].Path == this.getAttribute("name")) {
        this.matSelect.disabled = true;
        break;
      }
    }

    if (this.preSelectedEntity) {
      this.value = this.preSelectedEntity;
    }



  }

  private _filter(value) {

    let filterValue = "";

    if (typeof value === 'object') {
      let val = this.resolvePropertyBinding(value, this.titleField);
      filterValue = val.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }


    //if (!this.loadingResults) {
    this.loadingResults = true;
    this.req.next();
    this.loadAutoComplete(filterValue);
    //}

    return this.list.filter(option => this.resolvePropertyBinding(option, this.titleField).toLowerCase().includes(filterValue));

  }
  
  focusDropdown() {
    if (this.searchQueryFilter != null && typeof this.searchQueryFilter != "undefined" && Object.keys(this.searchQueryFilter).length > 2 && this.type == 1 && this.clientUIControl == false) {      
      this.loadItems();     
    }
  }

  displayFn(value) {
    // now you have access to 'this' 
    return value ? this.resolvePropertyBinding(value, this.titleField) : undefined;
  }

  getValue() {
    if (typeof (this.value) != "undefined" && this.value != null) {
      return this.resolvePropertyBinding(this.value, this.titleField)
    }
    return "";
  }

  optionSelected(option) {
    this.value = option;

    let attName = this.getAttribute("name");

    if (attName !== null && attName !== undefined)
    {
      let control = this.dfms.globalForm.controls[attName];

      if (control !== null && typeof control !== "undefined") {
        this.dfms.globalForm.controls[attName].setValue(option);
        this.dfms.globalForm.controls[attName].updateValueAndValidity();
      }          

      this.dfms.updateControlsValidationArray(attName, option);
    }    

    if (this.type == 2) {
      if (this.useAsMultiField) {
        this.processMultiField(option);
      }
      this.utility.newDropdownValueSelected(option, this.bindingPropertyName);
    }

    // execute code after autocomplete dropdown option is selected    
    if (this.onSelectPostJS != null && typeof this.onSelectPostJS != "undefined" && this.onSelectPostJS.length > 0) {
      let preResult = function (str) {
        return eval(str);
      }.call(this, this.onSelectPostJS);
    }

    this.itemSelected.emit(option);
  }

  onFocus() {
    if (!this.valueRemoved) {
      this.req.next();
      this.loadingResults = true;
      this.loadAutoComplete("");
    }
    this.valueRemoved = false;
    /*if (this.type == "2") {
      this.loadingResults = true;
      this.req.next();

      let value = "";

      if (typeof (this.myControl.value) != "undefined" && this.myControl.value != null) {
        value = this.myControl.value;
      }

      this.loadAutoComplete(value);
    } else {
      if (!this.preLoad) {
        this.loadItems();
      }
    }*/
  }

  onSearchChange(searchValue: string) {
    //if (!this.loadingResults) {
    this.req.next();
    this.loadingResults = true;
    this.loadAutoComplete(searchValue);
    //}
  }

  resolvePropertyBinding(object, propertyPath) {

    if (typeof propertyPath != "undefined" && propertyPath != null) {

      if (typeof object == "number"
        || typeof object == "string") {
        return object;
      }

      let split = propertyPath.split(".");
      let obj = object;

      for (let i = 0; i < split.length; i++) {
        if (obj != null && typeof obj != "undefined") {
          obj = obj[split[i]];
        }
      }

      if (obj != null && typeof obj != "undefined") {
        return obj;
      }
    }

    return "";
  }


}
