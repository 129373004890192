import { Component, OnInit, Input, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { SemBaseComponent } from '../../common/sem-base/sem-base.component';
import { retry } from '../../../../../node_modules/rxjs/operators';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { MetaModelService } from '../../../services/metamodel/metamodel-provider.service';
import { DynamicLoadingUtil } from '../../../shared/helpers/dynamic-loading-util';

@Component({
  selector: 'sem-dynamic-search-field',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sem-dynamic-search-field.component.html',
  styleUrls: ['./sem-dynamic-search-field.component.css']
})
export class SemDynamicSearchFieldComponent extends SemControlComponent implements OnInit, AfterViewInit {

  @Input() modelIndex: any;
  @Input() controlName: string;
  @Input() rangeType: string;
  @Input() label: string;
  @Input() isCheckBox: boolean;
  @Input() isRangeField: boolean;
  @Input() name: string;
  @Input() isDropDown: boolean;
  @Input() fullType: string;
  @Input() dropdownType: string;	
  @Input() dropdownPreFilter: string;

  templateType: string;
  preFilterQuery: any = {};

  super() {

  }

  ngOnInit() {
    super.ngOnInit();

    if(this.dropdownType != null && typeof this.dropdownType != "undefined"){	
      this.fullType = this.dropdownType;	
    }	
    if(this.dropdownPreFilter != null && typeof this.dropdownPreFilter != "undefined" && this.dropdownPreFilter.length > 0){	
      try{        	
        //this.preFilterQuery = JSON.parse(this.dropdownPreFilter);        	
      }catch{}	
    }

    if (this.rangeType == "time") {
      this.templateType = "timer_month";
    } else if (this.rangeType == "date") {
      this.templateType = "calendar_month";
    }
  }

  ngAfterViewInit(): void {
   
  }


  searchValueOnChange(value: any) {
    this.searchService.changeSearchValue(value);
  }

  changeCheckBoxState(item: any){
    if(item != null && typeof item != "undefined") {
      if(item.CheckBoxState == null || typeof item.CheckBoxState == "undefined" || item.CheckBoxState == "novalue") {
        // next click value is checked if checkbox is not checked
        item.CheckBoxState = "checked-true";
      }else if(item.CheckBoxState == "checked-true"){
        // if checkbox is currently checked then next value is checked-false
        item.CheckBoxState = "checked-false";
      } else if(item.CheckBoxState == "checked-false"){
        // if currently checkbox is checked on false then next state is no value        
        item.CheckBoxState = "novalue";
      }
    }
  }
}
