import { Component, OnInit, AfterViewInit, Input, ViewChild, Output, EventEmitter, KeyValueChanges, KeyValueDiffer } from '@angular/core';
import { ValueAccessorBase } from '../../shared/value-accessor/value-accessor-base';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SemDynamicControlComponent } from '../../shared/sem-dynamic-control/sem-dynamic-control.component';

@Component({
  selector: 'sem-bound-content',
  templateUrl: './sem-bound-tab-content.component.html',
  styleUrls: ['./sem-bound-tab-content.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemBoundTabContentComponent, multi: true }
  ]
})
export class SemBoundTabContentComponent extends ValueAccessorBase<any> implements OnInit, AfterViewInit {

  @Input() bindingPropertyName: any;
  @Input() matGroup: any;
  @Input() boundComponent: SemBoundTabContentComponent;
  @Input() index;
  @Input() contentPropertyName;
  @Input() forwardEntity;

  @ViewChild("dynComp", { static: true }) dynComp: SemDynamicControlComponent;

  ngAfterViewInit() {
  }

  super() { }

  ngOnInit() {

    /*if(typeof(this.model) == "undefined" || this.model == null){
      this.model = {};
    }*/

    if (typeof this.forwardEntity[this.contentPropertyName] != "undefined" && this.forwardEntity[this.contentPropertyName]) {
      if (typeof this.model != "undefined" && this.model) {
        //var clonedProp = _.cloneDeep(this.forwardEntity)
        //clonedProp["PenaltyType"] = this.forwardEntity["PenaltyType"];
        this.dfms.assign(this.model, "PenaltyData", this.forwardEntity, true);
      } else {
        //var clonedProp = _.cloneDeep(this.forwardEntity)
        //clonedProp["PenaltyType"] = this.forwardEntity["PenaltyType"];
        this.model = this.forwardEntity[this.contentPropertyName];
        this.dfms.assign(this.model, "PenaltyData", this.forwardEntity, true);
      }
    }
    else {
      if (typeof this.model == "undefined" || this.model == null) {
        this.model = {};
        this.forwardEntity[this.contentPropertyName] = this.model;
        this.model["PenaltyData"] = this.forwardEntity;//_.cloneDeep(this.forwardEntity);
      }else{
        this.model["PenaltyData"]["PenaltyType"] = this.forwardEntity["PenaltyType"];
      }
    }

    this.restProviderService.modelValueChanged.subscribe(model => {

      //console.log("BoundTab RS model changed: ", model);

      /*if (typeof (this.dynComp.cmpRef) != "undefined" && this.dynComp.cmpRef != null) {
        if (typeof (this.dynComp.cmpRef.instance) != "undefined" && this.dynComp.cmpRef.instance != null) {
          let cModel = { Envelope: this.dynComp.cmpRef.instance.model };
        }
      }*/

    });

  }


}