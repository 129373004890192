import { Directive, Input, OnInit } from '@angular/core';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';

@Directive({
  selector: '[formReadOnly]'
})
export class FormReadOnlyDirective implements OnInit {

  @Input() formReadOnlyValue: any;

  constructor(public dfms:DynamicFieldsManagerService) { }

  ngOnInit() {

    if(this.formReadOnlyValue == "true"){
      this.dfms.isFormReadOnly = true;
    }
  }

}
