import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER, Injectable, Component, Compiler, ErrorHandler } from '@angular/core';
import { RouteNavigationService, configServiceFactory } from './services/dynamic-routes-loader/route-navigation.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SemNavigationModule } from '../app/navigation/modules/sem-navigation/sem-navigation.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, UrlSerializer } from '@angular/router';
import { SemDynamicComponent } from './shared/components/sem-dynamic/sem-dynamic.component';
import { CustomUrlSerializer } from './shared/helpers/custom-url-serializer';
import { SemLoaderComponent } from './shared/components/sem-loader/sem-loader.component';
import { SemDateFormatPipePipe } from './shared/helpers/pipes/sem-date-format-pipe.pipe';
import { SemDashboardContainerComponent } from './components/containers/sem-dashboard-container/sem-dashboard-container.component';
import { NavigationRequestModel } from './models/navigation/navigation-request-model';
import { ErrorsHandler } from './errorshandler';
import { ServerErrorsInterceptor } from './server-errors.interceptor';
import { SemNotFoundComponent } from './components/containers/sem-not-found/sem-not-found.component';
import { SemConfirmDialogComponent } from './components/controls/dialogs/sem-confirm-dialog/sem-confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SemSearchFieldsDialogComponent } from './components/controls/dialogs/sem-search-fields-dialog/sem-search-fields-dialog.component';
import { SemGridDisplayFieldsDialogComponent } from './components/controls/dialogs/sem-grid-display-fields-dialog/sem-grid-display-fields-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WindowRefService } from './services/window-ref.service';
import { NotificationCenterComponent } from './components/containers/notification-center/notification-center/notification-center.component';
import { ContainerWrapperComponent } from './components/containers/container-wrapper/container-wrapper/container-wrapper.component';
import { SemSharedModule } from './shared/modules/sem-shared/sem-shared.module';
//import { NgSelectComponent } from '@ng-select/ng-select';
import { SemVersionDialogComponent } from './components/controls/dialogs/sem-version-dialog/sem-version-dialog.component';
import { SemParameterDialogComponent } from './components/controls/dialogs/sem-parameter-dialog/sem-parameter-dialog.component';
import { AuthGuardService } from './services/guards/auth/auth-guard.service';
import { SemCompareDialogComponent } from './components/controls/dialogs/sem-compare-dialog/sem-compare-dialog.component';
import { SemActionComponent } from './components/controls/sem-action/sem-action.component';
import { WorkflowCenterComponent } from './components/containers/workflow-center/workflow-center.component';
import { SemUserProfileDialogComponent } from './components/controls/dialogs/sem-user-profile-dialog/sem-user-profile-dialog.component';
import { SemMinMaxDirective } from './directives/validators/sem-min-max.directive';
import { LoginComponent } from './components/login-views/login-v1/login.component';
import { LoginV2Component } from './components/login-views/login-v2/login-v2.component';
import { PlatformRedirectComponent } from './platform-redirect/platform-redirect.component';
import { environment } from '../environments/environment';
import { LoginV3Component } from './components/login-views/login-v3/login-v3.component';
import { SemFileExplorerDialogComponent } from './components/controls/dialogs/sem-file-explorer-dialog/sem-file-explorer-dialog.component';
import {NgxPhotoEditorModule} from "ngx-photo-editor";
//import { MatTooltipModule } from '@angular/material/tooltip';

import localeSl from '@angular/common/locales/sl';
import { SemViewSettingsDialogComponent } from './components/controls/dialogs/sem-view-settings-dialog/sem-view-settings-dialog.component';

// Register the Slovenian locale data
registerLocaleData(localeSl);

@NgModule({
  declarations: [
    AppComponent,
    SemDynamicComponent,
    SemDateFormatPipePipe,
    SemDashboardContainerComponent,
    SemNotFoundComponent,
    SemConfirmDialogComponent,
    SemConfirmDialogComponent,
    SemSearchFieldsDialogComponent,
    SemGridDisplayFieldsDialogComponent,
    NotificationCenterComponent,
    ContainerWrapperComponent,
    SemVersionDialogComponent,
    SemParameterDialogComponent,
    SemCompareDialogComponent,
    WorkflowCenterComponent,
    SemUserProfileDialogComponent,
    SemMinMaxDirective,
    LoginComponent,
    LoginV2Component,
    PlatformRedirectComponent,
    LoginV3Component,
    SemFileExplorerDialogComponent,
    SemViewSettingsDialogComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    MatDialogModule,
    DragDropModule,
    SemNavigationModule,
    //MatTooltipModule,
    SemSharedModule,
    NgxPhotoEditorModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'account/login', pathMatch: 'full' },
      {
        path: "",
        component: AppComponent,
        data: {
          breadcrumb: 'main'
        }
      },
      {
        path: "platform",
        component: PlatformRedirectComponent
      },
      {
        path: "account/login",
        component: environment.loginComponent,
        data: { breadcrumb: 'login' }
      },      
      {
        path: "cms",
        component: ContainerWrapperComponent,
        data: { 
          breadcrumb: 'main' 
        },
        children: [
          {
            path: 'notificationcenter',
            component: NotificationCenterComponent,
            canActivate: [AuthGuardService],
            data: {
              breadcrumb: 'galis_js_ActionCenter'
            },
          },
          {
            path: 'workflowcenter',
            component: WorkflowCenterComponent,
            canActivate: [AuthGuardService],
            data: {
              breadcrumb: 'galis_js_ActionCenter'
            },
          },
        ]
      }
    ], { onSameUrlNavigation: 'reload' }
    )
  ],
  providers: [RouteNavigationService, SemLoaderComponent, SemDateFormatPipePipe, WindowRefService,
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [Injector, RouteNavigationService], multi: true
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer
    },
    NavigationRequestModel,
    /*{
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },*/
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true
    }
  ],
  exports: [
    SemConfirmDialogComponent,
    SemSearchFieldsDialogComponent,
    //MatTooltipModule,
    SemGridDisplayFieldsDialogComponent,
    DragDropModule,
    SemSharedModule,
    NgxPhotoEditorModule,
    SemParameterDialogComponent,
    SemCompareDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
