
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { ChatItem, ItemFunctionalType, ItemType } from '../../../models/chat/ChatItem';
import { RequestModel } from '../../../models/request/request-model';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'sem-chat-bot',
  templateUrl: './sem-chat-bot.component.html',
  styleUrls: ['./sem-chat-bot.component.css']
})
export class SemChatBotComponent extends ValueAccessorBase<string> implements OnInit, AfterViewInit {

  chatItems: ChatItem[] = [];
  lastChatItemDetectedCommand: ChatItem | null = null;
  //userInput: string = 'wgcmd search type=inventory keyword="smuči"';
  userInput: string = '';
  isChatLoading: boolean = false;

  super() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  showChatLoader() {
    this.isChatLoading = true;
  }

  hideChatLoader() {
    this.isChatLoading = false;
  }

  confirmDetectedCommand(chatItem: ChatItem, showPotrjujem: boolean = true) {

    var chatRequestModel: RequestModel = new RequestModel
    {

    };

    chatRequestModel.url = "/api/wgcmd";

    chatRequestModel.data = {
      userConfirmCmd: true,
      userInput: chatItem.text
    };

    if (showPotrjujem) {
      var newItem: ChatItem = {
        id: this.chatItems.length + 1,
        text: "POTRJUJEM",
        itemType: ItemType.UserInput,
        itemFunctionalType: ItemFunctionalType.Text,
        redirectUrl: null,
        response: null
      };

      this.chatItems.push(newItem);
    }

    setTimeout(() => {
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }, 0);

    this.showChatLoader();

    this.restProviderService.getDataPOST<any>(chatRequestModel).subscribe({
      next: res => {

        if (res.Success) {

          this.processCommandResponseData(res);

        } else {
          var newItem: ChatItem = {
            id: this.chatItems.length + 1,
            text: res.Errors[0],
            itemType: ItemType.ResponseMessage,
            itemFunctionalType: ItemFunctionalType.Error,
            redirectUrl: null,
            response: res
          };
          this.chatItems.push(newItem);
        }
        this.hideChatLoader();
        setTimeout(() => {
          window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        }, 0);
      }
    });
  }

  redirectToAddedRecord(chatItem: ChatItem) {
    this.utility.processNavigationUrl(chatItem.redirectUrl);
  }

  cancelDetectedCommand(chatItem: ChatItem) {
    alert("Cancel:" + chatItem.text);
  }

  openItem(id) {
    var redirectUrl = "/cms/galisweb/item?id=" + id;
    this.utility.processNavigationUrl(redirectUrl);
  }

  processCommandResponseData(res: any) {
    if (res.ParsedCommand.Action == "add") {

      var newItem: ChatItem = {
        id: this.chatItems.length + 1,
        text: "Uspešno dodan zapis " + res.ExecutionResponseData.Model.InvNumber + ".",
        itemType: ItemType.ResponseMessage,
        itemFunctionalType: ItemFunctionalType.OpenRecord,
        redirectUrl: "/cms/galisweb/item?id=" + res.ExecutionResponseData.Model.Id,
        response: res
      };
      this.chatItems.push(newItem);
    }

    if (res.ParsedCommand.Action == "search") {
      var newItem: ChatItem = {
        id: this.chatItems.length + 1,
        text: "Najdeno je bilo " + res.ExecutionResponseData.TotalCount + " zapisov.",
        itemType: ItemType.ResponseMessage,
        itemFunctionalType: ItemFunctionalType.SearchResult,
        redirectUrl: "",
        response: res
      };
      this.chatItems.push(newItem);
    }
  }



  sendUserInput(event) {

    if(this.userInput=="cls"){
      this.chatItems = [];
      this.userInput = "";
      return;
    }

    var newItem: ChatItem = {
      id: this.chatItems.length + 1,
      text: this.userInput,
      itemType: ItemType.UserInput,
      itemFunctionalType: ItemFunctionalType.Text,
      redirectUrl: null,
      response: null,
    };

    this.chatItems.push(newItem);



    setTimeout(() => {
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }, 0);


    var chatRequestModel: RequestModel = new RequestModel
    {

    };

    chatRequestModel.url = "/api/wgcmd";

    chatRequestModel.data = {

      userInput: this.userInput
    };


    this.userInput = "";


    this.showChatLoader();


    this.restProviderService.getDataPOST<any>(chatRequestModel).subscribe({
      next: res => {

        if (res.Success) {


          if (res.ExecutionResponseData && res.ParsedCommand) {
            this.processCommandResponseData(res);
          } else {

            if (res.DetectedCommand && res.DetectedCommand.startsWith("wgcmd search")) {


              var detectedSearchItem: ChatItem = {
                id: this.chatItems.length + 1,
                text: res.DetectedCommand,
                itemType: ItemType.ResponseMessage,
                itemFunctionalType: ItemFunctionalType.WgCmdConfirmation,
                redirectUrl: null,
                response: res
              };

              this.confirmDetectedCommand(detectedSearchItem, false);

              return;
            }

            var newItem: ChatItem = {
              id: this.chatItems.length + 1,
              text: res.Message,
              itemType: ItemType.ResponseMessage,
              itemFunctionalType: ItemFunctionalType.Text,
              redirectUrl: null,
              response: res
            };

            this.chatItems.push(newItem);

            if (res.DetectedCommand) {

              var newItem: ChatItem = {
                id: this.chatItems.length + 1,
                text: res.DetectedCommand,
                itemType: ItemType.ResponseMessage,
                itemFunctionalType: ItemFunctionalType.WgCmdConfirmation,
                redirectUrl: null,
                response: res
              };

              this.lastChatItemDetectedCommand = newItem;

              this.chatItems.push(newItem);
            }

          }

        } else {
          var newItem: ChatItem = {
            id: this.chatItems.length + 1,
            text: res.Errors[0],
            itemType: ItemType.ResponseMessage,
            itemFunctionalType: ItemFunctionalType.Error,
            redirectUrl: null,
            response: res
          };
          this.chatItems.push(newItem);
        }

        this.hideChatLoader();

        setTimeout(() => {
          window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        }, 0);

      }
    });
  }




}
