import { RootModel } from '../root-model';
import { Injectable } from "@angular/core";
import { FacetViewModel } from './facet-view.model';

@Injectable()
export class ProjectionFacetWrapperModel {
    
    public projection: string[];
    public facets: FacetViewModel[];

}
