import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuTypePipe',
  pure: false
})
export class MenuTypePipe implements PipeTransform {
  transform(items: any[], filter: number): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    if (filter == 1) {
      return items.filter(item => item.Type == 1 || item.Type == 3);
    } else {
      return items.filter(item => item.Type == filter);
    }
  }
}
