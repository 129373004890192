import { Injectable } from '@angular/core';
import { CanActivate } from 'src/app/interfaces/can-activate/can-activate';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestProviderService } from '../../rest-provider/rest-provider.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
 
  constructor(private _router:Router, private restService:RestProviderService ) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {

      //check some condition  
      if (this.restService.getToken() == '')  {
          //redirect to login/home page etc
          //return false to cancel the navigation
          //this._router.navigateByUrl("/account/login");
          window.location.href = '/account/login';
          return false;
      } 
      return true;
  }

}
