import { Component, OnInit, Injector, ChangeDetectorRef, inject } from '@angular/core';
import { SemBaseComponent } from '../sem-base/sem-base.component';
import { NavigationNodeModel } from '../../../models/navigation/navigation-node-model';
import { NavigationRequestModel } from '../../../models/navigation/navigation-request-model';
import { NavigationResponseModel } from '../../../models/navigation/navigation-response-model';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { RequestModel } from '../../../models/request/request-model';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { RouteNavigationService } from '../../../services/dynamic-routes-loader/route-navigation.service';
import { SemDynamicComponent } from '../../../shared/components/sem-dynamic/sem-dynamic.component';
import { Location } from '../../../../../node_modules/@angular/common';
import { SemDateFormatPipePipe } from '../../../shared/helpers/pipes/sem-date-format-pipe.pipe';
import { FileUploadService } from 'src/app/services/file-upload-service/file-upload.service';
import { MatDialog } from '@angular/material/dialog';
import { ExportService } from 'src/app/services/export/export.service';
import { SearchService } from 'src/app/services/search/search.service';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { ActionsService } from 'src/app/services/actions-service/actions.service';
import { LoaderServiceService } from 'src/app/services/loader-service/loader-service.service';
import { LocalStorageService } from '../../../services/local-storage-service/local-storage.service';
import { FormService } from '../../../services/form-service/form.service';
import { FileUploadStandaloneService } from '../../../services/file-upload-standalone/file-upload-standalone.service';

declare var navigationPreFunctions: any;
declare var navigationPostFunctions: any;

@Component({
  selector: 'app-sem-navigation',
  templateUrl: './sem-navigation.component.html',
  styleUrls: ['./sem-navigation.component.css']
})
export class SemNavigationComponent extends SemDynamicComponent implements OnInit {

  restProvider: RestProviderService;
  url: string;
  model: RequestModel;
  request: NavigationRequestModel;
  result: NavigationResponseModel[];

  constructor(public injector: Injector, public router: Router, public changeDet: ChangeDetectorRef) {
    super(
        injector,
        injector.get(RestProviderService),
        injector.get(RequestModel), 
        injector.get(Router), 
        injector.get(ActivatedRoute), 
        injector.get(Location), 
        injector.get(SemDateFormatPipePipe), 
        injector.get(FileUploadService),
        injector.get(MatDialog),
        injector.get(ExportService),
        injector.get(SearchService),
        injector.get(TranslationsProviderService),
        injector.get(ChangeDetectorRef),
        injector.get(LoaderServiceService),
        injector.get(ActionsService),
        injector.get(LocalStorageService),
        injector.get(FormService),
        injector.get(FileUploadStandaloneService));
    this.restProvider = injector.get(RestProviderService);
    this.model = injector.get(RequestModel);
    this.request = injector.get(NavigationRequestModel);
  }

  ngOnInit() {
  }

  preJS(item: any, functionName: string) {
    if (typeof navigationPreFunctions[functionName] != "undefined") {
      let preResult = navigationPreFunctions[functionName](item);

      if (typeof preResult != "undefined" && preResult != null) {

        //return apiCall(preResult);

      }

    }

    return null;

  }

  postJS(item: any, functionName: string) {
    if (typeof navigationPostFunctions[functionName] != "undefined") {
      let postResult = navigationPostFunctions[functionName](item);

      if (typeof postResult != "undefined" && postResult != null) {

        //return this.apiCall(postResult);

      }

    }

    return null;

  }

}
