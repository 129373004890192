import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SemTextInputComponent } from '../sem-text-input/sem-text-input.component';
import {
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';

import * as moment from 'moment';


@Component({
  selector: 'sem-time-picker',
  templateUrl: './sem-time-picker.component.html',
  styleUrls: ['./sem-time-picker.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemTimePickerComponent, multi: true }
  ]
})
export class SemTimePickerComponent extends ValueAccessorBase<string> implements OnInit {

  @Input() fields: any;
  bindingPropertyName: string="";  

  super() {}

  ngOnInit() {

    /*let name = this.getAttribute("name");
    if (name != null && typeof name != "undefined") {
      this.bindingPropertyName = name.replace("model.", "");
    }

    this.restProviderService.modelValueChanged.subscribe(model =>{
      let selectedVal = this.dfms.getValue(model, this.bindingPropertyName);
      if(selectedVal != null && selectedVal != "undefined")
      {
        this.value = "19:30";
      }
    });*/

    super.ngOnInit();
  }

  onChange(){
    var t = this.value;
  }

  onTextChange(value){
    
    var t = this.value;
    let attName = this.getAttribute("name");

    let control = this.dfms.globalForm.controls[attName];

    if (control !== null && typeof control !== "undefined") {
      this.dfms.globalForm.controls[attName].setValue(value);
      this.dfms.globalForm.controls[attName].updateValueAndValidity();
    } 
    
  }

  getValue(){
    if(moment(this.value, moment.ISO_8601, true).isValid()){
      return moment(this.value).format("HH:mm").toString();
    }

    return this.value;
  }
}
