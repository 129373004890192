import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sem-button',
  templateUrl: './sem-button.component.html',
  styleUrls: ['./sem-button.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemButtonComponent, multi: true }
  ]
})
export class SemButtonComponent extends ValueAccessorBase<string> implements OnInit, AfterViewInit {

  @Input() customActionName: string = null;
  @Input() customActionOnlyClientLogic: boolean = false;
  @Input() customActionMaintainLoader: boolean = false;


  ngAfterViewInit() {
  }

  shown:boolean = true;

  super() { }

  ngOnInit() {
    //console.log("Button events", this.events);
    this.restProviderService.modelValueChanged.subscribe(model => {
      if (typeof model != "undefined" && model && Object.keys(model).length > 0) {
        this.checkInitialValue(model);
        this.model = model;
      }
    });
  }

  click() {
    
    if(this.customActionName != null && typeof this.customActionName != "undefined" && this.customActionName.length > 0){
      this.actionsService.getActionAndExecute(this.customActionName, this.customActionOnlyClientLogic, this, this.customActionMaintainLoader,false, this.model);
    }

    if (this.getAttribute("name") != null && typeof this.getAttribute("name") != "undefined") {
      // HC FOR AZ .!.
      if (this.getAttribute("name") == "Növbəti") {
        this.next();
      } else if (this.getAttribute("name") == "Geri qayıt") {
        this.previous();
      } else if (this.getAttribute("name") == "Yadda saxla") {
        document.getElementById("form-save-button").click();
      }
      else if (this.getAttribute("name") == "Əlavə et") {
        this.router.navigateByUrl(window.location.pathname);
      }
    }

    if(typeof this.onValueChangeEval != "undefined" && this.onValueChangeEval){
      eval(this.onValueChangeEval);
    }

    this.changeDetection.detectChanges();
  }

  //kodo za next button/previous v repeaterju za tabe (ne brisi)
  next() {
    var result = document.getElementsByClassName("mat-tab-label");
    let selected = 0;
    let nextSelected = 0;

    // get current selected tab
    Object.entries(result).forEach(([key, value]) => {
      if (value.classList.contains("mat-tab-label-active")) {
        selected = parseInt(key);
      }
    });

    var found = false;
    // find next tab item that is not disabled
    for (var i = selected + 1; i < result.length; i++) {
      // continue from selected on (ignore previous tabs)
      // check if not disabled      
      if (!result[i].classList.contains("mat-tab-disabled")) {
        nextSelected = i
        found = true;
        break;
      };
    }

    /*if(nextSelected == result.length - 1 && this.getAttribute("name") == "Növbəti"){
      this.shown = false;
    }*/

    // if not found go from beggining to current selected
    if (!found) {
      for (var i = 0; i < selected; i++) {
        if (!result[i].classList.contains("mat-tab-disabled")) {
          nextSelected = i
          found = true;
          break;
        };
      }
    }

    this.checkParent(result[nextSelected]);

    var evt = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
    result[nextSelected].dispatchEvent(evt);
  }

  previous() {
    var result = document.getElementsByClassName("mat-tab-label");
    let selected = 0;
    let previousSelected = 0;
    var found = false;
    // get current selected tab
    Object.entries(result).forEach(([key, value]) => {
      if (value.classList.contains("mat-tab-label-active")) {
        selected = parseInt(key);
      }
    });

    var start = 0;
    // if current selected is first tab item
    // go from last and select first that is not disabled
    if (selected == 0 && result.length > 1) {
      start = result.length - 1;
    } else if (selected > 0 && result.length > 1) {
      start = selected - 1;
    }
/*
    if(start == 0 && this.getAttribute("name") == "Geri qayıt"){
      this.shown = false;
    }
*/
    for (var i = start; i >= 0; i--) {
      // continue from selected on (ignore previous tabs)
      // check if not disabled      
      if (!result[i].classList.contains("mat-tab-disabled")) {
        previousSelected = i
        found = true;
        break;
      };
    }

    // go from last and select first that's not disabled
    if (!found) {
      for (var i = result.length - 1; i >= 0; i--) {
        if (!result[i].classList.contains("mat-tab-disabled")) {
          previousSelected = i
          found = true;
          break;
        };
      }
    }

    this.checkParent(result[previousSelected]);

    var evt = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
    result[previousSelected].dispatchEvent(evt);
  }

  checkParent(element: Element) {
    if (element["style"]["display"] == "none" || element["style"]["display"] == "") {
      let val = element.getAttribute("aria-label");
      let query = "[aria-label=" + "parent-" + val + "]";
      let elements = document.querySelectorAll(query);
      let arrelements = Array.prototype.slice.call(elements);

      if (arrelements.length == 1) {
        var evt = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
        arrelements[0].dispatchEvent(evt);
      }
    }
  }

}
