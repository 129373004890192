import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { MetaModelService } from 'src/app/services/metamodel/metamodel-provider.service';
import { UserService } from 'src/app/services/user/user-provider.service';
import { SemSearchComponent } from '../../sem-search/sem-search.component';
import { UserSearchSettingModel } from 'src/app/models/search/user-search-setting-model';
import { UserSearchSettingPropertyModel } from 'src/app/models/search/user-search-setting-property-model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { first } from 'rxjs/operators';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';
import { SearchService } from 'src/app/services/search/search.service';


@Component({
  selector: 'sem-search-fields-dialog',
  templateUrl: './sem-search-fields-dialog.component.html',
  styleUrls: ['./sem-search-fields-dialog.component.css']
})
export class SemSearchFieldsDialogComponent implements OnInit, AfterViewInit {
  
constructor(        
    public dialogRef: MatDialogRef<SemSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,        
    public translationsService: TranslationsProviderService,    
    public metaModelService: MetaModelService,
    public userService: UserService,
    public searchService: SearchService) {   
  }  

  availibleFields: any = [];
  addedFields: any = [];
  originalFieldsList: any = [];
  originalAddedFielsList: any = [];
  userSearchSettings: UserSearchSettingModel;

  private modelName: string;
  private viewId: string;
  private customViewFilter: any;
  private enableFacets: any;
  private quickViewEnabled: any; 
  private selectedItem: any;
  private quickViewFormName: any; 
  private onlyLastNodeNames:any;
  private showItemNumber: boolean;
  private showActionsColumn: boolean;
  private searchPlaceHolder: string;
  private metamodelSearchFields;

  ngOnInit() {

    const data = this.dialogData;

    if (data) {
      this.availibleFields = data["availibleFields"];
      this.addedFields = data["addedFields"];
      this.originalFieldsList = data["originalFieldsList"];
      this.originalAddedFielsList = data["originalAddedFieldsList"];
      this.userSearchSettings = data["userSearchSettings"];
      this.modelName = data["modelName"];
      this.viewId = data["viewId"];
      this.enableFacets = data["enableFacets"];
      this.quickViewEnabled = data["quickViewEnabled"];
      this.customViewFilter = data["customViewFilter"];
      this.onlyLastNodeNames = data["onlyLastNodeNames"];
      this.showItemNumber = data["showItemNumber"];
      this.showActionsColumn = data["showActionsColumn"];
      this.quickViewFormName = data["quickViewFormName"];
      this.searchPlaceHolder = data["searchPlaceHolder"];
      this.metamodelSearchFields = data["metamodelSearchFields"];
    }
  }

  ngAfterViewInit(){
    
  }

  removeAddedSearchField() {
    if (typeof this.selectedItem != "undefined" && this.selectedItem != null) {
      // remove from array of addedFields
      let index = this.addedFields.findIndex(x => x.Name == this.selectedItem.Name);
      if (index >= 0) {
        this.addedFields.splice(index, 1);
        // add to array of availibleFields
        this.availibleFields.unshift(this.selectedItem);
        this.originalFieldsList.unshift(this.selectedItem);
        this.selectedItem = null;
      }


       // selectedGridField is next availible grid field
       let newSelectedItem = this.addedFields[index - 1];
       if(newSelectedItem < 0){
         newSelectedItem = 0;
       }
 
       if(newSelectedItem != null && typeof newSelectedItem != "undefined"){
         this.selectedItem = newSelectedItem;
       }else{
         // get first if at least one field is present
         if(this.addedFields.length > 0){          
           this.selectedItem = this.addedFields[0];
         }else{
           this.selectedItem = null;
         }                
       }  
    }
  }

  listItemClick(event, newValue) {
    this.selectedItem = newValue;
  }

  addSearchField() {
    if (typeof this.selectedItem != "undefined" && this.selectedItem != null) {
      // remove from array of availibleFields
      let index = this.availibleFields.findIndex(x => x.Name == this.selectedItem.Name);
      if (index >= 0) {
        //this.originalFieldsList = this.availibleFields.map(x => Object.assign({}, x));
        this.availibleFields.splice(index, 1);
        // add to array of added fields
        this.addedFields.push(this.selectedItem);

        if (this.originalFieldsList != null && typeof this.originalFieldsList != "undefined") {
          // find selected in original and remove
          let i = this.originalFieldsList.findIndex(x => x.Name == this.selectedItem.Name);
          if (i >= 0) {
            this.originalFieldsList.splice(i, 1);
          }
        }

        
        // selectedGridField is next availible grid field
        let newSelectedItem = this.availibleFields[index - 1];
        if(newSelectedItem < 0){
          newSelectedItem = 0;
        }

        if(newSelectedItem != null && typeof newSelectedItem != "undefined"){
          this.selectedItem = newSelectedItem;
        }else{
          // get first if at least one field is present
          if(this.availibleFields.length > 0){          
            this.selectedItem = this.availibleFields[0];
          }else{
            this.selectedItem = null;
          }                
        }   


      }
    }
  }

  fieldSearch(searchValue: string) {
    if (typeof searchValue != "undefined" && searchValue.trim() != "") {
      this.availibleFields = this.originalFieldsList.map(x => Object.assign({}, x));
      let lowerCaseSearchValue = searchValue.toLocaleLowerCase();
      let filteredItems = this.availibleFields.filter(x => x.Translation.toLocaleLowerCase().includes(lowerCaseSearchValue));
      this.availibleFields = filteredItems;
    } else {
      // bosko why did u comment this?
      this.availibleFields = this.originalFieldsList.map(x => Object.assign({}, x));
    }
  }

  restoreSearchSettings() {

    // return all addedFields to availible
    //for (let item in this.addedFields) {
      //this.availibleFields.push(item);
    //}

    // clear
    //this.addedFields = [];
    //this.originalAddedFielsList = [];

    this.saveUserAdditionalSearchFields(true);
  } 

  saveUserAdditionalSearchFields(restore: boolean) {
    DynamicLoadingUtil.loader.showLoader = true;

    let userSearchSettingId = 0;
    let version = null;
    if (this.userSearchSettings != null || typeof this.userSearchSettings != "undefined") {
      userSearchSettingId = this.userSearchSettings?.Id;
      version = this.userSearchSettings?.Version;
    }

    this.userSearchSettings = new UserSearchSettingModel;
    this.userSearchSettings.Id = userSearchSettingId;
    this.userSearchSettings.Version = version;
    this.userSearchSettings.ModelName = this.modelName;
    this.userSearchSettings.EnableFacets = this.enableFacets;
    this.userSearchSettings.QuickViewEnabled = this.quickViewEnabled;
    this.userSearchSettings.OnlyLastNodeNames = this.onlyLastNodeNames;
    this.userSearchSettings.ShowItemNumber = this.showItemNumber;
    this.userSearchSettings.ShowActionsColumn = this.showActionsColumn;
    this.userSearchSettings.SearchPlaceHolderTitle = this.searchPlaceHolder;
    this.userSearchSettings.CustomPageSize = this.searchService.pageSize;
    this.userSearchSettings.QuickViewFormName = this.quickViewFormName;
    this.userSearchSettings.LayoutName = this.userService.currentLayout;
    
    let customViewFilterString = null;
    if(this.customViewFilter != null && typeof this.customViewFilter != "undefined"){
      customViewFilterString = JSON.stringify(this.customViewFilter);
    }
    this.userSearchSettings.FilterQuery = customViewFilterString;
    this.userSearchSettings.View = { "Id": this.viewId};    
    

    this.userSearchSettings.SearchFields = [];
    for (let field of this.addedFields) {

      var searchField = new UserSearchSettingPropertyModel;
      var userSearchSet = new UserSearchSettingModel;

      searchField.PropertyPath = field["Name"];
      userSearchSet.Id = this.userSearchSettings.Id;
      searchField.UserSearchSetting = userSearchSet;
      searchField.FieldType = 1;
      this.userSearchSettings.SearchFields.push(searchField);
    }

    this.userService.saveUserSettings(this.userSearchSettings, false, restore).subscribe(data => {
      
      this.userSearchSettings = data;
      if(data != null && typeof data != "undefined" && data["Version"] != null && typeof data["Version"] != "undefined"){        
        this.userService.userSearchSettingCurrentVersion = data["Version"];        
      }      

      if(restore){
          this.addedFields = [];
          // go through saved fields
          for (let i = 0; i < this.userSearchSettings?.SearchFields.length; i++) {

            if(this.userSearchSettings.SearchFields[i].FieldType == 1){
              // find saved field in metamodelSearchFields
              let index = this.metamodelSearchFields.findIndex(x => x.Name == this.userSearchSettings.SearchFields[i].PropertyPath);
              if (index >= 0) {
                // if property found add field
                this.metamodelSearchFields[index]["SearchValue"] = "";
                this.metamodelSearchFields[index]["SearchValueFrom"] = "";
                this.metamodelSearchFields[index]["SearchValueTo"] = "";
                this.metamodelSearchFields[index]["CheckBoxState"] = "novalue";                                
                // also add into addedFields
                this.addedFields.push(this.metamodelSearchFields[index]);
              }
            }            
          }    
          
          this.originalAddedFielsList = this.addedFields.map(x => Object.assign({}, x));

      }    
      
      this.availibleFields = [];
      this.originalFieldsList = [];
      // go through all fields
      for (let i = 0; i < this.metamodelSearchFields.length; i++) {
        // check if exists in addedFields
        let index = this.addedFields.findIndex(x => x.Name == this.metamodelSearchFields[i].Name);

        if (index < 0) {
          // if doesnt exists add to availible fields        
          this.availibleFields.push(this.metamodelSearchFields[i]);
          this.originalFieldsList.push(this.metamodelSearchFields[i]);
        }
      }

      
      // save is successfull
      // close dialog and send data back      
      var returnData = {
        "modelAdditionalSearchFields": this.addedFields.map(x => Object.assign({}, x)),
        "availibleFields": this.availibleFields,
        "addedFields": this.addedFields,
        "originalFieldsList": this.originalFieldsList,
        "originalAddedFieldsList": this.originalAddedFielsList,
        "userSearchSettings": this.userSearchSettings,
        "placeHolder":this.searchPlaceHolder,
        "restore": restore
      }

      DynamicLoadingUtil.loader.showLoader = false;
      this.dialogRef.close(returnData);
      
    }, error => { 
      DynamicLoadingUtil.loader.showLoader = false;
       
    });    
  }

  drop(event: CdkDragDrop<string[]>) {
    //var correntprevious = event.container.data.findIndex(row => row === event.item.data);
    //var prev = event.previousIndex;
    //moveItemInArray(this.addedFields, event.previousIndex, 1);
    moveItemInArray(this.addedFields, event.previousIndex, event.currentIndex);

  }

  cancelSearchSettings() {
    this.addedFields = this.originalAddedFielsList.map(x => Object.assign({}, x));
    this.availibleFields = this.originalFieldsList.map(x => Object.assign({}, x));       
    
    var returnData = {
      "modelAdditionalSearchFields": this.addedFields.map(x => Object.assign({}, x)),
      "availibleFields": this.availibleFields,
      "addedFields": this.addedFields,
      "originalFieldsList": this.originalFieldsList,
      "originalAddedFieldsList": this.originalAddedFielsList,
      "userSearchSettings": this.userSearchSettings,
      "placeHolder":this.userSearchSettings.SearchPlaceHolderTitle

    }

    this.dialogRef.close(returnData);

  }  

  translate(key: string){
    return this.translationsService.translate(key);
  }

  doubleClickAdd(){
    if(this.selectedItem != null && typeof this.selectedItem != "undefined"){
      this.addSearchField();
    }    
  }

  doubleClickRemove(){
    if(this.selectedItem != null && typeof this.selectedItem != "undefined"){
      this.removeAddedSearchField();
    }    
  }

}
