import { Component, OnInit, Inject } from '@angular/core';
import { EntityVersionService } from 'src/app/services/versioning/entity-version-service.service';
import { EntityVersionModel } from 'src/app/models/versioning/entity-version-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sem-version-dialog',
  templateUrl: './sem-version-dialog.component.html',
  styleUrls: ['./sem-version-dialog.component.css']
})
export class SemVersionDialogComponent implements OnInit {

  
  versions:EntityVersionModel[];
  selectedVersion:EntityVersionModel = null;
  comparationResult:any = null;
  comparisionLoading:boolean = false;
  restoringVersion:boolean=false;


  constructor(private entityVersionService:EntityVersionService, public dialogRef: MatDialogRef<SemVersionDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any) {

  }

  ngOnInit() {
    this.loadVersions(this.dialogData.DomainModelTypeFullName, this.dialogData.EntityId);
  }

  loadVersions(type:string, id:number){
    this.entityVersionService.loadVersions(type, id).subscribe(d=>{
      this.versions = d;
    }, 
    error =>{

    });
  }

  compareSelected(){

    this.comparisionLoading = true;
    this.entityVersionService.comapre(this.selectedVersion.Id).subscribe(d=>{
        this.comparationResult = d;
        this.comparisionLoading = false;
    }, error =>{

    });
  }

  setSelectedVersion(version, event){
    if(event.target.checked){
      this.selectedVersion = version;
      this.compareSelected();
    }else{
      this.selectedVersion = null;
    }
  }

  formatDate(date:string){
     var d =  new Date(date);

     var dd = d.getDate();

    var mm = d.getMonth()+1; 
    var yyyy = d.getFullYear();

    var hh = d.getHours();
    var mm = d.getMinutes();

    return dd+"."+mm+"."+yyyy+ "  "+hh+":"+mm+":"+d.getSeconds();
  }

  closeVersionDialog(){
    this.dialogRef.close();
  }

  restoreToVersion(){
    this.restoringVersion = true;

    this.entityVersionService.restore(this.selectedVersion.Id).subscribe(d=>{
        this.restoringVersion = false;
        window.location.reload();
  }, error =>{

  });
  }
}
