import { Component, OnInit, Input, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ValueAccessorBase } from '../../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActionsService } from '../../../../services/actions-service/actions.service';
import { RestProviderService } from '../../../../services/rest-provider/rest-provider.service';
import { SemParameterDialogComponent } from '../../dialogs/sem-parameter-dialog/sem-parameter-dialog.component';
import { RequestModel } from 'src/app/models/request/request-model';
import { HeroHighlightModel } from '../../../../models/control-models/hero-highlight-model';

@Component({
  selector: 'sem-quick-actions',
  templateUrl: './sem-quick-actions.component.html',
  styleUrls: ['./sem-quick-actions.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemQuickActionsComponent, multi: true }
  ]
})
export class SemQuickActionsComponent extends ValueAccessorBase<string> implements OnInit, AfterViewInit, AfterViewChecked {

  ngAfterViewChecked() {
  }

  @Input() settings: any;

  private restService: RestProviderService;
  public qaValue: number | null = null;
  public actionCalled = false;
  public actionPostResponseTitleValue: string = null;
  public showLoaderAction: boolean = false;

  super() { }

  ngOnInit() {
    this.sidebarMenuOpened = true;
    this.actionsService = this.injector.get(ActionsService);
    this.restService = this.injector.get(RestProviderService);
  }

  ngAfterViewInit() {
    let test = this.settings;
  }

  ngOnChanges() {
    if (this.settings?.Actions[0]) {
      if (this.settings?.["QuickActionType"] == 1) {
        this.callAction(this.settings?.Actions[0]);
      }
    }
  }  

  callAction(action) {
    if (!this.actionCalled) {
      this.actionCalled = true;
      if (this.settings) {
        if (this.settings.Actions[0]) {
          if (this.settings.Actions[0]["ActionType"]?.toLowerCase() == "post") {
            let rModel = new RequestModel;
            rModel.url = this.settings.Actions[0]["ActionName"];
            rModel.data = {"Envelope":this.settings.Actions[0]["Body"]};

            this.restProviderService.getDataPOST(rModel).subscribe((data: number) => {

              console.log("Quick Action result: ", data);
              this.qaValue = data;

              this.changeDetection.detectChanges();

            });

          }
          // new way of visualization - ask nino for wiki
          else if (this.settings.Actions[0]["ActionType"]?.toLowerCase() == "post-new") {
            let rModel = new RequestModel;
            rModel.url = this.settings.Actions[0]["ActionName"];
            rModel.data = { "Envelope": this.settings.Actions[0]["Body"] };

            this.restProviderService.getDataPOST(rModel).subscribe((data: HeroHighlightModel) => {

              console.log("Quick Action result: ", data);
              this.qaValue = data.Number;
              this.actionPostResponseTitleValue = data.Title;

              this.changeDetection.detectChanges();

            });
          } else if (this.settings.Actions[0]["ActionType"]?.toLowerCase() == "get") {
            this.restProviderService.getDataGET(this.settings.Actions[0]["ActionName"]).subscribe((data: any) => {

              console.log("Quick Action result: ", data);
              this.qaValue = data;

              this.changeDetection.detectChanges();

            });
          }
        }
      }
    }
  }

  getActionTitle(actionTitle: string) {

    if (actionTitle != null && actionTitle !== undefined && actionTitle.length > 0) {
      return this.translationsService.translate(actionTitle);
    } else {
      return this.actionPostResponseTitleValue;
    }
  }

  actionClick(url: string, action: any) {
    if (action != null && typeof action != "undefined") {
      if (action.ActionName != null && typeof action.ActionName != "undefined") {

        this.showLoaderAction = true;
        this.restService.getDataGET("api/actions/" + action.ActionName, "application/json").subscribe(data => {

          let formAction = JSON.parse(data);


          //eval(action.NavigationNodeRule.ActionBody);
          // if form actions has parameter form linked then we should first show popup with parameter form
          if (formAction.ParameterForm != null && typeof formAction.ParameterForm != "undefined" && formAction.ParameterForm.Id > 0) {
            var reportId = null;
            if (formAction.ParameterForm.Report != null && typeof formAction.ParameterForm.Report != "undefined") {
              reportId = formAction.ParameterForm.Report.Id;
            }

            const dialogRef = this.dialog.open(SemParameterDialogComponent, {
              data: {
                parameterFormId: formAction.ParameterForm.Id,
                reportId: reportId,
                customHeaderTitle: formAction.ParameterForm.CustomHeaderTitle,
                customCssClass: formAction.ParameterForm.CustomCssClass
              }
            });

            dialogRef.afterClosed().subscribe(returnData => {
              if (returnData != null && typeof returnData != "undefined") {
                // only call action if parameter popup was confirmed (not cancled - close button)
                if (returnData.Canceled == false) {
                  var inputParametersModel = returnData["Model"];
                  this.actionsService.callAction(formAction, this.model, null, null, this, false, inputParametersModel,
                    formAction.OnlyClientLogic, false, false, formAction.SkipBulkActionApiMethod);
                    this.showLoaderAction = false;
                }else{
                  this.showLoaderAction = false;
                }
              }
            });
          } else {
            // just call action without parameters form popup
            this.actionsService.callAction(formAction, this.model, null, null, this, false, null,
              formAction.OnlyClientLogic, false, false, formAction.SkipBulkActionApiMethod);
            this.showLoaderAction = false;
          }


        }, error => {
          console.log(error);
        });
      } else {
        this.utility.processNavigationUrl(url);
      }
    }
  }

  navigate(url){
    this.utility.processNavigationUrl(url);
  }

}
