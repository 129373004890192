import { Component, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DynamicLoadingUtil } from '../../../shared/helpers/dynamic-loading-util';
import { SemTextBoxComponent } from '../sem-text-box/sem-text-box.component';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';

@Component({
  selector: 'split-search',
  templateUrl: './split-search.component.html',
  styleUrls: ['./split-search.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SplitSearchComponent, multi: true }
  ]
})
export class SplitSearchComponent extends ValueAccessorBase<string> implements OnInit,OnDestroy {

  defaultStringQuery: any;
  searchClassTypesList: any = [];
  globalSearchType: any = "document";
  mainQuery: any = {};
  result: any = [];
  searchByCurrentModelOnly: boolean = true;
  loadedRoute: any = null;
  isOr: boolean = false;

  subscription:Subscription;
  bSubject:any = null;

  ngOnDestroy(): void
  {
    this.subscription.unsubscribe();
  }


  ngOnInit(): void {
    super.ngOnInit();

    document.getElementsByClassName("item-container")[0].classList.add("splitted-view");
    this.defaultStringQuery = "{\"RequestedFacets\": null,\"Projection\": [],\"TreeQuery\": {},\"ClassTypes\": [],\"Page\": \"1\",\"GlobalQueryType\": \"" + this.globalSearchType + "\",\"PageSize\": " + 30 + ",\"RepositoryName\": \"lucene\",\"UseCache\": false}";

    this.mainQuery = JSON.parse(this.defaultStringQuery);
    this.mainQuery.Projection = ["Title", "FirstMediaId", "DocumentCreator.Username", "DocumentCreator.Title", "Id", "InvNumber"]


    this.subscription = this.searchService.splitSearch.subscribe(data => {

      if (this.searchClassTypesList.length == 0 && !this.searchByCurrentModelOnly) {

        this.searchService.getAllowedSearchClasses(this.globalSearchType).subscribe(data => {

          if (data != null && typeof data != "undefined" && data.length > 0) {
            this.searchClassTypesList.push(...data);
            this.mainQuery["ClassTypes"] = this.searchClassTypesList.map(x => x.FullName);
          }

          this.setupSearchQuery();

        }, error => {

        });
      } else {

        if (this.loadedRoute == null) {

          this.loadedRoute = this.dynamicRoutesLoader.getCurrentRoute();
        }

        if (this.loadedRoute && this.searchByCurrentModelOnly) {
          this.mainQuery["ClassTypes"] = [this.loadedRoute.ApplicationModelName];
          this.setupSearchQuery();
        } else if (!this.searchByCurrentModelOnly) {
          this.mainQuery["ClassTypes"] = this.searchClassTypesList.map(x => x.FullName);
          this.setupSearchQuery();
        }

      }

    }, error => {

    });

  }

  modelChanged(ev: any) {
    this.bSubject = this.searchService.splitSearchS.next(undefined);
  }

  switchSearchMode(val: boolean) {
    this.searchByCurrentModelOnly = val;
    this.bSubject = this.searchService.splitSearchS.next(undefined);
  }

  getQueryValue(val) {
    if (isNaN(val.value)) {
      return {
        Type: "FieldValue",
        FieldName: val.bindingProperty,
        FieldValue: val.value.trim() + "*",
      }
    } else {

    }
  }

  setupSearchQuery() {

    this.mainQuery.TreeQuery = {};

    if (this.searchService.splitSearchKeywords.length > 1) {

      if (this.isOr) {
        this.mainQuery.TreeQuery = { Type: "OR", SubQueries: [] }
      } else {
        this.mainQuery.TreeQuery = { Type: "AND", SubQueries: [] }
      }

      for (var i = 0; i < this.searchService.splitSearchKeywords.length; i++) {
        if (this.searchService.splitSearchKeywords[i].value && this.searchService.splitSearchKeywords[i].value.length > 0 && this.searchService.splitSearchKeywords[i].value.trim().length > 0) {
          this.mainQuery.TreeQuery.SubQueries.push(this.getQueryValue(this.searchService.splitSearchKeywords[i]));
        }
      }
    } else if (this.searchService.splitSearchKeywords.length == 1) {
      if (this.searchService.splitSearchKeywords[0].value && this.searchService.splitSearchKeywords[0].value.length > 0 && this.searchService.splitSearchKeywords[0].value.trim().length > 0) {
        this.mainQuery.TreeQuery = this.getQueryValue(this.searchService.splitSearchKeywords[0]);
      } else {
        return;
      }
    } else {
      return;
    }

    this.searchService.performSearch(this.mainQuery, "", null, null).subscribe((data: any) => {
      this.result = data.Models;
    }, error => {
      console.log(error);
    })

  }

  close() {
    this.searchService.splitSearchKeywords = [];
    this.searchService.splitSearchEnabled = false;
    document.getElementsByClassName("item-container")[0].classList.remove("splitted-view");
  }

  removeItem(item: any) {
    this.searchService.splitSearchKeywords = this.searchService.splitSearchKeywords.filter(i => i.bindingProperty != item.bindingProperty);

    this.setupSearchQuery();
  }

  searchTermChanged(val: any) {
    this.setupSearchQuery();
  }

  navigateToListing() {
    let a = DynamicLoadingUtil.currentRoute;

    if (a) {
      let url = a.BreadCrumbs.filter(i => i.Url.includes("/list?"))[0].Url;
      this.utility.processNavigate(url, { query: this.mainQuery, type: "split-search" });
      //this.router.navigateByUrl(url, { state: this.mainQuery.TreeQuery });
    }
  }

}
