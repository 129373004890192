import { Component, OnInit, Input, Injector, ChangeDetectorRef, AfterViewInit, Output, EventEmitter } from '@angular/core';

import { RouteNavigationService } from '../../../services/dynamic-routes-loader/route-navigation.service';

import { HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { RootModel } from '../../../models/root-model';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NavigationNodeSerializer } from '../../../models/serializing/navigation-node-serializer';
import { Navigation } from '../../../../../node_modules/@types/selenium-webdriver';
import { retry } from '../../../../../node_modules/rxjs/operators';
import { map } from 'rxjs/operators';

import { SemHeaderMenuComponent } from '../sem-header-menu/sem-header-menu.component';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { RequestModel } from '../../../models/request/request-model';
import { NavigationRequestModel } from '../../../models/navigation/navigation-request-model';
import { NavigationResponseModel } from '../../../models/navigation/navigation-response-model';
import { NavigationNodeModel } from '../../../models/navigation/navigation-node-model';
import { Router } from '../../../../../node_modules/@angular/router';
import { SemNavigationComponent } from '../../../components/common/sem-navigation/sem-navigation.component';
import { SemLoaderComponent } from '../../../shared/components/sem-loader/sem-loader.component';
import { DynamicLoadingUtil } from '../../../shared/helpers/dynamic-loading-util';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/local-storage-service/local-storage.service';
import { TranslationsProviderService } from '../../../services/translations-provider/translations-provider.service';
import { FileUploadStandaloneService } from '../../../services/file-upload-standalone/file-upload-standalone.service';



declare var navigationPreFunctions: any;
declare var navigationPostFunctions: any;

@Component({
  selector: 'app-sem-left-menu',
  templateUrl: './sem-left-menu.component.html',
  styleUrls: ['./sem-left-menu.component.css'],
  providers: [FileUploadStandaloneService],
  animations: [
    trigger('componentTransform', [
      state('in', style({
      })),
      state('out', style({
        display: 'block',
        marginTop: '19px'
      })),

    ]),
    trigger('slideInOut', [
      state('in', style({
        width: '280px'
      })),
      state('out', style({
        width: '108px'
      })),
      transition('in => out', animate('200ms ease-in-out')),
      transition('out => in', animate('200ms ease-in-out'))
    ]),
    trigger('itemTransform', [
      state('in', style({
        display: 'inline-block'
      })),
      state('out', style({
        display: 'none'
      }))
    ]),
    trigger('quickAction', [
      state('show', style({
        display: 'block'
      })),
      state('hide', style({
        display: 'none'
      }))
    ]),
    trigger('nodeImg', [
      state('out', style({
        width: 'auto',
        paddingLeft: '0px',
        textAlign: 'center'
      })),
      state('in', style({
        width: '17px'
      }))
    ]),
    trigger('Img', [
      state('out', style({
        //marginLeft: '-4px'
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
      })),
      state('in', style({
        marginLeft: '4px'
      }))
    ])
  ]
})

export class SemLeftMenuComponent extends SemNavigationComponent implements OnInit, AfterViewInit {

  semHeaderComponent: SemHeaderMenuComponent;
  @Input('state') state: string = 'closed';
  @Input() vloader: SemLoaderComponent;
  url: string;
  result: NavigationResponseModel[];
  quickActionsItems: NavigationNodeModel[];
  opened: boolean = false;
  aux: number;
  imgPath: string = environment.sidebarIcon;
  @Output() sidebarClicked = new EventEmitter();
  projectName:string = environment.projectName;
  version:string = environment.version;

  constructor(injector: Injector, router: Router, public restProvider: RestProviderService, public model: RequestModel, public request: NavigationRequestModel, changeDet: ChangeDetectorRef, public localStorageService: LocalStorageService, public translationsService:TranslationsProviderService) {
    super(injector, router, changeDet);
  }

  ngOnInit() {
    this.getMenuItems();
    if (window.location.pathname.includes("dashboard")) {
      this.menuState = "in";
      this.opened = true;
      this.sidebarClicked.emit(true);
    }

    this.changeDet.detectChanges();
  }

  ngAfterViewInit() {
    if (typeof this.vloader != "undefined") {
      DynamicLoadingUtil.loader = this.vloader;
    }
  }

  getChildNodes(nodeChildren){	
    let nodesList: any[] = [];	
    if(nodeChildren != null){	
      for(let i = 0; i < nodeChildren.length; i++){	
        let nodechild = nodeChildren[i];	
        if(nodechild != null){	
          let condition = true;	
          if(nodechild.NavigationNodeRule != null){	
            	
            if(nodechild.NavigationNodeRule.Condition != null && nodechild.NavigationNodeRule.Condition.length > 0){	
              condition = eval(nodechild.NavigationNodeRule.Condition);	
            }	
          }	
          if(condition == true && nodechild.Type == 1){	
            nodesList.push(nodechild);	
          }            	
        }	
      }	
    }	
    return nodesList;	
  }

  async getMenuItems() {


    this.model.url = "/api/navigation/getNodes";
    this.request.context = "left-menu";
    this.request.depth = 0;
    this.request.rootNode = null;
    this.model.data = this.request;

    this.restProvider.serializer = new NavigationNodeSerializer;
    let cacheData = null;

    if (environment.useRouteAndMasterpageCache) {
      cacheData = this.localStorageService.get("/api/navigation/getNodes_left-menu");
    }

    if (cacheData) {
      this.result = JSON.parse(cacheData);
    }
    else {
      this.restProvider.getDataPOST<NavigationResponseModel[]>(this.model).subscribe(
        data => {
          DynamicLoadingUtil.navigationNodes = data;
          this.result = data;
          this.dynamicRoutesLoader.leftMenuNodes = data;
          this.localStorageService.add("/api/navigation/getNodes_left-menu", JSON.stringify(data));
        },
        error => {
          //console.log("Error", error);
        }
      );
    }

  }

  menuState: string = 'out';
  quickActionState: string = 'hide';

  addLink: string = '';
  listLink: string = '';
  searchLink: string = '';
  favoriteLink: string = '';
  offsetTop: string = '';
  childClick: boolean = false;

  closeMenu(event) {
    this.menuState = "out";
    this.opened = false;
    this.quickActionState = "hide";
  }

  toggleMenu(event) {
    // 1-line if statement that toggles the value:
    this.childClick = true;
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.opened = this.menuState === 'in' ? true : false;

    //if (this.quickActionState == "show" && !this.opened) {
    //  this.quickActionState = "hide";
    //}

    this.sidebarClicked.emit(this.opened);
  }

  processClickItemUrl(node: NavigationNodeModel, event) {
    var previousState = node.Selected;
    ///////////////////////////////////////// Set item's state to close

    for (var i = 0; i < this.result.length; i++) {
      var isChild = false;

      for (var j = 0; j < this.result[i].Children.length; j++) {
        if (this.result[i].Children[j].Title == node.Title) {
          isChild = true;
        }
      }

      if (!isChild) {
        this.result[i].Selected = false;
      }
    }
    this.changeDet.detectChanges();
    /////////////////////////////////////////  Opening the menu
    if (this.menuState === 'out') {
      this.menuState = 'in';
      this.opened = true;
    }

    if (!previousState) {
      node.Selected = true;
    }

    try {
      this.quickActionsItems = null;
      for (var i = 0; i < node.Children.length; i++) {
        if (node.Children[i].Type == 2) {
          this.quickActionsItems = node.Children;
        }
      }
    } catch (e) { }


    if (this.quickActionsItems != null) {
      this.quickActionState = "show";
    } else {
      this.quickActionState = "hide";
    }

    if (event.target.className.includes("item ")) {
      this.offsetTop = event.target.offsetTop + "px";
    } else if ((event.target.className.includes("node-child"))) {
      this.offsetTop = event.target.parentElement.offsetTop + event.target.parentElement.parentElement.offsetTop + "px";
    }
    else {
      this.offsetTop = event.target.parentElement.offsetTop + "px";
    }

    this.childClick = true;
    this.changeDet.detectChanges();
    this.sidebarClicked.emit(this.opened);
  }

  parentClick() {

    if (!this.childClick) {
      this.menuState = 'out';
      this.opened = false;
      this.quickActionState = "hide";
    }

    this.childClick = false;
    this.sidebarClicked.emit(this.opened);
  }

  containsSubItemsOfGroup(items, group) {

    for (var i = 0; i < items.length; i++) {
      if (items[i].Type == group  || items[i].Type == 3 && group == 1) {
        return true;
      }
    }

    return false;
  }

  toggleSubMenu() {
    // 1-line if statement that toggles the value:
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  itemClicked(node: NavigationNodeModel, event) {

    this.processClickItemUrl(node, event);
    this.sidebarClicked.emit(this.opened);
    //var preJsRes = this.preJS(node, node.Provider);
    //var postJsRes = this.postJS(node, node.Provider);

  }

  decodeUri(uri: string): string {
    return decodeURIComponent(uri);
  }

  async navigate(node) {
    //DynamicLoadingUtil.currentRoute = node;
    await this.utility.processNavigationUrl(node["Url"]);
    this.menuState = 'out';
    this.opened = false;
    this.quickActionState = "hide";
    this.sidebarClicked.emit(this.opened);
  }

  GetGroup(children, type): any[] {
    var grChildren = [];

    for (var i = 0; i < children.length; i++) {
      if (children[i].Type == type) {
        grChildren[i] = children[i];
      }
    }

    return grChildren;

  }

  isRootLink(node: NavigationNodeModel): boolean {
    if (node.Url && node.Url.length > 0) {
      return true;
    }

    return false;
  }

  childPadding(child){
    if(child["Type"] == 3){
      return true;
    }

    return false;
  }

}
