import { RootModel } from '../root-model';
import { Injectable } from "@angular/core";

@Injectable()
export class FacetGroupModel {

    
    public count: number;
    public path: any[];
    public label: string;
    public value: any;
    public end: any;
    public start: any;  
    public facetChecked: boolean;

}
