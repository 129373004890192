import { ControlValueAccessor } from '@angular/forms';
import { Input, Type, OnInit, Directive, Component } from '@angular/core';
import { SemControlComponent } from '../../sem-control/sem-control.component';
import { SemDatePickerComponent } from '../../sem-date-picker/sem-date-picker.component';
import * as moment from 'moment';
import { throws } from 'assert';


@Component({
  selector: 'value-accessor',
  templateUrl: './value-accessor.html',
  styleUrls: ['./value-accessor.css']
})
export abstract class ValueAccessorBase<T> extends SemControlComponent implements ControlValueAccessor, OnInit {

  @Input() parent: any;
  @Input() public predefinedValueEvent: any;
  @Input() alwaysEditable:boolean = false;
  @Input() defaultValueToday:boolean = false;

  super() {
  }

  private innerValue: T;
  private initialValueSet: boolean = false;

  private changed = new Array<(value: T) => void>();
  private touched = new Array<() => void>();

  ngOnInit() {
    /*this.restProviderService.modelValueChanged.subscribe(model => {
      if (typeof model != "undefined" && model && Object.keys(model).length > 0) {
        this.checkInitialValue(model);
      }
    });*/
  }

  checkInitialValue(model) {

    if(typeof (this.defaultValueToday) != "undefined" && this.defaultValueToday){
      if(this.defaultValueToday == true && !this.value){
        let val:any = new Date();
        this.value = val;
        this.innerValue = val;

        this.initialValueSet = true;

        return;
      }
    }

    if (typeof this.predefinedValueEvent != "undefined" && this.predefinedValueEvent) {
      if (this.predefinedValueEvent != "") {
        //console.log("Predefined value event: ", this.predefinedValueEvent);
        //console.log("Component: ", this);

        if (this.isDate(this.predefinedValueEvent)) {
          this.innerValue = this.getMomentDate(this.predefinedValueEvent);
          this.value = this.getMomentDate(this.predefinedValueEvent);
        } else {
          if (!model) {
            this.innerValue = this.predefinedValueEvent;
            this.value = this.predefinedValueEvent;
          } else {
            let val = eval(this.predefinedValueEvent);

            if (typeof val != "undefined" && val) {
              this.innerValue = val;
              this.value = val;
            }
          }
        }

        this.initialValueSet = true;

      }
    }
  }


  get value(): T {
    try {

      return this.innerValue;
    } catch (e) { console.log(e); }
  }


  set value(value: T) {
    try {
      if (this.innerValue !== value) {
        this.innerValue = value;

        this.valueModified();
        this.changed.forEach(f => f(value));
      }

    } catch (e) { console.log(e); }
  }


  touch() {
    try {
      this.touched.forEach(f => f());
    } catch (e) { console.log(e); }
  }


  writeValue(value: T) {
    try {

      this.innerValue = value;
      this.checkInitialValue(null);

      if (typeof value != "undefined" && value != null) {
        this.valueChanged();
      }

      let attName = this.getAttribute("name");/*
      for (var i = 0; i < this.dfms.controlValuesForValidation.length; i++) {
        if (this.dfms.controlValuesForValidation[i]["Name"] == attName) {
          this.dfms.controlValuesForValidation[i]["Value"] = value;
          return;
        }
      }

      this.dfms.controlValuesForValidation.push({ "Name": attName, "Value": value });*/

      this.dfms.updateControlsValidationArray(attName,value);

    } catch (e) { console.log(e); }
  }


  registerOnChange(fn: (value: T) => void) {
    try {
      this.changed.push(fn);
    } catch (e) { console.log(e); }
  }


  registerOnTouched(fn: () => void) {
    try {
      this.touched.push(fn);
    } catch (e) { console.log(e); }
  }

  protected valueModified() { }

  protected valueChanged(): void { }

  isDate(s) {

    if(s.length == 1 && (!s.includes(".") && !s.includes("/"))){
      return false;
    }

    var date = moment(this.predefinedValueEvent);

    return date.isValid();
  }

  getMomentDate(value): any {
    if (typeof this.predefinedValueEvent != "undefined" && this.predefinedValueEvent) {
      if (this.predefinedValueEvent != "") {
        return moment(this.predefinedValueEvent, "MM-DD-YYYY");
      }
    }
  }

  extractNumberFromString(value){
    try{

      let matches = value.match(/(\d|,)+/g);
    
      return matches;
    
    }
    catch(e){
    }
  }
}
