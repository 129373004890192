import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ContainerWrapperComponent } from '../../container-wrapper/container-wrapper/container-wrapper.component';
import { RequestModel } from 'src/app/models/request/request-model';
import { SemDynamicComponent } from 'src/app/shared/components/sem-dynamic/sem-dynamic.component';
import { SemDynamicControlComponent } from 'src/app/components/controls/shared/sem-dynamic-control/sem-dynamic-control.component';
import { type } from 'os';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';
import { parse } from 'querystring';
import { RestProviderService } from 'src/app/services/rest-provider/rest-provider.service';
import { ActionsService } from 'src/app/services/actions-service/actions.service';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import * as moment from 'moment';
import { SemSearchComponent } from 'src/app/components/controls/sem-search/sem-search.component';
import { Subscription } from 'rxjs';
import { SearchService } from 'src/app/services/search/search.service';
import { NavigationRequestModel } from '../../../../models/navigation/navigation-request-model';
import { UserSearchSettingModel } from '../../../../models/search/user-search-setting-model';
import { NavigationNodeSerializer } from '../../../../models/serializing/navigation-node-serializer';
import { NavigationResponseModel } from '../../../../models/navigation/navigation-response-model';
import { ActivatedRoute } from '@angular/router';
import { SemParameterDialogComponent } from '../../../controls/dialogs/sem-parameter-dialog/sem-parameter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SortFieldModel } from 'src/app/models/search/sort-field.model';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.css']
})
export class NotificationCenterComponent extends ContainerWrapperComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.paginationSubs.unsubscribe();
  }

  super() { }

  acceptDD: boolean = false;

  items: any[] = [];
  paging: Number[] = [];
  currentItem: any = null;
  currentTaskOutcome: any = null;
  selectedItems: any[] = null;
  today = new Date();
  time = new Date();
  showButtons: boolean = true;
  currentPage: Number = 1;
  currentPageSize: Number = 5;
  public restService: RestProviderService = null;
  public changeDetection: ChangeDetectorRef = null;
  public actionsService: ActionsService = null;
  public translationService: TranslationsProviderService = null;
  paginationSubs: Subscription;
  numberOfPagesValue: any;
  formActions: any[] = [];
  totalItems: number = 0;
  previousPage = 0;
  nextPage = 0;
  totalPages = 1;
  pages: any[] = [];
  itemsLoaded: boolean = true;
  searchService = this.injector.get(SearchService);  
  private alreadyOpened: any[] = [];
  dialog: any = null;
  private checkboxAllItemsChecked: boolean = false;
  private checkedBulkItemStatus: any = {};
  private checkedBulkItems: any = [];
  searchPerformedSubs = null;
  private sortingFlags: any = []
  private currentSortFields: any = []
  private taskModelName = "Semantika.WebGalis.Application.Models.Tasks.TaskModel";

  @ViewChild("dynComp", { read: SemDynamicControlComponent }) dynComp: SemDynamicControlComponent;
  @ViewChild("searchBar", { read: SemSearchComponent }) searchComponent: SemSearchComponent;

  

  ngOnInit() {    
    
    this.refreshTime();
    setInterval(this.refreshTime, 10000);
    this.numberOfPagesValue = 10;

    this.translationService = this.injector.get(TranslationsProviderService);
    this.restService = this.injector.get(RestProviderService);
    this.changeDetection = this.injector.get(ChangeDetectorRef);
    this.actionsService = this.injector.get(ActionsService);
    this.items = [];
    //this.loadItems();

    var searchService = this.injector.get(SearchService);
    this.dialog = this.injector.get(MatDialog);

    this.paginationSubs = searchService.currentPagination.subscribe(value => {

      if (value != null && typeof value != "undefined") {

        this.totalItems = value.totalCount;
        this.pages = [];

        // convert to int      
        let totalCountNumber = value.totalCount;
        let currentPage = value.page;

        if (totalCountNumber == 0) {
          this.pages.push({ "Number": 1, "Active": true });
        } else {

          var ps: number = + searchService.pageSize;
          let pageNum = (totalCountNumber + ps - 1) / ps;
          pageNum = Math.floor(pageNum);

          let from = 0;
          let to = pageNum;
          this.totalPages = pageNum;

          from = currentPage - 2;
          if (from <= 0) {
            from = 1;
          }

          to = from + 5;

          if (to > pageNum) {
            to = pageNum + 1;
          }

          this.previousPage = currentPage - 1;
          if (this.previousPage <= 0) {
            this.previousPage = 0;
          }

          this.nextPage = currentPage + 1;
          if (this.nextPage > pageNum) {
            this.nextPage = 0;
          }

          for (let i = from; i < to; i++) {

            let active = false;
            if (i == currentPage) {
              active = true;
            }

            this.pages.push({ "Number": i, "Active": active });
          }
        }

      }
    }); 

    this.searchPerformedSubs = this.searchService.currentSearchExecuted.subscribe(value => {

      if (typeof value != "undefined" && value != null) {
        // if search was performed
        // clear all checked document ids for bulk action
        this.checkedBulkItems = [];
        this.checkedBulkItemStatus = {};
      }
    });

    let UserSearchSetting = new UserSearchSettingModel;
    UserSearchSetting.View = { "Id": 1 };
    UserSearchSetting.ModelName = this.taskModelName;

    this.loadFormActions("top-list-action", UserSearchSetting);


    this.sortingFlags["DateCreated"] = {"ShowSort":false, "SortingUsed":false, "SortDirection":0};
    this.sortingFlags["DueDate"] = {"ShowSort":false, "SortingUsed":false, "SortDirection":0};
    this.sortingFlags["Complete"] = {"ShowSort":false, "SortingUsed":false, "SortDirection":0};
  }

 
  checkboxBulkCheckAllItems() {

    // change to reverse value
    this.checkboxAllItemsChecked = !this.checkboxAllItemsChecked;
    var items = this.searchComponent?.model?.Models

    if (items != null && typeof items != "undefined") {
      for (var i = 0; i < items.length; i++) {
        this.checkboxBulkItemClicked(items[i], true, this.checkboxAllItemsChecked, false);
      }
    }    
  }

  checkboxBulkItemClicked(object, allCheckedFunction: boolean = false, allItemsChecked: boolean = false, oneItemClick: boolean = true) {

    if (typeof object != "undefined" && object != null) {
      var documentId = this.resolvePropertyBinding(object, "Id");
      var modelName = "Semantika.WebGalis.Application.Models.Tasks.TaskModel";
      var index = this.checkedBulkItems.findIndex(x => x.Id == documentId);

      // check if already in
      if (index >= 0) {
        
        // if all checked, but this already checked, dont remove
        if(!allCheckedFunction || !allItemsChecked){
          // user unchecked
          // remove from             
          this.checkedBulkItems.splice(index, 1);
          this.checkedBulkItemStatus[documentId] = false;
        }        
      } else {
        
        if(!allItemsChecked){
          if(oneItemClick){
            this.checkedBulkItems.push({ "Id": documentId, "ApplicationModelName": modelName });
            this.checkedBulkItemStatus[documentId] = true;  
          }
        }else{
          // user checked
          // add
          this.checkedBulkItems.push({ "Id": documentId, "ApplicationModelName": modelName });
          this.checkedBulkItemStatus[documentId] = true;
        }        
      }
      // save current checked bulk items
      this.searchService.setCurrentCheckedBulkDocuments(this.checkedBulkItems);
    }
  }

  getCondition(action) {
    try {
      if (action.NavigationNodeRule) {
        if (action.NavigationNodeRule.Condition != null && typeof action.NavigationNodeRule.Condition != "undefined" && action.NavigationNodeRule.Condition.length > 0) {
          return eval(action.NavigationNodeRule.Condition);
        }
      }

      return true;
    } catch (e) {
      return false;
    }
  }

   // this method is for purposes of action with children (but in some cases children are hiden based on condition and in that case action is shown without children - that action is useless in that case, so we dont need to show it)
   getActionFilteredChildrenCount(action){    

    let children = 0;

    if(action.Children == null || typeof action.Children == "undefined"){
      children = 0;
    }else{
      children = action.Children.length;
    }

    // if action is only set as parent and has no children then return 0
    if(action.IsOnlyParent == true && children == 0){
      return 0;
    }

    // if action has no children show on UI
    if(children < 1){
      return 1;
    }

    // if action has children show on UI only if at least on children can be shown (based on children condition)
    if(action.Children != null && typeof action.Children != "undefined" && action.Children.length > 0){
      for(var i = 0; i < action.Children.length; i++){
        var child = action.Children[i];
        if(child != null && typeof child != "undefined"){
          var show = this.getCondition(child);
          if(show == true){            
            return 1;
          }
        }
      }
    }

    return 0;
  }

  getFilteredChildren(children) {

    var newFiltered = [];
    if (children != null && typeof children != "undefined") {
      for (var i = 0; i < children.length; i++) {
        var show = this.getCondition(children[i]);
        if (show) {
          newFiltered.push(children[i]);
        }
      }
    }

    return newFiltered;
  }

  getActionBody(action) {

    let actionService = this.injector.get(ActionsService);

    if (action.NavigationNodeRule) {
      //eval(action.NavigationNodeRule.ActionBody);
      // if form actions has parameter form linked then we should first show popup with parameter form
      if (action.NavigationNodeRule.FormAction.ParameterForm != null && typeof action.NavigationNodeRule.FormAction.ParameterForm != "undefined" && action.NavigationNodeRule.FormAction.ParameterForm.Id > 0) {
        var reportId = null;
        if(action.NavigationNodeRule.FormAction.ParameterForm.Report != null && typeof action.NavigationNodeRule.FormAction.ParameterForm.Report != "undefined" ){
          reportId = action.NavigationNodeRule.FormAction.ParameterForm.Report.Id;
        }

        const dialogRef = this.dialog.open(SemParameterDialogComponent, {
          data: {
               parameterFormId: action.NavigationNodeRule.FormAction.ParameterForm.Id,
               reportId: reportId,
               customHeaderTitle: action.NavigationNodeRule.FormAction.ParameterForm.CustomHeaderTitle,
               customCssClass: action.NavigationNodeRule.FormAction.ParameterForm.CustomCssClass  
              }
        });

        dialogRef.afterClosed().subscribe(returnData => {
          if (returnData != null && typeof returnData != "undefined") {
            // only call action if parameter popup was confirmed (not cancled - close button)
            if (returnData.Canceled == false) {
              var inputParametersModel = returnData["Model"];
                actionService.callAction(action.NavigationNodeRule.FormAction, {}, null, null, this, action.BulkAction, inputParametersModel, action.NavigationNodeRule.FormAction.OnlyClientLogic, false, action.BulkExport, action.NavigationNodeRule.FormAction.SkipBulkActionApiMethod);
            }
          }
        });
      } else {
        // just call action without parameters form popup
          actionService.callAction(action.NavigationNodeRule.FormAction, {}, null, null, this, action.BulkAction, null, action.NavigationNodeRule.FormAction.OnlyClientLogic, false, action.BulkExport, action.NavigationNodeRule.FormAction.SkipBulkActionApiMethod);
      }
    }

    return "";
  }


  sortDropDownClick(name, event) {
    event.stopPropagation();
    if (this.sortingFlags[name]["ShowSort"]) {
      this.sortingFlags[name]["ShowSort"] = false;
    } else {
      this.sortingFlags[name]["ShowSort"] = true;
    }
    return false;
  }

  setColumnSorting(name, direction, event) {
    event.stopPropagation();
    this.sortingFlags[name]["SortingUsed"] = true;
    this.sortingFlags[name]["SortDirection"] = direction;
    // hide    
    this.sortingFlags[name]["ShowSort"] = false;


    // add to current sort field if doesn't already exists
    // if exists just update sort direction
    let index = this.currentSortFields.findIndex(x => x.FieldName == name);
    if (index >= 0) {
      // if new direction is same as current then ignore it
      if (this.currentSortFields[index].SortDirection != direction) {
        this.currentSortFields[index].SortDirection = direction;
        // update observable
        this.searchService.changeSortFields(this.currentSortFields);
      }
    } else {
      this.currentSortFields.push(new SortFieldModel(direction, name, this.taskModelName));
      this.searchService.changeSortFields(this.currentSortFields);
    }

    return false;
  }

  loadFormActions(context, userSearchSetting: UserSearchSettingModel) {

    let request = new NavigationRequestModel;
    let rModel = new RequestModel

    rModel.url = "/api/navigation/getNodes";
    request.context = context;
    request.depth = 0;
    request.rootNode = null;

    if (typeof userSearchSetting != "undefined" && userSearchSetting != null) {
      request.UserSearchSetting = userSearchSetting;
    }

    request.dynamicFormId = parseInt("3389-List".split("-")[0]);

    rModel.data = request;

    this.restService.serializer = new NavigationNodeSerializer;

    this.restService.getDataPOST<NavigationResponseModel[]>(rModel).subscribe(
      data => {
        this.formActions = data;       
      },
      error => {
        console.log("Error", error);
      }
    );
  }

  resolvePropertyBinding(object, propertyPath) {

    if (typeof propertyPath != "undefined" && propertyPath != null) {
      let split = propertyPath.split(".");
      let obj = object;

      for (let i = 0; i < split.length; i++) {
        if (obj != null && typeof obj != "undefined") {
          obj = obj[split[i]];
        }
      }

      if (obj != null && typeof obj != "undefined") {
        return obj;
      }
    }

    return "";
  }  

  changeNumberOfPages(event) {

      this.searchService.changePageSize(event.target.value);
      this.numberOfPagesValue = event.target.value;      
  }
  
  refreshTime() {
    var targetTime = new Date();
    var n = targetTime.getTimezoneOffset();
    var timezone = n / -60;
    var tzDifference = timezone * 60 + targetTime.getTimezoneOffset();
    var currentItem = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
    if(currentItem != null && typeof currentItem != "undefined"){
      let minutes = currentItem.getMinutes();
      let timeString = "";
      if(minutes < 10){
        timeString = currentItem.getHours() + ":0"+minutes;
      }else{
        timeString = currentItem.getHours() + ":" + minutes;
      }

      	
      if(document.getElementById("time") != null){	
        document.getElementById("time").innerHTML = timeString;	
      } 
    }
  }

  pageChanged(pageNum) {
    this.searchService.changeSearchPage(pageNum);
  }

  toLastPage() {
    if (this.totalPages < 1) {
      this.totalPages = 1;
    }
    this.searchService.changeSearchPage(this.totalPages);
  }

  toFirstPage() {
    this.searchService.changeSearchPage(1);
  }  

  checkIfTaskHasFile(task){

    if(task && task.TaskFiles && task.TaskFiles.length > 0){
      return true;
    }

    return false;
  } 

  checkIfOpened(task) {
    var index = this.alreadyOpened.findIndex(x => x == task.Id);
    if (index >= 0) {
      return true;
    }

    if(task.FirstOpenDate != null && typeof task.FirstOpenDate != "undefined"){
      return true;
    }

    if (task.CanOpenIfComplete == null || typeof task.CanOpenIfComplete == "undefined" && task.CanOpenIfComplete == false) {
      if (task.Complete) {
        return true;
      }
    }

    return false;
  }

  accept() {
    this.acceptDD = !this.acceptDD;
    this.changeDetection.detectChanges();

    this.restService.getDataGET("api/task/gettask/" + this.currentItem["Id"]).subscribe(data => {
      this.currentItem = JSON.parse(data);
      if (typeof this.currentItem["TaskOutcomes"] != "undefined" && this.currentItem["TaskOutcomes"]) {
        if (this.currentItem["TaskOutcomes"].length == 1) {
          this.currentTaskOutcome = this.currentItem["TaskOutcomes"][0];
          this.executeOutcome(this.currentTaskOutcome);
        }
      }
    }, error => {
      console.log(error);
    });


  }

  executeOutcome(outcome) {
    this.currentTaskOutcome = outcome;

    if (typeof (this.dynComp.cmpRef) != "undefined" && this.dynComp.cmpRef != null) {
      if (typeof (this.dynComp.cmpRef.instance) != "undefined" && this.dynComp.cmpRef.instance != null) {
        let cModel = this.dynComp.cmpRef.instance.model;
        this.dynComp.model = this.dynComp.cmpRef.instance.model;
        if (typeof outcome["Action"] != "undefined" && outcome["Action"] != null) {
          this.actionsService.callAction(outcome["Action"], cModel, null, this.actionExecuted.bind(this), this, false, null, outcome.Action.OnlyClientLogic, true);
        } else {
          this.actionExecuted(null, null);
        }
      } else {
        this.actionExecuted(null, null);
      }
    } else {
      if (typeof outcome["Action"] != "undefined" && outcome["Action"] != null) {
        var preJS = outcome["Action"]["PreJS"];
        if (typeof preJS != "undefined" && preJS) {
          if (preJS.length > 0) {
            eval(preJS);
          }
        }
      }
      this.actionExecuted(null, null);
    }
  }  

  checkIfCurrentClick(task){
    if(this.currentItem != null && typeof this.currentItem != "undefined"){
      if(this.currentItem.Id == task.Id){
        return true;
      }
    }
    return false;
  }

  private currentClickedTaskId: number = 0;	
  itemSelectedInTable(obj, event) {	
    //console.log("Item selected: ", item.srcElement["checked"]);	
    let item = event.currentTarget.cells[0].children[0];    	
    this.resetCBs();    	
    if (!this.checkIfOpened(obj.Id)) {	
      this.restService.getDataGET("api/task/opened/" + obj.Id, "application/json").subscribe(data => {	
        this.alreadyOpened.push(obj.Id);	
      });	
    }	
    this.currentItem = null;	
    this.currentTaskOutcome = null;	
    this.changeDetection.detectChanges();	
    if (this.currentClickedTaskId != obj.Id) {	
      this.currentItem = obj;	
      this.currentClickedTaskId = obj.Id;	
    }else if(this.currentClickedTaskId == obj.Id){	
      // set to 0 if clicked twice in a row	
      // because if clicked for 3rd time then it will show again	
      this.currentClickedTaskId = 0;	
    }	
    if (this.currentItem != null && typeof this.currentItem != "undefined" && this.currentItem.ShowMessageOnly) {	
      this.showButtons = false;	
    } else {	
      this.showButtons = true;	
    }	
  }

  closePopup(){

    this.currentItem = null;
    this.currentTaskOutcome = null;
    this.resetCBs();

  }

  resetCBs() {
    let table = document.getElementsByClassName("sem-noti-check-box");

    for (var i = 0; i < table.length; i++) {
      table[i]["checked"] = false;
    }
  }

  checkDateCreated(task) {
    if (task != null && typeof task != "undefined" && task.DateCreated != null && typeof task.DateCreated != "undefined") {
      return true;
    }
    return false;
  }

  checkDueDate(task) {
    if (task != null && typeof task != "undefined" && task.DueDate != null && typeof task.DueDate != "undefined") {
      return true;
    }
    return false;
  }

  actionExecuted(model, error) {
    if (error != null && typeof (error) != "undefined") {
      return;
    }

    if (typeof (model) != "undefined" && model != null) {

      var rModel = model["Model"];

      if (rModel != null && typeof rModel != "undefined") {
        rModel["SerializationObjectFormLink"] = this.dynComp.model["SerializationObjectFormLink"];
        this.dynComp.model = rModel;
        this.dynComp.cmpRef.instance.model = this.dynComp.model;
        this.currentItem["EntityID"] = rModel["Id"];
      }
    }

    this.currentItem["Complete"] = true;
    this.currentItem["Result"] = this.currentTaskOutcome["OutcomeText"];

    let entityId = this.currentItem["EntityID"];

    if (entityId == 0) {
      entityId = null;
    }

    var fModel = new RequestModel;
    fModel.data =
    {
      TaskId: this.currentItem["TaskID"],
      Result: this.currentItem["Result"],
      Completed: this.currentItem["Complete"],
      EntityID: entityId
    };
    fModel.url = "api/task";

    DynamicLoadingUtil.loader.showLoader = true;

    this.restService.getDataPOST(fModel).subscribe(data => {
      //console.log("Task saved ", data);
      setTimeout(() => {
      },1000);
      
      DynamicLoadingUtil.loader.showLoader = false;
      // hide task popup buttons after task is completed
      this.showButtons = false;
      //this.loadItems();

      this.searchComponent.searchProjection = ["Id", "Title", "Description", "DueDate", "DateCreated", "Complete", "Result", "TaskFiles", "TaskOutcomes"];
      this.searchComponent.performSearch(true);

      for (var i = 0; i < this.searchComponent.model.Models.length; i++) {
        if (this.searchComponent.model.Models["Id"] == this.currentItem["Id"]) {
          this.searchComponent.model.Models["Complete"] = true;
        }
      }

      this.currentItem = null;
    }, error => {
      //console.log("Error while saving task: ", error);
      DynamicLoadingUtil.loader.showLoader = false;
    });

  }

  decline() {
    this.currentItem = null;
    this.currentTaskOutcome = null;
  }

  //downloadTaskFile(task) {

  //  //if (task != null && typeof task != "undefined") {
  //  //  var model: RequestModel = new RequestModel;
  //  //  model.url = "api/files/get/processedresultexcel/"+task.Id;

  //  //  this.restService.getDataPOST(model).subscribe(data => {
  //  //    const linkSource = `data:${data["MimeType"]};base64,${data["Content"]}`;
  //  //    const downloadLink = document.createElement('a');
  //  //    document.body.appendChild(downloadLink);
  //  //    downloadLink.href = linkSource;
  //  //    downloadLink.target = '_self';
  //  //    downloadLink.download = data["FileName"];
  //  //    downloadLink.click();
  //  //  });
  //  //}

  //  if (task != null && typeof task != "undefined") {

  //    var filename;

  //    if (task.TaskFiles != null && task.TaskFiles.length > 0) {
  //      filename = task.TaskFiles[0].FileName;
  //    }

  //    //DynamicLoadingUtil.loader.showLoader = true;
      
  //    let url = "api/files/taskfile/" + task.Id;
  //    this.restService.downloadFile(url, filename);
  //    //this.restService.getDataGET(url, "blob").subscribe((data: Blob) => {        

  //    //  const downloadLink = document.createElement('a');
  //    //  downloadLink.href = window.URL.createObjectURL(data);
  //    //  downloadLink.download = filename;
  //    //  downloadLink.click();
  //    //  window.URL.revokeObjectURL(downloadLink.href);
  //    //  downloadLink.remove();


  //    //  DynamicLoadingUtil.loader.showLoader = false;
  //    //}, error => {
  //    //  DynamicLoadingUtil.loader.showLoader = false;
  //    //});

  //  }

  //}

  async downloadTaskFile(task) {

      if (task != null && typeof task != "undefined") {

      var filename;

      if (task.TaskFiles != null && task.TaskFiles.length > 0) {
        filename = task.TaskFiles[0].FileName;
      }
      
      let url = "api/files/taskfile/" + task.Id;

        this.restService.fileDownload(url).subscribe(async (event) => {
          let data = event as HttpResponse<Blob>;

          const downloadedFile = new Blob([data.body as BlobPart], {
            type: data.body?.type
          });
          
          if (downloadedFile.type != "") {
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = filename;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
          }
        });
    }   

  }
}
