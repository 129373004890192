import { Directive, ElementRef, HostListener, Input, Optional, Renderer2, Self } from '@angular/core';
import { FormControlDirective, FormControlName } from '@angular/forms';

@Directive({
  selector: '[SemMinMax]'
})
export class SemMinMaxDirective {

  @Input()
  public min: number;

  @Input()
  public max: number;

  constructor(private ref: ElementRef) { }

    @HostListener('input', [ '$event' ])
  public onInput(a_Event: InputEvent): void {
    let val = parseInt(this.ref.nativeElement.value);
    if(this.max !== null && this.max !== undefined  && val >= this.max)
      this.ref.nativeElement.value = this.max.toString();
    else if (this.min !== null && this.min !== undefined  && val <= this.min)
      this.ref.nativeElement.value = this.min.toString();
  }

}
