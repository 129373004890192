import { RootModel } from '../root-model';
import { Injectable } from "@angular/core";

@Injectable()
export class FacetModel {

  public fieldName: string;
  public fieldValue: string;
  public fieldPath: string[];
  public fieldValueFrom: string;
  public fieldValueTo: string;
  public facetType: string; // range facet, normal, taxonomic

  constructor(fieldName: string, fieldValue: string, facetType: string) {
      this.fieldName = fieldName;
      this.fieldValue = fieldValue;
      this.facetType = facetType;
  }

}
