import { Injectable, Compiler, Injector, Component, NgModule } from '@angular/core';
import { Router, RouterModule, NavigationStart } from '../../../../node_modules/@angular/router';
import { RequestModel } from '../../models/request/request-model';
import { RestProviderService } from '../rest-provider/rest-provider.service';
import { SemDynamicComponent, ResponseStatus, DialogType } from '../../shared/components/sem-dynamic/sem-dynamic.component';
import { CommonModule } from '../../../../node_modules/@angular/common';
import { SemSharedModule } from '../../shared/modules/sem-shared/sem-shared.module';
import { NavigationRequestModel } from '../../models/navigation/navigation-request-model';
import { SemLeftMenuComponent } from '../../navigation/components/sem-left-menu/sem-left-menu.component';
import { SemHeaderMenuComponent } from '../../navigation/components/sem-header-menu/sem-header-menu.component';
import { SemNavigationModule } from '../../navigation/modules/sem-navigation/sem-navigation.module';
import { DynamicLoadingUtil } from '../../shared/helpers/dynamic-loading-util';
import { NavigationNodeSerializer } from '../../models/serializing/navigation-node-serializer';
import { NavigationResponseModel } from '../../models/navigation/navigation-response-model';
import { FileUploadService } from '../file-upload-service/file-upload.service';
import { FileUploadObservableState } from 'src/app/shared/helpers/file-util';
import { async } from '@angular/core/testing';
import { SemNotFoundComponent } from 'src/app/components/containers/sem-not-found/sem-not-found.component';
import { TranslationsProviderService } from '../translations-provider/translations-provider.service';
import { SemConfirmDialogComponent } from 'src/app/components/controls/dialogs/sem-confirm-dialog/sem-confirm-dialog.component';
import { Subscription } from 'rxjs';
import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';
import { DynamicFieldsManagerService } from '../dynamic-fields/dynamic-fields-manager.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FileUploadStandaloneService } from '../file-upload-standalone/file-upload-standalone.service';
import { StateModel } from '../../models/navigation/state-model';


@Injectable({
  providedIn: 'root'
})
export class RouteNavigationService {
  private _configData: any;
  private _promise: Promise<any>;
  private _promiseDone: boolean = false;
  private masterPageComponent: any;
  private masterPages = [];
  public wgRoutes:any[] = [];
  public loadedRoutes = [];
  public leftMenuNodes = [];
  public loadedRoute: any = null;
  public isPageInHalf:boolean = false;

  masterPage: string;
  masterPageUrl: string;

  constructor(private localStorageService: LocalStorageService, private http: HttpClient, private model: RequestModel, private restProvider: RestProviderService, private compiler: Compiler, private injector: Injector, public fileUploadService: FileUploadService, public translationsService: TranslationsProviderService, public dfms: DynamicFieldsManagerService) { }

  loadConfig(url: string): Promise<any> {
    if (this._promiseDone) {
      return Promise.resolve();
    }

    let request = new NavigationRequestModel;
    this.model.url = "/api/navigation/getNodes";
    request.context = "left-menu";
    request.depth = 0;
    request.rootNode = null;

    this.model.data = request;

    this.restProvider.serializer = new NavigationNodeSerializer;

    let cacheData = null;

    if (environment.useRouteAndMasterpageCache) {
      cacheData = this.localStorageService.get("/api/navigation/getNodes_left-menu");
    }

    if (cacheData) {
      DynamicLoadingUtil.navigationNodes = JSON.parse(cacheData);
      this.dfms._routesLoaded.next(DynamicLoadingUtil.navigationNodes);
    }
    else {

      this.restProvider.getDataPOST<NavigationResponseModel[]>(this.model).subscribe(
        data => {
          this.localStorageService.add("/api/navigation/getNodes_left-menu", JSON.stringify(data));
          DynamicLoadingUtil.navigationNodes = data;
          this.restProvider.serializer = null;
          this.dfms._routesLoaded.next(data);
        },
        error => {
          console.log("Error", error);
        }
      );
    }

    let routeDoesntExists = true;

    for (let route in this.router.config) {
      if (route["path"] == url) {
        routeDoesntExists = false;
      }
    }

    //console.log("Current routes: ", this.router.config);

    if (routeDoesntExists) {
      return new Promise((resolve, reject) => {


        this.model.url = "api/navigation/getMasterPages";
        this.model.data = new NavigationRequestModel;
        this.model.data.context = environment.wgProfileHost.substring(environment.wgProfileHost.indexOf('//') + 2); //window.location.host;

        this.restProvider.getDataPOST(this.model).subscribe(
          async (data: any[]) => {

            this.masterPages = data;

            //for (var i = 0; i < data.length; i++) {
            //  if (data[i]["Url"] == url.split("/")[0]) {
            //    await this.registerMasterPage(data[i]);
            //  }
            //}

            this.model.url = "api/navigation/getRoutes";
            let routesJson = this.localStorageService.get("api/navigation/getRoutes");

            if(routesJson != null){

              data = JSON.parse(routesJson);
              this.wgRoutes = data;
              for (var i = 0; i < data.length; i++) {
                var urlToCompare = data[i]["PrettyUrl"];
                if (urlToCompare == url) {
                  this.loadedRoute = data[i];
                  await this.registerRoute(data[i]);
                  break;
                }
              }

              resolve(true);
            }else{
              this.restProvider.getDataPOST(this.model).subscribe(
                async (data: any[]) => {
  
                  this.wgRoutes = data;
                  this.localStorageService.add("api/navigation/getRoutes", JSON.stringify(data));
  
                  for (var i = 0; i < data.length; i++) {
                    var urlToCompare = data[i]["PrettyUrl"];
                    if (urlToCompare == url) {
                      this.loadedRoute = data[i];
                      await this.registerRoute(data[i]);
                      break;
                    }
                  }
  
                  resolve(true);
                },
                error => {
                  console.log("Error", error);
                  reject(false);
                }
              );
            }

            

            //resolve();
          },
          error => {
            console.log("Error", error);
            reject(false);
          }
        );
      });
    }
    else {
      return null;
    }
  }

  get configData(): any {
    return this._configData;
  }

  public get router(): Router { //this creates router property on your service.
    return this.injector.get(Router);
  }

  getCurrentRoute(){
    let routesJson = localStorage.getItem("api/navigation/getRoutes");

    if (routesJson) {
      let data = JSON.parse(routesJson);
      for (var i = 0; i < data.length; i++) {
        var urlToCompare = data[i]["PrettyUrl"];
        if (urlToCompare == window.location.pathname.substring(1)) {
          return data[i];
        }
      }
    }

    return null;
  }

  registerRoute(wgRoute): Promise<any> {

    return new Promise((resolve, reject) => {

      this.masterPageUrl = "/MasterPages/";
      let masterPageLoaded: boolean = false;
      let formInnerMasterPage: string = "";

      var masterPagePreEl = window.location.pathname.substring(1).split("/")[0];

      for (var i = 0; i < this.masterPages.length; i++) {
        if (this.masterPages[i].Url == masterPagePreEl) {
          this.masterPageUrl += this.masterPages[i].MasterPage;
          masterPageLoaded = true;
        }
      }

      this.restProvider.getDataGET(this.masterPageUrl).subscribe(masterPageTemplate => {

        this.masterPage = masterPageTemplate;

        this.restProvider.getDataGET("/MasterPages/" + wgRoute.MasterPage).subscribe(masterPageInnerTemplate => {

          this.masterPage = masterPageTemplate;

          this.restProvider.getDataGET(wgRoute.Url).subscribe(async rawTemplate => {

            this.loadedRoutes.push({ Url: wgRoute.Url, Html: rawTemplate, ExplicitEditMode: wgRoute.ExplicitEditMode });

            /*if(this.loadedRoute.ExplicitEditMode){
              rawTemplate = "<sem-edit-mode-btn></sem-edit-mode-btn>" + rawTemplate;
            }*/

            if (masterPageLoaded) {
              let formName: string = wgRoute.Title;
              this.masterPage = this.masterPage.replace("<*--formTemplatePlaceholder-->", masterPageInnerTemplate);
              this.masterPage = this.masterPage.replace("<*--formInnerTemplatePlaceholder-->", rawTemplate);
              this.masterPage = this.masterPage.replace("addNewLink", wgRoute.PrettyUrl);
              this.masterPage = this.masterPage.replace("<*--sofm-->", wgRoute.SerializationObjectFormLink);

              let trans = null;
              if (typeof this?.translationsService?.translations != "undefined" && this?.translationsService?.translations) {
                if (typeof this?.translationsService?.translations[formName] != "undefined" && typeof this?.translationsService?.translations[formName]) {
                  trans = typeof this?.translationsService?.translations[formName];
                }
              }

              if (trans) {
                this.masterPage = this.masterPage.replace("<*--pageName-->", this.translationsService?.translations[formName].toUpperCase());
              }

            } else {
              this.masterPage = this.masterPage.replace("<app-root></app-root>", rawTemplate);
            }
            
            const tmpCmp = Component({ template: this.masterPage.toString(), providers:[FileUploadStandaloneService] })(class extends SemDynamicComponent {
              submitedModel = {};
              actionFinishedSubscription: any;
              showSideForm: boolean = false;
              showPhotosContainer: boolean = false;
              showFilesContainer: boolean = false;
              showFingerPrintContainer: boolean = false;
              collapseSideFormByDefault: boolean = false;
              includeDocumentLinks: boolean = false;

              loaderSubscription: Subscription = null;
              titleIcon: string = "assets/css/images/platform/ikona-user@3x.png";                                          

              ngAfterViewChecked() {

              }

              ngOnDestroy() {
                this.actionsService.setActionAsFinished(undefined);
                if (typeof this.actionFinishedSubscription != "undefined" && this.actionFinishedSubscription != null) {
                  this.actionFinishedSubscription.unsubscribe();
                }

                if (this.loaderSubscription != null) {
                  this.loaderSubscription.unsubscribe();
                }
              }

              ngOnInit() {

                this.checkSideForm();
                var currentRoute = this.utility.getCurrentRoute();

                if (currentRoute) {
                  let tIcon = currentRoute["TitleIcon"];
                  if (tIcon) {
                    if (tIcon.length > 0) {
                      this.titleIcon = tIcon;
                    }
                  }
                }

                this.actionFinishedSubscription = this.actionsService.currentActionFinished.subscribe(value => {
                  if (typeof value != "undefined" && value != null) {
                    this.utility.reqStatus = value.MessageType;
                    this.utility.reqMessage = value.Message;
                    this.utility.reqResponse = true;
                    this.resetResponseStatus();
                  }
                });

                this.model = {
                  SerializationObjectFormLink: this.SerializationObjectFormLink
                };

                /*this.mainForm.valueChanges.subscribe(val => {
                  if (this.mainForm.submitted) {
                    this.checkErrorsOnForm();
                  }
                });*/

                this.loaderSubscription = this.loaderService.subject.subscribe(data => {
                  if (data) {
                    this.updateStatus(data);
                  }
                });

              }

              checkErrorsOnForm() {
                /*this.utility.reqMessage = null;
                this.mandatoryFields = [];
                for (const name in this.mainForm.controls) {
                  if (this.mainForm.controls[name].invalid) {
                    this.mandatoryFields.push(this.translationsProviderService.labels[name]);
                  }
                }
  
                /*if (this.mandatoryFields.length == 0) {
                  this.utility.reqResponse = false;
                }*/
              }

              closeErrorBox() {
                this.loading = false;
                this.utility.reqResponse = false;
              }

              toggleErrorBox() {
                this.utility.reqResponse = !this.utility.reqResponse;
              }

              checkSideForm() {
                let requestM: RequestModel = new RequestModel();
                requestM.url = "api/dynamicforms/sideforminfo";
                requestM.data = { "DynamicFormRouteUrl": window.location.pathname };
                requestM.contentType = "application/json";

                this.loading = true;

                this.restProviderService.getDataPOST(requestM).subscribe(data => {
                  if (data != null && typeof data != "undefined") {

                    this.showFilesContainer = data["ShowFiles"];
                    this.showFingerPrintContainer = data["ShowFingerPrints"];
                    this.showPhotosContainer = data["ShowPhotos"];
                    this.collapseSideFormByDefault = data["CollapseSideFormByDefault"];
                    this.includeDocumentLinks = data["IncludeDocumentLinks"];

                    if (this.showFilesContainer == true || this.showFingerPrintContainer == true || this.showPhotosContainer == true) {
                      this.showSideForm = true;
                    } else {
                      this.showSideForm = false;
                    }
                  }

                  this.loading = false;
                },error=>{
                  this.loading = false;
                });

                //return false;
              }

              // info: this is now called from sem-dynamic.component.ts -> method = formSubmit()
              async onSubmit(f) {
                /*if (this.mainForm.valid) {*/                
                var res = await this._submitAll();
                /*} else {
  
                  this.checkErrorsOnForm();
                  this.utility.reqStatus = ResponseStatus.ErrorMandatoryFields;
                  this.utility.reqResponse = true;
                  this.resetResponseStatus();
  
                }*/
              }

              async _submitAll() {


                this.loading = true;                         
                this.submitFilesRequestEvent.next();
                //await this.fileDelete();

                // if you uncomment this for testing purposes, after you're done then comment again because in some cases this can lead to save fail
                //console.log(JSON.stringify(this.model));

                //console.log("MEDIAS before saving : ", this.model["Medias"]);
                return await this.submit(this.model).subscribe(data => {

                  //console.log("Model submitted");

                  //this.loading = false;

                  if (data["Metadata"] != null && typeof data["Metadata"] != "undefined" && data["Metadata"].ServerMessageMetadata != null && typeof data["Metadata"].ServerMessageMetadata != "undefined" && data["Metadata"].ServerMessageMetadata.MessageType == 2) {
                    // ok envelope response but error type                      
                    this.utility.reqMessage = data["Metadata"].ServerMessageMetadata.Message;
                    this.utility.reqStatus = ResponseStatus.Error;
                    this.utility.reqResponse = true;
                    this.resetResponseStatus();

                    if (this.utility.afterFormSubmitErrorJS != null && typeof this.utility.afterFormSubmitErrorJS != "undefined" && this.utility.afterFormSubmitErrorJS.length > 0) {
                      // if error occurs on submit -> execute afterFormSubmitErrorJS logic	
                      this.utility.afterFormSubmitErrorJS = this.utility.afterFormSubmitErrorJS.replace(/\\/g, "");
                      let preResult = function (str) {
                        return eval(str);
                      }.call(this, this.utility.afterFormSubmitErrorJS);
                    }

                    this.loading = false;

                  } else {

                    if (this.utility.redirectUrlAfterSubmit != null && typeof this.utility.redirectUrlAfterSubmit != "undefined" && this.utility.redirectUrlAfterSubmit.length > 0) {
                      this.utility.processNavigationUrl(this.utility.redirectUrlAfterSubmit, null, true);
                    } else {
                      if (typeof data["Model"] != "undefined" && data["Model"] != null) {

                        var preperedModel = data["Model"];
                        preperedModel = this.dfms.setInitValues(preperedModel);

                        this.model = preperedModel;
                        this.model["SerializationObjectFormLink"] = this.SerializationObjectFormLink;

                        this.formName = this.model["Title"];

                        this.changeDet.detectChanges();
                        var m = data["Metadata"].ServerMessageMetadata.Message;
                        this.updateStatusWithMessage(1, false, m);
                        this.changeDet.detectChanges();

                        this.fileUpload().subscribe(
                          data => {
                            //console.log("Observable finished");

                            if (data == FileUploadObservableState.Finished) {

                              let stModel = null;
                              if (this.utility.tabInformations != null) {
                                stModel = {};
                                stModel["tabInformations"] = { tabId: this.utility.tabInformations["tabId"] };
                                this.utility.tabInformations = null;
                              }
                              
                              this.utility.reqStatus = ResponseStatus.Ok;
                              //no need to reset default value of response message if it is successfull (custom text when saving entity)
                              //this.utility.reqMessage = null;
                              this.utility.reqResponse = true;

                              if (this.router.url.includes("?id=")) {
                                
                                setTimeout(() => {
                                  this.utility.processNavigationUrl(this.router.url, stModel, true);
                                }, 1500);
                              } else {
                                
                                setTimeout(() => {
                                  this.utility.processNavigationUrl(this.router.url + "?id=" + this.model["Id"], stModel, true);
                                }, 1500);
                              }

                            }
                            else if (data == FileUploadObservableState.Error) {
                              this.utility.reqMessage = "Napaka pri shranjevanju datoteke. Pripeta datoteka ni bila shranjena."
                              this.utility.reqStatus = ResponseStatus.Error;
                              this.utility.reqResponse = true;
                              this.resetResponseStatus();
                              DynamicLoadingUtil.loader.showLoader = false;
                              this.loading = false;
                            }
                            else{
                              this.utility.reqMessage = "Shranjevanje datotek v teku ..."
                              this.utility.reqStatus = ResponseStatus.Ok;
                              this.utility.reqResponse = true;
                            }
                          },
                          error => {
                            console.log("Observable error", error);
                            this.utility.reqMessage = "Napaka pri shranjevanju datoteke. Pripeta datoteka ni bila shranjena."
                            this.utility.reqStatus = ResponseStatus.Error;
                            this.utility.reqResponse = true;
                            this.resetResponseStatus();
                            DynamicLoadingUtil.loader.showLoader = false;
                            this.loading = false;
                          },
                          () => {
                            console.log("We made it here: ", this);
                            DynamicLoadingUtil.loader.showLoader = false;
                          });

                      } else {

                        this.utility.reqStatus = ResponseStatus.Ok;
                        this.utility.reqMessage = null;
                        this.utility.reqResponse = true;
                        setTimeout(() => {
                          this.utility.processNavigationUrl("cms/dashboard", null, false);
                        }, 1500);

                        
                      }
                    }
                  }

                }, error => {

                  try {
                    if (this.utility.afterFormSubmitErrorJS != null && typeof this.utility.afterFormSubmitErrorJS != "undefined" && this.utility.afterFormSubmitErrorJS.length > 0) {
                      // if error occurs on submit -> execute afterFormSubmitErrorJS logic	
                      this.utility.afterFormSubmitErrorJS = this.utility.afterFormSubmitErrorJS.replace(/\\/g, "");
                      let preResult = function (str) {
                        return eval(str);
                      }.call(this, this.utility.afterFormSubmitErrorJS);
                    }
                  } catch (e) {
                    console.log(e);
                  }

                  console.log("Error saving entity", error);
                  this.utility.reqMessage = this.translationsProviderService.translate("galis_js_error_saving_entity");
                  this.utility.reqStatus = ResponseStatus.Error;
                  this.utility.reqResponse = true;
                  this.resetResponseStatus();
                  this.loading = false;
                });

              }

              updateStatus(status, hideLoader: boolean = true) {
                if (status == 1) {
                  this.utility.reqStatus = ResponseStatus.Ok;
                  this.utility.reqMessage = null;
                  this.utility.reqResponse = true;
                }
                else {
                  this.utility.reqStatus = ResponseStatus.Error;
                  this.utility.reqMessage = null;
                  this.utility.reqResponse = true;
                }
                this.resetResponseStatus();

                if (hideLoader == true) {
                  this.loading = false;
                }
              }

              //usefull when we have custom message with response (used in post save/submit)
              updateStatusWithMessage(status, hideLoader: boolean = true, message: string) {
                if (status == 1) {
                  this.utility.reqStatus = ResponseStatus.Ok;
                  this.utility.reqMessage = (message != null || message != "undefined") ? message : null;
                  this.utility.reqResponse = true;
                }
                else {
                  this.utility.reqStatus = ResponseStatus.Error;
                  this.utility.reqMessage = null;
                  this.utility.reqResponse = true;
                }
                this.resetResponseStatus();

                if (hideLoader == true) {
                  this.loading = false;
                }
              }

              _addNew(url) {

                this.stateModel = new StateModel;
                this.utility.stateObjectHistory = [];

                if (this.mainForm.dirty) {

                  const dialogRef = this.dialog.open(SemConfirmDialogComponent, { width: '350px', height: '185px', data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
                  dialogRef.afterClosed().subscribe(result => {

                    if (result) {

                      this.model = {};
                      this.loading = false;
                      var navigated = false;
                      this.mandatoryFields = [];

                      this.loading = true;
                      DynamicLoadingUtil.loader.showLoader = true;
                      if (this.params.hasOwnProperty('urlNew')) {
                        this.utility.processNavigationUrl(this.params.urlNew, null, true);
                        navigated = true;
                      } else if (this.params.hasOwnProperty('url')) {
                        this.utility.processNavigationUrl(this.params.url, null, true);
                        navigated = true;
                      } else {
                        this.utility.processNavigationUrl(window.location.pathname.split("?")[0], null, true);
                      }
                      DynamicLoadingUtil.loader.showLoader = false;
                      this.loading = false;
                    }

                  });
                } else {
                  this.model = {};
                  this.loading = false;
                  var navigated = false;
                  this.mandatoryFields = [];
                  
                  DynamicLoadingUtil.loader.showLoader = true;
                  if (this.params.hasOwnProperty('urlNew')) {
                    this.utility.processNavigationUrl(this.params.urlNew, null, true);
                    navigated = true;
                  } else if (this.params.hasOwnProperty('url')) {
                    this.utility.processNavigationUrl(this.params.url, null, true);
                    navigated = true;
                  } else {
                    this.utility.processNavigationUrl(window.location.pathname.split("?")[0], null, true);
                  }
                  //DynamicLoadingUtil.loader.showLoader = false;
                }

              }

              _delete() {

                this.getPrompt().subscribe(result => {
                  if (typeof result != "undefined") {
                    if (result) {
                      this.loading = true;
                      super.delete(this.model).subscribe(data => {

                        if (data == null) {
                          //console.log("Model submited for delete");
                          //console.log(data);
                          this.ngOnInit();
                          this.utility.reqStatus = ResponseStatus.Ok;
                          this.formName = "Add new";
                        } else {
                          this.utility.reqStatus = ResponseStatus.Error;
                        }

                        this.utility.reqResponse = true;
                        this.resetResponseStatus();
                        this.loading = false;
                        this._addNew(this.router.url);

                      }, error => {

                        console.log("Error deleting the entity", error);
                        this.utility.reqStatus = ResponseStatus.Error;
                        this.utility.reqResponse = true;
                        this.resetResponseStatus();
                        this.loading = false;

                      });
                    }
                  }
                });
              }

              resetResponseStatus() {
                setTimeout(() => {
                  this.utility.reqResponse = false;
                  this.mandatoryFields = [];
                }, 6000);
              }

              sidebarChanged(event) {
                this.sidebarMenuOpened = event;
              }

            });



            let masterPageName = wgRoute.Title.split("/")[0];
            const routes = [{ path: '', component: tmpCmp }];
            const tmpModule = NgModule({ imports: [CommonModule, SemSharedModule, SemNavigationModule, RouterModule.forChild(routes)], declarations: [tmpCmp], providers: [] })(class {
            });

            await this.compiler.compileModuleAsync(tmpModule).then((module) => {

              const appRoutes = [...this.router.config];

              const route = {
                path: wgRoute.PrettyUrl,
                loadChildren() {
                  return module;
                },
                data: {
                  breadcrumb: 'page'
                }
              };

              (<SemDynamicComponent>tmpCmp.prototype).SerializationObjectFormLink = wgRoute.SerializationObjectFormLink;

              appRoutes.push(route);

              this.router.resetConfig(appRoutes);
              //console.log("Registered routes: ");
              //console.log(this.router.config);

            });

            resolve(true);

          }, error => {
            console.log("Error retrieving template", error);
            reject(false);
          });

        }, error => {
          console.log("Error retrieving inner master page template", error);
          reject(false);
        });


      }, error => {
        console.log("Error retrieving masterpage", error);
        reject(false);
      });
      //observable execution

    });
  }

  registerMasterPage(wgRoute): Promise<boolean> {

    return new Promise((resolve, reject) => {


      this.masterPageUrl = "/MasterPages/";

      this.restProvider.getDataGET(this.masterPageUrl + wgRoute["MasterPage"]).subscribe(masterPageTemplate => {

        this.masterPage = masterPageTemplate;


        this.restProvider.getDataGET(this.masterPageUrl + wgRoute["MasterPage"]).subscribe(async rawTemplate => {

          this.masterPage = this.masterPage.replace("<*--formTemplatePlaceholder-->", rawTemplate);
          this.masterPage = this.masterPage.replace("addNewLink", wgRoute.Title);
          this.masterPage = this.masterPage.replace("<*--sofm-->", wgRoute.SerializationObjectFormLink);

          const tmpCmp = Component({ template: this.masterPage.toString() })(class extends SemDynamicComponent {

            menuOpened: boolean = false;
            //@ViewChild(SemLeftMenuComponent) leftMenu: SemLeftMenuComponent;
            masterPage: string;
            masterPageUrl: string;

            super() {

            }

            ngOnInit() {
            }

            isMenuOpened() {
              //return this.leftMenu.opened;
            }

          });


          this.masterPageComponent = tmpCmp;

          const routes = [{ path: '', component: tmpCmp }];
          const tmpModule = NgModule({ imports: [CommonModule, SemSharedModule, SemNavigationModule, RouterModule.forChild(routes)], declarations: [tmpCmp], providers: [] })(class {
          });

          await this.compiler.compileModuleAsync(tmpModule).then((module) => {

            const appRoutes = [...this.router.config];

            const route = {
              path: wgRoute.Url,
              loadChildren() {
                return module;
              },
              children: []
            };


            (<SemDynamicComponent>tmpCmp.prototype).SerializationObjectFormLink = wgRoute.SerializationObjectFormLink;

            appRoutes.push(route);




            // test navigation
            //this.router.navigateByUrl(wgRoute.Url);


          });

          resolve(true);

        }, error => {
          console.log("Error retrieving template", error);
          reject(false);
        });


      }, error => {
        console.log("Error retrieving masterpage", error);
        reject(false);
      });
      // observable execution

    });
  }
}


export function configServiceFactory(injector: Injector, configService: RouteNavigationService): Function {
  return () => {

    var router = injector.get(Router);
    var url = window.location.pathname.substring(1);
    router.onSameUrlNavigation = "reload";

    var compExists = false;

    for (var i = 0; i < router.config.length; i++) {
      if (router.config[i]["path"] == url) {
        compExists = true;
        break;
      }
    }

    if (!compExists) {
      return injector.get(RouteNavigationService)
        .loadConfig(url);
    }

    return;
  }
}
