import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationsProviderService } from '../../../../services/translations-provider/translations-provider.service';
import { QueryBuilderConfig } from '../../sem-query-builder/query-builder.interfaces';
import { UserService } from '../../../../services/user/user-provider.service';
import { UserSearchSettingModel } from '../../../../models/search/user-search-setting-model';
import { Router } from '@angular/router';
import { UtilityService } from '../../../../services/utility/utility.service';
import { DynamicLoadingUtil } from '../../../../shared/helpers/dynamic-loading-util';
import { SearchService } from '../../../../services/search/search.service';
import { parse } from 'path';
import { filter } from 'lodash';
import { LocalStorageService } from '../../../../services/local-storage-service/local-storage.service';

@Component({
  selector: 'app-sem-view-settings-dialog',
  templateUrl: './sem-view-settings-dialog.component.html',
  styleUrls: ['./sem-view-settings-dialog.component.css']
})
export class SemViewSettingsDialogComponent implements OnInit {
  
  // other dialog settings
  createNewView: boolean;
  validationErrorMessage: string = "";

  // properties for tab iskalna polja
  availibleSearchFields: any[] = [];
  addedSearchFields: any[] = [];
  originalSearchFieldsList: any[] = [];
  originalAddedSearchFieldsList: any[] = [];
  selectedSearchItem: any;

  // properties for tab prikazna polja
  availibleDisplayFields: any[] = [];
  addedDisplayFields: any[] = [];
  originalDisplayFieldsList: any[] = [];
  originalAddeddisplayFieldsList: any[] = [];
  selectedDisplayItem: any;

  // properties for basic view settings
  viewLabel: string = "";
  placeHolderTitle: string = "";
  currentSettingId: number = 0;

  // properties for query builder
  queryBuilderModel: any;
  queryBConfig: any;

  constructor(public dialogRef: MatDialogRef<SemViewSettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public translationsService: TranslationsProviderService, public userService: UserService, private router: Router,
    private utilityService: UtilityService, private searchService: SearchService, private localStorageService: LocalStorageService) {
  }  

  ngOnInit() {
      
    if(this.dialogData){

      this.createNewView = this.dialogData["createNewView"];

      // basic view info
      // based on current setting ID, we will create copy of some of the fields user cannot set via UI
      this.currentSettingId = this.dialogData["currentSettingId"];
      this.viewLabel = this.dialogData["viewLabel"];
      this.placeHolderTitle = this.dialogData["searchPlaceholderTitle"];

      // search fields tab
      this.availibleSearchFields = this.dialogData["availibleSearchFields"].map(x => Object.assign({}, x));
      this.originalSearchFieldsList = this.dialogData["originalSearchFieldsList"].map(x => Object.assign({}, x));
      this.addedSearchFields = this.dialogData["addedSearchFields"].map(x => Object.assign({}, x));
      this.originalAddedSearchFieldsList = this.dialogData["originalAddedSearchFieldsList"].map(x => Object.assign({}, x));

      // display fields tab
      this.availibleDisplayFields = this.dialogData["availibleDisplayFields"].map(x => Object.assign({}, x));
      this.originalDisplayFieldsList = this.dialogData["originalDisplayFieldsList"].map(x => Object.assign({}, x));
      this.addedDisplayFields = this.dialogData["addedDisplayFields"].map(x => Object.assign({}, x));
      this.originalAddeddisplayFieldsList = this.dialogData["originalAddedDisplayFieldsList"].map(x => Object.assign({}, x));

      // filter tab
      this.queryBuilderModel = this.dialogData["queryBuilderModel"];
      this.queryBConfig = this.dialogData["queryBuilderConfig"];
    }
  }

  searchFieldSearch(searchValue: string) {
    if (typeof searchValue != "undefined" && searchValue.trim() != "") {
      this.availibleSearchFields = this.originalSearchFieldsList.map(x => Object.assign({}, x));
      let lowerCaseSearchValue = searchValue.toLocaleLowerCase();
      let filteredItems = this.availibleSearchFields.filter(x => x.Translation.toLocaleLowerCase().includes(lowerCaseSearchValue));
      this.availibleSearchFields = filteredItems;
    }else{
      this.availibleSearchFields = this.originalSearchFieldsList.map(x => Object.assign({}, x));
    }
  }

  displayFieldSearch(searchValue: string) {
    if (typeof searchValue != "undefined" && searchValue.trim() != "") {
      this.availibleDisplayFields = this.originalDisplayFieldsList.map(x => Object.assign({}, x));
      let lowerCaseSearchValue = searchValue.toLocaleLowerCase();
      let filteredItems = this.availibleDisplayFields.filter(x => x.Translation.toLocaleLowerCase().includes(lowerCaseSearchValue));
      this.availibleDisplayFields = filteredItems;
    }else{
      this.availibleDisplayFields = this.originalDisplayFieldsList.map(x => Object.assign({}, x));
    }
  }

  searchListItemClick(event, newValue) {
    this.selectedSearchItem = newValue;
  }
  displayListItemClick(event, newValue) {
    this.selectedDisplayItem = newValue;
  }

  closeDialog(){    
    this.dialogRef.close(null);
  }

  deleteView(){
    if(this.currentSettingId && this.currentSettingId > 0){
      
      this.userService.deleteSearchView(this.currentSettingId).subscribe({
        next: response => {
                              
          if(response.error === true){
            this.showCustomErrorMessage("Napaka: " + response.message);              
          }else{
            // we get UserSearchSetting.View.Id back, we only need this to redirect to new view
            let currentUrl = this.router.url;
            // data represent id of UserSearchSetting.View (this is what we need to redirect to new view, everything else in url is the same)
            if(response.data && response.data > 0){
              // redirect to new view
              // replace view url param with new viewid            
              const newViewUrl = this.utilityService.updateURLQueryParam(currentUrl, 'view', response.data.toString());
              this.utilityService.processNavigationUrl(newViewUrl, null, true, true);
              this.dialogRef.close();
            }else{
              // if no default view, redirect to dashboard
              //this.utilityService.processNavigationUrl(newViewUrl, null, true, true);
              this.dialogRef.close();
            }  
          }

          DynamicLoadingUtil.loader.showLoader = false;         
        },
        error: error => { 
          this.showCustomErrorMessage("Napaka");       
          DynamicLoadingUtil.loader.showLoader = false;
        }
      });
    }
  }

  addSearchField() {
    if (typeof this.selectedSearchItem != "undefined" && this.selectedSearchItem != null) {
      // remove from array of availibleFields
      let index = this.availibleSearchFields.findIndex(x => x.Name == this.selectedSearchItem.Name);
      if (index >= 0) {
        //this.originalFieldsList = this.availibleFields.map(x => Object.assign({}, x));
        this.availibleSearchFields.splice(index, 1);
        // add to array of added fields
        this.addedSearchFields.push(this.selectedSearchItem);

        if (this.originalSearchFieldsList != null && typeof this.originalSearchFieldsList != "undefined") {
          // find selected in original and remove
          let i = this.originalSearchFieldsList.findIndex(x => x.Name == this.selectedSearchItem.Name);
          if (i >= 0) {
            this.originalSearchFieldsList.splice(i, 1);
          }
        }

        
        // selectedGridField is next availible grid field
        let newSelectedItem = this.availibleSearchFields[index - 1];
        if(newSelectedItem < 0){
          newSelectedItem = 0;
        }

        if(newSelectedItem != null && typeof newSelectedItem != "undefined"){
          this.selectedSearchItem = newSelectedItem;
        }else{
          // get first if at least one field is present
          if(this.availibleSearchFields.length > 0){          
            this.selectedSearchItem = this.availibleSearchFields[0];
          }else{
            this.selectedSearchItem = null;
          }                
        }   
      }
    }
  }

  addDisplayField() {
    if (typeof this.selectedDisplayItem != "undefined" && this.selectedDisplayItem != null) {
      // remove from array of availibleFields
      let index = this.availibleDisplayFields.findIndex(x => x.Name == this.selectedDisplayItem.Name);
      if (index >= 0) {
        //this.originalFieldsList = this.availibleFields.map(x => Object.assign({}, x));
        this.availibleDisplayFields.splice(index, 1);
        // add to array of added fields
        this.addedDisplayFields.push(this.selectedDisplayItem);

        if (this.originalDisplayFieldsList != null && typeof this.originalDisplayFieldsList != "undefined") {
          // find selected in original and remove
          let i = this.originalDisplayFieldsList.findIndex(x => x.Name == this.selectedDisplayItem.Name);
          if (i >= 0) {
            this.originalDisplayFieldsList.splice(i, 1);
          }
        }

        
        // selectedGridField is next availible grid field
        let newSelectedItem = this.availibleDisplayFields[index - 1];
        if(newSelectedItem < 0){
          newSelectedItem = 0;
        }

        if(newSelectedItem != null && typeof newSelectedItem != "undefined"){
          this.selectedDisplayItem = newSelectedItem;
        }else{
          // get first if at least one field is present
          if(this.availibleDisplayFields.length > 0){          
            this.selectedDisplayItem = this.availibleDisplayFields[0];
          }else{
            this.selectedDisplayItem = null;
          }                
        }   
      }
    }
  }

  removeAddedSearchField() {
    if (typeof this.selectedSearchItem != "undefined" && this.selectedSearchItem != null) {
      // remove from array of addedFields
      let index = this.addedSearchFields.findIndex(x => x.Name == this.selectedSearchItem.Name);
      if (index >= 0) {
        this.addedSearchFields.splice(index, 1);
        // add to array of availibleFields
        this.availibleSearchFields.unshift(this.selectedSearchItem);
        this.originalSearchFieldsList.unshift(this.selectedSearchItem);
        this.selectedSearchItem = null;
      }


       // selectedGridField is next availible grid field
       let newSelectedItem = this.addedSearchFields[index - 1];
       if(newSelectedItem < 0){
         newSelectedItem = 0;
       }
 
       if(newSelectedItem != null && typeof newSelectedItem != "undefined"){
         this.selectedSearchItem = newSelectedItem;
       }else{
         // get first if at least one field is present
         if(this.addedSearchFields.length > 0){          
           this.selectedSearchItem = this.addedSearchFields[0];
         }else{
           this.selectedSearchItem = null;
         }                
       }  
    }
  }

  removeAddedDisplayField(){
    if (typeof this.selectedDisplayItem != "undefined" && this.selectedDisplayItem != null) {
      // remove from array of addedFields
      let index = this.addedDisplayFields.findIndex(x => x.Name == this.selectedDisplayItem.Name);
      if (index >= 0) {
        this.addedDisplayFields.splice(index, 1);
        // add to array of availibleFields
        this.availibleDisplayFields.unshift(this.selectedDisplayItem);
        this.originalDisplayFieldsList.unshift(this.selectedDisplayItem);
        this.selectedDisplayItem = null;
      }


       // selectedGridField is next availible grid field
       let newSelectedItem = this.addedDisplayFields[index - 1];
       if(newSelectedItem < 0){
         newSelectedItem = 0;
       }
 
       if(newSelectedItem != null && typeof newSelectedItem != "undefined"){
         this.selectedDisplayItem = newSelectedItem;
       }else{
         // get first if at least one field is present
         if(this.addedDisplayFields.length > 0){          
           this.selectedDisplayItem = this.addedDisplayFields[0];
         }else{
           this.selectedDisplayItem = null;
         }                
       }  
    }
  }

  doubleClickSearchFieldAdd(){
    if(this.selectedSearchItem != null && typeof this.selectedSearchItem != "undefined"){
      this.addSearchField();
    }    
  }

  doubleClickDisplayFieldAdd(){
    if(this.selectedDisplayItem != null && typeof this.selectedDisplayItem != "undefined"){
      this.addDisplayField();
    }    
  }

  doubleClickSearchFieldRemove(){
    if(this.selectedSearchItem != null && typeof this.selectedSearchItem != "undefined"){
      this.removeAddedSearchField();
    }    
  }

  doubleClickDisplayRemove(){
    if(this.selectedDisplayItem != null && typeof this.selectedDisplayItem != "undefined"){
      this.removeAddedDisplayField();
    }    
  }

  translate(key: string) {
    return this.translationsService.translate(key);
  }

  saveUpdateViewSettings(){
    DynamicLoadingUtil.loader.showLoader = true;
    
    let searchFieldsMapped: any[] = [];
    if(this.addedSearchFields){
      let orderCtn = 1;
      this.addedSearchFields.forEach(element => {
        searchFieldsMapped.push({ "PropertyPath": element.Name, "Order": orderCtn });
        orderCtn++;
      });
    }

    let displayFieldsMapped: any[] = [];
    if(this.addedDisplayFields){
      let orderCtn = 1;
      this.addedDisplayFields.forEach(element => {
        displayFieldsMapped.push({ "PropertyPath": element.Name, "Order": orderCtn });
        orderCtn++;
      });
    }
        
    let filterQuery: string | null = null;
      
    if(this.queryBuilderModel && this.queryBConfig && this.queryBuilderModel.rules && this.queryBuilderModel.rules.length > 0){
      try{
        let parsedQueryBuilder = this.searchService.parseQueryBuilder(this.queryBuilderModel, this.queryBConfig);      
        if(parsedQueryBuilder){
          filterQuery = JSON.stringify(parsedQueryBuilder);
        }

      }catch{}      
    }      

    let requestModel = {
      "CurrentSearchSettingId": this.currentSettingId,
      "SearchViewName": this.viewLabel,
      "SearchPlaceholderTitle": this.placeHolderTitle,
      "FilterQuery": filterQuery,
      "DisplayFields": displayFieldsMapped,
      "SearchFields": searchFieldsMapped
    };

    if(this.createNewView === true){           
  
      this.userService.saveNewSearchView(requestModel).subscribe({
        next: data => {

          if(data){

            if(data.error === true){
              this.showCustomErrorMessage("Napaka: " + data.message);              
            }else{
              // we get UserSearchSetting.View.Id back, we only need this to redirect to new view
              let currentUrl = this.router.url;
              // data represent id of UserSearchSetting.View (this is what we need to redirect to new view, everything else in url is the same)
              if(data.viewid && data.viewid > 0){
                // redirect to new view
                // replace view url param with new viewid            
                const newViewUrl = this.utilityService.updateURLQueryParam(currentUrl, 'view', data.viewid.toString());
                this.utilityService.processNavigationUrl(newViewUrl, null, true, true);
                this.dialogRef.close();
              }
            }
          }else{
            this.showCustomErrorMessage("Napaka");
          }          

          DynamicLoadingUtil.loader.showLoader = false;         
        },
        error: error => { 
          this.showCustomErrorMessage("Napaka");       
          DynamicLoadingUtil.loader.showLoader = false;
        }
      });

    }else{      

      this.userService.updateSearchView(requestModel).subscribe({
        next: response => {

          if(response){

            if(response.error === true){
              this.showCustomErrorMessage("Napaka: " + response.message);              
            }else{
              // we get UserSearchSetting.View.Id back, we only need this to redirect to new view
              let currentUrl = this.router.url;
              // data represent id of UserSearchSetting.View (this is what we need to redirect to new view, everything else in url is the same)
              if(response.data){
                
                // we should delete only for current search view not all
                for (let [key, value] of Object.entries(localStorage)) {
                  if (key.endsWith("usersearch") || key.endsWith("usergrid")) {
                    this.localStorageService.remove(key);
                  }
                }

                // redirect to new view
                // replace view url param with new viewid            
                const newViewUrl = this.utilityService.updateURLQueryParam(currentUrl, 'view', response.data.View.Id.toString());
                this.utilityService.processNavigationUrl(newViewUrl, null, true, true);


                /*this.model["AdditionalSearchFields"] = result["modelAdditionalSearchFields"];
                this.availibleFields = result["availibleFields"];
                this.addedFields = result["addedFields"];
                this.originalFieldsList = result["originalFieldsList"];
                this.originalAddedFielsList = result["originalAddedFieldsList"];
                this.userSearchSettings = result["userSearchSettings"];
                this.searchPlaceholderTitle = result["placeHolder"];
                this.searchService.currentPlaceHoldertitle = this.searchPlaceholderTitle;
                this.searchService.changeCurrentSearchSettings(this.userSearchSettings, "search");
                
                    
      // save is successfull
      // close dialog and send data back      
      var returnData = {
        "modelAdditionalSearchFields": this.addedFields.map(x => Object.assign({}, x)),
        "availibleFields": this.availibleFields,
        "addedFields": this.addedFields,
        "originalFieldsList": this.originalFieldsList,
        "originalAddedFieldsList": this.originalAddedFielsList,
        "userSearchSettings": this.userSearchSettings,
        "placeHolder":this.searchPlaceHolder,
        "restore": restore
      }
                
                
                */
                /*let returnData = {
                  "update":true,
                  "data": response.data
                }*/
                this.dialogRef.close();
              }
            }
          }else{
            this.showCustomErrorMessage("Napaka");
          }          

          DynamicLoadingUtil.loader.showLoader = false;         
        },
        error: error => { 
          this.showCustomErrorMessage("Napaka");       
          DynamicLoadingUtil.loader.showLoader = false;
        }
      });

    }    
  }

  private showCustomErrorMessage(message: string) {
    this.validationErrorMessage = message;
    setTimeout(() => {
      this.validationErrorMessage = "";
    }, 10000); // 5000 milliseconds = 5 seconds
  }
}
