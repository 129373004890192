import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    private router: Router
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if(request.url.endsWith("api/media/GetPreview")){
      request = request.clone({
        headers: request.headers
          // Remove Cache-Control if previously set
          .delete('Cache-Control')
          .delete('Pragma')
          // Optionally, set Cache-Control to a desired value
          // .set('Cache-Control', 'your-desired-value')
      });
    }


    return next.handle(request).pipe(
      tap(event => {
      }),
      catchError((error: HttpErrorResponse) => {

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        if (error.status == 403 || error.status == 401) {
          if (window.location.pathname != "/account/login") {
            localStorage.clear();
            window.location.href = "/account/login";
          }
        }

        //window.alert(errorMessage);
        return throwError(errorMessage);
      })
    );
  }
}
