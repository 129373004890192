import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { SemTextInputComponent } from '../sem-text-input/sem-text-input.component';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { registerLocaleData } from '@angular/common';
import localeAzCa from '@angular/common/locales/az';
import localeAzCaExtra from '@angular/common/locales/extra/az';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import * as _ from "lodash";

import {
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SemDateFormatPipePipe } from '../../../shared/helpers/pipes/sem-date-format-pipe.pipe';
import { SemSharedModule, MY_FORMATS } from 'src/app/shared/modules/sem-shared/sem-shared.module';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'sem-date-picker',
  templateUrl: './sem-date-picker.component.html',
  styleUrls: ['./sem-date-picker.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemDatePickerComponent, multi: true }
  ]
})
export class SemDatePickerComponent extends ValueAccessorBase<string> implements OnInit, AfterViewInit {

  @Input() templateType: string;
  @Input() format: string;
  @Input() setDisabled: boolean = false;
  @Input() defaultValue: string;


  @ViewChild('picker', { read: ElementRef, static: true }) picker: ElementRef;
  @ViewChild('input', { read: ElementRef, static: true }) el: ElementRef;

  type: string;
  view: string;

  
  super() {
  }

  ngOnInit() {
    //registerLocaleData(localeAzCa, localeAzCaExtra);
    //MY_FORMATS.display.dateInput = this.format;

    /*this.dfms._modelValueSubmitValidator.subscribe(data => {
      this.dfms?.globalForm?.controls[this.getAttribute("name")]?.setValue(this.value);
      this.dfms?.globalForm?.controls[this.getAttribute("name")]?.updateValueAndValidity();
    });*/

  }

  ngAfterViewInit() {
    if (typeof this.templateType != "undefined" && this.templateType.length > 0) {
      var splited = this.templateType.split("_");
      this.type = splited[0];
      this.view = splited[1];
    }

    this.getDefaultValue();
    //this.picker.nativeElement.nativeElement.nativeElement.style.zoom = "0.3";
  }

  /*onTextChange(value:moment.Moment) {
    this.value = value.utc().toJSON();
  }*/

  onTextChange(value) {
    if (this.el.nativeElement.value.length > 0) {
      if (this.el.nativeElement.value[this.el.nativeElement.value.length - 1] != ".") {
        if (this.el.nativeElement.value.length == 2) {
          this.el.nativeElement.value = this.el.nativeElement.value + ".";
        } else if (this.el.nativeElement.value.length == 5) {
          this.el.nativeElement.value = this.el.nativeElement.value + ".";
        }
      }
    }
    
    this.value = value;
    this.utility.newSectionAdded.next(this.dfms.globalModel);

    /*this.dfms.globalForm.controls[this.getAttribute("name")].setValue(value.toDate());
    this.dfms.globalForm.controls[this.getAttribute("name")].updateValueAndValidity();
    */

    let val = null;

    if(value){
      val = value.toDate();
    }

    let attName = this.getAttribute("name");
    //this.dfms.globalForm.controls[attName].setValue(value.toDate());
    //this.dfms.globalForm.controls[attName].updateValueAndValidity();

    this.dfms.updateControlsValidationArray(attName,val);

    console.log("datepicker value" + this.value);
    

    if(typeof this.onValueChangeEval != "undefined" && this.onValueChangeEval){
      eval(this.onValueChangeEval);
    }

  }

  getDefaultValue() {
    if ((this.value == null || typeof this.value == "undefined") && this.defaultValue != null && typeof this.defaultValue != "undefined" && this.defaultValue.length > 0) {
      if (this.defaultValue == "today") {
        let val = new Date().toISOString();                 
        let val2: any = moment.utc();
        this.onTextChange(val2);
        
       
      } else {
        if (this.defaultValue.startsWith("${") && this.defaultValue.endsWith("}")) {
          //interpolate
          // use custom delimiter ${ }
          try {
            _.templateSettings.interpolate = /\${([\s\S]+?)}/g;
            let formModel = this.dfms.globalModel;

            if (formModel != null && typeof formModel != "undefined") {
              let compiled = _.template(this.defaultValue);
              let compiledValue = compiled(formModel);
              if (compiledValue != null && compiledValue != "" && typeof compiledValue != "undefined") {
                this.value = compiledValue;
              }
            }
          } catch (e) { }
        } else {
          // defaultValue should be specified in iso for example "2020-10-10T00:00:00.000Z"
          this.value = this.defaultValue;
        }
      }
    }


    return this.value;
  }

  afterPickerOpen() {
  }

  input(value) {
    //this.value = moment(value.toJSON()).format(this.format);
  }

  valueChanged() {
    //var dateFormatPipe = new SemDateFormatPipePipe("az");
    //this.value = dateFormatPipe.transform(this.value, "dd.MM.yyyy");
  }

  preventBlur() {
    setTimeout(() => {
      const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
      input.focus();
      input.select();
    }, 500);
  }
  translate(key: string) {

    if (typeof key != "undefined" && key != null) {
      var k = key.replace("Translation.", "");

      if (this.translationsService.translations[k]) {
        return this.translationsService.translations[k];
      } else {
        return key;
      }
    }

    return key;

  }

  focusOut(value: any) {
    try {
      if (typeof (value) != "undefined") {
        if (value.isUtcOffset() == false) {
          let newDate = new Date(this.value);
          newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
          this.value = moment(newDate).toJSON();
        } else {
          this.value = value.utc().toJSON();
        }
      }
    } catch {

    }

    const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
    input.blur();
  }

}


