import { RootModel } from '../root-model';

export class FileDataRightModel extends RootModel {

  public WriteRight: boolean;
  public DeleteRight: boolean;

  super() {
  }

  constructor(model: any) {
    super();

    if (model != null) {
      this.WriteRight = model["WriteRight"];
      this.DeleteRight = model["DeleteRight"];      
    }
  }

}