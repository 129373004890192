import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { SemHttpUtility } from '../../../shared/helpers/sem-http-utility';
import { RequestModel } from '../../../models/request/request-model';

@Component({
  selector: 'sem-list',
  templateUrl: './sem-list.component.html',
  styleUrls: ['./sem-list.component.css']
})
export class SemListComponent extends SemControlComponent implements OnInit, AfterViewInit {

  reqModel: RequestModel = new RequestModel;

  super() {
  }

  @Input() query: any;

  async ngOnInit() {
    super.ngOnInit();

    let sQuery: string = JSON.stringify(this.query);

    let values: Array<string> = Object.values(this.params);
    for (var i = 0; i < values.length; i++) {
      var exp = "params[" + i + "]";
      sQuery = sQuery.split(exp).join(values[i]);
    }

    this.reqModel.data = JSON.parse(SemHttpUtility.semDecode(sQuery));
    this.reqModel.url = "api/data/list";
    await this.restProviderService.getDataPOST(this.reqModel).subscribe(data => {
      this.model = data;
    });
  }

  async ngAfterViewInit() {

  }

}
