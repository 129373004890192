import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { RestProviderService } from 'src/app/services/rest-provider/rest-provider.service';
import { RequestModel } from 'src/app/models/request/request-model';
import { SemControlComponent } from '../../sem-control/sem-control.component';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';
import { DatePipe } from '@angular/common';
import { SearchService } from 'src/app/services/search/search.service';

@Component({
  selector: 'app-sem-generate-dialog',
  templateUrl: './sem-generate-dialog.component.html',
  styleUrls: ['./sem-generate-dialog.component.css']
})
export class SemGenerateDialogComponent implements OnInit {

  parameterData: any = [];
  form: any;
  model: any = {};

  constructor(
    public dialogRef: MatDialogRef<SemGenerateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private restProvider: RestProviderService,
    private changeDet: ChangeDetectorRef,
    public translationService: TranslationsProviderService,
    public dfms: DynamicFieldsManagerService,
    public translationsService: TranslationsProviderService,
    public searchService: SearchService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.form = this.dialogData["form"];
    if (typeof this.dialogData["reportId"] != "undefined" && this.dialogData["reportId"]) {
      let model = new RequestModel;
      model.url = "api/report/parameters";
      model.data = { ReportId: this.dialogData["reportId"] };

      DynamicLoadingUtil.loader.showLoader = true;

      this.restProvider.getDataPOST(model).subscribe(data => {
        //console.log("Parameter data : ", data);
        this.parameterData = data;
        DynamicLoadingUtil.loader.showLoader = false;
      }, error => {
        //console.log("Error retrieving parameter data", error);
      });
    }
  }

  getProp(prop, expression) {
    let value = "";

    if (expression) {
      let midValue = eval(expression);
      //let midValue = new Function(expression).call(this);
      value = midValue;
    }
    else {
      value = this.dfms.getValue(this.model, prop);
      //this.form.controls[prop + this.sectionId].setValue(value);
    }

    return value;
  }

  setProp(prop, value) {
    this.dfms.assign(this.model, prop, value, true);
    //this.form.controls[prop + this.sectionId].setValue(value);
  }

  print() {
    //console.log("Model: ", this.model);
  }

  async generate() {

    let apiModel = {};
    for (var i = 0; i < Object.keys(this.model).length; i++) {
      apiModel[Object.keys(this.model)[i]] = { "StringValue": this.model[Object.keys(this.model)[i]] };
    }

    //We moved retrieving of values from auto parameters to server side report generation method. Leaving it in, cause you never know :D
    /*for(var i = 0;i<this.parameterData.length;i++){
      if (this.parameterData[i]["IsAuto"]) {

        let res = null;

        try {

          res = await this.searchService.performSearch(JSON.parse(this.parameterData[i]["Query"]).Query, null).toPromise();
          apiModel[this.parameterData[i]["Name"]] = { "StringValue": res["Models"][0]["Model"][Object.keys(res["Models"][0]["Model"])[0]] };

        } catch (e) {
          console.log("Error setting the auto parameter value");
        }

      }
    }*/

    var query = {
      "ReportId": this.dialogData["reportId"],
      "Query": {
        "MainQuery": null,
        "FieldQueries": null,
        "TreeQuery": {
          "Type": "FieldValue",
          "FieldName": "Id",
          "FieldValue": this.dialogData["entityId"],
          "Path": null,
          "Exclude": false,
          "Subqueries": null,
          "BoostFactor": null,
          "Negate": false
        },
        "Facets": null,
        "Projection": [],
        "RequestedFacets": null,
        "Page": 1,
        "PageSize": 100,
        "ClassType": null,
        "ClassTypes": [],
        "SortFields": null,
        "UseCache": true,
        "RepositoryName": "nhibernate",
        "ParseMode": 0,
        "IncludeParents": false
      },
      "ResultType": null,
      "DocumentId": this.dialogData["entityId"],
      "FileName": "report_" + Date.now().toString(),
      "Parameters": apiModel,
      "GlobalConfig": {
        "DateFormat": "dd.MM.yyyy"
      }

    }


    //console.log("Print init.");
    DynamicLoadingUtil.loader.showLoader = true;

    var fModel = new RequestModel;
    fModel.data = query;

    fModel.contentType = "application/json";
    fModel.responseType = "application/octet-stream;base64 ";
    fModel.url = "api/report/generate";
    var datePipe = new DatePipe('en-US');

    this.restProvider.getDataPOST<string>(fModel).subscribe(data => {
      //console.log(data);

      const linkSource = 'data:application/pdf;base64,' + data["Data"];
      const downloadLink = document.createElement("a");
      const fileName = datePipe.transform(Date.now(), "dd_MM_yyyy_hh_mm_ss") + "_report.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      DynamicLoadingUtil.loader.showLoader = false;

    }, error => {
      //console.log(error);
      DynamicLoadingUtil.loader.showLoader = false;
    });

  }
}
