import { Component, Input, OnInit } from '@angular/core';
import { ValueAccessorBase } from '../../shared/value-accessor/value-accessor-base';

@Component({
  selector: 'sem-table-presentation',
  templateUrl: './sem-table-presentation.component.html',
  styleUrls: ['./sem-table-presentation.component.css']
})
export class SemTablePresentationComponent extends ValueAccessorBase<string> implements OnInit {

  @Input() settings: any = null;
  @Input() result: any = null;

  variable: string = "";

  super() { }

  ngOnInit(): void {


    if (this.settings) {
      this.restProviderService.getData(this.settings["ApiUrl"]).subscribe(data => {
        this.result = data;
      },
        error => {

        });
    }


  }

}
