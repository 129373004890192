import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { ClientAction, FileDataModel } from 'src/app/models/file-upload/file-data';
import { FileUtil } from 'src/app/shared/helpers/file-util';

@Component({
  selector: 'sem-file-picker',
  templateUrl: './sem-file-picker.component.html',
  styleUrls: ['./sem-file-picker.component.css']
})

export class SemFilePickerComponent extends SemControlComponent implements OnInit, AfterViewInit, OnDestroy{

  //Supported file extensions
  @Input() availableExtensions: any[] = [];
  @Input() BindingPropertyName: string = null;
  //Html iput type file element
  @ViewChild('chooseFile',{ read: ElementRef, static:true })
  private chooseFile: ElementRef;

  files: any[] = [];  

  super() {}
  
  ngOnInit() {
    this.fileUploadService.files = [];
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(){    
  }
  
  onFileChange(event) {

    for (var i = 0; i < this.chooseFile.nativeElement.files.length; i++) {
      if (this.checkFile(this.chooseFile.nativeElement.files[i])) {

        var split = this.chooseFile.nativeElement.files[i].name.split(".");                
        this.files.push({          
          "Name":split[0],
          "Size":FileUtil.getFriendlyFileSize(this.chooseFile.nativeElement.files[i].size),
          "File":this.chooseFile.nativeElement.files[i],
          "Extension":split[1].toLowerCase()
        });

        // add to fileservice so we can get info from other controls
        let fileDataModel = new FileDataModel(null);
        fileDataModel.Size = this.chooseFile.nativeElement.files[i].size;
        fileDataModel.Name = this.chooseFile.nativeElement.files[i].name;
        fileDataModel.File = this.chooseFile.nativeElement.files[i];        
        this.fileUploadService.files.push(fileDataModel);        
      }
    }

    this.chooseFile.nativeElement.value = "";        
  }

  removeFile(file){    
    if(file != null && typeof file != "undefined"){
      var indx = this.files.findIndex(x => x.Name == file.Name);
      if(indx >= 0){
        this.files.splice(indx, 1);
      }

      // also remove from file upload service list
      var indx2 = this.fileUploadService.files.findIndex(x => x.Name == file.File.name);
      if(indx2 >= 0){
        this.fileUploadService.files.splice(indx2, 1);
      }
    }    
  }

  checkFile(file): boolean {

    if(file == null ||typeof file == "undefined"){
      return false;
    }

    var fileSizeMB = file.size/1024/1024;
    if(fileSizeMB > 100){
      alert("File exceeds 100MB");
      return false;
    }

    if (this.availableExtensions.length > 0) {
      var fileExtentionSplit = file.name.split(".");
      for (var i = 0; i < this.availableExtensions.length; i++) {
        if (fileExtentionSplit[fileExtentionSplit.length - 1].toLowerCase() == this.availableExtensions[i].toLowerCase()) {
          return true;
        }
      }
      return false;
    }
    return true;
  } 
  
  public openFileDialog(): void {
    let event = new MouseEvent('click', { bubbles: false });
    this.chooseFile.nativeElement.dispatchEvent(event);
  }

}
