import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SemControlComponent } from '../../sem-control/sem-control.component';
import { ValueAccessorBase } from '../../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SemParameterDialogComponent } from '../../dialogs/sem-parameter-dialog/sem-parameter-dialog.component';

@Component({
  selector: 'one-to-many-section',
  templateUrl: './one-to-many-section.component.html',
  styleUrls: ['./one-to-many-section.component.css'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: OneToManySectionComponent, multi: true }]
})
export class OneToManySectionComponent extends ValueAccessorBase<any> implements OnInit {

  @Input() bindingPropertyName: any;
  @Input() maxCount: number = -1;
  @Input() sectionTransferUrl: any;
  @Input() sectionContent: any;
  @Input() contentTemplate: any;
  @Input() section: any;
  @Input() forwardEntityName: any;
  @Input() number: any;
  @Input() actions: any = [];
  @Input() hideRepeaterActionsCondition: string;
  @Input() isInline: any;
  currentNumber: number = 0;
  showDD: boolean = false;

  private forwardEntSubs: any = null;


  @Output() removeSectionEvent: EventEmitter<any> = new EventEmitter<any>();

  super() { }

  ngAfterViewInit(): void {
    // for forward entitypurposes
    /*if(this.forwardEntityName != null && typeof this.forwardEntityName != "undefined"){
      this.forwardEntSubs = this.utility.currentDropdownSelectedValue.subscribe(model => {

        if (typeof model != "undefined" && model != null && model.SelectedDropDownBinding != null && typeof model.SelectedDropDownBinding != "undefined") {        
          if(model.SelectedDropDownBinding === this.forwardEntityName && this.model != null && typeof this.model != "undefined"){
            this.model[this.forwardEntityName] = model.SelectedValue;
          }
        }

      });
    }*/
  }

  
  ngOnInit() {
  }

  ngOnDestroy(): void {

    if (typeof this.forwardEntSubs != "undefined" && this.forwardEntSubs != null) {
      this.forwardEntSubs.unsubscribe();
    }
  }

  toggleDD() {
    this.dfms.globalForm.form.markAsDirty();
    this.showDD = !this.showDD;
  }

  
  removeSection(section) {	
    this.removeSectionEvent.emit(section);	
    this.changeDetection.detectChanges();    	
  }	
  showRepeaterActions(){	
    if(this.hideRepeaterActionsCondition != null && typeof this.hideRepeaterActionsCondition != "undefined" && this.hideRepeaterActionsCondition.length > 0){	
      var evalResponse = eval(this.hideRepeaterActionsCondition);	
      if(evalResponse == true){	
        return false;	
      }	
      return true;	
    }	
    return true;	
  }

  evaluateSectionAction(action) {
    if (action.FormAction) {
      
      this.restProviderService.getDataGET("api/actions/" + action.FormAction, "application/json").subscribe((data:any) => {
        let formAction = JSON.parse(data);





        if (formAction) {
          if (formAction.ParameterForm != null && typeof formAction.ParameterForm != "undefined" && formAction.ParameterForm.Id > 0) {
            var reportId = null;
            if (formAction.ParameterForm.Report != null && typeof formAction.ParameterForm.Report != "undefined") {
              reportId = formAction.ParameterForm.Report.Id;
            }
  
            const dialogRef = this.dialog.open(SemParameterDialogComponent, {
              data: {
                parameterFormId: formAction.ParameterForm.Id,
                reportId: reportId,
                customHeaderTitle: formAction.ParameterForm.CustomHeaderTitle,
                customCssClass: formAction.ParameterForm.CustomCssClass
              }
            });
  
            dialogRef.afterClosed().subscribe(returnData => {
              if (returnData != null && typeof returnData != "undefined") {
                // only call action if parameter popup was confirmed (not cancled - close button)
                if (returnData.Canceled == false) {
                  var inputParametersModel = returnData["Model"];
                  this.actionsService.callAction(formAction, this.section, null, null, this, action.BulkAction, inputParametersModel, formAction.OnlyClientLogic, false, action.BulkExport, formAction.SkipBulkActionApiMethod);
                }
              }
            });
          } else {
            // just call action without parameters form popup
            this.actionsService.callAction(formAction, this.section, null, null, this, action.BulkAction, null, formAction.OnlyClientLogic, false, action.BulkExport, formAction.SkipBulkActionApiMethod);
          }
        }




        
        return formAction;
      }, error => {
        console.log(error);
      });

    }
    else if (action.ActionBody) {
      this.evaluateAction(action.ActionBody);
    }
  }

}
