import { Component, OnInit, Compiler, Inject, Injector, NgModule, NgModuleRef, ViewContainerRef, Input, ViewChild, EventEmitter, Output, OnChanges, ComponentRef, KeyValueDiffer, KeyValueChanges, ViewEncapsulation } from '@angular/core';
import { SemTextBoxComponent } from '../../sem-text-box/sem-text-box.component';
import { SemSharedModule } from '../../../../shared/modules/sem-shared/sem-shared.module';
import { CommonModule } from '../../../../../../node_modules/@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR } from '../../../../../../node_modules/@angular/forms';
import { SemControlComponent } from '../../sem-control/sem-control.component';
import { SemDataGridComponent } from '../../sem-data-grid/sem-data-grid.component';
import { RouteNavigationService } from '../../../../services/dynamic-routes-loader/route-navigation.service';
import { SemDynamicComponent } from 'src/app/shared/components/sem-dynamic/sem-dynamic.component';
import { SemNavigationModule } from 'src/app/navigation/modules/sem-navigation/sem-navigation.module';
//import { NgSelectComponent } from '@ng-select/ng-select';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';
import { ValueAccessorBase } from '../value-accessor/value-accessor-base';
import { ParameterFormsService } from 'src/app/services/parameter-forms-service/parameter-forms.service';
import { SemBaseComponent } from 'src/app/components/common/sem-base/sem-base.component';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { UserService } from '../../../../services/user/user-provider.service';
import { LocalStorageService } from '../../../../services/local-storage-service/local-storage.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DynamicFieldsManagerService } from '../../../../services/dynamic-fields/dynamic-fields-manager.service';

@Component({
  selector: 'app-sem-dynamic-control',
  templateUrl: './sem-dynamic-control.component.html',
  styleUrls: ['./sem-dynamic-control.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemDynamicControlComponent, multi: true }
  ]
})
export class SemDynamicControlComponent extends ValueAccessorBase<any> implements OnInit {

  items: any;
  @ViewChild('vc', { read: ViewContainerRef, static: true }) vc: ViewContainerRef;
  @Input() url: any;
  @Input() template: any;
  @Input() entityId: any;
  @Input() entity: any;
  @Input() useForParams: any;  
  @Input() component: any;
  @Input() bindingPropertyName:any;
  @Input() number:any;
  @Input() forwardEntityName:any;
  @Input() isSplitView:boolean = false;

  private forwardEntSubs: any = null;
  public cmpRef: ComponentRef<any>;
  showLoader: boolean = false;

  super() {
  }

  async initDynamicForm() {
    let routeService = this.injector.get(RouteNavigationService);

    /*if(routeService.wgRoutes.length == 0){
      this.showLoader = true;
      await routeService.loadConfig(window.location.pathname.substring(1));
      this.showLoader = false;
    }*/

    if (this.url != "" && this.url != null && typeof (this.url) != "undefined") {
      for (var i = 0; i < routeService.wgRoutes.length; i++) {
        if (routeService.wgRoutes[i]["PrettyUrl"] == this.url || this.useForParams == true) {

          this.showLoader = true;

          let urlReq = routeService.wgRoutes[i]["Url"];

          if(this.useForParams == true) {
            urlReq = this.url;
          }

          this.restProviderService.getDataGET(urlReq).subscribe(async frmTemplate => {

            //console.log("TASK FORM TEMPLATE : ", frmTemplate);            

            var tmpCmp = null;

            if(this.useForParams){              

              var confirmLabel = this.translationsService.translate('galis_js_ParameterForm_Confirm');
              var closeLabel = this.translationsService.translate('galis_js_ParameterForm_Close');

              frmTemplate += "<div class='btn-group btn-group-param-popup'><button (click)='confirmParameterDialog()' class='accept'>" + confirmLabel + "</button><button (click)='cancelParameterDialog()' class='decline'>" + closeLabel + "</button></div>";
            
              // component without extending class SemDynamicComponent
              tmpCmp = Component({ template: frmTemplate })(class extends SemControlComponent implements OnChanges {
  
                model: any;

                ngOnChanges(changes) {                  
                }
  
                ngOnInit() {                  
                  this.model = {};                  

                }

                checkIfInUserGroup(id: any) {                  
                  return this.userService.checkIfInUserGroup(id);
                }

                confirmParameterDialog() {   
                  this.parameterFormService.setParameterFormModel(this.model);
                  this.parameterFormService.closeParameterFormDialog(false);               
                }
              
                cancelParameterDialog() {                  
                  this.parameterFormService.setParameterFormModel({});
                  this.parameterFormService.closeParameterFormDialog(true);
                }

              });  
            }else{
              tmpCmp = Component({ template: frmTemplate, providers:[DynamicFieldsManagerService] })(class extends SemDynamicComponent implements OnChanges {

                ngOnChanges(changes) {
                  //console.log("Changes invoked: ", changes);
                }
  
                ngOnInit() {
                }
              });  
            }
            
            const tmpModule = NgModule({ imports: [CommonModule, SemSharedModule, SemNavigationModule], declarations: [tmpCmp], providers: [NgSelectComponent] })(class {
            });

            await this._compiler.compileModuleAndAllComponentsAsync(tmpModule)
              .then((factories) => {
                const f = factories.componentFactories[factories.componentFactories.length - 1];
                this.cmpRef = f.create(this._injector, [], null, this._m);
                this.cmpRef.instance.name = 'DynamicControlComponent';
                this.cmpRef.instance.isSplitView = this.isSplitView;
                
                if (this.entityId != null && this.entityId != "" && typeof (this.entityId) != "undefined") {
                  this.restProviderService.getDataGET("api/data/form/" + routeService.wgRoutes[i].SerializationObjectFormLink + "/" + this.entityId).subscribe(data => {
                    let jsonData = JSON.parse(data);
                    let prepModel = jsonData["Model"];

                    prepModel["SerializationObjectFormLink"] = routeService.wgRoutes[i].SerializationObjectFormLink;                    
                    this.model = prepModel;
                    this.cmpRef.instance.model = prepModel;
                    this.vc.insert(this.cmpRef.hostView);

                    this.showLoader = false;

                  }, error => {
                    console.log("Error retrieving model for task: ", error);
                  });
                } else {

                  this.cmpRef.instance.model = { SerializationObjectFormLink: routeService.wgRoutes[i].SerializationObjectFormLink };
                  this.vc.insert(this.cmpRef.hostView);
                  this.showLoader = false;
                }

              });


          }, error => { console.log("Error retrieving task template ", error) });
          DynamicLoadingUtil.loader.showLoader = false;
          break;
        }
      }
    }
  }

  formTabChanged(){
    
  }

  /*ngAfterViewInit(): void {
    // for forward entitypurposes
    if(this.forwardEntityName != null && typeof this.forwardEntityName != "undefined"){
      this.forwardEntSubs = this.utility.currentDropdownSelectedValue.subscribe(model => {

        if (typeof model != "undefined" && model != null && model.SelectedDropDownBinding != null && typeof model.SelectedDropDownBinding != "undefined") {        
          if(model.SelectedDropDownBinding === this.forwardEntityName && this.model != null && typeof this.model != "undefined"){
            this.model[this.forwardEntityName] = model.SelectedValue;
          }
        }

      });
    }
  }*/

  ngOnInit() {

    super.ngOnInit;
    this.model = this.entity;

    if (typeof (this.template) != "undefined" && this.template != null && this.template.length > 0) {
      this.initDynamicWithTemplate(this.forwardEntityName);
    }
    else {
      this.initDynamicForm();
    }

  }

  ngOnDestroy(): void {
    
    if (typeof this.forwardEntSubs != "undefined" && this.forwardEntSubs != null) {
      this.forwardEntSubs.unsubscribe();
    }
  }
  
  async initDynamicWithTemplate(forwardEntityName) {

    this.template = this.template.replace(new RegExp('o2m-content', 'g'), '');
    //this.template = this.template + "<div (click)=\"showModel()\">SHOW MODEL</div>";
    let routeService = this.injector.get(RouteNavigationService);
    const tmpCmp = Component({ template: this.template })(class extends ValueAccessorBase<any> implements OnChanges {

      super() { }

      component: SemBaseComponent = null;

      ngOnChanges(changes) {
        //console.log("Changes invoked: ", changes);
      }

      ngOnInit() {
        //console.log("Dynamic form init., model: ", this.model);
      }

      ngAfterViewInit(): void {

        // for forward entitypurposes
        this.utility.currentDropdownSelectedValue.subscribe(model => {
          if(forwardEntityName != null && typeof forwardEntityName != "undefined"){
            if (typeof model != "undefined" && model != null && model.SelectedDropDownBinding != null && typeof model.SelectedDropDownBinding != "undefined") {        
              if(model.SelectedDropDownBinding === forwardEntityName && this.model != null && typeof this.model != "undefined"){
                this.model[forwardEntityName] = model.SelectedValue;
              }
            }
          }          
        });              
      }

      getProp(prop, expression) {
        let value = "";

        if (expression) {
          let midValue = eval(expression);
          //let midValue = new Function(expression).call(this);
          value = midValue;
        }
        else {
          value = this.dfms.getValue(this.model, prop);
        }

        return value;
      }

      setProp(prop, value) {
        this.dfms.assign(this.model, prop, value, true);
      }

      showModel() {
        //console.log("Dynamic model: ", this.model);
      }

      modelChanged($event) {
        //console.log("Dynamic model changed: ", event);
      }

    });

    const tmpModule = NgModule({ imports: [CommonModule, SemSharedModule, SemNavigationModule, FormsModule], exports: [CommonModule, SemSharedModule, SemNavigationModule, FormsModule], declarations: [tmpCmp], providers: [NgSelectComponent, { provide: NG_VALUE_ACCESSOR, useExisting: tmpCmp, multi: true }] })(class {
    });

    await this._compiler.compileModuleAndAllComponentsAsync(tmpModule)
      .then((factories) => {

        const f = factories.componentFactories[factories.componentFactories.length - 1];
        this.cmpRef = f.create(this._injector, [], null, this._m);
        this.cmpRef.instance.name = 'DynamicControlComponent';

        this.cmpRef.instance.component = this.component;
        this.model = this.entity;
        this.model.number = this.number;
        this.model.bindingPropertyName = this.bindingPropertyName;
        this.cmpRef.instance.model = this.entity;
        this.vc.insert(this.cmpRef.hostView);
        this.showLoader = false;
        this.changeDetection.detectChanges();
      });

  }

  public async generateDynamicViewComponent(tmpCmp: any, vc: ViewContainerRef) {

    const tmpModule = NgModule({
      declarations: [tmpCmp],
      imports: [CommonModule, SemSharedModule, FormsModule]
    })(class {
    });

    await this._compiler.compileModuleAndAllComponentsAsync(tmpModule)
      .then((factories) => {
        const f = factories.componentFactories[0];
        const cmpRef = f.create(this._injector, [], null, this._m);
        cmpRef.instance.name = 'DynamicControlComponent';
        vc.insert(cmpRef.hostView);
      });
  }


}
