import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RequestModel } from 'src/app/models/request/request-model';
import { MetaModelService } from 'src/app/services/metamodel/metamodel-provider.service';
import { RestProviderService } from 'src/app/services/rest-provider/rest-provider.service';
import { SearchService } from 'src/app/services/search/search.service';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { UserService } from 'src/app/services/user/user-provider.service';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';
import { SemSearchComponent } from '../../sem-search/sem-search.component';
import { LocalStorageService } from '../../../../services/local-storage-service/local-storage.service';
import { SemConfirmDialogComponent } from '../sem-confirm-dialog/sem-confirm-dialog.component';
import { DialogType } from '../../../../shared/components/sem-dynamic/sem-dynamic.component';

export class UserModel {

  public OldPassword: string = "";
  public NewPassword: string = "";
  public NewPasswordRepeat: string = "";
  public UserName: string = "";
  public Email: string = "";
  public Name: string = "";
  public Surname: string = "";
  public TaskSubscriptionEnabled: boolean = false;
  public MobileNumber: string = "";
  public UserGroupsString: string = "";
  public Id: number = 0;

}

@Component({
  selector: 'app-sem-user-profile-dialog',
  templateUrl: './sem-user-profile-dialog.component.html',
  styleUrls: ['./sem-user-profile-dialog.component.css']
})
export class SemUserProfileDialogComponent implements OnInit {

  public userModel: UserModel = new UserModel();
  public statusMessage: string = "";
  public initials:string = "";  

  constructor(
    public dialogRef: MatDialogRef<SemSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public translationsService: TranslationsProviderService,
    public metaModelService: MetaModelService,
    public userService: UserService,
    public searchService: SearchService,
    public localStorageService: LocalStorageService,
    public restClient: RestProviderService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    var user = this.restClient.currentUser;

    this.userModel.Email = this.restClient.currentUser.Email;
    this.userModel.Name = this.restClient.currentUser.Name;
    this.userModel.Surname = this.restClient.currentUser.Surname;
    this.userModel.UserName = this.restClient.currentUser.Username;
    this.userModel.TaskSubscriptionEnabled = this.restClient.currentUser.TaskSubscriptionEnabled;
    this.userModel.MobileNumber = this.restClient.currentUser.MobileNumber;
    this.userModel.Id = this.restClient.currentUser.Id;

    let userGroups = this.restClient.currentUser.Groups;

    if(userGroups && userGroups.length > 0){
      for(let i = 0; i < userGroups.length; i++){
        this.userModel.UserGroupsString += userGroups[i].Name;
        if((i+1) < userGroups.length){
          this.userModel.UserGroupsString += ", ";
        }
      }
    }

    this.getInitials();
  }

  changePass() {

    const dialogRef = this.dialog.open(SemConfirmDialogComponent, { data: { type: DialogType.Confirmation, text: "V primeru uspešne spremembe gesla vas bo sistem odjavil in na elektronski naslov boste dobili sporočilo o spremembi gesla. Prijavite se lahko z novim geslom." } });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        if (typeof DynamicLoadingUtil.loader != "undefined") {
          DynamicLoadingUtil.loader.showLoader = true;
        }
    
    
        var requestModel = new RequestModel();
        requestModel.url = "api/user/change/pass";    
        requestModel.data = this.userModel;    
    
        var response = this.restClient.getDataPOST(requestModel).subscribe(data => {
    
          if(data){
            let serverMessageData = data["Metadata"]["ServerMessageMetadata"];
    
            // if success - logout user
            if(serverMessageData.MessageType == 1){              
              this.statusMessage = serverMessageData["Message"];
              setTimeout(() => { this.statusMessage = ""; }, 10000);
              this.logout();              
            }else{
              this.statusMessage = serverMessageData["Message"];
              setTimeout(() => { this.statusMessage = ""; }, 3000);
            }                  
          }      
        
          // hide loader
          if (typeof DynamicLoadingUtil.loader != "undefined") {
            DynamicLoadingUtil.loader.showLoader = false;
          }
    
        }, error => {
          this.statusMessage = "Prišlo je do napake, prosim poskusite znova";
          setTimeout(() => { this.statusMessage = ""; }, 3000);          
    
          if (typeof DynamicLoadingUtil.loader != "undefined") {
            DynamicLoadingUtil.loader.showLoader = false;
          }
        }); 
      }

    });    
  }

  changeInfo() {
    var requestModel = new RequestModel();
    requestModel.url = "api/user/change/info";    
    requestModel.data = this.userModel;

    if (typeof DynamicLoadingUtil.loader != "undefined") {
      DynamicLoadingUtil.loader.showLoader = true;
    }

    var response = this.restClient.getDataPOST(requestModel).subscribe(data => {


      if(data){
        let serverMessageData = data["Metadata"]["ServerMessageMetadata"];

        // if success update info
        if(serverMessageData.MessageType == 1){          

          let responseData = data["Model"];

          if(responseData){
            
            this.userModel.Name = responseData["Name"];
            this.restClient.currentUser.Name = responseData["Name"];

            this.userModel.Surname = responseData["Surname"];
            this.restClient.currentUser.Surname = responseData["Surname"];

            this.userModel.Email = responseData["Email"];
            this.restClient.currentUser.Email = responseData["Email"];

            this.userModel.Id = responseData["Id"];
            this.restClient.currentUser.Id = responseData["Id"];

            this.userModel.TaskSubscriptionEnabled = responseData["TaskSubscriptionEnabled"];
            this.restClient.currentUser.TaskSubscriptionEnabled = responseData["TaskSubscriptionEnabled"];
                                    
            this.restClient.currentUser.FullName = data["FullName"];

            // also update localStorage data for user
             // remove old value
             this.localStorageService.remove("user");
             // add with new data
             this.localStorageService.add("user", JSON.stringify(this.restClient.currentUser));             

            this.getInitials();
          }          
        }

        this.statusMessage = serverMessageData.Message;
        setTimeout(() => { this.statusMessage = ""; }, 3000);

      }      


      // hide loader
      if (typeof DynamicLoadingUtil.loader != "undefined") {
        DynamicLoadingUtil.loader.showLoader = false;
      }
    }, error => {
      this.statusMessage = "Prišlo je do napake, prosim poskusite znova";
      setTimeout(() => { this.statusMessage = ""; }, 3000);      

      if (typeof DynamicLoadingUtil.loader != "undefined") {
        DynamicLoadingUtil.loader.showLoader = false;
      }
    });
  }

  logout(){
    this.userService.logout();
  }

  tabChanged(tab){
    this.statusMessage = "";
  }

  getInitials(){
    this.initials = "";

    if(this.restClient.currentUser.Name && this.restClient.currentUser.Name.length > 0){
      this.initials += this.restClient.currentUser.Name[0];
    }
    if(this.restClient.currentUser.Surname && this.restClient.currentUser.Surname.length > 0){
      this.initials += this.restClient.currentUser.Surname[0];
    }
  }

  closeProfilePage(){
    this.dialogRef.close();
  }

}
