import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SemTextBoxComponent } from '../../../components/controls/sem-text-box/sem-text-box.component';
import { SemChatBotComponent } from '../../../components/controls/sem-chat-bot/sem-chat-bot.component';
import { SemDatePickerComponent } from '../../../components/controls/sem-date-picker/sem-date-picker.component';
import { SemSelectComponent } from '../../../components/controls/sem-select/sem-select.component';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { SemTextInputComponent } from '../../../components/controls/sem-text-input/sem-text-input.component';
import { SemControlComponent } from '../../../components/controls/sem-control/sem-control.component';
import { SemQueryComponent } from '../../../components/controls/sem-query/sem-query.component';
import { SemDomPipe } from '../../helpers/pipes/sem-dom-pipe.pipe';
import { LimitToPipe } from '../../helpers/pipes/limit-to.pipe';
import { SemDataGridComponent } from '../../../components/controls/sem-data-grid/sem-data-grid.component';
import { SemListComponent } from '../../../components/controls/sem-list/sem-list.component';
import { SemTextAreaComponent } from '../../../components/controls/sem-text-area/sem-text-area.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { SemRadioButtonComponent } from '../../../components/controls/sem-radio-button/sem-radio-button.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SemManyToOneControlComponent } from '../../../components/controls/sem-many-to-one-control/sem-many-to-one-control.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SemSearchComponent } from '../../../components/controls/sem-search/sem-search.component';
import { SemDynamicSearchFieldComponent } from '../../../components/controls/sem-dynamic-search-field/sem-dynamic-search-field.component';
import { SemCheckBoxComponent } from '../../../components/controls/sem-check-box/sem-check-box.component';
import { SemFileUploadComponent } from '../../../components/controls/sem-file-upload/sem-file-upload.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SemImageViewerComponent } from '../../../components/controls/sem-image-viewer/sem-image-viewer.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { SemFacetsComponent } from 'src/app/components/controls/sem-facets/sem-facets.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentUtcDateAdapter } from '../../helpers/moment-utc-adapter';
import { SemGraphComponent } from 'src/app/components/controls/sem-graph/sem-graph.component';
import { SemGraphPieComponent } from 'src/app/components/controls/sem-graph-pie/sem-graph-pie.component';
import { SemGraphHistogramComponent } from 'src/app/components/controls/sem-graph-histogram/sem-graph-histogram.component';
import { SemGraphBarComponent } from 'src/app/components/controls/sem-graph-bar/sem-graph-bar.component';
import { SemGraphDonutComponent } from 'src/app/components/controls/sem-graph-donut/sem-graph-donut.component';
import { SemDialogComponent } from 'src/app/components/controls/dialogs/sem-dialog/sem-dialog.component';
import { DisableControlDirective } from 'src/app/directives/disable-control/disable-control.directive';
import { MakeDraggable } from 'src/app/directives/draggable/make-draggable.directive';
import { MakeDroppable } from 'src/app/directives/draggable/make-dropable.directive';
import { SemFileExplorerComponent } from 'src/app/components/controls/sem-file-explorer/sem-file-explorer.component';
import { SemGroupRepeaterControlComponent } from 'src/app/components/controls/sem-group-repeater/sem-group-repeater.component';
import { SemDynamicControlComponent } from 'src/app/components/controls/shared/sem-dynamic-control/sem-dynamic-control.component';
import { SemQuickActionsComponent } from 'src/app/components/controls/content-controls/sem-quick-actions/sem-quick-actions.component';
import { SemLastEditedDataComponent } from 'src/app/components/controls/content-controls/sem-last-edited-data/sem-last-edited-data.component';
import { SemSearchBarComponent } from 'src/app/components/controls/sem-search-bar/sem-search-bar.component';
import { SemButtonComponent } from 'src/app/components/controls/sem-button/sem-button.component';
import { ImagesPathDirective } from 'src/app/directives/images-path.directive';
import { SemRequiredDirective } from 'src/app/directives/validators/sem-required.directive';
//import { DragScrollModule } from 'ngx-drag-scroll';
import { HttpClientModule } from '@angular/common/http';
import { SemOneToManyContainerComponent } from 'src/app/components/controls/sem-one-to-many-container/sem-one-to-many-container.component';
import { OneToManySectionComponent } from 'src/app/components/controls/sem-one-to-many-container/one-to-many-section/one-to-many-section.component';
import { StopClickPropagation } from 'src/app/components/common/custom-directives/stop-propagation';
import { SemMultiFieldsComponent } from 'src/app/components/controls/sem-multi-fields/sem-multi-fields.component';
import { SemBoundTabControlComponent } from 'src/app/components/controls/sem-bound-tab-control/sem-bound-tab-control.component';
import { SemBoundTabContentComponent } from 'src/app/components/controls/sem-bound-tab-control/sem-bound-tab-content/sem-bound-tab-content.component';
import { SemReportGeneratorComponent } from 'src/app/components/controls/sem-report-generator/sem-report-generator.component';
import { SemMultipleSelectComponent } from 'src/app/components/controls/sem-multiple-select/sem-multiple-select.component';
import { SemBoundLabelComponent } from 'src/app/components/controls/sem-bound-label/sem-bount-label.component';
//import { QuillModule } from 'ngx-quill'
import { SemFilePickerComponent } from 'src/app/components/controls/sem-file-picker/sem-file-picker.component';
import { SemGenerateDialogComponent } from 'src/app/components/controls/dialogs/sem-generate-dialog/sem-generate-dialog.component';
import { PreFormSubmitDirective } from 'src/app/directives/pre-submit/pre-form-submit.directive';
import { FormReadOnlyDirective } from 'src/app/directives/validators/form-read-only.directive';
import { SemTableDataComponent } from 'src/app/components/controls/sem-table-data-control/sem-table-data.component';
import { SemTreeviewDialogComponent } from 'src/app/components/controls/dialogs/sem-treeview-dialog/sem-treeview-dialog.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { SemTableGridComponent } from 'src/app/components/controls/charts/sem-table-chart/sem-table-chart.component';
import { SemTimePickerComponent } from '../../../components/controls/sem-time-picker/sem-time-picker.component';
import { SemRichTextEditorComponent } from 'src/app/components/controls/sem-rich-text-editor/sem-rich-text-editor.component';
import { SemGeoMapComponent } from '../../../components/controls/sem-geo-map/sem-geo-map.component';
import { SemRegexInputComponent } from '../../../components/controls/sem-regex-input/sem-regex-input.component'
import { SemActionComponent } from 'src/app/components/controls/sem-action/sem-action.component';

import { environment } from 'src/environments/environment';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SemLineGraphComponent } from 'src/app/components/controls/charts/graphs/sem-line-graph/sem-line-graph.component';
import { SemLastAddedRecordsComponent } from 'src/app/components/controls/content-controls/sem-last-added-records/sem-last-added-records.component';
import { SemEditModeBtnComponent } from '../../../components/controls/sem-edit-mode-btn/sem-edit-mode-btn.component';
import { SemTablePresentationComponent } from '../../../components/controls/content-controls/sem-table-presentation/sem-table-presentation.component';
import { SemD3DirectionalGraphComponent } from '../../../components/controls/charts/graphs/sem-d3-directional-graph/sem-d3-directional-graph.component';
import { QueryBuilderComponent } from '../../../components/controls/sem-query-builder/query-builder.component';
import { SemQueryBuilderDialogComponent } from '../../../components/controls/dialogs/sem-query-builder-dialog/sem-query-builder-dialog.component';
import { FormLoadEventDirective } from '../../../directives/formLoadEvent/form-load-event.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SemGalleryControlComponent } from 'src/app/components/controls/content-controls/sem-gallery-control/sem-gallery-control.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SemLoaderComponent } from '../../components/sem-loader/sem-loader.component';
import { NumbersOnly } from '../../../directives/input/numbers-only.directive';
import { SemSideContainerComponent } from '../../../components/controls/sem-side-container/sem-side-container.component';
import { SplitSearchComponent } from '../../../components/controls/split-search/split-search.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { SemSwitchComponent } from '../../../components/controls/sem-switch/sem-switch.component';

export const MY_FORMATS = {
  parse: {
    dateInput: environment.dateFormat,
  },
  display: {
    dateInput: environment.dateFormat,
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    SemDialogComponent,
    SemLoaderComponent,
    SemTextBoxComponent,
    SemDatePickerComponent,
    SemSelectComponent,
    SemTextInputComponent,
    SemControlComponent,
    SemQueryComponent,
    SemDomPipe,
    LimitToPipe,
    SemDataGridComponent,
    SemListComponent,
    SemTextAreaComponent,
    SemRadioButtonComponent,
    SemSearchComponent,
    SemDynamicSearchFieldComponent,
    SemCheckBoxComponent,
    SemManyToOneControlComponent,
    SemFileUploadComponent,
    SemImageViewerComponent,
    SemFacetsComponent,
    SemGraphComponent,
    SemGraphPieComponent,
    SemGraphHistogramComponent,
    SemGraphBarComponent,
    SemGraphDonutComponent,
    SemGroupRepeaterControlComponent,
    SemGraphDonutComponent,
    DisableControlDirective,
    MakeDraggable,
    MakeDroppable,
    SemFileExplorerComponent,
    SemDynamicControlComponent,
    SemQuickActionsComponent,
    SemLastEditedDataComponent,
    SemSearchBarComponent,
    SemButtonComponent,
    SemDynamicControlComponent,
    ImagesPathDirective,
    SemRequiredDirective,
    SemOneToManyContainerComponent,
    OneToManySectionComponent,
    SemBoundTabControlComponent,
    SemBoundTabContentComponent,
    SemReportGeneratorComponent,
    SemMultipleSelectComponent,
    StopClickPropagation,
    SemMultiFieldsComponent,
    SemBoundLabelComponent,
    SemFilePickerComponent,
    SemGenerateDialogComponent,
    SemFilePickerComponent,
    PreFormSubmitDirective,
    SemTableDataComponent,
    SemTreeviewDialogComponent,
    SemTableGridComponent,
    SemTimePickerComponent,
    SemRichTextEditorComponent,
    SemGeoMapComponent,
    SemRegexInputComponent,
    FormReadOnlyDirective,
    SemLastAddedRecordsComponent,
    SemLineGraphComponent,
    SemEditModeBtnComponent,
    SemTablePresentationComponent,
    SemD3DirectionalGraphComponent,
    QueryBuilderComponent,
    SemQueryBuilderDialogComponent,
    FormLoadEventDirective,
    SemChatBotComponent,
    SemGalleryControlComponent,
    NumbersOnly,
    SemSideContainerComponent,
    SplitSearchComponent,
    SemSwitchComponent
  ],

  imports: [
    CommonModule,
    //NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSelectModule,
    MatTabsModule,
    MatRadioModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatInputModule,
    //DragScrollModule,
    MatButtonModule,
    CdkTreeModule,
    MatTreeModule,
    MatIconModule,
    //QuillModule.forRoot(),
    //PerfectScrollbarModule,
    NgxChartsModule,
    PdfViewerModule,
    NgScrollbarModule,
    ScrollingModule,
    MatSlideToggleModule
  ],
  exports: [
    MatSelectModule,
    SemDialogComponent,
    SemTextBoxComponent,
    SemDatePickerComponent,
    SemSelectComponent,
    SemTextInputComponent,
    SemControlComponent,
    SemQueryComponent,
    SemDomPipe,
    LimitToPipe,
    SemDataGridComponent,
    SemListComponent,
    SemTextAreaComponent,
    FormsModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    CdkTreeModule,
    MatTreeModule,
    SemRadioButtonComponent,
    MatRadioModule,
    SemSearchComponent,
    SemDynamicSearchFieldComponent,
    SemCheckBoxComponent,
    MatRadioModule,
    SemManyToOneControlComponent,
    MatDialogModule,
    SemFileUploadComponent,
    SemImageViewerComponent,
    SemFacetsComponent,
    MatTooltipModule,
    SemGraphComponent,
    SemGraphPieComponent,
    SemGraphHistogramComponent,
    SemGraphBarComponent,
    SemGraphDonutComponent,
    DisableControlDirective,
    MakeDraggable,
    MakeDroppable,
    SemFileExplorerComponent,
    SemGraphDonutComponent,
    SemGroupRepeaterControlComponent,
    SemDynamicControlComponent,
    SemQuickActionsComponent,
    SemLastEditedDataComponent,
    SemSearchBarComponent,
    SemButtonComponent,
    SemDynamicControlComponent,
    ImagesPathDirective,
    MatAutocompleteModule,
    MatInputModule,
    SemRequiredDirective,
    SemOneToManyContainerComponent,
    OneToManySectionComponent,
    MatDatepickerModule,
    StopClickPropagation,
    SemMultiFieldsComponent,
    SemBoundTabControlComponent,
    SemBoundTabContentComponent,
    SemReportGeneratorComponent,
    SemMultipleSelectComponent,
    SemBoundLabelComponent,
    //QuillModule,
    SemFilePickerComponent,
    SemGenerateDialogComponent,
    SemFilePickerComponent,
    PreFormSubmitDirective,
    SemTableDataComponent,
    SemTreeviewDialogComponent,
    SemTableGridComponent,
    SemTimePickerComponent,
    SemRichTextEditorComponent,
    SemRegexInputComponent,
    FormReadOnlyDirective,
    //PerfectScrollbarModule,
    NgxChartsModule,
    SemLastAddedRecordsComponent,
    SemLineGraphComponent,
    SemEditModeBtnComponent,
    SemTablePresentationComponent,
    SemD3DirectionalGraphComponent,
    QueryBuilderComponent,
    SemQueryBuilderDialogComponent,
    FormLoadEventDirective,
    SemChatBotComponent,
    PdfViewerModule,
    SemGalleryControlComponent,
    NgScrollbarModule,
    ScrollingModule,
    SemLoaderComponent,
    NumbersOnly,
    SemSideContainerComponent,
    SplitSearchComponent,
    MatSlideToggleModule,
    SemSwitchComponent
  ],

  providers: [
    { provide: MAT_DATE_LOCALE, useValue: environment.locale },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: LOCALE_ID, useValue: 'sl' }
  ]
})
export class SemSharedModule {
}
