import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicFieldsManagerService {

  dynamicFields: any[] = [];
  metaModel: any[] = [];
  manyToOneReadOnly: any[] = [];
  manyToOne: any[] = [];
  controlValuesForValidation: any[] = [];
  globalModel: any;
  globalForm: any;
  isFormReadOnly: boolean = false;
  wasInEditMode:boolean = false;
  editModeEnabled:boolean = true;

  // listener for manyToOne value change
  _modelMTOValueChanged = new BehaviorSubject({});
  modelMTOValueChanged = this._modelMTOValueChanged.asObservable();
  //listeners for changing model value outside of scope, use it at your own risk
  public _bindValueChange = new BehaviorSubject({});
  bindValueChange = this._bindValueChange.asObservable();

  // listener for manyToOne value change
  _modelValueReset = new BehaviorSubject({});
  modelValueReset = this._modelValueReset.asObservable();

  _modelValueSubmitValidator = new BehaviorSubject({});
  modelValueSubmitValidator = this._modelValueSubmitValidator.asObservable();

  _routesLoaded = new BehaviorSubject({});
  routesLoaded = this._routesLoaded.asObservable();

  constructor() {
    /*this.metaModel = [
      { "Path": "OffenderProfile.Person.FirstName", "R": 0, "W": 0 },
      { "Path": "OffenderProfile.Person.MiddleName", "R": 1, "W": 0 },
      { "Path": "Court", "R": 1, "W": 0 },
      { "Path": "CaseDecisionDate", "R": 1, "W": 0 },
      { "Path": "Approved", "R": 1, "W": 0 },
      { "Path": "OffenderProfile", "R": 1, "W": 0 },
      { "Path": "RegulationItems", "R": 1, "W": 0 }
    ];*/
  }

  setInitValues(model: any): any {

    for (var i = 0; i < this.dynamicFields.length; i++) {
      if (typeof model[this.dynamicFields[i]] == "undefined" && model[this.dynamicFields[i]] == null) {
        model = this.assign(model, this.dynamicFields[i], {});
      }
    }

    return model;

  }

  assign(obj, path, val, shouldAssign = false): any {
    if (typeof path != "undefined") {
      const keys = path.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce((obj, key) =>
        obj[key] = obj[key] || {},
        obj);

      if (!shouldAssign) {
        if (lastObj[lastKey] == null || typeof lastObj[lastKey] == "undefined") {
          lastObj[lastKey] = val;
        }
      } else {
        lastObj[lastKey] = val;
      }

      return obj;
    }
    return obj;
  }

  getValue(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k != null && typeof k != "undefined" && o != null && typeof o != "undefined" && k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  updateControlsValidationArray(attName: string, value: any) {
    for (var i = 0; i < this.controlValuesForValidation.length; i++) {
      if (this.controlValuesForValidation[i]["Name"] == attName) {
        this.controlValuesForValidation[i]["Value"] = value;
        return;
      }
    }

    this.controlValuesForValidation.push({ "Name": attName, "Value": value });
  }

  setFormAsReadOnly() {
    this.wasInEditMode = true;
    this.isFormReadOnly = !this.isFormReadOnly;
  }


  valueChanged(val : any){
    this._bindValueChange.next(val);
  }
}
