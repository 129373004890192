import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SemLeftMenuComponent } from '../../components/sem-left-menu/sem-left-menu.component';
import { SemHeaderMenuComponent } from '../../components/sem-header-menu/sem-header-menu.component';
import { RequestModel } from '../../../models/request/request-model';
import { RouterModule, Routes, Router, RouterLink, UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from '../../../shared/helpers/custom-url-serializer';
import { MenuTypePipe } from '../../pipes/menu-type-pipe';
import { SemBreadcrumbComponent } from '../../components/sem-breadcrumbs/sem-breadcrumbs.component';
import { SemLeftMenuItemComponent } from '../../components/sem-left-menu-item/sem-left-menu-item.component';
import { NgScrollbarModule } from 'ngx-scrollbar';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgScrollbarModule
  ],
  declarations: [
    SemLeftMenuComponent,
    SemHeaderMenuComponent,
    SemBreadcrumbComponent,
    MenuTypePipe,
    SemLeftMenuItemComponent
  ],
  exports: [
    SemLeftMenuComponent,
    SemHeaderMenuComponent,
    SemBreadcrumbComponent,
    SemLeftMenuItemComponent
  ],
  providers: [RequestModel,[{ provide: UrlSerializer, useClass: CustomUrlSerializer }]],
})
export class SemNavigationModule { }
