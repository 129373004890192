import { OnInit, AfterViewInit, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SemDataGridComponent } from "../../sem-data-grid/sem-data-grid.component";
import { TranslationsProviderService } from "src/app/services/translations-provider/translations-provider.service";
import { MetaModelService } from "src/app/services/metamodel/metamodel-provider.service";
import { UserService } from "src/app/services/user/user-provider.service";
import { UserSearchSettingModel } from "src/app/models/search/user-search-setting-model";
import { DataGridSortingFlag } from "src/app/models/datagrid/data-grid-column-sorting-flag";
import { UserSearchSettingPropertyModel } from "src/app/models/search/user-search-setting-property-model";
import { ProjectionFacetWrapperModel } from "src/app/models/search/facet-projection-wrapper.model";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { DynamicLoadingUtil } from "src/app/shared/helpers/dynamic-loading-util";
import { SearchService } from "src/app/services/search/search.service";


@Component({
  selector: 'sem-grid-display-fields-dialog',
  templateUrl: './sem-grid-display-fields-dialog.component.html',
  styleUrls: ['./sem-grid-display-fields-dialog.component.css']
})
export class SemGridDisplayFieldsDialogComponent implements OnInit, AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<SemDataGridComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public translationsService: TranslationsProviderService,
    public metaModelService: MetaModelService,
    public userService: UserService,
    public searchService: SearchService) {
  }


  selectedGridField: any = null;
  gridAvailibleFields: any = [];
  gridViewFields: any = [];
  gridAddedFields: any = [];
  gridOriginalFieldsList: any = [];
  gridOriginalAddedFielsList: any = [];
  userGridSettings: UserSearchSettingModel = null;
  modelName: string = null;
  placeHolder: string = null;
  gridSettings: any = null;
  originalGridColumnSettings: any = null;
  helperColumnFlags: any = [];
  metamodelGridFields;
  gridFieldModified:boolean = false;

  ngOnInit() {

    this.gridAvailibleFields = this.dialogData["gridAvailibleFields"];

    /*if(this.dialogData.type.includes("grid")){
      this.gridAvailibleFields = this.gridAvailibleFields.concat(this.dialogData["gridAddedFields"]);
      this.gridViewFields = this.dialogData["gridAddedFields"];
    }else{
      this.gridAddedFields = this.dialogData["gridAddedFields"];
    }*/

    /*if(this.dialogData.type.includes("grid")){
      this.gridAddedFields = this.dialogData["gridAddedFields"].filter(x => x.FieldType == 3);
    }else{
      this.gridAddedFields = this.dialogData["gridAddedFields"].filter(x => x.FieldType == 2);
    }*/

    //this.gridAddedFields = this.dialogData.userGridSettings.SearchFields;
    this.gridAddedFields = this.dialogData["gridAddedFields"];

    this.gridOriginalFieldsList = this.dialogData["gridOriginalFieldsList"];
    this.gridOriginalAddedFielsList = this.dialogData["gridOriginalAddedFielsList"];
    this.userGridSettings = this.dialogData["userGridSettings"];
    this.modelName = this.dialogData["modelName"];
    this.gridSettings = this.dialogData["gridSettings"];
    this.originalGridColumnSettings = this.dialogData["originalGridColumnSettings"];
    this.helperColumnFlags = this.dialogData["helperColumnFlags"];
    this.placeHolder = this.dialogData["placeHolder"];
    this.metamodelGridFields = this.dialogData["metamodelGridFields"];

  }

  ngAfterViewInit() {

  }

  removeAssign(){
    this.gridAddedFields = [];
    this.gridViewFields = [];
    this.gridFieldModified = true;
  }

  listItemClick(event, newValue) {
    this.selectedGridField = newValue;
  }

  addGridField() {

    if(this.dialogData.type.includes("grid")){
      if(this.gridAddedFields.length > 5){
        return;
      }
    }

    if (typeof this.selectedGridField != "undefined" && this.selectedGridField != null) {
      // remove from array of availibleFields
      let index = this.gridAvailibleFields.findIndex(x => x.Name == this.selectedGridField.Name);
      if (index >= 0) {
        
        if(this.dialogData.type == "list"){
          this.selectedGridField.FieldType = 2;
          this.gridAvailibleFields.splice(index, 1);
        }else{
          this.selectedGridField.FieldType = 4;
        }

        // add to array of added fields
        this.gridAddedFields.push(this.selectedGridField);

        if (this.gridOriginalFieldsList != null && typeof this.gridOriginalFieldsList != "undefined") {
          // find selected in original and remove
          let i = this.gridOriginalFieldsList.findIndex(x => x.Name == this.selectedGridField.Name);
          if (i >= 0) {
            this.gridOriginalFieldsList.splice(i, 1);
          }
        }

        // selectedGridField is next availible grid field
        let newSelectedItem = this.gridAvailibleFields[index - 1];
        if (newSelectedItem < 0) {
          newSelectedItem = 0;
        }

        if (newSelectedItem != null && typeof newSelectedItem != "undefined") {
          this.selectedGridField = newSelectedItem;
        } else {
          // get first if at least one field is present
          if (this.gridAvailibleFields.length > 0) {
            this.selectedGridField = this.gridAvailibleFields[0];
          } else {
            this.selectedGridField = null;
          }
        }
      }
      this.gridFieldModified = true;
    }
  }

  removeAddedGridField() {
    if (typeof this.selectedGridField != "undefined" && this.selectedGridField != null) {
      // remove from array of addedFields
      let index = this.gridAddedFields.findIndex(x => x.Name == this.selectedGridField.Name);
      if (index >= 0) {
        this.gridAddedFields.splice(index, 1);
        // add to array of availibleFields
        this.gridAvailibleFields.unshift(this.selectedGridField);
        // add to array of original av fields, since when adding we also removed from originalfieldslist
        this.gridOriginalFieldsList.unshift(this.selectedGridField);
        this.selectedGridField = null;
      }

      // selectedGridField is next availible grid field
      let newSelectedItem = this.gridAddedFields[index - 1];
      if (newSelectedItem < 0) {
        newSelectedItem = 0;
      }

      if (newSelectedItem != null && typeof newSelectedItem != "undefined") {
        this.selectedGridField = newSelectedItem;
      } else {
        // get first if at least one field is present
        if (this.gridAddedFields.length > 0) {
          this.selectedGridField = this.gridAddedFields[0];
        } else {
          this.selectedGridField = null;
        }
      }
    }
  }

  fieldSearch(searchValue: string) {
    if (typeof searchValue != "undefined" && searchValue.trim() != "") {
      let lowerCaseSearchValue = searchValue.toLocaleLowerCase();
      let filteredItems = this.gridOriginalFieldsList.filter(x => x.Translation.toLocaleLowerCase().includes(lowerCaseSearchValue));
      this.gridAvailibleFields = filteredItems;
    } else {
      this.gridAvailibleFields = this.gridOriginalFieldsList.map(x => Object.assign({}, x));
    }
  }


  restoreGridSettings() {

    // return all addedFields to availible
    //for (let item in this.gridAddedFields) {
    //this.gridAvailibleFields.push(item);
    //}

    // clear
    //this.gridAddedFields = [];
    //this.gridOriginalAddedFielsList = [];
    this.saveUserAdditionalGridFields(true);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.gridAddedFields, event.previousIndex, event.currentIndex);
  }

  cancelGridSettings() {
    this.gridAddedFields = this.gridOriginalAddedFielsList.map(x => Object.assign({}, x));
    this.gridAvailibleFields = this.gridOriginalFieldsList.map(x => Object.assign({}, x));

    // close dialog and send data back      
    var returnData = {
      "gridAvailibleFields": this.gridAvailibleFields,
      "gridAddedFields": this.gridAddedFields,
      "gridOriginalFieldsList": this.gridOriginalFieldsList,
      "gridOriginalAddedFielsList": this.gridOriginalAddedFielsList,
      "userGridSettings": this.userGridSettings,
      "gridSettings": this.gridSettings,
      "originalGridColumnSettings": this.originalGridColumnSettings,
      "helperColumnFlags": this.helperColumnFlags
    }

    this.dialogRef.close(returnData);

  }

  addAllowed(){
    if(this.dialogData.type.includes("grid")){
      if(this.gridAddedFields.length > 5){
        return false;
      }
    }

    return true;
  }

  saveUserAdditionalGridFields(restore: boolean) {

    if (this.userGridSettings == null || typeof this.userGridSettings == "undefined") {
      this.userGridSettings = new UserSearchSettingModel;
      this.userGridSettings.ModelName = this.modelName;
      this.userGridSettings.SearchPlaceHolderTitle = this.placeHolder;
    }


    this.userGridSettings.SearchFields = [];
    for (let field of this.gridAddedFields) {

      var searchField = new UserSearchSettingPropertyModel;
      var userSearchSet = new UserSearchSettingModel;

      searchField.PropertyPath = field["Name"];
      userSearchSet.Id = this.userGridSettings.Id;
      searchField.UserSearchSetting = userSearchSet;

      if(this.dialogData.type.includes("grid")){
        searchField.FieldType = 4;
      }else{
        searchField.FieldType = 2;
      }

      this.userGridSettings.SearchFields.push(searchField);
    }

    if ((this.userGridSettings.View == null || typeof this.userGridSettings.View == "undefined") && this.dialogData["viewId"] != null && typeof this.dialogData["viewId"] != "undefined") {
      this.userGridSettings.View =
      {
        "Id": this.dialogData["viewId"]
      }
    }


    this.userGridSettings.CustomPageSize = this.searchService.pageSize;
    let helperColumnFlagsTemp: DataGridSortingFlag = {};

    DynamicLoadingUtil.loader.showLoader = true;

    this.userService.saveUserSettings(this.userGridSettings, true, restore).subscribe(data => {

      this.userGridSettings = data;
      if (data != null && typeof data != "undefined" && data["Version"] != null && typeof data["Version"] != "undefined") {
        this.userService.userSearchSettingCurrentVersion = data["Version"];
      }

      if (restore) {

        this.gridAddedFields = [];
        for (let i = 0; i < this.userGridSettings?.SearchFields?.length; i++) {

          if (this.userGridSettings.SearchFields[i].FieldType != 1) {
            // find saved field in metamodelGridFields
            let index = this.metamodelGridFields.findIndex(x => x.Name == this.userGridSettings.SearchFields[i].PropertyPath);

            let headerText = this.userGridSettings.SearchFields[i].PropertyPath;
            let binding = this.userGridSettings.SearchFields[i].PropertyPath;
            let isImageControl = false;
            let isSortable = false;
            let isDateTime = false;
            let isBoolean = false;

            if (index >= 0) {

              headerText = this.metamodelGridFields[index]["Translation"];
              binding = this.metamodelGridFields[index]["Name"];
              isImageControl = this.metamodelGridFields[index]["IsImageControl"];
              isSortable = this.metamodelGridFields[index]["IsSortable"];
              isDateTime = this.metamodelGridFields[index]["IsDateTime"];
              isBoolean = this.metamodelGridFields[index]["IsCheckBox"];

              // also add into addedFields
              this.gridAddedFields.push(this.metamodelGridFields[index]);
            }

            this.helperColumnFlags[binding] = {
              "IsSortable": isSortable,
              "ShowSort": false,
              "SortingUsed": false
            }
          }
        }

        this.gridOriginalAddedFielsList = this.gridAddedFields.map(x => Object.assign({}, x));

      }

      if (this.gridAddedFields != null && this.gridAddedFields.length > 0) {
        let savedColumns = [];


        // update grid header columns with saved grid fields(fieldtype = 2)
        for (let item of this.gridAddedFields) {
          savedColumns.push(
            {
              "HeaderText": item["Translation"],
              "BindingField": item["Name"],
              "Width": 30,
              "IsImageControl": item["IsImageControl"],
              "IsDateTime": item["IsDateTime"],
              "IsBoolean": item["IsCheckBox"]
            });

          helperColumnFlagsTemp[item["Name"]] = {
            "IsSortable": item["IsSortable"],
            "ShowSort": false,
            "SortingUsed": false
          }

        }

        this.gridSettings.Columns = savedColumns.map(x => Object.assign({}, x));
      } else {
        this.gridSettings.Columns = this.originalGridColumnSettings.map(x => Object.assign({}, x));
      }


      /*this.gridAvailibleFields = this.dialogData["gridAvailibleFields"];
      this.gridAddedFields = this.dialogData["gridAddedFields"];
      this.gridOriginalFieldsList = this.dialogData["gridOriginalFieldsList"];
      this.gridOriginalAddedFielsList = this.dialogData["gridOriginalAddedFielsList"];
      this.userGridSettings = this.dialogData["userGridSettings"];
      this.modelName = this.dialogData["modelName"];
      this.gridSettings = this.dialogData["gridSettings"];
      this.originalGridColumnSettings = this.dialogData["originalGridColumnSettings"];*/


      // new grid settings (columns) saved
      // need to change projection for search      
      let newProjection = this.gridSettings.Columns.map(x => x.BindingField);


      if (helperColumnFlagsTemp != null && typeof helperColumnFlagsTemp != "undefined") {
        for (let key in helperColumnFlagsTemp) {

          if (this.helperColumnFlags[key] != null && typeof this.helperColumnFlags[key] != "undefined") {

            this.helperColumnFlags[key].IsSortable = helperColumnFlagsTemp[key].IsSortable;
            this.helperColumnFlags[key].ShowSort = false;
            this.helperColumnFlags[key].SortingUsed = false;
          } else {
            this.helperColumnFlags[key] = {
              "IsSortable": helperColumnFlagsTemp[key].IsSortable,
              "ShowSort": false,
              "SortingUsed": false
            }
          }
        }
      }

      // save is successfull
      // close dialog and send data back      
      var returnData = {
        "gridAvailibleFields": this.gridAvailibleFields,
        "gridAddedFields": this.gridAddedFields,
        "gridOriginalFieldsList": this.gridOriginalFieldsList,
        "gridOriginalAddedFielsList": this.gridOriginalAddedFielsList,
        "userGridSettings": this.userGridSettings,
        "gridSettings": this.gridSettings,
        "originalGridColumnSettings": this.originalGridColumnSettings,
        "newProjection": newProjection,
        "helperColumnFlags": this.helperColumnFlags,
        "restore": restore
      }

      DynamicLoadingUtil.loader.showLoader = false;
      this.dialogRef.close(returnData);

    }, error => {
      DynamicLoadingUtil.loader.showLoader = false;

    });

  }

  translate(key: string) {
    return this.translationsService.translate(key);
  }

  doubleClickAdd() {
    if (this.selectedGridField != null && typeof this.selectedGridField != "undefined") {
      this.addGridField();
    }
  }

  doubleClickRemove() {
    if (this.selectedGridField != null && typeof this.selectedGridField != "undefined") {
      this.removeAddedGridField();
    }
  }

}
