import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicFieldsManagerService } from '../../../../services/dynamic-fields/dynamic-fields-manager.service';
import { RestProviderService } from '../../../../services/rest-provider/rest-provider.service';
import { SearchService } from '../../../../services/search/search.service';
import { TranslationsProviderService } from '../../../../services/translations-provider/translations-provider.service';
import { SemGenerateDialogComponent } from '../sem-generate-dialog/sem-generate-dialog.component';

@Component({
  selector: 'app-sem-file-explorer-dialog',
  templateUrl: './sem-file-explorer-dialog.component.html',
  styleUrls: ['./sem-file-explorer-dialog.component.css']
})
export class SemFileExplorerDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SemFileExplorerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private restProvider: RestProviderService,
    private changeDet: ChangeDetectorRef,
    public translationService: TranslationsProviderService,
    public dfms: DynamicFieldsManagerService,
    public translationsService: TranslationsProviderService,
    public searchService: SearchService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

}
