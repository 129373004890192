import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sem-bound-tab-control',
  templateUrl: './sem-bound-tab-control.component.html',
  styleUrls: ['./sem-bound-tab-control.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemBoundTabControlComponent, multi: true }
  ]
})
export class SemBoundTabControlComponent extends ValueAccessorBase<any> implements OnInit, AfterViewInit {

  @Input() bindingPropertyName: any;
  @Input() contentPropertyName: any;
  @Input() matGroup: any;
  @Input() contentName: any;
  @Input() tabName: any;
  public content: any;
  public templateProvided: boolean = false;

  ngAfterViewInit() {
  }

  super() { }

  ngOnInit() {
    //console.log("Mat group: ", this.matGroup);
    this.getContentTemplate();
  }

  getContentTemplate() {

    let templateUrl = "";

    for (var i = 0; i < this.dynamicRoutesLoader.wgRoutes.length; i++) {
      if (this.dynamicRoutesLoader.wgRoutes[i]["PrettyUrl"] == window.location.pathname.substring(1)) {
        templateUrl = this.dynamicRoutesLoader.wgRoutes[i].Url;
        break;
      }
    }

    for (var i = 0; i < this.dynamicRoutesLoader.loadedRoutes.length; i++) {
      if (this.dynamicRoutesLoader.loadedRoutes[i].Url == templateUrl) {
        this.templateProvided = true;
        this.loadContent(this.dynamicRoutesLoader.loadedRoutes[i].Html);
        break;
      }
    }

    if (!this.templateProvided) {
      this.restProviderService.getDataGET(templateUrl).subscribe(async rawTemplate => {
        //console.log("Retrieved dynamic template: ", rawTemplate);
        this.loadContent(rawTemplate);

      });
    }
  }

  loadContent(rawTemplate) {
    let startParseIndex;
    let endParseIndex;
    let contentLength;

    if (typeof (this.tabName) == "undefined" || this.tabName == null || this.tabName.length == 0) {
      startParseIndex = rawTemplate.indexOf(this.contentName + "start") + 5 + this.contentName.length;
      endParseIndex = rawTemplate.indexOf(this.contentName + "end");
      contentLength = endParseIndex - startParseIndex;
    }
    else {
      startParseIndex = rawTemplate.indexOf(this.tabName + "start") + 5 + this.tabName.length;
      endParseIndex = rawTemplate.indexOf(this.tabName + "end");
      contentLength = endParseIndex - startParseIndex;
    }


    this.content = rawTemplate.substring(startParseIndex, endParseIndex);

    this.restProviderService.modelValueChanged.subscribe(model => {

      this.model = model;

      if (this.model[this.bindingPropertyName]) {
        for (var i = 0; i < this.model[this.bindingPropertyName].length; i++) {
          if (typeof (this.model[this.bindingPropertyName][i][this.contentPropertyName]) == "undefined" || this.model[this.bindingPropertyName][i][this.contentPropertyName] == null) {
            this.model[this.bindingPropertyName][i][this.contentPropertyName] = {};
          }
        }
      }

    });
  }

  modelChanged(model:any) {
    //console.log("BoundTab parent model changed: ", event);
  }

  test(){
    
  }

}