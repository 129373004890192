import { Component, OnInit, Input } from '@angular/core';
import { SemTextInputComponent } from '../sem-text-input/sem-text-input.component';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '../../../../../node_modules/@angular/forms';
import { retry } from '../../../../../node_modules/rxjs/operators';

@Component({
  selector: 'sem-text-area',
  templateUrl: './sem-text-area.component.html',
  styleUrls: ['./sem-text-area.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemTextAreaComponent, multi: true }
  ]
})
export class SemTextAreaComponent extends ValueAccessorBase<string> implements OnInit {

  maxLength: number = 0;
  instructionTextPresent: boolean = false;
  @Input() instructionText: any;
  @Input() minLength: any;
  @Input() readOnlyCondition: any;


  super() { }

  ngOnInit() {
    this.value = "";
    
    if(this.instructionText != null && typeof this.instructionText != "undefined" && this.instructionText.length > 0){
      this.instructionTextPresent = true;
    }
  }

  onTextChange(value) {
    let attName = this.getAttribute("name");

  
    let control = this.dfms.globalForm.controls[attName];

    if (control !== null && typeof control !== "undefined") {
      this.dfms.globalForm.controls[attName].setValue(value);
    this.dfms.globalForm.controls[attName].updateValueAndValidity();
    }   

    

    this.dfms.updateControlsValidationArray(attName,value);

    if(typeof this.onValueChangeEval != "undefined" && this.onValueChangeEval){
      eval(this.onValueChangeEval);
    }
  }

  get getValue() {
    if (typeof this.value != "undefined" && this.value != null) {
      return this.value;
    } else {
      return "";
    }
  }

  valueChanged() {

  }

}
