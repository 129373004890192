import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import {
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'sem-check-box',
  templateUrl: './sem-check-box.component.html',
  styleUrls: ['./sem-check-box.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemCheckBoxComponent, multi: true }
  ]
})

export class SemCheckBoxComponent extends ValueAccessorBase<string> implements OnInit, AfterViewInit {

  @Input() showLabel: boolean = true;
  @ViewChild('cb') input: ElementRef<HTMLInputElement>;

  super() { }

  ngOnInit() {
    this.restProviderService.modelValueChanged.subscribe(model => {

      if (this.model != null && typeof this.model != "undefined" && Object.keys(model).length > 3) {
        let v = this.dfms.getValue(model,this.getAttribute('name').replace("model.",""));
        if(typeof (v) != "undefined" && v != null){
          this.value = v;
        }
      }

    });
  }

  ngAfterViewInit(): void {
  }

  onChange(event) {

    /*let attName = this.getAttribute("name");
    this.dfms.updateControlsValidationArray(attName, this.value.toString());
    this.dfms.globalForm.controls[attName].setValue(this.value.toString());
    this.dfms.globalForm.controls[attName].updateValueAndValidity();


    if (typeof this.onValueChangeEval != "undefined" && this.onValueChangeEval) {
      eval(this.onValueChangeEval);
    }*/
  }

  modelChange(event){
    let attName = this.getAttribute("name");

    
    let control = this.dfms.globalForm.controls[attName];

    if (control !== null && typeof control !== "undefined") {
      this.dfms.globalForm.controls[attName].setValue(event);
      this.dfms.globalForm.controls[attName].updateValueAndValidity();  
    }   
    this.dfms.updateControlsValidationArray(attName, event);

    if (typeof this.onValueChangeEval != "undefined" && this.onValueChangeEval) {
      eval(this.onValueChangeEval);
    }
  }

}
