import { Injectable } from '@angular/core';
import { RestProviderService } from '../rest-provider/rest-provider.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  add(key, value) {
    localStorage.setItem(key, value);
  }

  get(key) {
    return localStorage.getItem(key);
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  compileKey(keys: string[]): string {
    let cacheKey: string = "";
    keys.forEach(el => {
      if (el) {
        cacheKey += el.toString().toLowerCase().trim() + ";";
      }
    });

    return cacheKey.substring(0, cacheKey.length - 1);
  }
}
