import { Injectable } from "../../../../node_modules/@angular/core";
import { retry } from "../../../../node_modules/rxjs/operators";

@Injectable()
export class SemHttpUtility {

  static semDecode(content: string) {
    if (content != null && typeof content != "undefined") {
      return content.replace(/%7B/g, "{").replace(/%7D/g, "}");
    }
    return "";
  }

}
