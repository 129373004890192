export class BulkActionModel {

    public Entities: any[];
    public ActionName: string;    
    public ActionProvider: string;
    public Projection: any[];
    public ProjectionDynamicForm: any;
  
    constructor(entities: any[]) {
        this.Entities = entities;
    }
  }
  