import { Injectable } from "../../../../node_modules/@angular/core";

@Injectable()
export class FileUtil {

  static getFriendlyFileSize(size:number) {
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
    i=0;while(size>900){size/=1024;i++;}
    return (Math.round(size*100)/100)+' '+fSExt[i];
  }

}

export enum FileUploadObservableState {
  Error = -2,
  Finished = -1,
  OnGoing = 0
}