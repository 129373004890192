import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sem-geo-map',
  templateUrl: './sem-geo-map.component.html',
  styleUrls: ['./sem-geo-map.component.css']
})
export class SemGeoMapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
