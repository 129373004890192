import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';

@Component({
  selector: 'sem-switch',
  templateUrl: './sem-switch.component.html',
  styleUrls: ['./sem-switch.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemSwitchComponent, multi: true }
  ]
})

export class SemSwitchComponent extends ValueAccessorBase<boolean> implements OnInit, AfterViewInit {

  imgPathSource: string = "../../../../assets/css/images/platform/";
  imgPathIn: string = "switch-in.png";
  imgPathOr: string = "switch-or.png";
  img: string = this.imgPathSource + this.imgPathIn;

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.value = false;
  }

  click() {
    this.value = !this.value;
    
    if (this.img.endsWith(this.imgPathIn)) {
      this.img = this.imgPathSource + this.imgPathOr;
    } else {
      this.img = this.imgPathSource + this.imgPathIn;
    }
  }

}
