import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { RequestModel } from '../models/request/request-model';
import { LocalStorageService } from '../services/local-storage-service/local-storage.service';
import { RestProviderService } from '../services/rest-provider/rest-provider.service';
import { TranslationsProviderService } from '../services/translations-provider/translations-provider.service';

@Component({
  selector: 'app-platform-redirect',
  templateUrl: './platform-redirect.component.html',
  styleUrls: ['./platform-redirect.component.css']
})
export class PlatformRedirectComponent implements OnInit {

  constructor(private rp: RestProviderService, private httpClient: HttpClient, private router: Router, private localStorageService: LocalStorageService, private translationsService: TranslationsProviderService) { }

  ngOnInit(): void {
    this.redirectSSO();    
  }

  redirectSSO(){
    var model = new RequestModel();
    model.url = "/api/usersso";

    this.rp.getDataPOST<any>(model).subscribe(response=>{
      var redirecturl = environment.wgPlatformHost+"/sso?token="+encodeURI(btoa(response.Token));
      window.location.href=redirecturl;
    });
  }

}
