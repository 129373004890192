import { Component, OnInit, Input, Injector, Output } from '@angular/core';
import { Http } from '../../../../../node_modules/@angular/http';
import { HttpClient } from '../../../../../node_modules/@types/selenium-webdriver/http';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { SemBaseComponent } from '../../common/sem-base/sem-base.component';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { DomSanitizer } from '../../../../../node_modules/@angular/platform-browser';
import { EventEmitter } from 'events';
import { DynamicLoadingUtil } from '../../../shared/helpers/dynamic-loading-util';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { SearchService } from 'src/app/services/search/search.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { FormService } from '../../../services/form-service/form.service';


@Component({
  selector: 'app-sem-dashboard-container',
  templateUrl: './sem-dashboard-container.component.html',
  styleUrls: ['./sem-dashboard-container.component.css']
})
export class SemDashboardContainerComponent extends SemBaseComponent implements OnInit {

  private myTemplate: any = "";
  @Input() url: string;  

  public translationService = null;
  private searchService = null;

  upcomingTransfers: any[] = [];
  courtJudgements: any[] = [];
  offenderProfiles: any[] = [];
  searchTerm: string;

  private searchServiceSubscriber = null;
  private defaultCJISearchQuery = "{\"Projection\":[\"Id\",\"Title\"],\"TreeQuery\":{\"Type\":\"FieldValue\",\"FieldName\":\"*\",\"FieldValue\":\"*\"},\"ClassTypes\":[\"Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Penitentiary.CourtJudgementInformationModel\"],\"Page\":\"1\",\"PageSize\":5,\"RepositoryName\":\"lucene\",\"UseCache\":false,\"SortFields\":[{\"Direction\":1,\"FieldName\":\"LastChangeDate\",\"RootClassName\":\"Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Penitentiary.CourtJudgementInformationModel\"}]}"
  private defaultOffendersSearchQuery = "{\"Projection\":[\"Id\",\"Person.Title\"],\"TreeQuery\":{\"Type\":\"FieldValue\",\"FieldName\":\"*\",\"FieldValue\":\"*\"},\"ClassTypes\":[\"Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Penitentiary.OffenderProfileModel\"],\"Page\":\"1\",\"PageSize\":10,\"RepositoryName\":\"lucene\",\"UseCache\":false,\"SortFields\":[{\"Direction\":1,\"FieldName\":\"LastChangeDate\",\"RootClassName\":\"Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Penitentiary.OffenderProfileModel\"}]}"
  private defaultTransfersSearchQuery = "{\"Projection\":[\"Id\",\"Title\"],\"TreeQuery\":{\"Type\":\"FieldValue\",\"FieldName\":\"*\",\"FieldValue\":\"*\"},\"ClassTypes\":[\"Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Probation.OffenderTransferModel\"],\"Page\":\"1\",\"PageSize\":5,\"RepositoryName\":\"lucene\",\"UseCache\":false,\"SortFields\":[{\"Direction\":1,\"FieldName\":\"LastChangeDate\",\"RootClassName\":\"Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Probation.OffenderTransferModel\"}]}"

  constructor(public http: RestProviderService, private _injector: Injector, private _sanitizer: DomSanitizer, activatedRoute: ActivatedRoute, translationService: TranslationsProviderService, searchService: SearchService, formService:FormService) {
    super(_injector, _injector.get(Router), activatedRoute,_injector.get(DynamicFieldsManagerService),_injector.get(UtilityService),_injector.get(FormService));
    
    this.searchService = searchService;
    this.translationService = translationService;
    
    if (typeof DynamicLoadingUtil.loader != "undefined") {
      DynamicLoadingUtil.loader.showLoader = true;
    }

    if (this.http.currentUser != "undefined" && this.http.currentUser != null) {
      if (this.http.currentUser["Username"] != "anonymous") {
        DynamicLoadingUtil.loader.showLoader = false;
        /* http.getDataGET("templates/UZ_DashboardTemplate").subscribe((html: any) => {
          this.myTemplate = _sanitizer.bypassSecurityTrustHtml(html);
          if (typeof DynamicLoadingUtil.loader != "undefined") {
            DynamicLoadingUtil.loader.showLoader = false;
          }
        }, error => { console.log(error); }); */
      } else {
        window.location.href = "/account/login?returnUrl=/cms/dashboard";
      }
    }
    else {
      window.location.href = "/account/login?returnUrl=/cms/dashboard";
    }

  }

  ngOnInit() {
    this.sidebarMenuOpened = true;    
    
    this.searchServiceSubscriber = this.searchService.performSearch(JSON.parse(this.defaultOffendersSearchQuery), null).subscribe(data => {      
      for (var i = 0; i < data["Models"].length; i++) {
        this.offenderProfiles.push({

          "Id":data["Models"][i].Model.Id,
          "Title": data["Models"][i].Model.Person.Title
        });        
      }                
    }, error => {      
    });


    this.searchServiceSubscriber = this.searchService.performSearch(JSON.parse(this.defaultCJISearchQuery), null).subscribe(data => {      
      for (var i = 0; i < data["Models"].length; i++) {
        this.courtJudgements.push({

          "Id":data["Models"][i].Model.Id,
          "Title": data["Models"][i].Model.Title
        });        
      }                
    }, error => {      
    });

    this.searchServiceSubscriber = this.searchService.performSearch(JSON.parse(this.defaultTransfersSearchQuery), null).subscribe(data => {      
      for (var i = 0; i < data["Models"].length; i++) {
        this.upcomingTransfers.push({

          "Id":data["Models"][i].Model.Id,
          "Title": data["Models"][i].Model.Title
        });        
      }                
    }, error => {      
    });

  }

  isMenuOpened() { }

  

  navigate(url: string) {
    this.utility.processNavigationUrl(url);
  }  

  navigateToDetails(url:string, item: any){
    let detailsUrl = url + item["Id"];
    this.utility.processNavigationUrl(detailsUrl);
  }

  sidebarChanged(event) {
    this.sidebarMenuOpened = event;
  }

  dashboardSearch(event){    
    let urlsearch = "cms/ppmscis/list?model=Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Penitentiary.CourtJudgementInformationModel&url=cms/probation/courtjudgementinformation&view=1&search=" + event.target.value + "*";
    this.utility.processNavigationUrl(urlsearch);
  }

  goToSearch(){
    if(typeof this.searchTerm == "undefined"){
      this.searchTerm = "";
    }
    let urlsearch = "cms/ppmscis/list?model=Eurodesign.MoJ.PPMSCIS.Application.Model.Module.Penitentiary.CourtJudgementInformationModel&url=cms/probation/courtjudgementinformation&view=1&search=" + this.searchTerm+"*";
    this.utility.processNavigationUrl(urlsearch);
  }

}
