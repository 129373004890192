import { RootModel } from '../root-model';

export class FileDataModel extends RootModel {

  public Id: number = 0;
  public FileID: number = 0;
  public Name: string = "";
  public MimeType: string = "";
  public BackendStore: number = 0;
  public FileChecksum: any;
  public SystemSignature: string = "";
  public UserSignature: string = "";
  public UploadDate: Date = new Date;
  public LastModificationDate: Date = new Date;
  public UniquePermissions: boolean = false;
  public Category: number = 0;
  public FileDataBackendInfo: string = "";
  public Url: string = "";

  public Title: string = "";
  public Description: string = "";
  public FileOrder: number = 0;
  public FileCategory: string = "";
  public Annotations: string = "";

  public Size: number = null;

  public File: File = null;
  public Action: ClientAction = ClientAction.New;

  public ReadOnly:boolean = false;

  super() {
  }

  constructor(model: any) {
    super();

    if (model != null) {
      this.Id = model["FileID"];
      this.FileID = model["FileID"];
      this.Name = model["Name"];
      this.MimeType = model["MimeType"];
      this.BackendStore = model["BackendStore"];
      this.FileChecksum = model["FileChecksum"];
      this.SystemSignature = model["SystemSignature"];
      this.UserSignature = model["UserSignature"];
      this.UploadDate = model["UploadDate"];
      this.LastModificationDate = model["LastModificationDate"];
      this.UniquePermissions = model["UniquePermissions"];
      this.Category = model["Category"];
      this.FileDataBackendInfo = model["FileDataBackendInfo"];
      this.Url = model["Url"];
      this.Title = model["Title"];
      this.Description = model["Description"];
      this.FileOrder = model["FileOrder"];
      this.FileCategory = model["FileCategory"];
      this.Annotations = model["Annotations"];
    }
  }

}

export enum ClientAction {
  New = 0,
  Delete = 1,
  Old = 2,
  Linked = 3
}