import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit } from '@angular/core';

import { HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { RootModel } from '../../../models/root-model';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NavigationNodeSerializer } from '../../../models/serializing/navigation-node-serializer';

import { SemNavigationModule } from '../../modules/sem-navigation/sem-navigation.module';
import { SemLeftMenuComponent } from '../sem-left-menu/sem-left-menu.component';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { RequestModel } from '../../../models/request/request-model';
import { NavigationRequestModel } from '../../../models/navigation/navigation-request-model';
import { NavigationResponseModel } from '../../../models/navigation/navigation-response-model';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { DataGridSortingFlag } from 'src/app/models/datagrid/data-grid-column-sorting-flag';
import { ActionsService } from 'src/app/services/actions-service/actions.service';
import { MatDialog } from '@angular/material/dialog';
import { SemConfirmDialogComponent } from 'src/app/components/controls/dialogs/sem-confirm-dialog/sem-confirm-dialog.component';
import { DialogType } from 'src/app/shared/components/sem-dynamic/sem-dynamic.component';
import { SemUserProfileDialogComponent } from 'src/app/components/controls/dialogs/sem-user-profile-dialog/sem-user-profile-dialog.component';
import { LocalStorageService } from 'src/app/services/local-storage-service/local-storage.service';
import { UserService } from 'src/app/services/user/user-provider.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-sem-header-menu',
  templateUrl: './sem-header-menu.component.html',
  styleUrls: ['./sem-header-menu.component.css']
})
export class SemHeaderMenuComponent implements OnInit, AfterViewInit {

  //Communicating betweeen components
  @Output() closeMenuEm = new EventEmitter();
  @Input() com1ref: SemLeftMenuComponent;
  @Input() viewTitle: string = "";
  @Input() headerText: string;
  @Input() supportMail: string = null;

  //Menu triggers
  userMenuState: boolean = false;
  languageMenuState: boolean = false;
  headerChildClick: boolean = false;

  //WebAPI response/request models
  restProvider: RestProviderService;
  translationsService: TranslationsProviderService;
  url: string;
  currentUrl: string;
  model: RequestModel;
  request: NavigationRequestModel;
  userMenuResult: NavigationResponseModel[];
  languageMenuResult: NavigationResponseModel[];
  customNodes: NavigationResponseModel[];
  childrenNodes: DataGridSortingFlag = {};
  params: any;
  currentLanguage: string;
  showNotificationDotIcon: boolean = true;
  formActionResponses: any[] = [];
  version:string = environment.version;

  constructor(injector: Injector, private route: ActivatedRoute, private router: Router, public actionsService: ActionsService, public dialog: MatDialog, public localStorageService: LocalStorageService, public userService: UserService) {
    this.restProvider = injector.get(RestProviderService);
    this.model = injector.get(RequestModel);
    this.request = injector.get(NavigationRequestModel);
    this.translationsService = injector.get(TranslationsProviderService);
  }

  ngOnInit() {
    this.customNodes = [];
    this.currentUrl = this.router.url.substring(1, this.router.url.length);
    this.getMenuItems("user-menu", "/api/navigation/getNodes");
    this.getMenuItems("language-menu", "/api/navigation/getNodes");
    this.getMenuItems("custom-header-menu", "/api/navigation/getNodes");
    this.getMenuItems("form-action-value", "/api/navigation/getNodes");

    let languageCode = this.localStorageService.get("language");

    if (languageCode == null) {
      languageCode = environment.language.toUpperCase();
    }

    this.currentLanguage = languageCode;
    //this.route
    //  .queryParams
    //  .subscribe(params => {
    //    // Defaults to 0 if no query param provided.
    //    this.params = params;

    //    for (var i = 0; i < this.params.length; i++) {
    //    }
    //  });
  }

  ngAfterViewInit(): void {
  }

  getMenuItems(type: string, url: string) {

    this.model.url = url;
    this.request.context = type;
    this.request.depth = 0;
    this.request.rootNode = null;

    this.model.data = this.request;

    this.restProvider.serializer = new NavigationNodeSerializer;
    let cacheData = null;

    if (environment.useRouteAndMasterpageCache) {
      cacheData = this.localStorageService.get(url + "_" + type);
    }

    if (cacheData) {
      let res = JSON.parse(cacheData);

      if (type === "language-menu") {
        this.languageMenuResult = res;
      } else if (type == "user-menu") {
        this.userMenuResult = res;
      } else if (type == "custom-header-menu") {
        this.customNodes = res;

        for (var i = 0; i < this.customNodes.length; i++) {
          if (this.customNodes[i].Children != null && this.customNodes[i].Children.length > 0) {
            let key = this.customNodes[i].Id + "";
            this.childrenNodes[key] = {
              "Show": false
            }
          }
        }


        this.checkNotificationData();
      }
      else if (type == "form-action-value") {
        try {
          for (var i = 0; i < res.length; i++) {
            this.actionsService.callAction(res[i]["NavigationNodeRule"]["FormAction"], {}, null, this.formActionValueComplete.bind(this), this, false, null);
          }
        } catch (e) {

        }
      }

    }
    else {

      this.restProvider.getDataPOST<NavigationResponseModel[]>(this.model).subscribe(
        data => {

          this.localStorageService.add(url + "_" + type, JSON.stringify(data));

          if (type === "language-menu") {
            this.languageMenuResult = data;
          } else if (type == "user-menu") {
            this.userMenuResult = data;
          } else if (type == "custom-header-menu") {
            this.customNodes = data;

            for (var i = 0; i < this.customNodes.length; i++) {
              if (this.customNodes[i].Children != null && this.customNodes[i].Children.length > 0) {
                let key = this.customNodes[i].Id + "";
                this.childrenNodes[key] = {
                  "Show": false
                }
              }
            }

            this.checkNotificationData();

          }
          else if (type == "form-action-value") {
            try {
              for (var i = 0; i < data.length; i++) {
                this.actionsService.callAction(data[i]["NavigationNodeRule"]["FormAction"], {}, null, this.formActionValueComplete.bind(this), this, false, null);
              }
            } catch (e) {

            }
          }
        },
        error => {
          //console.log("Error", error);
        }
      );
    }



    //var fModel = new RequestModel;
    //fModel.data = JSON.parse("{\"Page\":" + 1 + ",\"PageSize\":" + 5 + "}");
    //fModel.url = "api/task/getTasks";

  }

  checkNotificationData() {
    // check if any of tasks is not opened yet          
    this.restProvider.getDataGET("api/task/checkunopened", "application/json").subscribe(
      data => {

        if (data != null && typeof data != "undefined" && data == "true") {
          this.showNotificationDotIcon = true;
          if (typeof this.customNodes != "undefined" && this.customNodes) {
            for (var i = 0; i < this.customNodes.length; i++) {
              if (this.customNodes[i].Icon == "notification-center-icon") {
                this.customNodes[i].Icon = "notification-center-dot-icon";
              }
            }
          }
        }

      },
      error => {
        //console.log("Error retrieving tasks", error);
      }
    );
  }

  formActionValueComplete(value) {
    this.formActionResponses.push(value);
  }

  closeMenuDef(event) {
    //this.com1ref.closeMenu(event);
    //this.userMenuState = false;
    //this.languageMenuState = false;
  }

  showBreadcrumb() {

    if (window.location.href.includes('dashboard')) {
      return false;
    }

    return true;
  }

  openChildrenNodes(node) {

    var keys = Object.keys(this.childrenNodes);

    // hide all others
    for (var i = 0; i < keys.length; i++) {
      var key = "" + keys[i];
      var currentKey = "" + node.Id;
      if (key != currentKey) {
        this.childrenNodes[key].Show = false;
      }
    }

    this.childrenNodes[node.Id].Show = !this.childrenNodes[node.Id].Show;
  }

  closeMenu(event) {
    //this.closeMenuEm.emit(event);

    //this.com1ref.closeMenu(event);

    //if (!this.headerChildClick) {
    //  this.userMenuState = false;
    //  this.languageMenuState = false;
    //}

    //this.headerChildClick = false;
  }

  helpSupport() {
    if (this.supportMail != null && typeof this.supportMail != "undefined" && this.supportMail.length > 0)
      window.location.href = "mailto:" + this.supportMail;
  }

  languageChange(language: string) {

    /*let rModel = new RequestModel;
    rModel.url = "api/language/setlanguage";
    rModel.data = { LanguageCode: language };

    this.currentLanguage = language.toUpperCase();

    var url = ("/changelanguage?language=" + language.toLowerCase() + "&ReturnUrl=" + encodeURIComponent(this.router.url));
    window.location.href = url;*/

    let token = this.localStorageService.get("token");
    let user = this.localStorageService.get("user");

    localStorage.clear();

    this.localStorageService.add("token",token);
    this.localStorageService.add("user",user);




    this.localStorageService.add("language", language);

    let transObj = new RequestModel;
    transObj.data = {
      "LanguageCode": language
    };

    transObj.url = "api/translation/all";

    this.restProvider.getDataPOST(transObj).subscribe((data: any) => {

      let translations = data["Translations"];
      this.translationsService.translations = translations;
      this.localStorageService.add("translations", JSON.stringify(translations));

      let currentUrl = this.router.url;
      this.router.resetConfig([]);
      window.location.href = currentUrl;

    }, error => {
      console.log(error);
    });

  }

  topMenuItemClick(item) {

    if (item != null && item.Url != null && item.Url.length > 1) {
      window.location.href = item.Url;
    }
    else if (item != null && item.NavigationNodeRule != null && item.NavigationNodeRule.ActionBody != null && typeof item.NavigationNodeRule.ActionBody != "undefined") {
      eval(item.NavigationNodeRule.ActionBody);
    }
  }

  userMenuClick() {
    this.userMenuState = !this.userMenuState;
    this.headerChildClick = true;
    this.languageMenuState = false;
  }

  userPopupClick() {

    const dialogRef = this.dialog.open(SemUserProfileDialogComponent, { data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
    dialogRef.afterClosed().subscribe(result => {

    });

  }

  languageMenuClick() {
    this.languageMenuState = !this.languageMenuState;
    this.headerChildClick = true;
    this.userMenuState = false;
  }

  notificationsClick() {
    window.location.href = "cms/notificationcenter";
  }
}
