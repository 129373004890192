import { Injectable } from '@angular/core';
import { RestProviderService } from '../rest-provider/rest-provider.service';
import { DynamicLoadingUtil } from '../../shared/helpers/dynamic-loading-util';
import { RequestModel } from '../../models/request/request-model';
import { Observable, throwError } from 'rxjs';
import { map, filter, switchMap, catchError, finalize } from 'rxjs/operators';
import { UserSearchSettingModel } from '../../models/search/user-search-setting-model';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { environment } from '../../../environments/environment';
import { ListingCache } from '../../models/cache/listingCache';
import { of } from 'rxjs';
import { env } from 'process';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  // version for usersearchsetting
  userSearchSettingCurrentVersion: string;
  currentLayout: string;

  // search settings
  private userSearchSettings: Observable<UserSearchSettingModel>; // store the shared observable
  private userSearchSettingsResult: UserSearchSettingModel; // store the result of the observable when complete

  // grid settings
  private userGridSettings: Observable<UserSearchSettingModel>; // store the shared observable
  private userGridSettingsResult: UserSearchSettingModel; // store the result of the observable when complete  

  constructor(private restProvider: RestProviderService, private localStorageService: LocalStorageService) {
  }


  saveUserSettings(model: UserSearchSettingModel, gridSettings: boolean, restore: boolean) {

    if (this.userSearchSettingCurrentVersion != null && typeof this.userSearchSettingCurrentVersion != "undefined") {
      model["Version"] = this.userSearchSettingCurrentVersion;
    }

    for (let [key, value] of Object.entries(localStorage)) {
      if (key.endsWith("usersearch") || key.endsWith("usergrid")) {
        this.localStorageService.remove(key);
      }
    }

    var reqModel = new RequestModel;

    if (restore) {
      if (gridSettings) {
        reqModel.url = "/api/user/grid/settings/restore";
      } else {
        reqModel.url = "/api/user/search/settings/restore";
      }
    } else {
      if (gridSettings) {
        reqModel.url = "/api/user/grid/settings/save";
      } else {
        reqModel.url = "/api/user/search/settings/save";
      }
    }

    reqModel.data = model;

    let savedUserSearchSettings = this.restProvider.getDataPOST<UserSearchSettingModel>(reqModel).
      pipe(
        map((data: UserSearchSettingModel) => {
          return data;
        }), catchError(error => {
          return throwError('User service error!', error)
        }));

    return savedUserSearchSettings;
  }

  updateUserSearchSettingsCustomPageSize(model: UserSearchSettingModel, customPageSize: any) {
    if (this.userSearchSettingCurrentVersion != null && typeof this.userSearchSettingCurrentVersion != "undefined") {
      model["Version"] = this.userSearchSettingCurrentVersion;
    }


    var reqModel = new RequestModel;
    reqModel.url = "/api/user/settings/updatecustompagesize";
    var postModel = {
      "UserSearchSettingData": model,
      "NewCustomPageSize": customPageSize
    }
    reqModel.data = postModel;


    let savedUserSearchSettings = this.restProvider.getDataPOST<UserSearchSettingModel>(reqModel).
      pipe(
        map((data: UserSearchSettingModel) => {
          return data;
        }), catchError(error => {
          return throwError('User service error!', error)
        }));

    return savedUserSearchSettings;
  }

  checkIfInUserGroup(id: any) {

    let user = this.localStorageService.get("user");

    if (user) {
      user = JSON.parse(user);

      for (var i = 0; i < user["Groups"].length; i++) {
        if (user["Groups"][i].Id == id) {
          return true;
        }
      }
    }

    return false;
  }

  getLoggedUser() {
    let user = this.localStorageService.get("user");

    if (user) {
      user = JSON.parse(user);

      return user;
    }

    return null;
  }

  getUserSearchSettings(modelName: string, viewId: string) {

    let cacheModel: ListingCache = new ListingCache;
    let key: any = null;

    if (environment.cacheEnabled) {
      key = this.localStorageService.compileKey([
        this.restProvider.currentUser.Id.toString(),
        modelName,
        viewId,
        "USERSEARCH"
      ]);
      var fromLocalStorageCache = this.localStorageService.get(key);

      if (fromLocalStorageCache) {
        this.userSearchSettingsResult = JSON.parse(fromLocalStorageCache);
        return of(this.userSearchSettingsResult);
      }
    }

    var reqModel = new RequestModel;
    reqModel.url = "/api/user/view-settings/load";
    reqModel.data = {
      "ModelName": modelName,
      "View": {
        "Id": viewId
      }
    };

    /*if (this.userSearchSettingsResult) { // if result has already been found (api has completed) return result
      // create dummy observable and return results
      return new Observable<UserSearchSettingModel>(observer => {
        observer.next(this.userSearchSettingsResult);
        observer.complete();
      })
    } else if (this.userSearchSettings) { // else if api is still processing, return shared observable
      return this.userSearchSettings;
    } else { // else api has not started, start api and return shared observable
*/
    this.userSearchSettings = this.restProvider.getDataPOST<UserSearchSettingModel>(reqModel).
      pipe(
        map((data: UserSearchSettingModel) => {
          this.userSearchSettingsResult = data;
          if (environment.cacheEnabled) {
            this.localStorageService.add(key, JSON.stringify(data));
          }
          return data;
        }), catchError(error => {
          return throwError('User service error!', error)
        }));

    return this.userSearchSettings;
    //}
  }

  getUserGridSettings(modelName: string, viewId: string, viewType: string, layout: string) {

    let cacheModel: ListingCache = new ListingCache;

    if (typeof (viewType) == "undefined" || !viewType) {
      viewType = "list";
    }

    var fromLocalStorageCache: any = null;
    let key: any = null;

    if (environment.cacheEnabled) {
      var res = this.localStorageService.get("CURRENT_LAYOUT_"+modelName);
      if (res) {
        layout = res;
      } else {
        layout = viewType;
      }


      key = this.localStorageService.compileKey([
        this.restProvider.currentUser.Id.toString(),
        modelName,
        viewId,
        layout,
        "USERGRID"
      ]);

      fromLocalStorageCache = this.localStorageService.get(key);

      if (fromLocalStorageCache && environment.cacheEnabled) {
        this.userGridSettingsResult = JSON.parse(fromLocalStorageCache);
        return of(this.userGridSettingsResult);
      }
    }

    var reqModel = new RequestModel;
    reqModel.url = "/api/user/grid/settings";
    reqModel.data = {
      "ModelName": modelName,
      "LayoutName": viewType,
      "View": {
        "Id": viewId
      }
    };

    /*if (this.userGridSettingsResult) { // if result has already been found (api has completed) return result
      // create dummy observable and return results
      return new Observable<UserSearchSettingModel>(observer => {
        observer.next(this.userGridSettingsResult);
        observer.complete();
      })
    } else if (this.userGridSettings) { // else if api is still processing, return shared observable
      return this.userGridSettings;
    } else { // else api has not started, start api and return shared observable
*/

    this.userGridSettings = this.restProvider.getDataPOST<UserSearchSettingModel>(reqModel).
      pipe(
        map((data: UserSearchSettingModel) => {
          this.userGridSettingsResult = data;

          if (environment.cacheEnabled) {
            let layoutTitle = "list";
            if (this.userGridSettingsResult?.LayoutName) {
              localStorage.setItem("CURRENT_LAYOUT_" + modelName, this.userGridSettingsResult?.LayoutName);
              layoutTitle = this.userGridSettingsResult?.LayoutName;
            }
            key = this.localStorageService.compileKey([
              this.restProvider.currentUser.Id.toString(),
              modelName,
              viewId,
              layoutTitle,
              "USERGRID"
            ]);

            this.localStorageService.add(key, JSON.stringify(data));
          }

          return data;
        }), catchError(error => {
          return throwError('User service error!', error)
        }));

    return this.userGridSettings;
    //}
  }

  logout() {
    let languageCode = this.localStorageService.get("language");
    if (languageCode == null) {
      languageCode = environment.language.toUpperCase();
    }

    localStorage.clear();

    this.localStorageService.add("language", languageCode);
    window.location.href = "/account/login";
  }


  updateTaskSubscription(taskSubscriptionValue: boolean) {
    let requestModel = new RequestModel;
    requestModel.url = "/api/user/tasksubscriptionset";
    requestModel.contentType = "application/json";
    requestModel.responseType = "application/json";
    requestModel.data = { "TaskSubscriptionEnabled": taskSubscriptionValue };

    DynamicLoadingUtil.loader.showLoader = true;

    DynamicLoadingUtil.loader.showLoader = true;

    return this.restProvider.getDataPOST<any>(requestModel).pipe(
      finalize(() => {
        DynamicLoadingUtil.loader.showLoader = false;
      }));
  }

  saveNewSearchView(model: any) {
    
    var reqModel = new RequestModel;

    reqModel.url = "/api/user/view-settings/save";
    reqModel.data = model;

    let savedUserSearchSettings = this.restProvider.getDataPOST<any>(reqModel).pipe(
      map((data: any) => {
        // We get UserSearchSetting.View.Id back, we only need this to redirect to new view
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = 'saveNewSearchView error!';        
        return throwError(errorMsg);
      })
    );

    return savedUserSearchSettings;
  }

  updateSearchView(model: any) {
    
    var reqModel = new RequestModel;

    reqModel.url = "/api/user/view-settings/update";
    reqModel.data = model;

    let savedUserSearchSettings = this.restProvider.getDataPOST<any>(reqModel).pipe(
      map((data: any) => {
        // We get UserSearchSetting.View.Id back, we only need this to redirect to new view
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = 'updateSearchView error!';        
        return throwError(errorMsg);
      })
    );

    return savedUserSearchSettings;
  }

  getUserSearchViews(modelName: string) {
    
    var reqModel = new RequestModel;

    reqModel.url = "/api/user/view-settings/list";
    reqModel.data = {
      "modelName": modelName
    };

    let userSearchViews = this.restProvider.getDataPOST<any[]>(reqModel).
      pipe(
        map((data: any[]) => {          
          return data;
        }), catchError(error => {
          return [];
        }));

    return userSearchViews;
  }

  deleteSearchView(searchViewId: number){
    var reqModel = new RequestModel;

    reqModel.url = "/api/user/view-settings/remove";
    reqModel.data = {
      "id": searchViewId
    };

    let userSearchViews = this.restProvider.getDataPOST<any>(reqModel).
      pipe(
        map((data: any) => {          
          return data;
        }), catchError(error => {
          return [];
        }));

    return userSearchViews;
  }

  loadUserSearchView(modelName: string, viewId: number) {
    
    var reqModel = new RequestModel;

    reqModel.url = "/api/user/view-settings/load";
    reqModel.data = {
      "modelName": modelName,
      "View": { "Id": viewId }
    };

    let userSearchViews = this.restProvider.getDataPOST<any[]>(reqModel).
      pipe(
        map((data: any[]) => {          
          return data;
        }), catchError(error => {
          return [];
        }));

    return userSearchViews;
  }

}
