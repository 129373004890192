import { RouteNavigationService } from '../../../services/dynamic-routes-loader/route-navigation.service';
import { Component, OnInit, Injector, ViewChild, ElementRef, ComponentRef, Input } from '@angular/core';
import { NavigationNodeModel } from '../../../models/navigation/navigation-node-model';
import { NavigationRequestModel } from '../../../models/navigation/navigation-request-model';
import { NavigationResponseModel } from '../../../models/navigation/navigation-response-model';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { RequestModel } from '../../../models/request/request-model';
import { Observable, config } from "rxjs";
import { map, filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { SemLoaderComponent } from '../../../shared/components/sem-loader/sem-loader.component';
import { DynamicLoadingUtil } from '../../../shared/helpers/dynamic-loading-util';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';
import { FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { ActionsService } from 'src/app/services/actions-service/actions.service';
import { FormService } from '../../../services/form-service/form.service';
import { UserService } from '../../../services/user/user-provider.service';


@Component({
  selector: 'app-sem-base',
  templateUrl: './sem-base.component.html',
  styleUrls: ['./sem-base.component.css']
})
export class SemBaseComponent implements OnInit {

  loading: boolean = false;
  sidebarMenuOpened: boolean = false;
  breadcrumbs: any;
  @Input() mainFormGroup: FormGroup;

  constructor(public injector: Injector, public router: Router, public activatedRoute: ActivatedRoute, public dfms: DynamicFieldsManagerService,
    public utility : UtilityService, public formService:FormService) {
  }

  ngOnInit() {
  }

  public get dynamicRoutesLoader(): RouteNavigationService { //this creates router property on your service.
    return this.injector.get(RouteNavigationService);
  }

  

  evaluateAction(source) {
    eval(source);
  }

  evaluateCondition(source) {
    return eval(source);
  }

  evaluateConditionForHiding(source, model) {

      if (typeof (model) != "undefined") {
        if (typeof (source) != "undefined" && source != null) {

          if (source.length > 0) {
            let res = eval(source);

            if (typeof res != "undefined" && res != null && res.length != 0 && res != false) {
              return true;
            } else {
              return false;
            }
          }
          else {
            return false;
          }
        } else {
          return false;
        }
      }

  }

  modelChanged(model){
    //console.log("Model changed, base component: ", model);
  }
}
