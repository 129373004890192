import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { RequestModel } from 'src/app/models/request/request-model';
import { SemDynamicComponent } from 'src/app/shared/components/sem-dynamic/sem-dynamic.component';
import { SemDynamicControlComponent } from 'src/app/components/controls/shared/sem-dynamic-control/sem-dynamic-control.component';
import { type } from 'os';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';
import { parse } from 'querystring';
import { RestProviderService } from 'src/app/services/rest-provider/rest-provider.service';
import { ActionsService } from 'src/app/services/actions-service/actions.service';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { ContainerWrapperComponent } from '../container-wrapper/container-wrapper/container-wrapper.component';

@Component({
  selector: 'app-notification-center',
  templateUrl: './workflow-center.component.html',
  styleUrls: ['./workflow-center.component.css']
})
export class WorkflowCenterComponent extends ContainerWrapperComponent implements OnInit {

  super() { }

  acceptDD: boolean = false;

  items: any[] = [];
  paging: Number[] = [];
  currentItem: any = null;
  currentTaskOutcome: any = null;
  selectedItems: any[] = null;
  today = new Date();
  showButtons: boolean = true;
  currentPage: Number = 1;
  currentPageSize: number = 5;
  public restService:RestProviderService = null;
  public changeDetection:ChangeDetectorRef = null;
  public actionsService:ActionsService = null;
  public translationService:TranslationsProviderService = null;

  private alreadyOpened: any[] = [];

  @ViewChild("dynComp", { read: SemDynamicControlComponent }) dynComp: SemDynamicControlComponent;

  ngOnInit() {
    this.translationService = this.injector.get(TranslationsProviderService);
    this.restService = this.injector.get(RestProviderService);
    this.changeDetection = this.injector.get(ChangeDetectorRef);
    this.actionsService = this.injector.get(ActionsService);

    this.items = [];
    this.loadItems();
  }

  loadItems(page = 1) {

    DynamicLoadingUtil.loader.showLoader = true;

    var fModel = new RequestModel;
    fModel.data = JSON.parse("{\"Page\":" + page + ",\"PageSize\":" + this.currentPageSize + "}");
    fModel.url = "api/task/getAllTasks";

    this.restService.getDataPOST<any>(fModel).subscribe(
      async data => {

        this.items = [];
        for (var i = 0; i < data["Models"].length; i++) {
          var objres = await this.restService.getDataGET("api/data/form/5483-Probation2ProbationForm/" + data["Models"][i]["Model"]["EntityID"]).toPromise();
          var obj = data["Models"][i]["Model"];
          obj["Entity"] = JSON.parse(objres)["Model"];
          this.items.push(obj);
        }

        if (parseInt(data["TotalCount"]) > this.currentPageSize) {
          this.paging = [];
          for (var i = 0; i < Math.ceil(parseInt(data["TotalCount"]) / 5); i++) {
            this.paging.push(i + 1);
          }
        }

        if (this.items != null && typeof this.items != "undefined") {
          for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].FirstOpenDate != null && typeof this.items[i].FirstOpenDate != "undefined") {
              this.alreadyOpened.push(this.items[i].Id);
            }
          }
        }

        DynamicLoadingUtil.loader.showLoader = false;

      },
      error => {
        console.log("Error retrieving tasks", error);
        DynamicLoadingUtil.loader.showLoader = false;
      }
    );
  }

  checkIfOpened(task) {
    var index = this.alreadyOpened.findIndex(x => x == task.Id);
    if (index >= 0) {
      return true;
    }
    
    if(task.CanOpenIfComplete == null || typeof task.CanOpenIfComplete == "undefined" && task.CanOpenIfComplete == false){
      if(task.Complete){
        return true;
      }
    }

    return false;
  }

  accept() {
    this.acceptDD = !this.acceptDD;
    this.changeDetection.detectChanges();

    if (this.currentItem["TaskOutcomes"].length == 1) {
      this.currentTaskOutcome = this.currentItem["TaskOutcomes"][0];
      this.executeOutcome(this.currentTaskOutcome);
    }

  }  

  executeOutcome(outcome) {
    this.currentTaskOutcome = outcome;

    if (typeof (this.dynComp.cmpRef) != "undefined" && this.dynComp.cmpRef != null) {
      if (typeof (this.dynComp.cmpRef.instance) != "undefined" && this.dynComp.cmpRef.instance != null) {
        let cModel = this.dynComp.cmpRef.instance.model;
        this.dynComp.model = this.dynComp.cmpRef.instance.model;
        if (typeof outcome["Action"] != "undefined" && outcome["Action"] != null) {
          this.actionsService.callAction(outcome["Action"], cModel, null, this.actionExecuted.bind(this), this, false, null, outcome.Action.OnlyClientLogic,true);
        } else {
          this.actionExecuted(null, null);
        }
      } else {
        this.actionExecuted(null, null);
      }
    } else {
      this.actionExecuted(null, null);
    }
  }

  itemSelected(item: Event, obj) {
    //console.log("Item selected: ", item.srcElement["checked"]);

    // if not opened yet
    if (item.srcElement["checked"] && !this.checkIfOpened(obj.Id)) {
      this.restService.getDataGET("api/task/opened/" + obj.Id, "application/json").subscribe(data => {
        this.alreadyOpened.push(obj.Id);        
      });
    }

    this.currentItem = null;
    this.currentTaskOutcome = null;

    this.changeDetection.detectChanges();

    if (item.srcElement["checked"]) {
      this.currentItem = obj;      
    }

    if(this.currentItem != null && typeof this.currentItem != "undefined" && this.currentItem.ShowMessageOnly){
      this.showButtons = false;
    }else{
      this.showButtons = true;    
    }

  }

  checkDateCreated(task){
    if(task != null && typeof task != "undefined" && task.DateCreated != null && typeof task.DateCreated != "undefined"){
      return true;
    }
    return false;
  }

  checkDueDate(task){
    if(task != null && typeof task != "undefined" && task.DueDate != null && typeof task.DueDate != "undefined"){
      return true;
    }
    return false;
  }

  actionExecuted(model, error) {
    if (error != null && typeof (error) != "undefined") {
      return;
    }

    if (typeof (model) != "undefined" && model != null) {

      var rModel = model["Model"];

      if (rModel != null && typeof rModel != "undefined") {
        rModel["SerializationObjectFormLink"] = this.dynComp.model["SerializationObjectFormLink"];
        this.dynComp.model = rModel;
        this.dynComp.cmpRef.instance.model = this.dynComp.model;
        this.currentItem["EntityID"] = rModel["Id"];
      }
    }

    this.currentItem["Complete"] = true;
    this.currentItem["Result"] = this.currentTaskOutcome["OutcomeText"];

    let entityId = this.currentItem["EntityID"];

    if (entityId == 0) {
      entityId = null;
    }

    var fModel = new RequestModel;
    fModel.data =
      {
        TaskId: this.currentItem["TaskID"],
        Result: this.currentItem["Result"],
        Completed: this.currentItem["Complete"],
        EntityID: entityId
      };
    fModel.url = "api/task";

    DynamicLoadingUtil.loader.showLoader = true;

    this.restService.getDataPOST(fModel).subscribe(data => {
      //console.log("Task saved ", data);
      DynamicLoadingUtil.loader.showLoader = false;
      // hide task popup buttons after task is completed
      this.showButtons = false;
      this.loadItems();
      this.currentItem = null;
    }, error => {
      console.log("Error while saving task: ", error);
      DynamicLoadingUtil.loader.showLoader = false;
    });

  }

  decline() {
    this.currentItem = null;
    this.currentTaskOutcome = null;
  }

  loadItemsPage(page) {
    this.currentPage = page;
    this.loadItems(page);
  }

  downloadTaskFile(task){
    
    if(task != null && typeof task != "undefined"){      
      var model: RequestModel = new RequestModel;
      model.url = "api/result/get/processedresultexcel/"+task.Id;      
      
      this.restService.getDataPOST(model).subscribe(data => {
          const linkSource = `data:${data["MimeType"]};base64,${data["Content"]}`;
		      const downloadLink = document.createElement('a');
		      document.body.appendChild(downloadLink);
		      downloadLink.href = linkSource;
		      downloadLink.target = '_self';
		      downloadLink.download = data["FileName"];
		      downloadLink.click(); 
      });
    }  
  }    
}
