import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { RequestModel } from '../../../models/request/request-model';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'sem-image-viewer',
  templateUrl: './sem-image-viewer.component.html',
  styleUrls: ['./sem-image-viewer.component.css']
})

export class SemImageViewerComponent extends SemControlComponent implements OnInit, AfterViewInit {

  @Input() mediaId: Number = 0;
  @Input() height: string = "90";
  @Input() width: string = "90";
  @Input() name: string = "";
  @Input() customMeadiasPropertyPath: string = "";

  @Output() fileLoaded: EventEmitter<File> = new EventEmitter<File>();

  private previewApiUrl: string = "api/media/GetPreview";
  imageUrls: any[] = [];

  fileObj: File | null = null;
  base64:any = null;

  private heightStyle: string = "";
  private widthStyle: string = "";

  super() { }

  ngOnInit() {

    this.heightStyle = this.height + " !important";
    this.widthStyle = this.width + " !important";

  }

  ngAfterViewInit() {

    this.loading = false;
    this.resolveImage();

  }

  getSpecifiedWidth() {
    var widthStyleValue = this.width + "px";
    return widthStyleValue;
  }

  getSpecifiedHeight() {
    var heightStyleValue = this.height + "px";
    return heightStyleValue;
  }

  resolveImage() {
    try {
      this.loading = true;
      let entityId: number = 0;

      if (typeof this.model != "undefined" && this.model) {
        entityId = this.model["Id"];
        if (typeof this.model["Medias"] != "undefined" && this.model["Medias"]) {
          this.mediaId = this.model["Medias"][0]["FileID"];
        }
      }

      if ((typeof this.mediaId == "undefined" || !this.mediaId || this.mediaId == 0) && this.customMeadiasPropertyPath) {
        let medias = this.dfms.getValue(this.model, this.customMeadiasPropertyPath);
        if (medias) {
          let min = Math.min(...medias.map(item => item.FileOrder));
          this.mediaId = medias.filter(item => item.FileOrder === min)[0].Id;
        }
      }

      this.imageUrls = [];

      let model = new RequestModel;
      model.url = this.previewApiUrl;
      model.data = { Id: this.mediaId, Height: this.height, Width: this.width, documentId: entityId };
      model.contentType = "application/json";
      model.responseType = "application/json";

      if (model.data.Id == "") {
        model.data.Id = 0;
      }

      this.restProviderService.imagePostRequest(model).subscribe((data: any) => {


        //fileObj is not used, so the fileObj is not init.
        //let file = this.dataURLtoFile("data:text/plain;base64," + data.Base64Img, this.name);
        //this.fileObj = file;

        let imageSrc = "data:text/plain;base64," + data.Base64Img;//URL.createObjectURL(file);
        this.base64 = data.Base64Img;

        this.imageUrls.push(imageSrc);

        this.loading = false;
      },

        err => {
          //console.log("Error GetPreview");
          if (typeof (err) == "string" && err.includes("Error Code: 304") && environment.useEtag) {
            // Image not modified, use the cached image
            console.log("We've got 304");
          }

          this.loading = false;
        });



      //return "";
    } catch (e) {

      this.loading = false;
    }
  }

  private dataURLtoFile(dataurl, filename) {
    this.loading = true;
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    this.loading = false;
    return new File([u8arr], filename, { type: mime });
  }

  imageClick() {
    //Image click is not implemented in html, so the fileObj is not init. on image base64 retrieval above
    if (this.fileObj != null) {
      this.fileLoaded.emit(this.fileObj);
    }
  }

}
