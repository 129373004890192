import { Injectable } from '@angular/core';
import { RestProviderService } from '../rest-provider/rest-provider.service';
import { EntityVersionModel } from 'src/app/models/versioning/entity-version-model';

@Injectable({
  providedIn: 'root'
})
export class EntityVersionService {

  constructor(public restService: RestProviderService) { }


  loadVersions(type:string, id:number){
    return this.restService.getData<EntityVersionModel[]>("api/versioning/list/"+type+"/"+id);   
  }

  comapre(id:number){
    return this.restService.getData<any>("api/versioning/compare/"+id);   
  }

  restore(id:number){
    return this.restService.getData<any>("api/versioning/restore/"+id);   
  }
}
