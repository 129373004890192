import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { RequestModel } from 'src/app/models/request/request-model';
import { takeUntil } from 'rxjs/operators';
import * as _ from "lodash";
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'sem-multiple-select',
  templateUrl: './sem-multiple-select.component.html',
  styleUrls: ['./sem-multiple-select.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemMultipleSelectComponent, multi: true }
  ]
})
export class SemMultipleSelectComponent extends ValueAccessorBase<any> implements OnInit, AfterViewInit {

  @Input() galregistryname: string;
  @Input() subBindingPropertyName: string;
  @Input() titleField: string = "Title";

  @Input() contentBindingProperty: string;

  @Input() searchQueryFilter: any = {};
  @Input() customDataApiInputParameters: any = null;	
  @Input() customDataApi: string = null;

  listControl = new FormControl();
  list: any[] = [];
  selectedValues: any[] = [];
  items: any[] = [];

  query = JSON.parse("{ \"MainQuery\": null, \"FieldQueries\": { }, \"FacetQueries\": { }, \"RequestedFacets\": null, \"Projection\": [], \"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"*\", \"FieldValue\": \"*\" }, \"ClassTypes\": [], \"Page\": \"1\", \"PageSize\": 500, \"RepositoryName\": \"lucene\", \"Form\": null, \"UseCache\": false, \"Facets\": [], \"SortFields\": [] }");

  ngAfterViewInit() {

    this.loadItems();
    let names = [];

    this.changeDetection.detectChanges();

  }

  super() { }

  ngOnInit() {
    this.route	
    .queryParams	
    .subscribe(params => {	
      // Defaults to 0 if no query param provided.	
      this.params = params;	
    });
  }

  loadItems() {

    if (typeof this.titleField == "undefined") {
      this.titleField = "Title";
    }

    let autocompleteTreeQuery = { Type: "FieldValue", FieldName: this.titleField, FieldValue: "*" }

    let requestModel: RequestModel = new RequestModel();
    requestModel.url = "/api/registry/dropdown";
    requestModel.contentType = "application/json"
    let searchQ = this.query;
    searchQ["ClassType"] = this.galregistryname;
    searchQ["ClassTypes"] = [this.galregistryname];
    searchQ["Projection"] = [this.titleField];
    searchQ["TreeQuery"] = autocompleteTreeQuery;
    requestModel.data = searchQ;

    /*if (this.searchQueryFilter != null && typeof this.searchQueryFilter != "undefined" && Object.entries(this.searchQueryFilter).length !== 0 && this.searchQueryFilter.constructor !== Object) {
      searchQ["TreeQuery"].Type = "AND";
      searchQ["TreeQuery"].SubQueries = [JSON.parse(this.searchQueryFilter), autocompleteTreeQuery];
    } else {
      searchQ["TreeQuery"] = autocompleteTreeQuery;
    }*/

    if(this.customDataApi != null && typeof this.customDataApi != "undefined" && this.customDataApi.length > 0){	
      requestModel = new RequestModel();	
      requestModel.contentType = "application/json"	
      requestModel.url = this.customDataApi;	
          if (this.customDataApiInputParameters != null && typeof this.customDataApiInputParameters != "undefined" && Object.keys(this.customDataApiInputParameters).length > 0) {	
            try {	
              _.templateSettings.interpolate = /\${([\s\S]+?)}/g;	
              let formModel = {};	
              if (this.model != null && typeof this.model != "undefined") {	
                formModel = this.model;	
              }	
        	
              if (Object.keys(formModel).length == 0) {	
                if (this.dfms.globalModel != null && typeof this.dfms.globalModel != "undefined") {	
                  formModel = this.dfms.globalModel;	
                }	
              }	
              formModel["Id"] = this.params["id"];	
              // interpolate	
              let compiled = _.template(this.customDataApiInputParameters);	
              let compiledInputParams = compiled(formModel);	
              if (compiledInputParams != null && typeof compiledInputParams != "undefined") {	
                let compiledInputParamsObject = JSON.parse(compiledInputParams);	
                requestModel.data = { "InputParameters": compiledInputParamsObject };	
              }	
            } catch {	
            }	
          }	
    }else{	
      if(this.searchQueryFilter != null && typeof this.searchQueryFilter != "undefined" && Object.entries(this.searchQueryFilter).length !== 0 && this.searchQueryFilter.constructor !== Object){	
        var finalQ = {"Type":"AND"};	
        var subq: any[] = [];	
        	
        subq.push(JSON.parse(this.searchQueryFilter));	
        subq.push(autocompleteTreeQuery);	
  	
        finalQ["SubQueries"] = subq;	
  	
        searchQ["TreeQuery"] = finalQ;	
      }	
    } 

    this.restProviderService.getDataPOST<any>(requestModel).subscribe(data => {
      this.zone.run(() => {
        let result = data["Models"];

        this.list = [];
        for (var i = 0; i < result.length; i++) {
          this.list = [...this.list, result[i]["Model"]];
        }

        if (this.titleField == "" || this.titleField == null || typeof this.titleField == "undefined") {
          this.titleField = "Title";
          this.changeDetection.detectChanges();
        } else {
          this.changeDetection.detectChanges();
        }
        /*this.restProviderService.modelValueChanged.subscribe(model => {
          this.selectedValues = [];
          this.items = this.dfms.getValue(model,this.bindingProperty);
          if (typeof this.items != "undefined" && this.items) {

            for (var i = 0; i < this.items.length; i++) {
              for (var j = 0; j < this.list.length; j++) {
                if (this.list[j]["Id"] == this.items[i]["Id"]) {
                  this.selectedValues.push(this.list[j]);
                  //names.push(this.model[this.name][i][this.titleField]);
                  break;
                }
              }
            }
            this.dfms.assign(model,this.bindingProperty,this.selectedValues,true);
            this.items = this.dfms.getValue(model,this.bindingProperty);
            this.listControl.setValue(model[this.name]);
          }
        });*/

        

      });
    },
      error => {
        //console.log("Error", error);

      }
    );
  }

  showModel() {
  }

  setValue(value) {
    this.value = value;
  }


  onSelectionChange(event: MatSelectChange) {

    let attName = this.getAttribute("name");
    let control = this.dfms.globalForm.controls[attName];
    let newMultipleSelectValue = null;

    if (control !== null && typeof control !== "undefined") {

      // dobis value iz modela na formi
      //newMultipleSelectValue = this.dfms.getValue(this.dfms.globalModel, this.bindingProperty);
      newMultipleSelectValue = control.value;

      // pripises value preko setValue
      this.dfms.globalForm.controls[attName].setValue(newMultipleSelectValue);
      this.dfms.globalForm.controls[attName].updateValueAndValidity();
    }

    // updetas controlValuesForValidation preko dfms metode
    if (newMultipleSelectValue !== null){
      this.dfms.updateControlsValidationArray(attName, newMultipleSelectValue);
    }    
  }  

  compareFn(o1: any, o2: any) {
    if(o1 != null && typeof o1 != "undefined" && o2 != null && typeof o2 != "undefined"){	
      return o1.Id === o2.Id;	
    }	
    return false;
}

}
