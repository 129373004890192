import { Component, OnInit, Input, AfterContentInit, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { SemHttpUtility } from '../../../shared/helpers/sem-http-utility';
import { SemControlComponent } from '../sem-control/sem-control.component';
import * as _ from "lodash";


@Component({
  selector: 'sem-query',
  templateUrl: './sem-query.component.html',
  styleUrls: ['./sem-query.component.css']
})
export class SemQueryComponent extends SemControlComponent implements OnInit {

  @Input() controlTitle: string;
  @Input() redirectLink: string;
  @Input() searchQuery: any;

  private searchServiceSubscriber = null;
  public resultItems: any = [];
  public pages: any = [];
  public previousPage = 0;
  public nextPage = 0;
  private interpolateMainSearchQueryObject: any;

  super() {}

  ngOnDestroy() {    
    if (this.searchServiceSubscriber != null) {
      this.searchServiceSubscriber.unsubscribe();
    }    
  }

  ngOnInit() {
    super.ngOnInit;

    this.route.queryParams.subscribe(params => {
      this.params = params;
    });    
    
    this.modelChangeNotifier.subscribe((p_model) => {
      
      let formModel = p_model as any;
      let stringSearchQuery = JSON.stringify(this.searchQuery);

      // use custom delimiter ${ }
      _.templateSettings.interpolate = /\${([\s\S]+?)}/g;

      // interpolate
      let compiled = _.template( stringSearchQuery );
      let compiledSearchQuery = compiled( formModel );

      if(compiledSearchQuery != null && typeof compiledSearchQuery != "undefined"){

        this.interpolateMainSearchQueryObject = JSON.parse(compiledSearchQuery);    
        this.performSearch();        
      }
    });
  }  

  private performSearch() {
    if (this.searchServiceSubscriber != null) {
      this.searchServiceSubscriber.unsubscribe();
    }

    this.resultItems = [];

    this.searchServiceSubscriber = this.searchService.performSearch(this.interpolateMainSearchQueryObject, null,null, null).subscribe(data => {                  

      for (var i = 0; i < data["Models"].length; i++) {            

        let item = data["Models"][i].Model;
        item["ItemLink"] = this.redirectLink + "?id=" + data["Models"][i].Model["Id"];            

        this.resultItems.push(item);

        // handle pagination
        this.pages = [];

        // convert to int      
        let totalCountNumber = data["TotalCount"];
        let currentPage = data["Page"];
  

        // only show pages if more than 1
        if(totalCountNumber != 0 && totalCountNumber > data["PageSize"]){

          let pageNum = (totalCountNumber + data["PageSize"] - 1) / data["PageSize"];        
          pageNum = Math.floor(pageNum);
  
          let from = 0;
          let to = pageNum;
  
          from = currentPage - 2;
          if(from <= 0){
            from = 1;
          }
  
          to = from + 5;
  
          if(to > pageNum){
            to = pageNum+1;
          }
            
          this.previousPage = currentPage - 1;
          if(this.previousPage <= 0){
            this.previousPage = 0;
          }
  
          this.nextPage = currentPage + 1;
          if(this.nextPage > pageNum){
            this.nextPage = 0;
          }
  
          for (let i = from; i < to; i++) {
    
            let active = false;            
            if(i == currentPage){
              active = true;
            }
    
            this.pages.push({ "Number": i , "Active": active});
          }              
        }            
      }
                           
    }, error => {     
      //console.log("Sem query error search: "+ error);
    });
  }

  redirectToRelatedItem(formLink: string) {
    this.utility.processNavigationUrl(formLink);
  }

  pageChanged(pageNumber: any) {
    if(pageNumber != null && typeof pageNumber != "undefined"){
      // change "Page" parameter of interpolateSearchQueryObject
      if(this.interpolateMainSearchQueryObject != null && typeof this.interpolateMainSearchQueryObject != "undefined"){
        this.interpolateMainSearchQueryObject["Page"] = pageNumber;
        this.performSearch();
      }
    }
  }

}
