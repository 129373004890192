import { Injectable } from '@angular/core';
import { RestProviderService } from '../rest-provider/rest-provider.service';
import { DynamicLoadingUtil } from '../../shared/helpers/dynamic-loading-util';
import { RequestModel } from '../../models/request/request-model';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, filter, switchMap, catchError } from 'rxjs/operators';
import { PaginationModel } from 'src/app/models/search/pagination.model';
import { ProjectionFacetWrapperModel } from 'src/app/models/search/facet-projection-wrapper.model';
import { AdditionalSearchSettingsModel } from 'src/app/models/search/additional-search-settings.model';
import { Router } from '@angular/router';
import { SearchRightInfoModel } from '../../models/search/search-right-info-model';
import { parse } from 'path';


@Injectable({
  providedIn: 'root'
})
export class SearchService {


  currentPreviewItemClicked = null;
  searchState: any = null;
  historySearchStateAdditionalFields: any = null;
  historySearchState: any = null;
  historySearchLatestFacets: any = null;
  historySearchMainSearchFieldValue: any = null;
  globalSearch: boolean = false;
  globalSearchType: string;

  splitSearchEnabled: boolean = false;
  splitSearchKeywords: any[] = [];

  facetsOrder: any[] = [];

  // settings for data grid and search component
  pageSize: number = 5;
  public currentPlaceHoldertitle: string;
  private searchRightInfo: Observable<SearchRightInfoModel[]>; // store the shared observable
  private searchRightInfoResponse: SearchRightInfoModel[];

  private defaultSearchUrl: string = "api/data/list";
  // search results
  private search: Observable<any[]>; // store the shared observable

  // projection and initial facets
  private searchProjectionAndFacets = new BehaviorSubject<ProjectionFacetWrapperModel>(undefined);
  currentSearchProjectionAndFacets = this.searchProjectionAndFacets.asObservable();

  // listener for search value change
  private newSearchValue = new BehaviorSubject(undefined);
  currentSearchValue = this.newSearchValue.asObservable();

  // listener for search pagination
  private pagination = new BehaviorSubject<PaginationModel>(undefined);
  currentPagination = this.pagination.asObservable();

  // listener for search page
  private searchPage = new BehaviorSubject(undefined);
  currentSearchPage = this.searchPage.asObservable();

  // listener for search facets - current facets that are included in search request
  private searchFacets = new BehaviorSubject(undefined);
  currentSearchFacets = this.searchFacets.asObservable();

  // facets that are availible for filtering on current listing page
  private availibleFacets = new BehaviorSubject(undefined);
  currentAvailibleFacets = this.availibleFacets.asObservable();

  // metamodel
  private metamodel = new BehaviorSubject(undefined);
  currentMetaModel = this.metamodel.asObservable();

  // sort field
  private sortFields = new BehaviorSubject(undefined);
  currentSortFields = this.sortFields.asObservable();

  // for enabling facets
  private enableFacets = new BehaviorSubject(undefined);
  currentEnableFacets = this.enableFacets.asObservable();

  // for additional search settings
  private additionalSearchSettings = new BehaviorSubject<AdditionalSearchSettingsModel>(undefined);
  currentAdditionalSearchSettings = this.additionalSearchSettings.asObservable();

  // listener for search event (if search was executed)
  private searchExecuted = new BehaviorSubject(undefined);
  currentSearchExecuted = this.searchExecuted.asObservable();


  private gridRestoredDefault = new BehaviorSubject(undefined);
  currentgridRestoredDefault = this.gridRestoredDefault.asObservable();

  private searchFieldsRestoredDefault = new BehaviorSubject(undefined);
  currentsearchFieldsRestoredDefault = this.searchFieldsRestoredDefault.asObservable();

  // listener for search event (if search was executed)
  private userSearchSettings = new BehaviorSubject(undefined);
  currentUserSearchSettings = this.userSearchSettings.asObservable();

  // listener for changing page size
  private pageS = new BehaviorSubject(undefined);
  currentPageSize = this.pageS.asObservable();

  // listener for split search
  splitSearchS = new BehaviorSubject(undefined);
  splitSearch = this.splitSearchS.asObservable();

  // current search query  
  private currentSearchQuery = null;
  // current data grid display fields
  private currentGridDisplayFields = null;

  private currentCheckedBulkDocuments = null;

  constructor(private restProvider: RestProviderService, private router: Router) {
  }

  setGridRestoredDefault(value) {
    this.gridRestoredDefault.next(value);
  }

  setSearchFieldsRestoredDefault(value) {
    this.searchFieldsRestoredDefault.next(value);
  }

  setEnableFacets(enable: boolean) {
    this.enableFacets.next(enable);
  }

  setAdditionalSearchSettings(settings: AdditionalSearchSettingsModel) {
    this.additionalSearchSettings.next(settings);
  }

  searchPerformed() {
    this.searchExecuted.next(true);
  }

  changeCurrentSearchSettings(currentSearchSettings: any, control: string) {
    this.userSearchSettings.next(
      {
        "Control": control,
        "Settings": currentSearchSettings
      });
  }

  changeSortFields(sortFields: any) {
    this.sortFields.next(sortFields);
  }

  changePageSize(pagesize: number) {
    if (pagesize != null && typeof pagesize != "undefined") {
      this.pageSize = pagesize;
      this.pageS.next(pagesize);
    }
  }

  changeMetaModelEntities(metamodel: any) {
    this.metamodel.next(metamodel);
  }

  changeAvailibleFacets(facets: any) {
    this.availibleFacets.next(facets);
  }

  changeSearchFacets(facets: any) {
    this.searchFacets.next(facets);
  }

  setCurrentSearchQuery(searchQuery: any) {
    this.currentSearchQuery = searchQuery;
  }

  getCurrentSearchQuery() {
    return this.currentSearchQuery;
  }

  setCurrentCheckedBulkDocuments(currentCheckedBulkDocs: any[]) {
    this.currentCheckedBulkDocuments = currentCheckedBulkDocs;
  }

  getCurrentCheckedBulkDocument() {
    return this.currentCheckedBulkDocuments;
  }

  setCurrentGridDisplayFields(displayFields: any[]) {
    this.currentGridDisplayFields = displayFields;
  }

  getCurrentGridDisplayFields() {
    return this.currentGridDisplayFields;
  }

  changeSearchPage(pageNum: any) {
    if (pageNum == null || typeof pageNum == "undefined") {
      this.searchPage.next(undefined);
    } else {
      this.searchPage.next(pageNum + "");
    }
  }

  changeSearchProjectionAndFacets(projectionAndFacets: ProjectionFacetWrapperModel) {
    this.searchProjectionAndFacets.next(projectionAndFacets);
  }

  changePaginationInfo(paginationInfo: PaginationModel) {
    this.pagination.next(paginationInfo);
  }

  historySearchStatePresent(query: any) {
    if (this.historySearchState != null && this.historySearchState["query"] != null && this.historySearchState["query"].data != null) {
      if (this.historySearchState["query"].data.ClassTypes[0] == query.ClassTypes[0] && this.historySearchState["url"] == window.location.href) {
        return true;
      }
    }
    return false;
  }

  getHistoryMainSearchFieldValue() {
    return this.historySearchMainSearchFieldValue;
  }

  getHistorySearchAdditionalFields() {
    let addFieldsHistory = [];

    if (this.historySearchStateAdditionalFields != null && typeof this.historySearchStateAdditionalFields != "undefined" && this.historySearchStateAdditionalFields.length > 0) {
      for (var i = 0; i < this.historySearchStateAdditionalFields.length; i++) {
        // add only those with searchvalues (because we need to show them on ui - biding)
        if (this.historySearchStateAdditionalFields[i].SearchValue != null && typeof this.historySearchStateAdditionalFields[i].SearchValue != "undefined" && this.historySearchStateAdditionalFields[i].SearchValue.length > 0) {
          addFieldsHistory.push(
            {
              "Binding": this.historySearchStateAdditionalFields[i].Name,
              "Value": this.historySearchStateAdditionalFields[i].SearchValue
            });
        } else if (this.historySearchStateAdditionalFields[i]["CheckBoxState"] != null && typeof this.historySearchStateAdditionalFields[i]["CheckBoxState"] != "undefined" && this.historySearchStateAdditionalFields[i]["CheckBoxState"] != "novalue") {
          addFieldsHistory.push(
            {
              "Binding": this.historySearchStateAdditionalFields[i].Name,
              "Value": this.historySearchStateAdditionalFields[i]["CheckBoxState"]
            });

        } else if ((typeof this.historySearchStateAdditionalFields[i]["SearchValueFrom"] != "undefined" && this.historySearchStateAdditionalFields[i]["SearchValueFrom"] != "")
          || (typeof this.historySearchStateAdditionalFields[i]["SearchValueTo"] != "undefined" && this.historySearchStateAdditionalFields[i]["SearchValueTo"] != "")) {

          var from = "*";
          var to = "*";

          if (typeof this.historySearchStateAdditionalFields[i]["SearchValueFrom"] != "undefined" && this.historySearchStateAdditionalFields[i]["SearchValueFrom"] != "" && this.historySearchStateAdditionalFields[i]["SearchValueFrom"] != null) {
            from = this.historySearchStateAdditionalFields[i]["SearchValueFrom"];
          }

          if (typeof this.historySearchStateAdditionalFields[i]["SearchValueTo"] != "undefined" && this.historySearchStateAdditionalFields[i]["SearchValueTo"] != "" && this.historySearchStateAdditionalFields[i]["SearchValueTo"] != null) {
            to = this.historySearchStateAdditionalFields[i]["SearchValueTo"];
          }

          addFieldsHistory.push(
            {
              "Binding": this.historySearchStateAdditionalFields[i].Name,
              "ValueFrom": from,
              "ValueTo": to
            });
        }
      }
    }

    return addFieldsHistory;
  }

  performSearch(query: any, customSearchUrl: string, additionalFieldsInfo: any, mainSearchValue: any) {

    let searchUrl = this.defaultSearchUrl;

    if (customSearchUrl != null && typeof customSearchUrl != "undefined" && customSearchUrl.trim() != "") {
      searchUrl = customSearchUrl;
    }

    var reqModel = new RequestModel;

    if (this.historySearchState != null && this.historySearchState["query"] != null && this.historySearchState["query"].data != null) {
      if (this.historySearchState["query"].data.ClassTypes[0] == query.ClassTypes[0] && this.historySearchState["url"] == window.location.href) {
        reqModel = this.historySearchState["query"];
      } else {
        reqModel.url = searchUrl;
        reqModel.data = query;
        this.searchState = reqModel;
        this.historySearchStateAdditionalFields = additionalFieldsInfo;
        this.historySearchMainSearchFieldValue = mainSearchValue;
      }
    } else {
      reqModel.url = searchUrl;
      reqModel.data = query;
      this.searchState = reqModel;
      this.historySearchStateAdditionalFields = additionalFieldsInfo;
      this.historySearchMainSearchFieldValue = mainSearchValue;
    }

    this.search = this.restProvider.getDataPOST<any[]>(reqModel).
      pipe(
        map((data: any[]) => {
          this.historySearchState = null;
          return data;
        }), catchError((error: any) => {
          //Observable.throw('Error while retrieving data from the server - ' + (error.json().errorMessage || 'Server error'))
          throw Observable.create(error);
        }
        ));


    return this.search;

  }

  getAllowedSearchClasses(searchType: string) {

    var reqModel = new RequestModel;
    reqModel.url = "/api/user/allowedsearchclass";
    reqModel.data = {
      "GlobalSearchType": searchType
    }

    this.searchRightInfo = this.restProvider.getDataPOST<SearchRightInfoModel[]>(reqModel).
      pipe(
        map((data: SearchRightInfoModel[]) => {
          this.searchRightInfoResponse = data;
          return data;
        }), catchError(error => {
          return throwError('User service error!', error)
        }));

    return this.searchRightInfo;
  }

  changeSearchValue(value: any) {
    this.newSearchValue.next(value);
  }

  setLatestHistorySearchFacets(currentFacets, classType, url) {
    this.historySearchLatestFacets =
    {
      "Facets": currentFacets,
      "url": url,
      "classType": classType
    };

    return this.historySearchLatestFacets;
  }

  parseQueryBuilder(query, config) {
    if (query !== null && query !== undefined) {

      if (query.condition !== null && query.condition === "and" || query.condition === "or") {

        // if condition present in query then it's ruleset
        const treeQueryWithSubQ = { Type: query.condition.toUpperCase(), SubQueries: [] };

        if (query.rules !== null && query.rules.length > 0) {
          // go through each subquery and parse recursevly
          for (let i = 0; i < query.rules.length; i++) {
            const returnedQ = this.parseQueryBuilder(query.rules[i], config);

            if (returnedQ !== null) {
              // add subquery to current subqueries
              treeQueryWithSubQ.SubQueries.push(returnedQ);
            }

          }
        }

        return treeQueryWithSubQ;
      }
      else if ((query.condition === undefined || query.condition === null) && query.field !== null) {
        // if condition is null and field present then normal fieldvalue,fieldrange query
        const queryField = query.field;
        // this is convention for now
        let queryFieldNameForSearch = queryField.replaceAll("$$", ".");

        // get configuration
        const queryFieldConfiguration = config.fields[queryField];

        if (queryFieldConfiguration !== null && queryFieldConfiguration !== undefined) {
          const queryFieldType = queryFieldConfiguration.type;

          if (queryFieldType !== null && queryFieldType !== undefined) {

            //string field
            if (queryFieldType === "string") {

              let fieldValueQ = query.value;
              if (!fieldValueQ.endsWith("*")) {
                fieldValueQ += "*";
              }

              const fieldValueQuery = { Type: "FieldValue", FieldName: queryFieldNameForSearch, FieldValue: fieldValueQ };

              // XXX: handle operators
              // for now just = and !=
              switch (query.operator.Value) {
                case "!=":
                  fieldValueQuery["Negate"] = true;
                  break;
              }


              return fieldValueQuery;

            }
            // range fields
            else if (queryFieldType === "number" || queryFieldType === "date" || queryFieldType === "time") {

              let isNumberType = false;
              if (queryFieldType === "number") {
                isNumberType = true;
              }

              let rangeValueTo = null;
              let rangeValueFrom = null;

              let fieldRangeQuery = { Type: "FieldRange", FieldName: queryFieldNameForSearch };

              switch (query.operator.Value) {
                case ">":

                  rangeValueTo = "*";

                  if (isNumberType) {
                    rangeValueFrom = query.value + 1;
                  } else {
                    // convert to date + add 1 day
                    rangeValueFrom = "#AddDays('" + query.value + "',1)#";
                  }

                  break;

                case "=>":

                  rangeValueTo = "*";
                  rangeValueFrom = query.value;

                  break;

                case "<":

                  rangeValueFrom = "*";

                  if (isNumberType) {
                    rangeValueTo = query.value - 1;
                  } else {
                    // convert to date and minus 1 day
                    rangeValueTo = "#AddDays('" + query.value + "',-1)#";
                  }

                  break;

                case "<=":

                  rangeValueTo = query.value;
                  rangeValueFrom = "*"

                  break;
                case "=":

                  rangeValueFrom = query.value;
                  rangeValueTo = query.value;

                  break;
                case "!=":

                  rangeValueFrom = query.value;
                  rangeValueTo = query.value;
                  fieldRangeQuery["Negate"] = true;

                  break;
              }

              if (rangeValueFrom !== null && rangeValueFrom !== undefined && rangeValueTo !== null && rangeValueTo !== undefined) {
                fieldRangeQuery["FieldValueFrom"] = rangeValueFrom;
                fieldRangeQuery["FieldValueTo"] = rangeValueTo;

                return fieldRangeQuery;
              } else {
                return null;
              }

            }
            // checkbox
            else if (queryFieldType === "boolean") {
              // for boolean(checkbox) we dont need to handle operators since there is only one operator ("=")
              let boolValue = false;
              if (query.value !== null && query.value !== undefined) {
                boolValue = query.value;
              }

              const fieldValueQuery = { Type: "FieldValue", FieldName: queryFieldNameForSearch, FieldValue: boolValue };
              return fieldValueQuery;
            }
            // dropdown 
            else if (queryFieldType === "category") {

              let dropDownValueId = 0;
              if (query.value !== null && query.value["Id"] !== null) {
                dropDownValueId = query.value["Id"];
              }

              let fieldValueCategoryQuery = { Type: "FieldValue", FieldName: queryFieldNameForSearch + ".Id", FieldValue: dropDownValueId };

              if (query.operator.Value === "!=") {
                fieldValueCategoryQuery["Negate"] = true;
              }

              return fieldValueCategoryQuery;

            }
          }
        }

      }
    }

    return null;
  }

  parseTreeQueryToQueryBuilder(treeQuery, queryBuilderConfig, depth){
    
    if(treeQuery){

      if(treeQuery.SubQueries && treeQuery.SubQueries.length > 0){

        let queryModel: any = {
          "condition": treeQuery.Type.toLocaleLowerCase(),
          "rules": []
        };                

        for(let i = 0; i < treeQuery.SubQueries.length; i++){

          let treeQuerySub = treeQuery.SubQueries[i];
          let parsedQueryB = this.parseTreeQueryToQueryBuilder(treeQuerySub, queryBuilderConfig, depth + 1);

          if(parsedQueryB){
            queryModel.rules.push(parsedQueryB);
          }
        }

        return queryModel;

      }else if(treeQuery.Type && treeQuery.Type.length > 0 && treeQuery.FieldName && treeQuery.FieldName.length > 0){
        
        let queryBuilderFieldName = treeQuery.FieldName.replaceAll(/\./g, "$$$$");
        // get configuration
        const queryFieldConfiguration = queryBuilderConfig.fields[queryBuilderFieldName];        
        let ruleToAdd = {
          "field": queryBuilderFieldName
        };        

        if(treeQuery.Type == "FieldValue"){        
            
          if (typeof treeQuery.FieldValue === 'string' && treeQuery.FieldValue.endsWith('*')) {
            // Remove the last '*' if it exists
            treeQuery.FieldValue = treeQuery.FieldValue.slice(0, -1);
          }     
          ruleToAdd["value"] = treeQuery.FieldValue;     

          if(treeQuery.Negate && treeQuery.Negate === true){
            ruleToAdd["operator"] = queryFieldConfiguration.operators.find(x => x.Value == '!=');
          }else{
            if(treeQuery.FieldValue === true || treeQuery.FieldValue === false){
              // boolean fields have "=" operator
              ruleToAdd["operator"] = queryFieldConfiguration.operators.find(x => x.Value == '=');            
            }else{
              ruleToAdd["operator"] = queryFieldConfiguration.operators.find(x => x.Value.toLocaleLowerCase() == 'contains');            
            }            
          }          

        }else if (treeQuery.Type == "FieldRange"){
          if(treeQuery.FieldValueFrom === "*"){            

            if(!treeQuery.FieldValueTo.includes("AddDays")){
              
              ruleToAdd["operator"] = queryFieldConfiguration.operators.find(x => x.Value == '<=');            
              ruleToAdd["value"] = treeQuery.FieldValueTo;

            }else if(treeQuery.FieldValueTo.includes("AddDays")){
              
              ruleToAdd["operator"] = queryFieldConfiguration.operators.find(x => x.Value == '<');            
              let extractedValue = this.extractDateFromAddDays(treeQuery.FieldValueTo);
              if(extractedValue){
                ruleToAdd["value"] = extractedValue;
              }

            }

            
          }else if(treeQuery.FieldValueTo == "*"){            

            if(!treeQuery.FieldValueFrom.includes("AddDays")){

              ruleToAdd["operator"] = queryFieldConfiguration.operators.find(x => x.Value == '=>'); 
              ruleToAdd["value"] = treeQuery.FieldValueFrom;                         

            }else if(treeQuery.FieldValueFrom.includes("AddDays")){
                            
              ruleToAdd["operator"] = queryFieldConfiguration.operators.find(x => x.Value == '>'); 
              let extractedValue = this.extractDateFromAddDays(treeQuery.FieldValueFrom);
              if(extractedValue){
                ruleToAdd["value"] = extractedValue;
              }

            }

          }else if(treeQuery.FieldValueTo == treeQuery.FieldValueFrom){

            if(treeQuery.Negate === true){
              ruleToAdd["operator"] = queryFieldConfiguration.operators.find(x => x.Value == '!='); 
            }else{
              ruleToAdd["operator"] = queryFieldConfiguration.operators.find(x => x.Value == '='); 
            }
            ruleToAdd["value"] = treeQuery.FieldValueFrom;       
          }
          
        }

        if(depth == 1){

          return {
            "condition": "and",
            "rules": [ruleToAdd]
          }; 

        }else{
          
          return ruleToAdd;        

        }        
      }

    }    
  }


  private extractDateFromAddDays(stringValue: string){
    if(stringValue){
      const firstQuoteIndex = stringValue.indexOf("'");
      const secondQuoteIndex = stringValue.indexOf("'", firstQuoteIndex + 1);
      
      if (firstQuoteIndex !== -1 && secondQuoteIndex !== -1) {
          // Extract the date part using substring
          const datePart = stringValue.substring(firstQuoteIndex + 1, secondQuoteIndex);
          return datePart;
      }
    }

    return null;
  }
}
