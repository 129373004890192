import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationsProviderService } from '../../../../services/translations-provider/translations-provider.service';
import { QueryBuilderConfig } from '../../sem-query-builder/query-builder.interfaces';
import { SemSearchComponent } from '../../sem-search/sem-search.component';


@Component({
  selector: 'sem-query-builder-dialog',
  templateUrl: './sem-query-builder-dialog.component.html',
  styleUrls: ['./sem-query-builder-dialog.component.css']
})
export class SemQueryBuilderDialogComponent implements OnInit, AfterViewInit {


  constructor(
    public dialogRef: MatDialogRef<SemSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public translationsService: TranslationsProviderService) {
  }

  private queryBConfig: QueryBuilderConfig = {
    fields: {}
  }
  private queryBuilderModel: any = null; 

  ngOnInit() {
    
    this.queryBConfig = this.dialogData["queryBuilderConfig"];
    this.queryBuilderModel = this.dialogData["queryBuilderModel"];

    if (this.queryBuilderModel === null
      || this.queryBuilderModel === undefined
      || this.queryBuilderModel.rules === null
      || this.queryBuilderModel.rules === undefined
      || this.queryBuilderModel.rules.length < 1) {

      this.queryBuilderModel = this.getDefaultQueryBuilderModel();
    }
  }

  ngAfterViewInit() {

  }

  translate(key)
  {
    return this.translationsService.translate(key);
  }

  applyQueryBuilder()
  {
    const returnData = {
      "queryBuilderModel": this.queryBuilderModel 
    }

    this.dialogRef.close(returnData);
  }

  resetQueryBuilder()
  {
    this.queryBuilderModel = this.getDefaultQueryBuilderModel();
  }

  getDefaultQueryBuilderModel()
  {

    // galisweb task #5719, when adding default no field is selected

    let obj = {
      condition: 'and',
      rules: []
    };

    obj.rules.push({ "field": undefined, "operator": undefined, "value": undefined });

    //if (this.queryBConfig && this.queryBConfig.fields) {

    //  let propName = null;
    //  for (const key in this.queryBConfig.fields) {
    //    propName = key;
    //    break;
    //  }


    //  // add first field so default wont be empty
    //  const fieldObj = this.queryBConfig.fields[propName];
    //  if (fieldObj) {
    //    obj.rules.push({ "field": propName, "operator": fieldObj.operators[0], "value": undefined });
    //  }
    //}

    return obj;
  }

  closeQueryBuilder(){
    this.dialogRef.close(null);
  }

}
