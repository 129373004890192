import { Component, OnInit, AfterViewInit, Input, AfterContentInit } from '@angular/core';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SemControlComponent } from '../sem-control/sem-control.component';

@Component({
    selector: 'sem-bound-label',
    templateUrl: './sem-bound-label.component.html',
    styleUrls: ['./sem-bound-label.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: SemBoundLabelComponent, multi: true }
    ]
})
export class SemBoundLabelComponent extends SemControlComponent implements OnInit, AfterContentInit {

    @Input() binding: string = null;
    @Input() arrayDisplayBinding: string = null;
    labelValue: any;

    ngAfterContentInit() {

        if (this.binding == "number") {
            this.labelValue = this.model[this.binding] + 1;
        }

        this.restProviderService.modelValueChanged.subscribe(model => {
            if (this.binding != "number") {
                if (model != null && typeof model != "undefined") {
                    if (this.binding != null && typeof this.binding != "undefined" && this.binding.length > 0) {
                        let value = this.dfms.getValue(model, this.binding);

                        if (typeof value != "undefined" && value != null) {
                            if (Array.isArray(value)) {
                                if (value != null && typeof value != "undefined" && this.arrayDisplayBinding != null && typeof this.arrayDisplayBinding != "undefined" && this.arrayDisplayBinding.length > 0) {
                                    this.labelValue = "";
                                    for (let i = 0; i < value.length; i++) {
                                        if ((i + 1) == value.length) {
                                            this.labelValue += value[i][this.arrayDisplayBinding];
                                        } else {
                                            this.labelValue += value[i][this.arrayDisplayBinding] + ", ";
                                        }
                                    }
                                }
                            } else {
                                this.labelValue = value;
                            }
                        }
                    }
                }
            }
        });
    }

    super() { }

    ngOnInit() {
    }

}
