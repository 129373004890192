import { Component, OnInit, Input, AfterViewInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { DataGridColumn } from '../../../models/datagrid/data-grid-column';
import { SemBaseComponent } from '../../common/sem-base/sem-base.component';
import { retry } from '../../../../../node_modules/rxjs/operators';
import { UserSearchSettingModel } from '../../../models/search/user-search-setting-model';
import { RequestModel } from '../../../models/request/request-model';
import { DynamicLoadingUtil } from '../../../shared/helpers/dynamic-loading-util';
import { MetaModelService } from '../../../services/metamodel/metamodel-provider.service';
import { UserSearchSettingPropertyModel } from '../../../models/search/user-search-setting-property-model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FacetModel } from 'src/app/models/search/facet.model';
import { FacetViewModel } from 'src/app/models/search/facet-view.model';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'sem-facets',
  templateUrl: './sem-facets.component.html',
  styleUrls: ['./sem-facets.component.css']
})
export class SemFacetsComponent extends SemControlComponent implements OnInit, AfterViewInit {

  @ViewChild('facetContainer', { read: true, static: true }) facetContainerElement: ElementRef;
  private currentSearchFacets: FacetModel[] = [];
  availibleFacets: FacetViewModel[] = [];
  private availibleFacetsSubs: any;
  private enableFacetsSubs: any;
  public facetsPresent: boolean = true;
  enableFacets: boolean = false;
  collapsedContainer: boolean = false;
  preload: boolean = false;
  showExpandTooltip: boolean = false;

  // listener for search value change
  _facetsLoaded = new BehaviorSubject({});
  facetsLoaded = this._facetsLoaded.asObservable();

  super() {

  }

  ngOnDestroy(): void {
    this.searchService.changeAvailibleFacets(undefined);
    if (typeof this.availibleFacetsSubs != "undefined" && this.availibleFacetsSubs != null) {
      this.availibleFacetsSubs.unsubscribe();
    }

    this.searchService.changeAvailibleFacets(undefined);
    if (typeof this.enableFacetsSubs != "undefined" && this.enableFacetsSubs != null) {
      this.enableFacetsSubs.unsubscribe();
    }
  }

  collapse() {
    this.showExpandTooltip = false;
    this.collapsedContainer = true;
    this.utility.setFacetsCollapsed(true);

    //this.facetContainerElement.nativeElement.parentElement.parentElement.parentElement.classList.add("retract-facets");

    let facetElement = document.getElementsByClassName('SemFacetsControl')[0].parentElement;

    if (!facetElement?.classList.contains("retract-facets")) {
      facetElement?.classList.add("retract-facets")
    }
  }

  showExpandTooltipEvent(){
    this.showExpandTooltip = true;
  }

  hideExpandTooltipEvent(){
    this.showExpandTooltip = false;
  }

  expand() {
    this.showExpandTooltip = false;
    this.collapsedContainer = false;
    this.utility.setFacetsCollapsed(false);

    //this.facetContainerElement.nativeElement.parentElement.parentElement.parentElement.classList.remove("retract-facets");

    let facetElement = document.getElementsByClassName('SemFacetsControl')[0].parentElement;

    if (facetElement?.classList.contains("retract-facets")) {
      facetElement?.classList.remove("retract-facets")
    }

  }

  ngOnInit() {
    this.loading = true;
    super.ngOnInit();


    this.availibleFacetsSubs = this.searchService.currentAvailibleFacets.subscribe(facets => {

      if (facets != null && typeof facets != "undefined") {
        this.availibleFacets = facets;
        
        if (this.availibleFacets == null || typeof this.availibleFacets == "undefined" || this.availibleFacets.length == 0) {
          this.facetsPresent = false;
        } else {          
          this.facetsPresent = true;
        }

        let facetElement = document.getElementsByClassName('SemFacetsControl')[0].parentElement;

        if (this.facetsPresent) {
          if (this.searchService.facetsOrder != null && typeof this.searchService.facetsOrder != "undefined" && this.searchService.facetsOrder.length > 0) {
            for (var i = 0; i < this.searchService.facetsOrder.length; i++) {
              let name = this.searchService.facetsOrder[i];
              let index = this.availibleFacets.findIndex(x => x.facetPropertyPath == name);
              if (index >= 0) {
                this.availibleFacets[index].order = i + 1;
              }
            }

            // reorder based on "order" property
            this.availibleFacets = this.availibleFacets.sort((a, b) => a.order - b.order);
          }

          if (facetElement?.classList.contains("hide-facets")) {
            facetElement?.classList.remove("hide-facets")
          }

        } else {

          if (!facetElement?.classList.contains("hide-facets")) {
            facetElement?.classList.add("hide-facets")
          }
        }
      }

      if (this.preload) {
        this.loading = false;
      }

      this.preload = true;

    }

    );

    this.enableFacetsSubs = this.searchService.currentEnableFacets.subscribe(enableFacets => {

      if (enableFacets != null && typeof enableFacets != "undefined") {
        this.enableFacets = enableFacets;
        this._facetsLoaded.next({});
      }
    });
  }

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
    this.facetsLoaded.subscribe(data => {
      let facetElement = document.getElementsByClassName('SemFacetsControl')[0].parentElement;
      if (!this.enableFacets) {

        if (!facetElement?.classList.contains("hide-facets")) {
          facetElement?.classList.add("hide-facets")
        }
      } else {
        if (facetElement?.classList.contains("hide-facets")) {
          facetElement?.classList.remove("hide-facets")
        }
      }
    });
  }


  facetChanged(facetFieldValue, facetFieldName, facetType, unChecked, start, end) {
    if (unChecked) {
      let index = -1;
      if (facetType == "range") {
        index = this.currentSearchFacets.findIndex(x => x.fieldValueFrom == start && x.fieldValueTo == end && x.fieldName == facetFieldName);
      } else {
        index = this.currentSearchFacets.findIndex(x => x.fieldValue == facetFieldValue && x.fieldName == facetFieldName);
      }
      if (index >= 0) {
        this.currentSearchFacets.splice(index, 1);
      }
    } else {
      if (facetType == "normal") {
        this.currentSearchFacets.push(new FacetModel(facetFieldName, facetFieldValue, facetType));
      } else if (facetType == "taxonomy") {
        let taxonomyFacet: FacetModel = new FacetModel(facetFieldName, facetFieldValue, facetType);
        // populate path
        let path: string[] = [];
        path.push(facetFieldValue);

        taxonomyFacet.fieldPath = path;
        this.currentSearchFacets.push(taxonomyFacet);
      } else if (facetType == "range") {
        let rangeFacet: FacetModel = new FacetModel(facetFieldName, null, facetType);
        // also set from,to value for range facet
        rangeFacet.fieldValueFrom = start;
        rangeFacet.fieldValueTo = end;
        this.currentSearchFacets.push(rangeFacet);
      }
    }

    this.searchService.changeSearchFacets(this.currentSearchFacets);
  }

}
