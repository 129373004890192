import { Directive, OnInit, Input } from '@angular/core';
import { FileUploadService } from '../services/file-upload-service/file-upload.service';

@Directive({
  selector: '[imagesPath]'
})
export class ImagesPathDirective implements OnInit  {

  @Input() imagesPathJson: string;

  constructor(private fileUploadService:FileUploadService ) { 

  }

  ngOnInit() {
    this.fileUploadService.imagesPath = JSON.parse(this.imagesPathJson);
  }

}
