import { RootModel } from '../root-model';

export class StateModel extends RootModel {

  public Model: any;
  public Prop: string;
  public Root: boolean = false;
  public Props: string[] = [];

  super() {
  }

}
