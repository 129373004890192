import { Component, OnInit, Input, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { type } from 'os';
import { Control, Controls } from '../../../../app/models/repeater/controls';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Guid } from 'src/app/shared/helpers/util';
import { RequestModel } from 'src/app/models/request/request-model';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';

@Component({
  selector: 'sem-group-repeater',
  templateUrl: './sem-group-repeater.component.html',
  styleUrls: ['./sem-group-repeater.component.css']
})
export class SemGroupRepeaterControlComponent extends SemControlComponent implements OnInit {

  @Input() controls: any;
  @Input() bindingPropertyName: any;
  @Input() maxCount: number = -1;
  @Input() sectionTransferUrl: any;
  currentNumber: number = 0;  
  controlsString: string ="empty";
  groupByModels: any[] = [];  
  repeaterItemsCount = 0;
  counter:number = 0;  
  shownSections: any[] = [];
  req: Subject<void> = new Subject<void>();

  private serializationObjectFormLinkValue: string = null;

  @Output() removeSectionEvent: EventEmitter<any> = new EventEmitter<any>();

  selectedGroup: number = -1;
  showDD:boolean = false;
  myGroup:FormGroup;
  sectionIds: any[] = [];

  headerTitles = [];
  formControls = [];
  orgModel: any;

  repeaterItemSectionCheckboxes: any[] = [];  
  showCheckboxDiv: boolean = false;
  repeaterItemCheckboxes: any[] = [];
  currentOpenSectionGroup: string = null;
  repeaterItemsWithoutCheckboxes: any[] = [];
  queryWithoutFilter = JSON.parse("{ \"MainQuery\": null, \"FieldQueries\": { }, \"FacetQueries\": { }, \"RequestedFacets\": null, \"Projection\": [], \"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"*\", \"FieldValue\": \"*\" }, \"ClassTypes\": [], \"Page\": \"1\", \"PageSize\": 1500, \"RepositoryName\": \"nhibernate\", \"Form\": null, \"UseCache\": false, \"Facets\": [], \"SortFields\": [] }");

  super() {
  }

  ngAfterContentInit() {     

    this.controlsString = JSON.stringify(this.controls);
    this.restProviderService.modelValueChanged.subscribe(model => {      

      if (this.serializationObjectFormLinkValue === null || this.serializationObjectFormLinkValue === undefined) {
        this.serializationObjectFormLinkValue = this.model["SerializationObjectFormLink"];
      }

      let toAssign = this.dfms.getValue(model, this.bindingPropertyName);

      if (typeof toAssign != "undefined") {
        this.currentNumber = toAssign.length;
        for (var i = 0; i < toAssign.length; i++) {
          toAssign[i]["SerializationObjectFormLink"] = this.serializationObjectFormLinkValue;
        }

        model["SerializationObjectFormLink"] = this.serializationObjectFormLinkValue;

        

        //this.orgModel = Object.assign({}, this.model);
        this.orgModel = Object.assign({}, model);
        this.model = Object.assign({}, model);        
        
        this.getSections(this.model, this.bindingPropertyName, true);
      }
    });  
  } 

  ngOnInit() {    
    this.myGroup = this.form.form;      
    
    if(typeof this.controls != "undefined" && typeof this.controls.Controls != "undefined"){
      for(var i = 0; i < this.controls.Controls.length; i++){
        var ctrlBinding = this.controls.Controls[i].BindingField;      
        // skip control in repeater if that control is configured as checkbox group      
        if(this.controls["CheckBoxModelBindingPropertyName"] == ctrlBinding){        
          continue;
        }   

        this.headerTitles.push(this.controls.Controls[i].HeaderText);
        this.formControls.push(this.controls.Controls[i]);
      }
    }
    
  }        

  getSections(model, bindingPropertyName, addedSection){
    
    if(this.shownSections.length > 0 && addedSection == false){
      return this.shownSections;
    }

    this.sectionIds = [];
    this.shownSections = [];
    this.groupByModels = [];

    let allSections = this.dfms.getValue(model,bindingPropertyName);    

    if(typeof allSections != "undefined" && allSections != null && allSections.length > 0){

      for(var j = 0; j < allSections.length; j++) {

        // if this item group is already added, skip it
        if(this.displayRow(allSections[j]) == false){
          continue;
        }

        // skip if already exists        
        var sectionGroupValue = this.getSectionGroupValue(allSections[j]);
        var indx = this.sectionIds.findIndex(x => x.SectionGroupValue == sectionGroupValue);
        if(indx >= 0){
          continue;
        }
        let sectionGuid = Guid.newGuid().substr(0,6);          
  
        this.sectionIds.push({
          "SectionId": allSections[j]["Id"],
          "SectionGuid": sectionGuid,
          "SectionGroupValue": sectionGroupValue,          
        });      
  
        for(var i = 0; i<this.controls.Controls.length; i++){
          var ctrlBinding = this.controls.Controls[i].BindingField;      
          // skip control in repeater if that control is configured as checkbox group      
          if(this.controls["CheckBoxModelBindingPropertyName"] == ctrlBinding){        
            continue;
          }      
    
          let validators = null;
    
          if(this.controls.Controls[i].IsRequired){
            validators = Validators.required;
          }
          
          if(typeof this.translationsService.labels[ctrlBinding + sectionGuid] == "undefined" && this.controls.Title != null){
            
            let name = "";
            
            if(this.controls.Title.includes(" ")){
              let res = this.controls.Title.split(" ");
    
              for(var j = 0;j < res.length; j++){
                name += this.checkTranslation(res[j]) + " - ";
              }
            }else{
              name = this.checkTranslation(this.controls.Title) + " - ";
            }
    
            this.translationsService.labels[ctrlBinding + sectionGuid] = name + this.checkTranslation(this.controls.Controls[i].HeaderText);
    
          }
    
          this.myGroup.addControl(ctrlBinding + sectionGuid, new FormControl('',validators));
         
        } 

        this.shownSections.push(allSections[j]);
        this.changeDetection.detectChanges();      
      }

      return this.shownSections;
    }
    
    return [];
  }

  getAdditionalProjection(additionalProjectionString){

    var proj = [];
    if(additionalProjectionString != null && typeof additionalProjectionString != "undefined" && additionalProjectionString.length > 0){

      var split = additionalProjectionString.split(",");

      if(split != null && typeof split != "undefined"){
        for(var i = 0; i < split.length; i++){
          proj.push(split[i]);
        }
      }

    }

    return proj;
  }

  ddItemSelected(value: any) {
    //console.log("DD Item Selected in repeater content", value);

    if (value["ObjectType"] == "PenaltyTypeModel") {
      this.selectedGroup = value["Id"];
    }
/*
    let toAssign = this.dfms.getValue(this.model, this.bindingPropertyName);
    for(var i=0;i<toAssign.lenght;i++){
      if(toAssign[i]["Rank"]["Id"] == value["Id"]){
        toAssign.splice(i,1);
        this.dfms.assign(this.model,this.bindingProperty,toAssign,true);
        break;
      }
    }*/
  }

  checkGroup(condition) {
    if (condition == "" || condition == null || condition == "-1" || condition == "true") {
      return true;
    } else {

      try {
        return eval("this.section." + condition);
      } catch (e) {
        return false;
      }

    }
  }

  getSectionGuid(section){
    
    if(typeof section != "undefined"){      
      var sectionGroupValue = this.getSectionGroupValue(section);
      var indx = this.sectionIds.findIndex(x => x.SectionGroupValue == sectionGroupValue);      
      if(indx >= 0){
        return this.sectionIds[indx].SectionGuid;
      }
    }    
      
    return 0;
  }

  getProp(prop,section) {
    let sectionGuid = this.getSectionGuid(section);  
    let value = this.dfms.getValue(section, prop);              
    this.form.controls[prop + sectionGuid].setValue(value);    
    
    return value;
  }  

  setProp(prop, value, section) {    
    let sectionGuid = this.getSectionGuid(section); 
    let sectionGroupValue = this.getSectionGroupValue(section);
    this.dfms.assign(section, prop, value, true);           

    // check if there are other sections in same group
    if(this.model != null && typeof this.model != "undefined" && typeof this.model[this.bindingPropertyName] != "undefined"){
      let allSections = this.dfms.getValue(this.model,this.bindingPropertyName);    
      for(var i = 0; i < allSections.length; i++){        
        let currentSectionGuid = this.getSectionGuid(allSections[i]);        

        //if same section group also same guid        
        if(currentSectionGuid == sectionGuid){
          // also change prop value
          this.dfms.assign(allSections[i], prop, value, true);
        }
      }
    }    

    // check if maybe user change value of grouping input - this means sectionGroupValue has changed
    let newSectionGroupValue = this.getSectionGroupValue(section);   
    if(sectionGroupValue != newSectionGroupValue){
      // change group value with new
      this.currentOpenSectionGroup = newSectionGroupValue;
      var indx = this.sectionIds.findIndex(x => x.SectionGroupValue == sectionGroupValue);      
      if(indx >= 0){
        this.sectionIds[indx].SectionGroupValue = newSectionGroupValue;
      }

      var indx2 = this.repeaterItemSectionCheckboxes.findIndex(x => x.SectionGroup == sectionGroupValue);
      if(indx2 >= 0){
        this.repeaterItemSectionCheckboxes[indx2].SectionGroup = newSectionGroupValue;
      }
    }

    this.form.controls[prop + sectionGuid].setValue(value);
  }

  transferSection(section){    
    this.utility.processNavigationUrl(this.controls.SectionTransferUrl + "?id=" + section["Id"])
  }  

  checkIfNumber(value){

    if(value != null && typeof value != "undefined" && value === true){
      return true;
    }

    return false;
  }

  checkForError(bindingProp){
    return this.form.controls[bindingProp].errors != null;
  }

  displayRow(section) {        
    this.counter++;
    var reset = false;

    var repeaterItemCtn = this.model[this.controls.BindingPropertyName].length;
    if(this.counter > repeaterItemCtn){
     this.counter = 1;
     reset = true; 
    }

    let propGroupStringValue: string = null;
    for(var i = 0; i < this.controls["GroupByModels"].length; i++) {
      // get value based on property name
      let propertyName = this.controls["GroupByModels"][i];      
      let objectVal = this.resolvePropertyBinding(section, propertyName);    
  
      if(objectVal != null && typeof objectVal != "undefined" && objectVal != ""){                        
        if(propGroupStringValue == null){
          propGroupStringValue = propertyName + objectVal["Id"];
        }else{
          propGroupStringValue += "-" + propertyName + objectVal["Id"];
        }        
      }       
    }

    // check if repeater row with same "propGroupStringValue" already exists
    // then dont show the row 
    var found = false;
    if(propGroupStringValue != null){
          
      if(reset == true){
        this.groupByModels = [];
        this.groupByModels.push(propGroupStringValue);
        found = false;
      }else{
        let indx = this.groupByModels.findIndex(x => x == propGroupStringValue);
        if(indx < 0){
          this.groupByModels.push(propGroupStringValue);
        }else{
          found = true;
        }      
      }      
    }    
                        
    
    if(found){
      return false;
    }else{
      return true;
    }   
  }

  openSectionCheckboxes(section){    
    // before another section checkbox div is opened - handle closing of other section checkbox div (if it's open)
    // this method will close current open section checkboxdiv
    // close only if not same section is opened
    var sectionGroupOpen = this.getSectionGroupValue(section);
    if(sectionGroupOpen != this.currentOpenSectionGroup && this.currentOpenSectionGroup != null){
      this.closeCheckboxDiv(true);
    }

    if(this.controls != null && typeof this.controls != "undefined" && this.controls["CheckBoxModel"] != null && this.controls["CheckBoxModel"] != "undefined" && this.controls["CheckBoxModel"].length > 0) {      

      this.currentOpenSectionGroup = sectionGroupOpen;

      // check if data is not already in "repeaterItemSectionCheckboxes"
      var indx = this.repeaterItemSectionCheckboxes.findIndex(x => x.SectionGroup == this.currentOpenSectionGroup);
      if(indx >= 0){
        this.showCheckboxDiv = true;
        this.repeaterItemCheckboxes = this.repeaterItemSectionCheckboxes[indx]["Checkboxitems"];
      } else {
        let requestModel: RequestModel = new RequestModel();
        requestModel.url = "/api/data/list";
        requestModel.contentType = "application/json"
        let searchQuery = this.queryWithoutFilter;
        searchQuery["ClassType"] = this.controls["CheckBoxModel"];
        searchQuery["ClassTypes"] = [this.controls["CheckBoxModel"]];

        if(this.controls["CheckBoxModelProjection"] != null && typeof this.controls["CheckBoxModelProjection"] != "undefined" && this.controls["CheckBoxModelProjection"].length > 0) {
          searchQuery["Projection"] = this.controls["CheckBoxModelProjection"];
        } else {
          // default projection          
          searchQuery["Projection"] = ["Id","Title","Version"]          
        }

        let propertyNameId = null;
        if(this.controls["CheckBoxModelBindingPropertyName"] != null && typeof this.controls["CheckBoxModelBindingPropertyName"] != "undefined" && this.controls["CheckBoxModelBindingPropertyName"].length > 0){
          propertyNameId = this.controls["CheckBoxModelBindingPropertyName"] + "Id";
        }          
        if(propertyNameId != null){
          searchQuery["Projection"].push(propertyNameId);
        }
        

        let addedFilterProp = false;
        // do we have additional filters for checkboxes?
        if (this.controls["CheckBoxSectionAdditionalFilterProperty"] != "undefined" && this.controls["CheckBoxSectionAdditionalFilterProperty"] != null
          && this.controls["CheckBoxSectionAdditionalFilterProperty"].length > 0) {

          // try getting the filter value from the model
          let filterValue = this.dfms.getValue(this.model, this.controls["CheckBoxSectionAdditionalFilterProperty"]); 

          if (filterValue != null && filterValue != "undefined") {
            addedFilterProp = true;
            // add aditional query
            searchQuery["TreeQuery"] = {
              Type: "FieldValue", FieldName: this.controls["CheckBoxSectionAdditionalFilterProperty"], FieldValue: filterValue
            };
          }
        }

      // do we have additional search query
      if (this.controls["CheckBoxSearchAdditionalFilterQuery"] != "undefined" && this.controls["CheckBoxSearchAdditionalFilterQuery"] != null
      && this.controls["CheckBoxSearchAdditionalFilterQuery"].length > 0) {

        var treequery = JSON.parse(this.controls["CheckBoxSearchAdditionalFilterQuery"]);

        if (treequery != null && treequery != "undefined") {
          if(addedFilterProp){
            var existingtreeQuery = Object.assign({}, searchQuery["TreeQuery"]);
            var newTree = {
              "Type":"AND",
              "SubQueries":[]
            };

            newTree.SubQueries.push(existingtreeQuery);
            newTree.SubQueries.push(treequery);
            searchQuery["TreeQuery"] = newTree;

          }else{
            searchQuery["TreeQuery"] = treequery;
          }
        }
      }


        requestModel.data = searchQuery;
        DynamicLoadingUtil.loader.showLoader = true;
        this.restProviderService.getDataPOST<any>(requestModel).pipe(takeUntil(this.req)).subscribe(data => {
          this.zone.run(() => {

            let checkboxItems = [];
            if (data != null) {
              let result = data["Models"];
              var checkedEntities = this.getCheckedEntities(section);

              for (var i = 0; i < result.length; i++) {                
                var checkboxItem = result[i]["Model"];

                var checked = false;
                // by default check by id and title
                // but if CheckBoxModelShownField specifed then by id and CheckBoxModelShownField
                let index = -1;               
                if(this.controls["CheckBoxModelShownField"] != null && this.controls["CheckBoxModelShownField"] != "undefined" && this.controls["CheckBoxModelShownField"].length > 0) {
                  index = checkedEntities.findIndex(x => x.Id == checkboxItem["Id"] && x[this.controls["CheckBoxModelShownField"]] == checkboxItem[this.controls["CheckBoxModelShownField"]]);
                } else {
                  index = checkedEntities.findIndex(x => x.Id == checkboxItem["Id"] && x.Title == checkboxItem["Title"]);
                }
                
                if (index >= 0) {
                  checked = true;
                }

                checkboxItem["Checked"] = checked;                

                /*if(this.controls["CheckBoxModelShownField"] != null && this.controls["CheckBoxModelShownField"] != "undefined" && this.controls["CheckBoxModelShownField"].length > 0) {
                  checkboxItem["Title"] = checkboxItem[this.controls["CheckBoxModelShownField"]];
                }*/

                checkboxItems.push(checkboxItem);
              }

              this.repeaterItemSectionCheckboxes.push(
                {
                  "SectionGroup": this.currentOpenSectionGroup,
                  "Checkboxitems": checkboxItems
                });

              this.showCheckboxDiv = true;
              this.repeaterItemCheckboxes = checkboxItems;
            }

            DynamicLoadingUtil.loader.showLoader = false;
          });
        }, error => {
              //console.log("Error", error);
              //this.loadingResults = false;
              DynamicLoadingUtil.loader.showLoader = false;
            });
      }      
    }
  }   

  getSectionGroupValue(section){
    let currentPropGroupStringValue: string = null;
    for(var i = 0; i < this.controls["GroupByModels"].length; i++) {
        // get value based on property name
      let propertyName = this.controls["GroupByModels"][i];      
      let objectVal = this.resolvePropertyBinding(section, propertyName);    
      if(objectVal != null && typeof objectVal != "undefined" && objectVal != ""){                        
        if(currentPropGroupStringValue == null){
          currentPropGroupStringValue = propertyName + objectVal["Id"];
        }else{
          currentPropGroupStringValue += "-" + propertyName + objectVal["Id"];
        }        
      }       
    }
    return currentPropGroupStringValue;
  }

  getCheckedEntities(section){

    let currentPropGroupStringValue: string = this.getSectionGroupValue(section);
    var repeaterItems = this.model[this.controls.BindingPropertyName];    
    var groupingItems = [];


    for(var i = 0; i < repeaterItems.length; i++){
      // find repeater items with same group (groups are defined in settings)
      var currentRepItem = repeaterItems[i];

      // get "propGroupStringValue" of currentItem

      let currentItemPropGroupStringValue = null
      for(var j = 0; j < this.controls["GroupByModels"].length; j++) {
        // get value based on property name
        let propertyName = this.controls["GroupByModels"][j];      
        let objectVal = this.resolvePropertyBinding(currentRepItem, propertyName);    

        if(objectVal != null && typeof objectVal != "undefined" && objectVal != ""){                        
          if(currentItemPropGroupStringValue == null){
            currentItemPropGroupStringValue = propertyName + objectVal["Id"];
          }else{
            currentItemPropGroupStringValue += "-" + propertyName + objectVal["Id"];
          }        
        }       
      }

      if(currentItemPropGroupStringValue == currentPropGroupStringValue){
        // if same get value of "CheckBoxModelBindingPropertyName"
        var value = this.resolvePropertyBinding(currentRepItem, this.controls["CheckBoxModelBindingPropertyName"]);
        if(value != null && typeof value != "undefined" && value != ""){          
          // get id and title by default
          var obj = {
            "Id":value["Id"],
            "Title":value["Title"]            
          };

          if(this.controls["CheckBoxModelShownField"] != null && this.controls["CheckBoxModelShownField"] != "undefined" && this.controls["CheckBoxModelShownField"].length > 0) {
            obj[this.controls["CheckBoxModelShownField"]] = value[this.controls["CheckBoxModelShownField"]];
          }

          groupingItems.push(obj);
        }        
      }
    }  
    
    return groupingItems;
  }

  addSection() {
    let toAssign = this.dfms.getValue(this.model, this.bindingPropertyName);
    if (typeof toAssign == "undefined" || toAssign == null) {
      toAssign = [];
    }

    if (this.maxCount == -1 || toAssign.length < this.maxCount) {
      let objToAdd = {};
      objToAdd["SerializationObjectFormLink"] = this.serializationObjectFormLinkValue;      

      toAssign.push(objToAdd);

      this.dfms.assign(this.model, this.bindingPropertyName, toAssign);      
      this.currentNumber = toAssign.length;
      this.getSections(this.model, this.bindingPropertyName, true);
    }

    //console.log("Grouped control model: ",this.model);
  } 

  checkboxClicked(item){    
    var boolValue = item["Checked"];
    item["Checked"] = !boolValue;
    this.closeCheckboxDiv(false, item);
  }

  removeSection(section: any) {
    //close checkbox div
    this.close();
    let removingPropGroupStringValue: string = this.getSectionGroupValue(section);
    let values = this.dfms.getValue(this.model, this.bindingPropertyName);
    let newValues = [];

    // those that are not removed add to newValues
    for (var i = 0; i < values.length; i++) {
      let currentPropGroupStringValue: string = this.getSectionGroupValue(values[i]);
      if (currentPropGroupStringValue != removingPropGroupStringValue) {
        newValues.push(values[i]);
      }
    }
    // assign new values to model
    this.dfms.assign(this.model, this.bindingPropertyName, newValues, true);

    this.currentNumber = newValues.length;
    // update UI grouped repeater table
    this.getSections(this.model, this.bindingPropertyName, true);
    //console.log("Grouped control model: ",this.model);
  }

  close(){
    this.showCheckboxDiv = false;
    this.currentOpenSectionGroup = null;
  }

  closeCheckboxDiv(actualyClose: boolean, currentlyClicked: any = null){
    

    // swap old checkbox items with new one for this section group
    var indx = this.repeaterItemSectionCheckboxes.findIndex(x => x.SectionGroup == this.currentOpenSectionGroup);
    if(indx >= 0){

      this.repeaterItemSectionCheckboxes[indx]["Checkboxitems"] = this.repeaterItemCheckboxes;

      // now change checked items with old items on main model
      // get original repeater items
      var originalRepeaterItems = this.model[this.controls.BindingPropertyName].map(x => Object.assign({}, x));    
      // this will be new repeater items that will change original ones
      var newRepeaterItems = [];
      // those that need change of value
      var currentRepeaterItems = [];
      // get section group value - so we know for which group we are replacing
      var currentSectionGroupValue = this.repeaterItemSectionCheckboxes[indx]["SectionGroup"];

      // check if any checkboxes is checked
      var checkedItems = this.repeaterItemCheckboxes.filter(x => x.Checked == true).map(x => Object.assign({}, x));         
      // number of repeater items in the same group that needs value change
      var checkboxesCount = checkedItems.length; 
      
      for(var j = 0; j < originalRepeaterItems.length; j++) {

        // calculate repeateritem "currentPropGroupStringValue"                    
        var repeaterItemSectionGroupValue = this.getSectionGroupValue(originalRepeaterItems[j]);

        if(repeaterItemSectionGroupValue == currentSectionGroupValue){
          currentRepeaterItems.push(originalRepeaterItems[j]);
        }else{
          // if its not same section just add back without changing any values
          newRepeaterItems.push(originalRepeaterItems[j]);
        }        
      }            
        
      if(checkboxesCount > 0 && currentRepeaterItems.length == 0) {
        // find original repeater item that has checkbox property null
        var indx = this.repeaterItemsWithoutCheckboxes.findIndex(x => x.SectionGroup == this.currentOpenSectionGroup);
        if(indx >= 0){
          currentRepeaterItems.push(this.repeaterItemsWithoutCheckboxes[indx]["Item"]);
        }        
      }
      
      // number of repeater items in the same group
      var currRepItemsCount = currentRepeaterItems.length;

      var finalRepeaterItems = [];

      if(checkedItems.length > 0 && (currRepItemsCount > checkboxesCount || currRepItemsCount == checkboxesCount)){
                  
          // skip slicing if same length
          if(currRepItemsCount == checkboxesCount){
            finalRepeaterItems = currentRepeaterItems;                    
          }else{

            // remove those that are not checked
            var tempArr = [];

            for(var i = 0; i < currentRepeaterItems.length; i++){
              var bindingitem = currentRepeaterItems[i][this.controls["CheckBoxModelBindingPropertyName"]];
              if(bindingitem != null && bindingitem.Id > 0){
                for(var j = 0; j < checkedItems.length; j++){
                  if(checkedItems[j]["Id"] == bindingitem.Id){
                    tempArr.push(currentRepeaterItems[i]);
                    break;
                  }
                }
              }
            }

            currentRepeaterItems = tempArr;
            finalRepeaterItems = currentRepeaterItems;
          }                  

          // repcheckboxes and finalrepitems should have the same length
          for(var i = 0; i < finalRepeaterItems.length; i++) {
            
            var bindingitem = finalRepeaterItems[i][this.controls["CheckBoxModelBindingPropertyName"]];

            if(bindingitem == null || typeof bindingitem == "undefined"){
              if(currentlyClicked != null) {
                bindingitem = Object.assign({}, currentlyClicked);             
              }
            }

            if(bindingitem != null && bindingitem.Id > 0){

              var itemch = null;
              for(var j = 0; j < checkedItems.length; j++){
                if(checkedItems[j]["Id"] == bindingitem.Id){
                  itemch = checkedItems[j];
                  break;
                }
              }

              if(itemch != null){
                // change old value with new from repCheckboxes                    
                delete itemch["Checked"];
                this.dfms.assign(finalRepeaterItems[i], this.controls["CheckBoxModelBindingPropertyName"], itemch, true);
                // after changing value add to "newRepeaterItems" array
                newRepeaterItems.push(finalRepeaterItems[i]);                
              }              
            }                     
          }  
        } else if (checkedItems.length > 0 && checkboxesCount > currRepItemsCount){
          // checkboxesCount > currRepItemsCount
         
          finalRepeaterItems = currentRepeaterItems;              

            if(currentlyClicked != null){

              var checkedWithoutNew = [];

              for(var i = 0; i < checkedItems.length; i++){
                if(currentlyClicked["Id"] != checkedItems[i]["Id"]){
                  checkedWithoutNew.push(checkedItems[i]);
                }
              }

              for(var i = 0; i < checkedWithoutNew.length; i++){
                
                for(var j = 0; j < finalRepeaterItems.length; j++){

                  var itemfr = finalRepeaterItems[j][this.controls["CheckBoxModelBindingPropertyName"]];
                  if(itemfr["Id"] == checkedWithoutNew[i]["Id"]) {
                    delete checkedWithoutNew[i]["Checked"];
                    this.dfms.assign(finalRepeaterItems[j], this.controls["CheckBoxModelBindingPropertyName"], checkedWithoutNew[i], true);
                    newRepeaterItems.push(finalRepeaterItems[j]);  
                  }                  
                }                
              }
            }else{
              for(var i = 0; i < currRepItemsCount; i++) {
                // change old value with new from repCheckboxes                    
                delete checkedItems[i]["Checked"];
                this.dfms.assign(finalRepeaterItems[i], this.controls["CheckBoxModelBindingPropertyName"], checkedItems[i], true);
                newRepeaterItems.push(finalRepeaterItems[i]);
              }
            }
             
            // also add newones
          // calculate how many new ones we need
          let start = currRepItemsCount;
          let finish = checkboxesCount; 
          let difference = (finish-start);

          let added = 0;
            // check if deleted and added again
            if(this.orgModel != null && currentlyClicked != null){
                var list = this.orgModel[this.controls["BindingPropertyName"]];
                if(list != null){
                  for(var i = 0; i < list.length; i++){
                    var repeaterItemSectionGroupValue = this.getSectionGroupValue(list[i]);
                    if(repeaterItemSectionGroupValue == currentSectionGroupValue){
                      var bindingitem = list[i][this.controls["CheckBoxModelBindingPropertyName"]];
                      if(bindingitem != null && bindingitem.Id == currentlyClicked.Id){
                        newRepeaterItems.push(list[i]);
                        added++;
                        if(added == difference){
                          break;
                        }
                      }
                    }
                  }                  
                }
            }                    

          if(difference > added){
            finish = difference - added;
            for(var i = 0; i < finish; i++){
              var object = Object.assign({}, finalRepeaterItems[0]);
              // other values are same just change checkbox and remove Id,Version
              // since it's new entry
              object["Id"] = 0;
              object["Version"] = null;
  
              var item = null;
              if(currentlyClicked != null){
                item = currentlyClicked;

                this.dfms.assign(object, this.controls["CheckBoxModelBindingPropertyName"], item, true);
                newRepeaterItems.push(object);
              }                          
            }   
          }  

        } else if(checkedItems.length == 0){
          for(var i = 0; i < currentRepeaterItems.length; i++){
            var indx = this.repeaterItemsWithoutCheckboxes.findIndex(x => x.SectionGroup == this.currentOpenSectionGroup);
            if(indx >= 0){
              this.repeaterItemsWithoutCheckboxes[indx]["Item"] = currentRepeaterItems[i];
            }else{
              this.repeaterItemsWithoutCheckboxes.push({
                "SectionGroup":this.currentOpenSectionGroup,
                "Item": currentRepeaterItems[i]
              });              
            }
          }          
        }

        // for each item in "newRepeaterItems" we must sync "input" values (from textboxex,cbs,.. on form)
        // so all items will have the same value as it is shown on form UI (in case user also edited those fields)
        // get first item (only first is showed on form and user can edit it)



        // replace old repeater items with "newRepeaterItems" on main model
                              
        this.model[this.controls.BindingPropertyName] = newRepeaterItems;  
        /*var ctypes = this.model[this.controls.BindingPropertyName];  
        for(var i = 0; i < ctypes.length; i++)
        {
          var item = ctypes[i];
          if(item != null){
            if(item.Id == 0){
              console.log("id 0");
              console.log(item);
              console.log("-------------------------")
            }
          }
        }*/
      
    }
    if(actualyClose){
      this.showCheckboxDiv = false;
    }    
  }
  
  resolvePropertyBinding(object, propertyPath) {

    if (typeof propertyPath != "undefined" && propertyPath != null) {
      let split = propertyPath.split(".");
      let obj = object;

      for (let i = 0; i < split.length; i++) {
        if (obj != null && typeof obj != "undefined") {
          obj = obj[split[i]];
        }
      }

      if (obj != null && typeof obj != "undefined") {
        return obj;
      }
    }

    return "";
  }



}
