import { Component, OnInit, Input, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { SemBaseComponent } from '../../common/sem-base/sem-base.component';
import { retry, flatMap, switchMap } from '../../../../../node_modules/rxjs/operators';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { RequestModel } from '../../../models/request/request-model';
import { UserSearchSettingModel } from '../../../models/search/user-search-setting-model';
import { DynamicLoadingUtil } from '../../../shared/helpers/dynamic-loading-util';
import { UserSearchSettingPropertyModel } from '../../../models/search/user-search-setting-property-model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OnDestroy } from 'node_modules/@angular/core';
import { PaginationModel } from 'src/app/models/search/pagination.model';
import { FacetModel } from 'src/app/models/search/facet.model';
import { FacetViewModel } from 'src/app/models/search/facet-view.model';
import { FacetGroupModel } from 'src/app/models/search/facet-group.model';
import { SortFieldModel } from 'src/app/models/search/sort-field.model';
import { SemSearchFieldsDialogComponent } from '../dialogs/sem-search-fields-dialog/sem-search-fields-dialog.component';
import { AdditionalSearchSettingsModel } from 'src/app/models/search/additional-search-settings.model';
import { SearchRightInfoModel } from '../../../models/search/search-right-info-model';
import { FormControl } from '@angular/forms';
import { QueryBuilderConfig } from '../sem-query-builder/query-builder.interfaces';
import { isNumber } from 'lodash';
import { SemQueryBuilderDialogComponent } from '../dialogs/sem-query-builder-dialog/sem-query-builder-dialog.component';
import { SemViewSettingsDialogComponent } from '../dialogs/sem-view-settings-dialog/sem-view-settings-dialog.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'sem-search',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sem-search.component.html',
  styleUrls: ['./sem-search.component.css']
})
export class SemSearchComponent extends SemControlComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() bindingPropertyName: any;
  @Input() mainSearchValue: string;
  @Input() repositoryName: string;
  @Input() customApiEndpoint: string;
  @Input() hideButtons: string;
  @Input() customPageSize: number;
  @Input() globalSearch: boolean = false;
  @Input() globalSearchType: string;
  @Input() hideSearchWhere: boolean = false;

  searchClassTypes = new FormControl('');
  private currentEnviromentSettings;
  private searchSubs: any;
  private paginationSubs: any;
  private searchFacetsSubs: any;
  private searchSortSubs: any;
  private searchSettingsSubs: any;
  private pageSizeSubs: any;

  // filtering search results (from user search settings)
  private customViewFilter: any = null;
  public searchProjection: any[] = ["Id", "Title"];
  private allFieldsDefaultQuery: string = "{ \"Type\": \"FieldValue\", \"FieldName\": \"*\", \"FieldValue\": \"*\" }";
  private defaultStringQuery: string = undefined;
  private mainQuery: any;
  private andQuery: any =
    {
      "Type": "AND",
      "SubQueries": []
    };

  public showLoader: boolean = false;
  private defaultSorting: any = [
    {
      "Direction": 1,
      "FieldName": "LastChangeDate",
      "RootClassName": ""
    }
  ];

  public searchClassTypesList: SearchRightInfoModel[] = [];

  // user's search settings
  private userSearchSettings: UserSearchSettingModel;
  // all metamodel search fields
  private metamodelSearchFields: any = [];
  // all metamodel grid fields
  private metamodelGridFields: any = [];

  // view settings display fields properties
  private addedDisplayFields: any[] = [];
  private originalDisplayFieldsList: any[] = [];
  private availibleDisplayFields: any[] = [];
  private originalAddedDisplayFields: any[] = [];

  private addedFields: any[] = [];
  private originalFieldsList: any[] = [];
  private availibleFields: any[] = [];
  private originalAddedFielsList: any[] = [];
  private searchPlaceholderTitle: string = null;
  private enableFacets = false;
  private searchServiceSubscriber = null;
  currentSearchPage = "1";
  private currentFacets: FacetModel[] = [];
  // all facets (normal,taxonomy,range) retrivied from metamodel - only facet names  
  private metamodelFacets: FacetViewModel[] = null;
  private currentSortFields: SortFieldModel[] = [];
  private additionalSearchSettings: AdditionalSearchSettingsModel =
    {
      quickViewEnabled: false,
      onlyLastNodeNames: false,
      showItemNumber: false,
      showActionsColumn: true,
      LayoutName: "",
      quickViewFormName: ""
    };

  private stateModel = null;
  gridFieldsRestoreSubs = null;
  private initialGlobalSearch: boolean = true;

  private showQueryBuilder: boolean = false;

  private queryBuilderModel: any = {
    condition: 'and',
    rules: []
  };

  private queryBConfig: QueryBuilderConfig = {
    fields: {}
  }
  private queryBuilderConfigPopulated = false;
  private queryBuilderSearching = false;

  private isSplitSearchInitial = false;
  private splitScreenQuery = null;



  super() {

  }

  ngOnDestroy(): void {
    
    this.queryBConfig = null;
    this.queryBuilderModel = null;
    this.searchService.changeSearchProjectionAndFacets(undefined);
    this.searchService.changePaginationInfo(undefined);
    this.searchService.changeSearchFacets(undefined);
    this.searchService.changeMetaModelEntities(undefined);
    this.searchService.changeSortFields(undefined);
    this.searchService.changeSearchPage(undefined);
    this.searchService.setEnableFacets(undefined);
    this.searchService.setAdditionalSearchSettings(undefined);

    if (typeof this.searchSubs != "undefined" && this.searchSubs != null) {
      this.searchSubs.unsubscribe();
    }

    if (typeof this.paginationSubs != "undefined" && this.paginationSubs != null) {
      this.paginationSubs.unsubscribe();
    }

    if (typeof this.searchFacetsSubs != "undefined" && this.searchFacetsSubs != null) {
      this.searchFacetsSubs.unsubscribe();
    }

    if (typeof this.searchSortSubs != "undefined" && this.searchSortSubs != null) {
      this.searchSortSubs.unsubscribe();
    }

    if (typeof this.searchSettingsSubs != "undefined" && this.searchSettingsSubs != null) {
      this.searchSettingsSubs.unsubscribe();
    }

    if (typeof this.pageSizeSubs != "undefined" && this.pageSizeSubs != null) {
      this.pageSizeSubs.unsubscribe();
    }

    this.searchService.setSearchFieldsRestoredDefault(undefined);
    if (typeof this.gridFieldsRestoreSubs != "undefined" && this.gridFieldsRestoreSubs != null) {
      this.gridFieldsRestoreSubs.unsubscribe();
    }

    this.metamodelFacets = null;

  }


  ngOnInit() {
    super.ngOnInit();

    this.currentEnviromentSettings = environment;
    if (this.customPageSize != null && typeof this.customPageSize != "undefined" && this.customPageSize > 0) {
      this.searchService.pageSize = this.customPageSize;
    }

    if (typeof this.model == "undefined" || !this.model) {
      this.model = {};
    }

    this.model["AdditionalSearchFields"] = [];

    this.searchService.globalSearch = this.globalSearch;
    this.searchService.globalSearchType = this.globalSearchType;

    if (this.globalSearch == true) {
      this.defaultStringQuery = "{\"RequestedFacets\": null,\"Projection\": [],\"TreeQuery\": {},\"ClassTypes\": [],\"Page\": \"1\",\"GlobalQueryType\": \"" + this.globalSearchType + "\",\"PageSize\": " + this.searchService.pageSize + ",\"RepositoryName\": \"lucene\",\"UseCache\": false}";

      this.searchService.getAllowedSearchClasses(this.globalSearchType).subscribe(data => {

        if (data != null && typeof data != "undefined" && data.length > 0) {
          this.searchClassTypesList.push(...data);
        }
      }, error => {

      });

    } else {
      this.defaultStringQuery = "{\"MainQuery\":null,\"FieldQueries\":{},\"FacetQueries\": {},\"RequestedFacets\": null,\"Projection\": [],\"TreeQuery\": {},\"ClassTypes\": [],\"Page\": \"1\",\"PageSize\": " + this.searchService.pageSize + ",\"RepositoryName\": \"" + this.repositoryName + "\",\"Form\": null,\"UseCache\": false}";
    }

    this.activatedRoute.paramMap.subscribe(data => {
      this.stateModel = window.history.state;

      this.stateModel = this.utility.checkStateForValidity(window.location.href, this.stateModel);

      if (this.stateModel && this.stateModel["type"] && this.stateModel["type"] == "split-search") {
        this.splitScreenQuery = this.stateModel["query"];
        this.isSplitSearchInitial = true;
        this.angLocation.replaceState(document.location.pathname+document.location.search,'',null);
        //this.performSearch(false, false, this.mainQuery);
      }
    });
  }

  ngAfterViewInit(): void {

    if (this.globalSearch == true) {

      let viewId = this.params["view"];

      if (typeof viewId == "undefined") {
        viewId = 1;
      }

      if (typeof this.params["search"] != "undefined" && this.params["search"]) {
        this.mainSearchValue = this.params["search"];
      }


      this.handleUserAdditionalSearchFields("", viewId);

    } else {

      if (this.params != null && typeof this.params != "undefined") {
        if (this.params["model"] != null && typeof this.params["model"] != "undefined") {

          let viewId = this.params["view"];

          if (typeof viewId == "undefined") {
            viewId = 1;
          }

          // get current user search field settings (if they exist)
          this.handleUserAdditionalSearchFields(this.params["model"], viewId);
        }
      }
    }


    this.gridFieldsRestoreSubs = this.searchService.currentgridRestoredDefault.subscribe(value => {

      if (value != null && typeof value != "undefined" && value == true) {

        if (this.globalSearch == true) {

        } else {
          if (this.params != null && typeof this.params != "undefined") {
            if (this.params["model"] != null && typeof this.params["model"] != "undefined") {

              let viewId = this.params["view"];

              if (typeof viewId == "undefined") {
                viewId = 1;
              }

              // get current user search field settings (if they exist)
              this.handleUserAdditionalSearchFields(this.params["model"], viewId, true);
            }
          }
        }
      }
    });

    // subsrice for any changes on projection and facets from data-grid component
    // facets are setteng only on first subscribe
    this.searchSubs = this.searchService.currentSearchProjectionAndFacets.subscribe(projectionFacets => {

      if (typeof projectionFacets != "undefined") {

        if (projectionFacets != null && projectionFacets.projection != null && projectionFacets.projection.length > 0) {
          this.searchProjection = projectionFacets.projection;
          this.metamodelFacets = projectionFacets.facets;

          // only once (so when metamodelFacets is null)
          //if(this.metamodelFacets == null && projectionFacets.facets != null && typeof projectionFacets.facets != "undefined"){
          //this.metamodelFacets = projectionFacets.facets;
          //}

          // after each new search set page to default
          this.currentSearchPage = "1";
          if (this.params != null && typeof this.params != "undefined" && this.params["model"] != null && typeof this.params["model"] != "undefined" && this.params["model"] == "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
            this.performTaskSearch();
          } else if (this.isSplitSearchInitial && this.splitScreenQuery) {
            this.performSearch(false, false,this.splitScreenQuery);
          } else {
            this.performSearch();
          }
        }
      } else {
        //this.isSplitSearchInitial = false;
      }
    });

    // listener after each search key is inserted on any of search fields
    this.searchService.currentSearchValue.subscribe(value => {

      if (typeof value != "undefined" && !this.isSplitSearchInitial) {
        this.currentSearchPage = "1";
        if (this.params != null && typeof this.params != "undefined" && this.params["model"] != null && typeof this.params["model"] != "undefined" && this.params["model"] == "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
          this.performTaskSearch();
        } else {
          this.performSearch();
        }
      }

    });

    this.searchSettingsSubs = this.searchService.currentUserSearchSettings.subscribe(value => {

      // only if user settings were changed from grid control
      if (typeof value != "undefined" && value != null && value["Control"] == "grid") {
        this.setNewUserSearchSettingObject(this.params["model"], this.params["view"]);
      }
    });

    // listener after each search key is inserted on any of search fields
    this.paginationSubs = this.searchService.currentSearchPage.subscribe(value => {

      if (typeof value != "undefined") {
        let page = "1";

        if (value != null && value != "0" && value != "") {
          page = value;
        }

        this.currentSearchPage = page;
        if (this.params != null && typeof this.params != "undefined" && this.params["model"] != null && typeof this.params["model"] != "undefined" && this.params["model"] == "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
          this.performTaskSearch();
        } else {
          this.performSearch();
        }
      } else {
        this.currentSearchPage = "1";
      }
    });

    // listener for facets fields
    this.searchFacetsSubs = this.searchService.currentSearchFacets.subscribe(value => {

      // clear previous
      this.currentFacets = [];
      if (value != null && typeof value != "undefined") {
        for (let i = 0; i < value.length; i++) {
          this.currentFacets.push(value[i])
        }

        this.currentSearchPage = "1";
        if (this.params != null && typeof this.params != "undefined" && this.params["model"] != null && typeof this.params["model"] != "undefined" && this.params["model"] == "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
          this.performTaskSearch();
        } else {
          this.performSearch();
        }
      }
    });

    // subscription for sort fields
    this.searchSortSubs = this.searchService.currentSortFields.subscribe(value => {

      // clear previous
      this.currentSortFields = [];
      if (value != null && typeof value != "undefined" && !this.isSplitSearchInitial) {
        for (let i = 0; i < value.length; i++) {
          this.currentSortFields.push(value[i])
        }

        this.currentSearchPage = "1";
        if (this.params != null && typeof this.params != "undefined" && this.params["model"] != null && typeof this.params["model"] != "undefined" && this.params["model"] == "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
          this.performTaskSearch();
        } else {
          this.performSearch();
        }
      }
    });

    // listener for page number change
    this.pageSizeSubs = this.searchService.currentPageSize.subscribe(value => {
      if (value != null && typeof value != "undefined") {
        this.currentSearchPage = "1";

        if (this.params != null && typeof this.params != "undefined" && this.params["model"] != null && typeof this.params["model"] != "undefined" && this.params["model"] == "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
          this.performTaskSearch();
        } else {
          this.performSearch();
        }
      }
    });

  }

  searchInChange(event) {
    if (event.isUserInput) {
      var value = event.source.value;
      var selected = event.source.selected;

      var index = this.searchClassTypesList.findIndex(x => x.FullName == value);
      if (index >= 0) {
        this.searchClassTypesList[index]["Selected"] = selected;
      }
    }
  }

  openViewSettings(createNewView: boolean){
    

    // SEARCH FIELDS CONFIGURATION
    this.originalAddedFielsList = this.addedFields.map(x => Object.assign({}, x));
    // set to default
    this.availibleFields = [];
    this.originalFieldsList = [];
    // go through all fields
    for (let i = 0; i < this.metamodelSearchFields.length; i++) {
      // check if exists in addedFields
      let index = this.addedFields.findIndex(x => x.Name == this.metamodelSearchFields[i].Name);

      // if createNewView then always add
      if (index < 0 || createNewView == true) {
        // if doesnt exists add to availible fields        
        this.availibleFields.push(this.metamodelSearchFields[i]);
        this.originalFieldsList.push(this.metamodelSearchFields[i]);
      }
    }        

    // open dialog and send needed data to dialog    
    let searchFieldsDialogDataObject = {        
      availibleFields: this.availibleFields,      
      originalFieldsList: this.originalFieldsList,
      addedFields: createNewView === true ? [] : this.addedFields,
      originalAddedFieldsList: createNewView === true ? [] : this.originalAddedFielsList,      
    };
    // END OF SEARCH FIELDS CONFIGURATION


    // DISPLAY FIELDS CONFIGURATION
    this.originalAddedDisplayFields = this.addedDisplayFields.map(x => Object.assign({}, x));

    // set to default
    this.availibleDisplayFields = [];
    this.originalDisplayFieldsList = [];
    // go through all fields
    for (let i = 0; i < this.metamodelGridFields.length; i++) {
      // ignore collection properties in grid (they cannot be shown in grid component)
      // also add only properties that are stored (can retrieve from index)
      // SAŠO ADDED - 20.8. - ignore fields that have no form controls attached (mostly IDs)
      if (!this.metamodelGridFields[i].IsCollectionProperty && this.metamodelGridFields[i].IsRetrievable
        && (this.metamodelGridFields[i].FormControlName != 'undefined' && this.metamodelGridFields[i].FormControlName != null
          && this.metamodelGridFields[i].FormControlName != '')) {
        // check if exists in addedFields
        let index = this.addedDisplayFields.findIndex(x => x.Name == this.metamodelGridFields[i].Name);

        if (index < 0 || createNewView == true) {
          // if doesnt exists add to availible fields        
          this.availibleDisplayFields.push(this.metamodelGridFields[i]);
          this.originalDisplayFieldsList.push(this.metamodelGridFields[i]);
        }
      }
    }

    let displayFieldsDialogDataObject = {        
      availibleFields: this.availibleDisplayFields,      
      originalFieldsList: this.originalDisplayFieldsList,
      addedFields: createNewView === true ? [] : this.addedDisplayFields,
      originalAddedFieldsList: createNewView === true ? [] : this.originalAddedDisplayFields,     
    };
    // END OF DISPLAY FIELDS CONFIGURATION


    let queryBuilderConfig: QueryBuilderConfig = {
      fields: {}
    };    

    for (let i = 0; i < this.metamodelSearchFields.length; i++) {
      const fieldObjectType = this.metamodelSearchFields[i].QueryBuilderType;

      if (fieldObjectType !== null) {
        const fieldObject = { name: this.metamodelSearchFields[i].Translation, type: fieldObjectType, galregname: this.metamodelSearchFields[i].FullType, operators: this.metamodelSearchFields[i].QueryBuilderOperators };
        const fieldObjectPropertyName = this.metamodelSearchFields[i].QueryBuilderPropertyName;

        queryBuilderConfig.fields[fieldObjectPropertyName] = fieldObject;
      }
    }

    let queryBuilderModel: any = {
      condition: 'and',
      rules: []
    };

    if(createNewView === false){
      // check if filterquery is present
      if(this.userSearchSettings && this.userSearchSettings.FilterQuery && this.userSearchSettings.FilterQuery.length > 0){
        try{
          let treeQueryObject = JSON.parse(this.userSearchSettings.FilterQuery);
          // parse filterquery (WG TreeQuery) back to query builder model
          queryBuilderModel = this.searchService.parseTreeQueryToQueryBuilder(treeQueryObject, queryBuilderConfig, 1);

        }catch{

        }        
      }
    }

    const dialogRef = this.dialog.open(SemViewSettingsDialogComponent, {
      data: {
        // basic dialog data
        createNewView: createNewView,

        // search fields data
        availibleSearchFields: searchFieldsDialogDataObject["availibleFields"],
        addedSearchFields: searchFieldsDialogDataObject["addedFields"],
        originalSearchFieldsList: searchFieldsDialogDataObject["originalFieldsList"],
        originalAddedSearchFieldsList: searchFieldsDialogDataObject["originalAddedFieldsList"],
        // grid fields data
        availibleDisplayFields: displayFieldsDialogDataObject["availibleFields"],
        addedDisplayFields: displayFieldsDialogDataObject["addedFields"],
        originalDisplayFieldsList: displayFieldsDialogDataObject["originalFieldsList"],
        originalAddedDisplayFieldsList: displayFieldsDialogDataObject["originalAddedFieldsList"],	

        // filter tab data
        queryBuilderModel: queryBuilderModel,
        queryBuilderConfig: queryBuilderConfig,

        // basic view data
        currentSettingId: this.userSearchSettings.Id,
        viewLabel: createNewView === true ? "" : this.userSearchSettings.SearchViewName,
        searchPlaceholderTitle: createNewView === true ? "" :this.userSearchSettings["SearchPlaceholderTitle"]

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {        
        if(result.cancel === true){
          this.addedFields = result["addedSearchFields"];
          this.addedDisplayFields = result["addedDisplayFields"];
          
        }else if(result.update === true){
          if(environment.cacheEnabled){

          }
          location.reload();
        }
      }
    });
  }

  openQueryBuilderDialog() {
    this.showQueryBuilder = true;

    if (this.showQueryBuilder === true) {
      // we must build query builder config
      // we will build it based on metamodel of current search list

      if (this.metamodelSearchFields === null || this.metamodelSearchFields.length < 1) {
        // call method to populate metamodelSearchFields
      }

      // execute this code once per listing, config is populated once per listing
      if (this.queryBuilderConfigPopulated === false) {
        for (let i = 0; i < this.metamodelSearchFields.length; i++) {
          const fieldObjectType = this.metamodelSearchFields[i].QueryBuilderType;

          if (fieldObjectType !== null) {
            const fieldObject = { name: this.metamodelSearchFields[i].Translation, type: fieldObjectType, galregname: this.metamodelSearchFields[i].FullType, operators: this.metamodelSearchFields[i].QueryBuilderOperators };
            const fieldObjectPropertyName = this.metamodelSearchFields[i].QueryBuilderPropertyName;

            this.queryBConfig.fields[fieldObjectPropertyName] = fieldObject;
          }
        }
        this.queryBuilderConfigPopulated = true;
      }

      // after populating field, open dialog
      // open dialog and send needed data to dialog
      const dialogRef = this.dialog.open(SemQueryBuilderDialogComponent, {
        data: {
          queryBuilderConfig: this.queryBConfig,
          queryBuilderModel: this.queryBuilderModel
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined && result !== null) {
          this.queryBuilderModel = result["queryBuilderModel"];
          this.searchBasedOnQueryBuilder();
        }
      });

    }
  }

  showDataTools() {
    if (this.globalSearch === true) {
      return false;
    }

    return true;
  }

  showSearchWhere() {

    if (this.hideSearchWhere === true) {
      return false;
    }

    return true;
  }

  searchValueOnChange(value: any) {
    this.searchService.changeSearchValue(value);
  }

  searchOptionNotSelected() {

    var selectedList = this.searchClassTypesList.filter(x => x.Selected == true);

    if (selectedList != null && typeof selectedList != "undefined" && selectedList.length > 0) {
      return false;
    }

    return true;
  }

  private handleMetaModelFacets() {

    let requestFacets = [];

    if (this.metamodelFacets != null && typeof this.metamodelFacets != "undefined" && this.metamodelFacets.length > 0) {
      for (let metamodelFacet of this.metamodelFacets) {

        let customFacetSpecified = this.customFacetSpecified(metamodelFacet.facetPropertyPath);

        if (customFacetSpecified) {
          requestFacets.push(
            {
              "MaxFacets": 5,
              "FieldName": metamodelFacet.facetPropertyPath,
              "RootClassName": this.params["model"]
            });
        }
      }
    }

    return requestFacets;
  }

  private handleSortFields() {

    let requestedSortFields = [];

    if (this.currentSortFields != null && typeof this.currentSortFields != "undefined" && this.currentSortFields.length > 0) {
      for (let sortField of this.currentSortFields) {

        let rootclassname = sortField.RootClassName;

        if (rootclassname == null || typeof rootclassname == "undefined" || rootclassname == "") {
          rootclassname = this.params["model"];
        }

        requestedSortFields.push(
          {
            "FieldName": sortField.FieldName,
            "Direction": sortField.SortDirection,
            "RootClassName": rootclassname
          });
      }
    }

    return requestedSortFields;
  }

  private prepareQueryObject(modelName: string) {
    this.mainQuery = JSON.parse(this.defaultStringQuery);
    if (this.globalSearch == true) {

      var searchOptionNotSelected = this.searchOptionNotSelected();

      if (this.globalSearch && this.initialGlobalSearch) {
        if (typeof (this.searchClassTypesList) != "undefined" && this.searchClassTypesList) {
          for (var i = 0; i < this.searchClassTypesList.length; i++) {
            this.searchClassTypesList[i]["Selected"] = true;
          }
        }

        // need to check why this doesnt build 
        //this.searchClassTypes.setValue(this.searchClassTypesList.map(x => x.FullName));

        searchOptionNotSelected = false;
        this.initialGlobalSearch = false;
      }

      if (searchOptionNotSelected) {
        this.mainQuery["ClassTypes"] = ["*"];
      } else {
        this.mainQuery["ClassTypes"] = this.searchClassTypesList.filter(x => x.Selected == true).map(x => x.FullName);
      }

    } else {
      this.mainQuery["ClassTypes"].push(modelName);
    }
  }

  public translate(key: string) {
    return this.translationsService.translate(key);
  }  

  private getMainSearchQuery(taskSearch = false, queryBuilderSearch = false): any {


    if (this.globalSearch === true || (this.params !== null && typeof this.params !== "undefined")) {
      if (this.globalSearch === true || (this.params["model"] !== null && typeof this.params["model"] !== "undefined")) {

        let mName = "";

        if (this.params !== null && typeof this.params !== "undefined") {
          mName = this.params["model"];
        }

        // add classtype value to mainquery
        this.prepareQueryObject(mName);
        let allFieldsQuery = JSON.parse(this.allFieldsDefaultQuery);
        let finalQuery = {};

        let defaultQueryAll = false;

        // always clear
        this.andQuery.SubQueries = [];

        if (queryBuilderSearch === false) {
          // handle additional search fields and add them to andQuery
          this.handleAdditionalSearchFields();
        } else if (queryBuilderSearch === true) {
          // parse querybuilder into semantika search object          
          const parsedQueryFromQueryBuilder = this.searchService.parseQueryBuilder(this.queryBuilderModel, this.queryBConfig);
          if (parsedQueryFromQueryBuilder !== null && parsedQueryFromQueryBuilder !== undefined) {
            this.andQuery.SubQueries.push(parsedQueryFromQueryBuilder);
          }
        }


        // handle search facet and add them to andQuery     
        this.handleSearchFacets();

        // check if searching for all fields (this is only when not in querybuilder)
        if (queryBuilderSearch === false && typeof this.mainSearchValue != "undefined" && (this.mainSearchValue != "" || this.mainSearchValue.trim().length > 0)) {

          // we add * on the end of each search value if it doesn't exists already
          let mainSearchValueWildcard = this.mainSearchValue;
          if (!mainSearchValueWildcard.endsWith("*")) {
            mainSearchValueWildcard += "*";
          }

          allFieldsQuery["FieldValue"] = mainSearchValueWildcard;

          // if also searcihng for additional fields then add to AND query
          if (this.andQuery.SubQueries.length > 0) {
            this.andQuery.SubQueries.push(allFieldsQuery);
            finalQuery = this.andQuery;
          } else {
            // treequery is acuatly fieldvalue query for all fields
            finalQuery = allFieldsQuery;
          }
        }
        else {
          // only if andquery is not empty
          if (this.andQuery != null && this.andQuery.SubQueries.length > 0) {
            finalQuery = this.andQuery;
          } else {
            if (this.params != null && typeof this.params != "undefined" && this.params["search"] != null && typeof this.params["search"] != "undefined") {
              allFieldsQuery["FieldValue"] = this.params["search"];
            } else {
              allFieldsQuery["FieldValue"] = "*";
            }

            finalQuery = allFieldsQuery;
            defaultQueryAll = true;
          }
        }

        if (taskSearch) {

          var userId = this.restProviderService.currentUser["Id"];
          var userGroups = this.restProviderService.currentUser["UserGroups"];
          var userGroupQuery = null;

          if (userGroups != null && typeof userGroups != "undefined") {
            userGroupQuery = {
              "Type": "OR",
              "SubQueries": []
            };

            for (var i = 0; i < userGroups.length; i++) {
              userGroupQuery["SubQueries"].push({ "Type": "FieldValue", "FieldName": "GroupID", "FieldValue": userGroups[i] });
            }
          }

          var secQueryTask = {
            "Type": "OR",
            "SubQueries": [{ "Type": "FieldValue", "FieldName": "UserID", "FieldValue": userId },
              userGroupQuery]
          };

          if (finalQuery != null && typeof finalQuery != "undefined") {
            var finalTaskQ = { "Type": "AND", "SubQueries": [] };
            finalTaskQ.SubQueries.push(finalQuery);
            finalTaskQ.SubQueries.push(secQueryTask);
            this.mainQuery["TreeQuery"] = finalTaskQ;
          } else {
            this.mainQuery["TreeQuery"] = secQueryTask;
          }
        }
        // check if custom filter exists
        else if (this.customViewFilter != null && typeof this.customViewFilter != "undefined") {

          if (typeof this.stateModel != "undefined" && this.stateModel) {
            if (this.customViewFilter["Type"] == "FieldValue" && (typeof this.customViewFilter["SubQueries"] == "undefined" || this.customViewFilter["SubQueries"] == null || this.customViewFilter["SubQueries"].length == 0)) {
              this.customViewFilter = this.manipulateCustomQuery(this.customViewFilter);
            } else if ((typeof this.customViewFilter["SubQueries"] != "undefined" && this.customViewFilter["SubQueries"] != null && this.customViewFilter["SubQueries"].length > 0)) {
              //Removed this line, type is already determened, and it can also be AND... this.customViewFilter["Type"] = "OR";
              for (var i = 0; i < this.customViewFilter["SubQueries"].length; i++) {
                this.customViewFilter["SubQueries"][i] = this.manipulateCustomQuery(this.customViewFilter["SubQueries"][i]);
              }
            }
          }

          // combine final query with customViewFilter 
          let combinedQuery = {
            "Type": "AND",
            "SubQueries": []
          };

          combinedQuery.SubQueries.push(finalQuery);
          combinedQuery.SubQueries.push(this.customViewFilter);
          this.mainQuery["TreeQuery"] = combinedQuery;
        }
        else {
          this.mainQuery["TreeQuery"] = finalQuery;
        }

        this.mainQuery["Page"] = this.currentSearchPage;
        return this.mainQuery;
      }
    }



    return null;
  }

  manipulateCustomQuery(customViewFilter: any) {
    try {
      if (customViewFilter["FieldValue"].includes("{{")) {
        var evalString = customViewFilter["FieldValue"].substring(
          customViewFilter["FieldValue"].lastIndexOf("{{") + 2,
          customViewFilter["FieldValue"].lastIndexOf("}}")
        );

        try {
          var res = eval(evalString);

          if (typeof res != "undefined" && res) {
            customViewFilter["FieldValue"] = res;
            //console.log("Parsed query: ", customViewFilter);
          }
          else {
            customViewFilter["FieldValue"] = "*";
          }
        } catch (e) {
          customViewFilter["FieldValue"] = "*";
          //console.log("Error parsing filter query: ", e);
        }
      }
      return customViewFilter;
    } catch (e) {
      //probablja parsing numbers in FieldValue
      return customViewFilter;
    }
  }

  onKeydown(event) {
    this.queryBuilderSearching = false;
    event.preventDefault();
    this.currentSearchPage = "1";

    if (this.params != null && typeof this.params != "undefined" && this.params["model"] != null && typeof this.params["model"] != "undefined" && this.params["model"] == "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
      this.performTaskSearch();
    } else {
      this.performSearch();
    }
  }

  searchBasedOnQueryBuilder() {
    this.queryBuilderSearching = true;
    this.currentSearchPage = "1";

    // also reset additional field values
    this.clearSearchFieldValues();
    // also reset main search value
    this.mainSearchValue = "";

    if (this.params !== null && typeof this.params !== "undefined" && this.params["model"] !== null && typeof this.params["model"] !== "undefined" && this.params["model"] === "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
      this.performTaskSearch(true);
    } else {
      this.performSearch(false, true);
    }
  }

  public performTaskSearch(queryBuilderSearch = false) {
    this.searchProjection = ["Id", "Title", "Description", "DueDate", "DateCreated", "FirstOpenDate", "FormName", "EntityID", "Complete", "Result", "TaskFiles", "TaskOutcomes", "TaskFiles.FileName", "CanOpenIfComplete", "ShowMessageOnly"];
    this.performSearch(true, queryBuilderSearch);
  }

  public performSearch(taskSearch = false, queryBuilderSearch = false, query = null) {

    if (this.queryBuilderSearching === true) {
      queryBuilderSearch = true;
    }

    // show loader
    this.showLoader = true;

    // calculate mainQuery
    if (!query) {
      this.mainQuery = this.getMainSearchQuery(taskSearch, queryBuilderSearch);
    } else {
      const currentNav = this.router.getCurrentNavigation();
      if (currentNav) {
        currentNav.extras.state = {}; // Resetting the state
      }
      this.mainQuery = query;
    }

    // update projection for condition evaluations
    if (typeof this.userSearchSettings != "undefined" && this.userSearchSettings) {
      if (typeof this.userSearchSettings.ConditionEvaluations != "undefined" && this.userSearchSettings.ConditionEvaluations) {
        for (let i = 0; i < this.userSearchSettings.ConditionEvaluations.length; i++) {

          if (this.userSearchSettings.ConditionEvaluations[i]["BindingPath"].includes(";")) {
            const splited = this.userSearchSettings.ConditionEvaluations[i]["BindingPath"].split(";");
            for (let j = 0; j < splited.length; j++) {
              this.searchProjection.push(splited[j]);
            }
          } else {
            this.searchProjection.push(this.userSearchSettings.ConditionEvaluations[i]["BindingPath"]);
          }
        }
      }
    }

    // set projection
    this.mainQuery["Projection"] = this.searchProjection;

    // for now skip facets in global search
    if (this.globalSearch == false) {
      // set facets
      this.mainQuery["Facets"] = this.handleMetaModelFacets();
    }


    let sortFields = this.handleSortFields();

    if (taskSearch) {
      if (sortFields != null && typeof sortFields != "undefined" && sortFields.length > 0) {
        this.mainQuery["SortFields"] = sortFields;
      } else {
        // hardcode default sort fields
        this.mainQuery["SortFields"] = [{
          "Direction": 1,
          "FieldName": "DateCreated",
          "RootClassName": "Semantika.WebGalis.Application.Models.Tasks.TaskModel"
        }];
      }
    } else {
      // set sorting
      this.mainQuery["SortFields"] = sortFields;
    }

    // set pagesize
    this.mainQuery["PageSize"] = this.searchService.pageSize;
    // hide quick preview
    this.searchService.currentPreviewItemClicked = null;
    // perform search
    this.getData(this.mainQuery);

    /*            this.isSplitSearchInitial = false;
            this.splitScreenQuery = null; */
  }

  clearSearchFieldValues() {
    if (this.model != null && typeof this.model != "undefined"
      && this.model.AdditionalSearchFields != null && typeof this.model.AdditionalSearchFields != "undefined") {
      for (var i = 0; i < this.model.AdditionalSearchFields.length; i++) {
        this.model.AdditionalSearchFields[i].CheckBoxState = "novalue";
        this.model.AdditionalSearchFields[i].SearchValueTo = "";
        this.model.AdditionalSearchFields[i].SearchValueFrom = "";
        this.model.AdditionalSearchFields[i].SearchValue = "";
      }
    }
  }

  modifySearchFields() {

    this.showLoader = true;
    this.originalAddedFielsList = this.addedFields.map(x => Object.assign({}, x));

    // set to default
    this.availibleFields = [];
    this.originalFieldsList = [];
    // go through all fields
    for (let i = 0; i < this.metamodelSearchFields.length; i++) {
      // check if exists in addedFields
      let index = this.addedFields.findIndex(x => x.Name == this.metamodelSearchFields[i].Name);

      if (index < 0) {
        // if doesnt exists add to availible fields        
        this.availibleFields.push(this.metamodelSearchFields[i]);
        this.originalFieldsList.push(this.metamodelSearchFields[i]);
      }
    }
    this.showLoader = false;

    // open dialog and send needed data to dialog
    const dialogRef = this.dialog.open(SemSearchFieldsDialogComponent, {
      data: {
        // XXX TODO: for some fields that are not set via UI (we're only setting display/search fields)
        // no need for sending around into dialog (showActionscolumn,...)
        availibleFields: this.availibleFields,
        addedFields: this.addedFields,
        originalFieldsList: this.originalFieldsList,
        originalAddedFieldsList: this.originalAddedFielsList,
        userSearchSettings: this.userSearchSettings,
        modelName: this.params["model"],
        searchPlaceHolder: this.searchPlaceholderTitle,
        enableFacets: this.enableFacets,
        quickViewEnabled: this.additionalSearchSettings.quickViewEnabled,
        quickViewFormName: this.additionalSearchSettings.quickViewFormName,
        onlyLastNodeNames: this.additionalSearchSettings.onlyLastNodeNames,
        showActionsColumn: this.additionalSearchSettings.showActionsColumn,
        showItemNumber: this.additionalSearchSettings.showItemNumber,
        viewId: this.params["view"],
        customViewFilter: this.customViewFilter,
        metamodelSearchFields: this.metamodelSearchFields
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined") {
        this.model["AdditionalSearchFields"] = result["modelAdditionalSearchFields"];
        this.availibleFields = result["availibleFields"];
        this.addedFields = result["addedFields"];
        this.originalFieldsList = result["originalFieldsList"];
        this.originalAddedFielsList = result["originalAddedFieldsList"];
        this.userSearchSettings = result["userSearchSettings"];
        this.searchPlaceholderTitle = result["placeHolder"];
        this.searchService.currentPlaceHoldertitle = this.searchPlaceholderTitle;
        this.searchService.changeCurrentSearchSettings(this.userSearchSettings, "search");

        if (result["restore"] == true) {
          this.searchService.setSearchFieldsRestoredDefault(true);
        }
      }
    });
  }

  customFacetSpecified(facetName: string) {

    if (this.userSearchSettings != null
      && typeof this.userSearchSettings != "undefined"
      && this.userSearchSettings.CustomFacets != null
      && typeof this.userSearchSettings.CustomFacets != "undefined") {

      var customFacetsList = this.userSearchSettings.CustomFacets.split(",");
      if (customFacetsList != null && typeof customFacetsList != "undefined") {
        var indx = customFacetsList.findIndex(x => x == facetName);
        if (indx < 0) {
          // return false only if customfacets specified and doesnt exists inside
          return false;
        }
      }
    }

    // return true if no custom facets OR if exists in custom facets
    return true;
  }

  getData(mainQuery: any) {

    // show loader
    this.showLoader = true;

    if (this.searchServiceSubscriber != null) {
      this.searchServiceSubscriber.unsubscribe();
    }

    if (this.params != null && typeof this.params != "undefined" && this.params["model"] != null && typeof this.params["model"] != "undefined" && this.params["model"] == "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
      // sorting stays unchanged for task
    } else {
      if (mainQuery != null && typeof mainQuery != "undefined") {

        // sorting for global search
        if (this.globalSearch) {
          // we should define this
        } else {
          this.defaultSorting[0].RootClassName = this.params["model"];

          if (mainQuery["SortFields"] != null || typeof mainQuery["SortFields"] != "undefined") {
            mainQuery["SortFields"].push(this.defaultSorting[0]);
          } else {
            mainQuery["SortFields"] = this.defaultSorting;
          }
        }
      }
    }

    // before performing search, save current search query into variable
    this.searchService.setCurrentSearchQuery(mainQuery);
    this.model["Models"] = [];

    // notify that search was performed
    this.searchService.searchPerformed();

    let historySearchStatePresent = false;
    // if history search state present, handle additional fields binding on UI
    if (this.searchService.historySearchStatePresent(mainQuery)) {
      historySearchStatePresent = true;
      this.searchService.pageSize = this.searchService.historySearchState.query.data["PageSize"];
      var historyAdditionalFields = this.searchService.getHistorySearchAdditionalFields();

      var mainSearchFieldValue = this.searchService.getHistoryMainSearchFieldValue();
      if (mainSearchFieldValue != null && typeof mainSearchFieldValue != "undefined" && mainSearchFieldValue.length > 0) {
        this.mainSearchValue = mainSearchFieldValue;
      }

      if (this.model.AdditionalSearchFields != null && typeof this.model.AdditionalSearchFields != "undefined") {
        for (var i = 0; i < historyAdditionalFields.length; i++) {
          var indx = this.model.AdditionalSearchFields.findIndex(x => x.Name == historyAdditionalFields[i].Binding);
          if (indx >= 0) {
            if (this.model.AdditionalSearchFields[indx].Type.startsWith("Bool")) {
              this.model.AdditionalSearchFields[indx].CheckBoxState = historyAdditionalFields[i].Value;
            } else if (historyAdditionalFields[i].ValueFrom != null && historyAdditionalFields[i].ValueTo != null
              && typeof historyAdditionalFields[i].ValueFrom != "undefined"
              && typeof historyAdditionalFields[i].ValueTo != "undefined") {

              this.model.AdditionalSearchFields[indx].SearchValueFrom = historyAdditionalFields[i].ValueFrom;
              this.model.AdditionalSearchFields[indx].SearchValueTo = historyAdditionalFields[i].ValueTo;
            } else {
              this.model.AdditionalSearchFields[indx].SearchValue = historyAdditionalFields[i].Value;
            }
          }
        }
      }

    }

    // perform search 
    this.searchServiceSubscriber = this.searchService.performSearch(mainQuery, this.customApiEndpoint, this.model.AdditionalSearchFields, this.mainSearchValue).subscribe(data => {

      let paginationInfo = new PaginationModel(data["TotalCount"], data["Page"]);
      this.searchService.changePaginationInfo(paginationInfo);

      for (var i = 0; i < data["Models"].length; i++) {
        this.model["Models"].push(data["Models"][i].Model);
      }

      if (data["Facets"] != null && typeof data["Facets"] != "undefined" && data["Facets"].length > 0) {
        if (this.metamodelFacets != null && typeof this.metamodelFacets != "undefined") {

          var currentMetaModelFacets: FacetViewModel[] = [];

          for (var i = 0; i < data["Facets"].length; i++) {

            var facetGroupName = data["Facets"][i].GroupName;

            // find facet in metamodelFacets
            let index = this.metamodelFacets.findIndex(x => x.facetPropertyPath == facetGroupName);
            var customFacetSpeicfied = this.customFacetSpecified(facetGroupName);
            if (index >= 0 && customFacetSpeicfied) {

              var curFac: FacetViewModel = new FacetViewModel();
              curFac.facetPropertyPath = this.metamodelFacets[index].facetPropertyPath;
              curFac.facetTitle = this.metamodelFacets[index].facetTitle;
              curFac.facetTranslation = this.metamodelFacets[index].facetTranslation;
              curFac.facetType = this.metamodelFacets[index].facetType;

              let facetGroups = [];
              for (var j = 0; j < data["Facets"][i].GroupFacets.length; j++) {

                // for now only support 'normal' facets
                let facetGroupModel = new FacetGroupModel();

                let count: number = 0;

                if (data["Facets"][i].GroupFacets[j].Count != null && typeof data["Facets"][i].GroupFacets[j].Count != "undefined") {
                  count = data["Facets"][i].GroupFacets[j].Count;
                }
                facetGroupModel.count = count;
                facetGroupModel.label = data["Facets"][i].GroupFacets[j].Label;
                facetGroupModel.value = data["Facets"][i].GroupFacets[j].Value;
                facetGroupModel.end = data["Facets"][i].GroupFacets[j].End;
                facetGroupModel.start = data["Facets"][i].GroupFacets[j].Start;
                facetGroupModel.path = data["Facets"][i].GroupFacets[j].Path;

                let checked = false;
                // check if current facet label is checked
                if (this.currentFacets != null && typeof this.currentFacets != "undefined") {
                  if (this.metamodelFacets[index].facetType == "normal") {
                    let ind = this.currentFacets.findIndex(x => x.fieldName == this.metamodelFacets[index].facetPropertyPath && x.fieldValue == data["Facets"][i].GroupFacets[j].Value);
                    if (ind >= 0) {
                      checked = true;
                    }
                  } else if (this.metamodelFacets[index].facetType == "taxonomy") {
                    let ind = this.currentFacets.findIndex(x => x.fieldName == this.metamodelFacets[index].facetPropertyPath && x.fieldValue == data["Facets"][i].GroupFacets[j].Value);
                    if (ind >= 0) {
                      checked = true;
                    }
                  } else if (this.metamodelFacets[index].facetType == "range") {
                    let ind = this.currentFacets.findIndex(x => x.fieldName == this.metamodelFacets[index].facetPropertyPath && x.fieldValueFrom == data["Facets"][i].GroupFacets[j].Start && x.fieldValueTo == data["Facets"][i].GroupFacets[j].End);
                    if (ind >= 0) {
                      checked = true;
                    }
                  }
                }

                facetGroupModel.facetChecked = checked;
                facetGroups.push(facetGroupModel);
              }

              curFac.groupFacets = facetGroups;
              currentMetaModelFacets.push(curFac);
              this.metamodelFacets[index].groupFacets = facetGroups;
            }
          }
        }

        var latestFacets = this.searchService.historySearchLatestFacets;
        if (historySearchStatePresent && latestFacets != null && typeof latestFacets != "undefined"
          && latestFacets["url"] == window.location.href && latestFacets["classType"] == mainQuery.ClassTypes[0]) {

          this.searchService.changeAvailibleFacets(latestFacets["Facets"]);

        } else {
          this.searchService.changeAvailibleFacets(currentMetaModelFacets);
          this.searchService.setLatestHistorySearchFacets(currentMetaModelFacets, mainQuery.ClassTypes[0], window.location.href);
        }
      } else {
        this.searchService.changeAvailibleFacets([]);
      }

      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      // reset facets if error
      this.searchService.changeAvailibleFacets([]);
      // reset page and total count if error
      this.searchService.changePaginationInfo(new PaginationModel(0, 1));

    });
  }

  // add search facets to mainQuery if exists
  private handleSearchFacets() {
    for (let facet of this.currentFacets) {

      let fieldType = "Facet";
      let fieldName = facet.fieldName;
      let facetQuery = null;


      if (facet.facetType == "normal") {

        facetQuery =
        {
          "Type": fieldType,
          "FieldName": fieldName,
          "FieldValue": facet.fieldValue
        };

      } else if (facet.facetType == "range") {

        facetQuery =
        {
          "Type": fieldType,
          "FieldName": fieldName,
          "FieldValueFrom": facet.fieldValueFrom,
          "FieldValueTo": facet.fieldValueTo
        };

      } else if (facet.facetType == "taxonomy") {

        facetQuery =
        {
          "Type": fieldType,
          "FieldName": fieldName,
          "Path": facet.fieldPath
        };
      }

      if (facetQuery != null) {
        this.andQuery.SubQueries.push(facetQuery);
      }
    }
  }

  // add additional search fields to andQuery if they exists
  private handleAdditionalSearchFields() {
    // handle additional search fields
    for (let item of this.model["AdditionalSearchFields"]) {


      if (
        (item["FormControlName"] == "SemDropDownSelectControl")
        ||
        (item["IsDropdown"] == true && item["DropdownFullTypeOfSimpleProperty"] != null)) {

        if (
          (item["FormControlName"] == "SemDropDownSelectControl" || item["FormControlName"] == "SemManyToOneControl")
          && item["IsDropdown"] == true) {
          let dropdownValue = item.SearchValue;

          if (dropdownValue != null && typeof dropdownValue != "undefined" && dropdownValue != "") {

            var newFieldQuery =
            {
              "Type": "FieldValue",
              "FieldName": item.Name + ".Id",
              "FieldValue": dropdownValue["Id"]
            };

            this.andQuery.SubQueries.push(newFieldQuery);

          }
        } else {

          // we add * on the end of each search value if it doesn't exists already
          let searchValue = item.SearchValue;
          if (!searchValue.endsWith("*")) {
            searchValue += "*";
          }

          var newFieldQuery2 =
          {
            "Type": "FieldValue",
            "FieldName": item.Name,
            "FieldValue": searchValue
          };

          this.andQuery.SubQueries.push(newFieldQuery2);
        }

      } else if (typeof item["SearchValue"] != "undefined" && item["SearchValue"] != "") {

        // we add * on the end of each search value if it doesn't exists already
        let searchValue = item.SearchValue;
        if (!searchValue.endsWith("*")) {
          searchValue += "*";
        }

        var newFieldQuery2 =
        {
          "Type": "FieldValue",
          "FieldName": item.Name,
          "FieldValue": searchValue
        };

        this.andQuery.SubQueries.push(newFieldQuery2);

      } else if ((typeof item["SearchValueFrom"] != "undefined" && item["SearchValueFrom"] != "") || (typeof item["SearchValueTo"] != "undefined" && item["SearchValueTo"] != "")) {

        var from = "*";
        var to = "*";

        if (typeof item["SearchValueFrom"] != "undefined" && item["SearchValueFrom"] != "" && item["SearchValueFrom"] != null) {
          from = item["SearchValueFrom"];
        }

        if (typeof item["SearchValueTo"] != "undefined" && item["SearchValueTo"] != "" && item["SearchValueTo"] != null) {
          to = item["SearchValueTo"];
        }

        var newRangeQuery =
        {
          "Type": "FieldRange",
          "FieldName": item.Name,
          "FieldValueFrom": from,
          "FieldValueTo": to
        };

        this.andQuery.SubQueries.push(newRangeQuery);
      } else if (item["CheckBoxState"] != null && typeof item["CheckBoxState"] != "undefined" && item["CheckBoxState"] != "novalue") {

        let fieldValue = null;
        if (item["CheckBoxState"] == "checked-true") {
          fieldValue = 1;
        } else if (item["CheckBoxState"] == "checked-false") {
          fieldValue = 0;
        }

        if (fieldValue != null) {
          var newQuery = null;
          if (item.PredefinedQuery != null) {

            try {
              if (fieldValue == 1
                && item.PredefinedQuery["QueryIfTrue"] != null
                && typeof item.PredefinedQuery["QueryIfTrue"] != "undefined") {


                var checkTrueQuery = JSON.parse(item.PredefinedQuery["QueryIfTrue"].replaceAll("'", "\""));
                newQuery = checkTrueQuery;

              } else if (fieldValue == 0
                && item.PredefinedQuery["QueryIfFalse"] != null
                && typeof item.PredefinedQuery["QueryIfFalse"] != "undefined") {

                var checkFalseQuery = JSON.parse(item.PredefinedQuery["QueryIfFalse"].replaceAll("'", "\""));
                newQuery = checkFalseQuery;
              }
            }
            catch { }
          } else {

            newQuery =
            {
              "Type": "FieldValue",
              "FieldName": item.Name,
              "FieldValue": fieldValue
            };
          }

          if (newQuery != null) {
            this.andQuery.SubQueries.push(newQuery);
          }
        }
      }
    }
  }


  setNewUserSearchSettingObject(modelName, viewId) {

    // if global search, no need for search settings
    if (this.globalSearch == false) {
      this.userService.getUserSearchSettings(modelName, viewId).subscribe(data => {

        if (data != null) {
          this.userSearchSettings = data;
          if (data != null && typeof data != "undefined" && data["Version"] != null && typeof data["Version"] != "undefined") {
            this.userService.userSearchSettingCurrentVersion = data["Version"];
          }
        }
      });
    }
  }

  getSearchPlaceHolder() {

    if (this.searchService.currentPlaceHoldertitle != null && typeof this.searchService.currentPlaceHoldertitle != "undefined") {
      return this.searchService.currentPlaceHoldertitle;
    }

    return 'galis_js_SearchForm_MainSearchPlaceholder';
  }

  handleUserAdditionalSearchFields(modelName, viewId, restore: boolean = false) {

    this.showLoader = true


    // check if user already has search field settings saved
    this.userService.getUserSearchSettings(modelName, viewId).subscribe(data => {
      if (data != null) {
        this.userSearchSettings = data;
        if (data != null && typeof data != "undefined" && data["Version"] != null && typeof data["Version"] != "undefined") {
          this.userService.userSearchSettingCurrentVersion = data["Version"];
        }

        this.searchService.facetsOrder = [];
        if (data["FacetsOrder"] != null && typeof data["FacetsOrder"] != "undefined" && data["FacetsOrder"].length > 0) {
          let splitArr = data["FacetsOrder"].split(',');

          if (splitArr != null && splitArr.length > 0) {
            this.searchService.facetsOrder = splitArr;
          }

        }

        if (data["FilterQuery"] != null && typeof data["FilterQuery"] != "undefined") {
          try {
            this.customViewFilter = JSON.parse(data["FilterQuery"]);
          } catch (e) {
            this.customViewFilter = null;
          }
        }

        if (data["EnableFacets"] != null && typeof data["EnableFacets"] != "undefined") {
          this.enableFacets = data["EnableFacets"];
        }

        if (data["CustomPageSize"] != null && typeof data["CustomPageSize"] != "undefined" && data["CustomPageSize"] > 0) {
          this.searchService.pageSize = data["CustomPageSize"];
        }

        if (data["SearchPlaceholderTitle"] != null && typeof data["SearchPlaceholderTitle"] != "undefined") {
          this.searchPlaceholderTitle = data["SearchPlaceholderTitle"];
          this.searchService.currentPlaceHoldertitle = this.searchPlaceholderTitle;

        }

        if (data["QuickViewEnabled"] != null && typeof data["QuickViewEnabled"] != "undefined") {
          this.additionalSearchSettings.quickViewEnabled = data["QuickViewEnabled"];
        } else {
          this.additionalSearchSettings.quickViewEnabled = false;
        }

        if (data["QuickViewFormName"] != null && typeof data["QuickViewFormName"] != "undefined") {
          this.additionalSearchSettings["quickViewFormName"] = data["QuickViewFormName"];
        } else {
          this.additionalSearchSettings["quickViewFormName"] = "";
        }

        if (data["OnlyLastNodeNames"] != null && typeof data["OnlyLastNodeNames"] != "undefined") {
          this.additionalSearchSettings.onlyLastNodeNames = data["OnlyLastNodeNames"];
        } else {
          this.additionalSearchSettings.onlyLastNodeNames = false;
        }

        if (data["LayoutName"] != null && typeof data["LayoutName"] != "undefined") {
          this.additionalSearchSettings.LayoutName = data["LayoutName"];
        } else {
          this.additionalSearchSettings.LayoutName = "list";
        }

        if (data["ShowItemNumber"] != null && typeof data["ShowItemNumber"] != "undefined") {
          this.additionalSearchSettings.showItemNumber = data["ShowItemNumber"];
        } else {
          this.additionalSearchSettings.showItemNumber = false;
        }

        if (data["ShowActionsColumn"] != null && typeof data["ShowActionsColumn"] != "undefined") {
          this.additionalSearchSettings.showActionsColumn = data["ShowActionsColumn"];
        } else {
          this.additionalSearchSettings.showActionsColumn = true;
        }

      }
      this.searchService.setAdditionalSearchSettings(this.additionalSearchSettings);
      this.searchService.setEnableFacets(this.enableFacets);

      let viewId = 0;
      if (this.params != null && typeof this.params != "undefined" && this.params["view"] != null && typeof this.params["view"] != "undefined") {
        viewId = this.params["view"];
      }

      this.metaModelService.getSearchFields(modelName, this.additionalSearchSettings.onlyLastNodeNames, viewId, this.globalSearch, this.globalSearchType).subscribe(data => {

        if (restore) {
          this.model["AdditionalSearchFields"] = [];
          this.addedFields = [];
        }

        if (data != null && typeof data != "undefined" && data.length > 0) {

          var luceneProperties: any[] = [];

          for (var i = 0; i < data.length; i++) {

            if (data[i] != null && typeof data[i] != "undefined") {

              const currentLuceneProperties = data[i]["LuceneProperties"];
              if (currentLuceneProperties != null && typeof currentLuceneProperties != "undefined") {
                luceneProperties.push(...currentLuceneProperties);
              }
            }
          }

          this.metamodelSearchFields = luceneProperties;
          this.searchService.changeMetaModelEntities(luceneProperties);


          // filter out those that are collection properties and select only those that are AvailibleInSearch
          //this.metamodelSearchFields = this.metamodelSearchFields.filter(x => x.IsCollectionProperty == false && x.AvailibleInSearch == true);
          this.metamodelSearchFields = this.metamodelSearchFields.filter(x => x.AvailibleInSearch == true);
          this.metamodelGridFields = luceneProperties.filter(x => x.AvailibleInGrid == true);


          if (this.globalSearch == false) {
            // if user has any saved searchSettings
            if (this.userSearchSettings != null && this.userSearchSettings.SearchFields != null && this.userSearchSettings.SearchFields.length > 0) {

              // go through saved fields
              for (let i = 0; i < this.userSearchSettings.SearchFields.length; i++) {


                let fieldType = this.userSearchSettings.SearchFields[i].FieldType;

                if(fieldType){
                  // search field
                  if(fieldType == 1){
                    // find saved field in metamodelSearchFields
                    let index = this.metamodelSearchFields.findIndex(x => x.Name == this.userSearchSettings.SearchFields[i].PropertyPath);
                    if (index >= 0) {
                      // if property found add field
                      this.metamodelSearchFields[index]["SearchValue"] = "";
                      this.metamodelSearchFields[index]["SearchValueFrom"] = "";
                      this.metamodelSearchFields[index]["SearchValueTo"] = "";
                      this.metamodelSearchFields[index]["CheckBoxState"] = "novalue";
                      this.model["AdditionalSearchFields"].push(this.metamodelSearchFields[index]);
                      // also add into addedFields
                      this.addedFields.push(this.metamodelSearchFields[index]);
                    }

                  // display field
                  }else{
                    // find saved field in metamodelGridFields
                    let index = this.metamodelGridFields.findIndex(x => x.Name == this.userSearchSettings.SearchFields[i].PropertyPath);

                    let headerText = this.userSearchSettings.SearchFields[i].PropertyPath;
                    let binding = this.userSearchSettings.SearchFields[i].PropertyPath;
                    let isImageControl = false;
                    let isSortable = false;
                    let isDateTime = false;
                    let isBoolean = false;

                    if (index >= 0) {

                      headerText = this.metamodelGridFields[index]["Translation"];
                      binding = this.metamodelGridFields[index]["Name"];
                      isImageControl = this.metamodelGridFields[index]["IsImageControl"];
                      isSortable = this.metamodelGridFields[index]["IsSortable"];
                      isDateTime = this.metamodelGridFields[index]["IsDateTime"];
                      isBoolean = this.metamodelGridFields[index]["IsCheckBox"];

                      // also add into addedFields
                      this.addedDisplayFields.push(this.metamodelGridFields[index]);
                    }
                  }
                }                
              }
            }
          }
        } else {
          this.metamodelSearchFields = [];
          this.searchService.changeMetaModelEntities([]);
        }



        if (this.params != null && typeof this.params != "undefined") {
          if (this.params["model"] != null && typeof this.params["model"] != "undefined") {
            // i see what u did here, nice abuse lol
            if (this.params["model"] == "Semantika.WebGalis.Application.Models.Tasks.TaskModel") {
              this.performTaskSearch();
            }
          }
        }

        this.showLoader = false;
      }, error => {
        this.showLoader = false;

      });

     
    }, error => {
      this.showLoader = false;
    });
  }
}
