import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { SemTextInputComponent } from '../sem-text-input/sem-text-input.component';
import { MinLengthValidator, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';

@Component({
  selector: 'sem-text-box',
  templateUrl: './sem-text-box.component.html',
  styleUrls: ['./sem-text-box.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemTextBoxComponent, multi: true }
  ]
})

export class SemTextBoxComponent extends ValueAccessorBase<string> implements OnInit {

  @Input() fields: any;
  @Input() isNumberInput: boolean = false;
  @Input() isPasswordInput: boolean = false;
  @Input() showTooltipValue: any;
  disResults: any[] = [];
  @Input() isActiveUrl: boolean = false;
  @Input() step: string = "1";


  @ViewChild("numInput") numInput: ElementRef;


  addResult() {
    this.disResults.push(this.checkForDisabled(this.getAttribute('name')));
  }

  super() { }

  ngOnInit() {
    super.ngOnInit();

    /*if (this.isNumberInput == null || typeof this.isNumberInput == "undefined") {
      this.isNumberInput = false;
    } else {
      this.restProviderService.modelValueChanged.subscribe(model => {

        if (this.model != null && typeof this.model != "undefined" && Object.keys(model).length > 3) {
          let bp = this.getAttribute('name').replace("model.", "");

          let v = this.dfms.getValue(this.model, bp);
          
          if (v) {
            this.value = v.toLocaleString("sl-SI");
          }

        }

      });
    }*/

    if (this.showTooltipValue == null || typeof this.showTooltipValue == "undefined") {
      this.showTooltipValue = false;
    }

  }

  protected override valueChanged(): void {

    if (!isNaN(Number(this.value)) && this.isNumberInput) {
      let v: number = Number(this.value);
      //TODO: Bad idea.... But until we refactor this with additional settings...
      this.value = v.toLocaleString("sl-SI").replace(".","");
    }

  }

  redirectToActiveUrl(value) {
    if (value != null && typeof value != "undefined" && value.length > 0) {
      window.open(value, "_blank");
    }
  }

  onTextChange(value) {

    if (this.isNumberInput) {
      var isValid = /^[0-9,.]*$/.test(value);
      if (!isValid) {
        value = "";
        this.value = "";
        this.dfms.assign(this.model, this.bindingProperty, "", true);
        if (this.numInput && this.numInput.nativeElement) {
          this.numInput.nativeElement.value = ""
        }
      }
    }

    let numberValue = parseFloat(value);

    if (!isNaN(this.min)) {
      let minNumber = parseFloat(this.min);
      if (numberValue < minNumber) {
        this.value = null;
        this.changeDetection.detectChanges();
        this.value = minNumber.toString();
        return;
      }
    }

    if (!isNaN(this.max)) {
      let maxNumber = parseFloat(this.max);
      if (numberValue > maxNumber) {
        this.value = null;
        this.changeDetection.detectChanges();
        this.value = maxNumber.toString();
        return;
      }
    }


    this.checkAfterInput(value);

  }

  checkAfterInput(value) {
    let attName = this.getAttribute("name");

    let control = this.dfms.globalForm.controls[attName];

    if (control !== null && typeof control !== "undefined") {
      this.dfms.globalForm.controls[attName].setValue(value);
      this.dfms.globalForm.controls[attName].updateValueAndValidity();
    }


    this.dfms.updateControlsValidationArray(attName, value);

    if (typeof this.onValueChangeEval != "undefined" && this.onValueChangeEval) {
      eval(this.onValueChangeEval);
    }
  }

}
