import { Injectable, Component } from '@angular/core';
import { RestProviderService } from '../rest-provider/rest-provider.service';
import { RequestModel } from 'src/app/models/request/request-model';
import * as _ from 'lodash';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';
import { UtilityService } from '../utility/utility.service';
import { BehaviorSubject } from 'rxjs';
import { SearchService } from '../search/search.service';
import { BulkActionModel } from 'src/app/models/bulk-action/bulk-action-model';
import { SemGenerateDialogComponent } from 'src/app/components/controls/dialogs/sem-generate-dialog/sem-generate-dialog.component';
import { FileUploadService, XHRFactory } from '../file-upload-service/file-upload.service';
import { SemParameterDialogComponent } from '../../components/controls/dialogs/sem-parameter-dialog/sem-parameter-dialog.component';
import { DynamicFieldsManagerService } from '../dynamic-fields/dynamic-fields-manager.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {

  actions: any[];
  formActions: any[];

  // if any bulk action on current form
  private bulkActionPresent = new BehaviorSubject(undefined);
  currentBulkActionPresent = this.bulkActionPresent.asObservable();

  private actionFinished = new BehaviorSubject(undefined);
  currentActionFinished = this.actionFinished.asObservable();

  private _formActionsReceived = new BehaviorSubject({});
  formActionsReceived = this._formActionsReceived.asObservable();


  constructor(public restService: RestProviderService, public utility: UtilityService, public searchService: SearchService, public fileUploadService: FileUploadService, public dfms: DynamicFieldsManagerService,
    public dialog: MatDialog) {

    restService.getDataGET("api/actions/list").subscribe(data => {

      this.actions = JSON.parse(data);
      //console.log("Retrieved actions :", this.actions);

      restService.getDataGET("api/actions/formactions").subscribe((formActionsApiData: any) => {

        this.formActions = JSON.parse(formActionsApiData);
        this._formActionsReceived.next({});
        //console.log("Retrieved actions :", this.actions);

      }, error => {
        //console.log("Error retrieving actions ", error);
      });

    }, error => {
      //console.log("Error retrieving actions ", error);
    });

  }

  setCurrentBulkActionPresent(present: boolean) {
    this.bulkActionPresent.next(present);
  }

  callAction(formAction,
    inputModel,
    preCallback: (data: any) => any,
    postCallback: (data: any) => void,
    context: any,
    bulkAction: boolean,
    inputParametersModel: any,
    onlyClientLogic: boolean = false,
    maintainLoader: boolean = false,
    bulkExport: boolean = false,
    skipBulkActionApiMethod: boolean = false) {

    if (onlyClientLogic == null || typeof onlyClientLogic == "undefined") {
      onlyClientLogic = false;
    }

    DynamicLoadingUtil.loader.showLoader = true;
    let action = this.getAction(formAction["ActionName"]);

    //TODO: check if we have pre-post callback actions already defined somewhere in the universe

    // use outputModel for any changes, to keep inputModel inatact
    let outputModel = _.cloneDeep(inputModel);

    let checkedItems = [];

    // if formAction is "bulk"    
    if (bulkAction == true) {
      var checkedBulkItems = this.searchService.getCurrentCheckedBulkDocument();
      let bulkActionModel: BulkActionModel = new BulkActionModel([]);

      if (typeof checkedBulkItems != "undefined" && checkedBulkItems != null && checkedBulkItems.length > 0) {
        for (var i = 0; i < checkedBulkItems.length; i++) {
          bulkActionModel.Entities.push(checkedBulkItems[i]);
          checkedItems.push(checkedBulkItems[i].Id);
        }
      }

      // if bulkExport is true then normal action is called 
      // otherwise use bulk action infrastructure
      if (bulkExport != true) {
        // in "bulk" actions ActionName becomes Action for bulk action model
        bulkActionModel.ActionName = formAction["ActionName"];
        bulkActionModel.ActionProvider = action["ProviderFullName"];
        bulkActionModel.Projection = formAction["Projection"];
        bulkActionModel.ProjectionDynamicForm = formAction["ProjectionDynamicForm"];

        bulkActionModel["SkipBulkActionApiMethod"] = skipBulkActionApiMethod;

        // outputModel becomes model for BulkAction
        outputModel = bulkActionModel;

        // actual action that will be always called for bulk actions is "ExecuteBulkAction"
        // so set action as ExecuteBulkAction

        /*if(skipBulkActionApiMethod){
          action = this.getAction(formAction["ActionName"]);
        }else{
          action = this.getAction("ExecuteBulkAction"); 
        }*/

        action = this.getAction("ExecuteBulkAction");

        /* But i want my custom action, to be called :( .... Boško
        action = this.getAction(formAction["ActionName"]);
        */
      }
    }

    // call preCallback        
    let preResult = function (str) {
      return eval(str);
    }.call(context, formAction["PreJS"]);

    if (bulkExport == true) {
      outputModel["CheckedItemIds"] = checkedItems;
    }

    if (!onlyClientLogic && typeof formAction["ActionName"] !== 'undefined' && formAction["ActionName"] !== null && formAction["ActionName"].length > 0 && typeof outputModel !== 'undefined' && outputModel != null) {     
      this.executeAction(action, inputModel, outputModel, postCallback, formAction["PostJS"], context, inputParametersModel, formAction);
    } else {

      if (formAction["PostJS"] != null && typeof formAction["PostJS"] != "undefined") {

        var resultPo = function (str) {
          eval(str);
          DynamicLoadingUtil.loader.showLoader = false;
        }.call(context, formAction["PostJS"]);
        
      } else if (onlyClientLogic && !maintainLoader) {
        DynamicLoadingUtil.loader.showLoader = false;
      } else {
        DynamicLoadingUtil.loader.showLoader = false;
      }
    }
  }

  
  evaluateJS(js:string,context){
    let preResult = function (str) {
      return eval(str);
    }.call(context, js);
  }
  executeAction(action, inputModel, outputModel, postCallback: (data: any, error: any, context: any) => void, postExecuteJs, context, inputParametersModel, fullFormAction: any = null) {

    if (action != null && typeof (action) != "undefined") {
      var fModel = new RequestModel;
      // if file action use outputModel as data
      // so you can send formData (logic in preJS)
      if (action["IsFileAction"]) {
        fModel.data = outputModel;
      } else {
        fModel.data = { Envelope: outputModel, InputParameters: inputParametersModel };
      }

      fModel.url = action["ActionUrl"];

      if (fModel.url !== null && fModel.url !== undefined && fModel.url.length > 0) {
        this.restService.getDataPOST(fModel).subscribe(data => {
          var msgType = 0;
          if (data != null && typeof data != "undefined"
            && data["Metadata"] != null
            && typeof data["Metadata"] != "undefined"
            && data["Metadata"]["ServerMessageMetadata"] != null
            && typeof data["Metadata"]["ServerMessageMetadata"] != "undefined"
            && data["Metadata"]["ServerMessageMetadata"]["Message"] != null
            && typeof data["Metadata"]["ServerMessageMetadata"]["Message"] != "undefined"
            && data["Metadata"]["ServerMessageMetadata"]["MessageType"] != null
            && typeof data["Metadata"]["ServerMessageMetadata"]["MessageType"] != "undefined") {
            msgType = data["Metadata"]["ServerMessageMetadata"]["MessageType"];
          }
          // response = error	
          if (msgType == 2) {

            // if error response	
            DynamicLoadingUtil.loader.showLoader = false;
            this.actionFinished.next(data["Metadata"]["ServerMessageMetadata"]);
            // response = popup	
          } else if (msgType == 5) {
            if (fullFormAction != null && fullFormAction.PopupResponseParameterForm != null) {
              // if response type = popup then show confirmation popup	
              const dialogRef = this.dialog.open(SemParameterDialogComponent, {
                data: {
                  parameterFormId: fullFormAction.PopupResponseParameterForm.Id,
                  customHeaderTitle: fullFormAction.PopupResponseParameterForm.CustomHeaderTitle,
                  customCssClass: fullFormAction.PopupResponseParameterForm.CustomCssClass,
                  model: fModel.data
                }
              });

              dialogRef.afterClosed().subscribe(returnData => {
                if (returnData != null && typeof returnData != "undefined") {
                  if (returnData.Canceled == false) {

                    // we know user confirmed confirmationpopup, set value	
                    if (inputParametersModel != null && typeof inputParametersModel != "undefined") {
                      inputParametersModel["ConfirmationPopupConfirmed"] = true;
                    } else {
                      inputParametersModel = { "ConfirmationPopupConfirmed": true };
                    }

                    // mybe some additional controls on confirmation popup, get model	
                    var newParamsModel = returnData["Model"];
                    // join current input with new	
                    var finalInputParams = { ...newParamsModel, ...inputParametersModel };
                    this.executeAction(action, inputModel, outputModel, postCallback, postExecuteJs, context,
                      finalInputParams);
                  }
                }
              });
            } else {
              DynamicLoadingUtil.loader.showLoader = false;
              // notify all subscribers that action finished -> show popup if data present        	
              this.setActionAsFinished(null);
            }
          } else {
            var resultPo = function (model, str) {
              eval(str);
            }.call(context, data, postExecuteJs);
            DynamicLoadingUtil.loader.showLoader = false;
            /*if(postCallback != null && typeof postCallback != "undefined"){	
              postCallback(data, null);	
            }*/
            if (postCallback != null && typeof postCallback != "undefined") {
              var resultPo = function (data) {
                postCallback(data, null, context);
              }.call(context, data);
            }
            // notify all subscribers that action finished -> show popup if data present	
            this.setActionAsFinished(data);
          }
        }, error => {
          DynamicLoadingUtil.loader.showLoader = false;
          // notify all subscribers that action finished -> show popup if data present        	
          this.setActionAsFinished(error);
        });	
      } else {
        DynamicLoadingUtil.loader.showLoader = false;	
      }      
    } else {	
      //console.log("Error! Action not found");	
      DynamicLoadingUtil.loader.showLoader = false;	
    }	
  }

  // notify all subscribers that action finished -> show popup if data present
  setActionAsFinished(response) {
    if (response != null && typeof response != "undefined"
      && response["Metadata"] != null && typeof response["Metadata"] != "undefined"
      && response["Metadata"]["ServerMessageMetadata"] != null && typeof response["Metadata"]["ServerMessageMetadata"] != "undefined") {
      this.actionFinished.next(response["Metadata"]["ServerMessageMetadata"]);
    } else {
      //Return data
      this.actionFinished.next(response);
    }
  }

  getAction(actionName) {
    if (this.actions) {
      for (var i = 0; i < this.actions.length; i++) {
        if (this.actions[i]["ActionName"] == actionName) {
          return this.actions[i];
        }
      }
    }
  }

getTargetActions() {
    let targetActions: any[] = [];
    if (this.formActions) {
      for (var i = 0; i < this.formActions.length; i++) {
        if (typeof (this.formActions[i]["Target"]) != "undefined" && (this.formActions[i]["Target"])) {
          if (this.formActions[i]["Target"] == document.location.pathname) {
            targetActions.push(this.formActions[i]);
          }
        }
      }
    }

    return targetActions;
  }

  getActionFromAPI(actionName){
  }

  getActionAndExecute(actionName, onlyClientLogic: boolean, context, maintainLoader: boolean, skipBulkActionApiMethod: boolean = false, model: any = null) {
    DynamicLoadingUtil.loader.showLoader = true;	
    this.restService.getDataGET("api/actions/" + actionName, "application/json").subscribe(data => {	
      if(data != null && typeof data != "undefined"){	
        let formAction = JSON.parse(data);	
        if (formAction.ParameterForm != null && typeof formAction.ParameterForm != "undefined" && formAction.ParameterForm.Id > 0) {	
          var reportId = null;	
          if(formAction.ParameterForm.Report != null && typeof formAction.ParameterForm.Report != "undefined" ){	
            reportId = formAction.ParameterForm.Report.Id;	
          }	
  	
          const dialogRef = this.dialog.open(SemParameterDialogComponent, {	
            data: {	
                 parameterFormId: formAction.ParameterForm.Id,	
                 reportId: reportId,	
                 customHeaderTitle: formAction.ParameterForm.CustomHeaderTitle,	
                 customCssClass: formAction.ParameterForm.CustomCssClass  	
                }	
          });	
  	
          dialogRef.afterClosed().subscribe(returnData => {	
            if (returnData != null && typeof returnData != "undefined") {	
              // only call action if parameter popup was confirmed (not cancled - close button)	
              if (returnData.Canceled == false) {	
                var inputParametersModel = returnData["Model"];	
                  this.callAction(formAction, null, null, null, context, formAction.BulkAction, inputParametersModel, onlyClientLogic,	
                    maintainLoader, formAction.BulkExport, formAction.SkipBulkActionApiMethod);	
              }	
            }	
          });	
        }else{	
          this.callAction(formAction, null, null, null, context, false, null, onlyClientLogic, maintainLoader, false, skipBulkActionApiMethod);	
        }	
      }                	
    }, error => {	
      console.log(error);	
      DynamicLoadingUtil.loader.showLoader = false;	
    });	
  }

  evaluateJs(js, context) {

  }

}
