import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SemGenerateDialogComponent } from '../dialogs/sem-generate-dialog/sem-generate-dialog.component';

@Component({
  selector: 'sem-report-generator',
  templateUrl: './sem-report-generator.component.html',
  styleUrls: ['./sem-report-generator.component.css']
})
export class SemReportGeneratorComponent extends ValueAccessorBase<any> implements OnInit {

  @Input() bindingProperty: any = "";
  @Input() urlBindingProperty: any = null;
  @Input() reportId: any = null;
  @Input() dialogTitle: any = null;
  @Input() propertyPath: any = null;

  @ViewChild("input", { read: FormControl }) input: FormControl;

  super() { }

  ngOnInit() {
  }

  generate() {

    if (!this.checkForDisabled(this.bindingProperty)) {
      this.value = new Date();
      var datePipe = new DatePipe('en-US');
      this.dfms.assign(this.model, this.bindingProperty, new Date(), true);

      var parsedJS = this.reportId.split("[").join("[\"");
      parsedJS = parsedJS.split("]").join("\"]");

      var result = eval("function reportId(model){ " + parsedJS + " } reportId(this.model);");

      var parsedPathJS = this.propertyPath.split("[").join("[\"");
      parsedPathJS = parsedPathJS.split("]").join("\"]");

      //console.log("Report ID: ", result);

      //data: { reportId: result, entityId: eval("this." + parsedPathJS), title:this.dialogTitle  }

      if (typeof result != "undefined" && result) {
        const dialogRef = this.dialog.open(SemGenerateDialogComponent, {
          data: { reportId: result, entityId: this.model["PenaltyData"]["Id"], title: this.dialogTitle }
        });
      }
    }
  }

  getProp(prop, expression) {
    if (!this.checkForDisabled(this.bindingProperty)) {
      let value = "";

      if (expression) {
        let midValue = eval(expression);
        //let midValue = new Function(expression).call(this);
        value = midValue;
      }
      else {
        value = this.dfms.getValue(this.model, prop);
      }

      return value;
    }
  }

  setProp(prop, value) {
    if (!this.checkForDisabled(this.bindingProperty)) {
      this.dfms.assign(this.model, prop, value, true);
      //this.form.controls[prop + this.sectionId].setValue(value);
    }
  }

  setClasses() {
    return {
      'generated': this.model[this.bindingProperty],
      'not-generated': !this.model[this.bindingProperty],
      'disabled-field': this.checkForDisabled(this.bindingProperty)
    };
  }

}
