import { Component, OnInit, Input, AfterContentInit, NgZone, ChangeDetectorRef, AfterViewInit, AfterContentChecked, Output, EventEmitter, ViewChild } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { RequestModel } from 'src/app/models/request/request-model';
import * as _ from "lodash";
import { Observable, of, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DataGridSortingFlag } from 'src/app/models/datagrid/data-grid-column-sorting-flag';
import { SortFieldModel } from 'src/app/models/search/sort-field.model';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';


@Component({
  selector: 'sem-table-data',
  templateUrl: './sem-table-data.component.html',
  styleUrls: ['./sem-table-data.component.css']  
})
export class SemTableDataComponent  extends SemControlComponent implements OnInit, AfterViewInit {

  
  @Input() searchQuery;
  @Input() redirectUrl;
  @Input() tableFields: any = [];
  @Input() tooltipText;
  @Input() showPageSizeOption: boolean = false;
  @Input() pageOptions: any = [];
  @Input() customApiEndpoint: string;


  tableData: any = {
    "Columns":[]    
  };
  itemsLoaded: boolean = false;
  items: any = [];
  totalItems: number = 0;  
  nextPage;
  totalPages = 1;
  previousPage;
  pages: any = [];
  helperColumnFlags: DataGridSortingFlag = {};
  private currentSortFields: SortFieldModel[] = [];
  showTableLoader:boolean = false;
  private selectedPageSize = 5;
  
  super() { }

  ngOnDestroy(): void {

  }

  ngAfterViewInit() {      

  }

  ngOnInit() {    

    this.currentSortFields = [];
    this.restProviderService.modelValueChanged.subscribe(model => {

      this.model = model;

      if(this.model != null && typeof this.model != "undefined" && this.model.Id > 0 && this.searchQuery != null && typeof this.searchQuery != "undefined"){   
        this.search(1);                          
      }
    });    
  
  }
  
  pageChanged(pageNum) {
    this.search(pageNum);
  }

  toLastPage(){    
    if(this.totalPages < 1){
      this.totalPages = 1;
    }    
    this.search(this.totalPages);
  }

  toFirstPage(){    
    this.search(1);
  }

  sortDropDownClick(column, event) {
    event.stopPropagation();
    if (this.helperColumnFlags[column.BindingField]["ShowSort"]) {
      this.helperColumnFlags[column.BindingField]["ShowSort"] = false;
    } else {
      this.helperColumnFlags[column.BindingField]["ShowSort"] = true;
    }
    return false;
  }

  setColumnSorting(column, direction, event) {
    event.stopPropagation();
    this.helperColumnFlags[column.BindingField]["SortingUsed"] = true;
    this.helperColumnFlags[column.BindingField]["SortDirection"] = direction;
    // hide    
    this.helperColumnFlags[column.BindingField]["ShowSort"] = false;


    // add to current sort field if doesn't already exists
    // if exists just update sort direction
    let index = this.currentSortFields.findIndex(x => x.FieldName == column.BindingField);
    if (index >= 0) {
      // if new direction is same as current then ignore it
      if (this.currentSortFields[index].SortDirection != direction) {
        this.currentSortFields[index].SortDirection = direction;        
      }
    } else {
      this.currentSortFields.push(new SortFieldModel(direction, column.BindingField, ""));
    }

    this.search(1);
    return false;
  }

  changeNumberOfPages(event) {
    this.selectedPageSize = event.target.value;
    this.search(1);
  }

  private searchCustomEndpoint(pageNum: number){

    DynamicLoadingUtil.loader.showLoader = true;
    this.showTableLoader = true;

    let formModel = {};

    if (this.model != null && typeof this.model != "undefined") {
      formModel = this.model;
    }

    let requestModel: RequestModel = new RequestModel();
    requestModel.url = "/api/data/list";
    requestModel.contentType = "application/json"
    let requestBody = {};


    

  }

  private postCustomEndpoint(requestModel: RequestModel){

    this.restProviderService.getDataPOST<any>(requestModel).subscribe(data => {
      this.zone.run(() => {
        this.items = data.Models; 
        this.totalItems = data.TotalCount;     
                      
        let currentPage = data.Page;
        this.pages = [];
        
        if (this.totalItems == 0) {
          this.pages.push({ "Number": 1, "Active": true });
        } else {

          let pageNum = (this.totalItems + data.PageSize - 1) / data.PageSize;
          pageNum = Math.floor(pageNum);

          let from = 0;
          let to = pageNum;
          this.totalPages = pageNum;
          
          from = currentPage - 2;
          if (from <= 0) {
            from = 1;
          }

          to = from + 5;

          if (to > pageNum) {
            to = pageNum + 1;
          }

          this.previousPage = currentPage - 1;
          if (this.previousPage <= 0) {
            this.previousPage = 0;
          }

          this.nextPage = currentPage + 1;
          if (this.nextPage > pageNum) {
            this.nextPage = 0;
          }

          for (let i = from; i < to; i++) {

            let active = false;
            if (i == currentPage) {
              active = true;
            }

            this.pages.push({ "Number": i, "Active": active });
          }
        }
        this.itemsLoaded = true;  
        DynamicLoadingUtil.loader.showLoader = false;
        this.showTableLoader = false;

      });
    
    }, error => {
      this.itemsLoaded = true;
      DynamicLoadingUtil.loader.showLoader = false;
      this.showTableLoader = false;

    })
  }

  private search(pageNum: number){

    DynamicLoadingUtil.loader.showLoader = true;
    this.showTableLoader = true;

    // use custom delimiter ${ }
    _.templateSettings.interpolate = /\${([\s\S]+?)}/g;
      
    let formModel = {};

    if (this.model != null && typeof this.model != "undefined") {
      formModel = this.model;
    }

              
    let requestModel: RequestModel = new RequestModel();
    requestModel.url = "/api/data/list";
    requestModel.contentType = "application/json"
    let requestBody = {};

    try {
      // interpolate
      let compiled = _.template(this.searchQuery);
      let compiledSearchQuery = compiled(formModel);
      if (compiledSearchQuery != null && typeof compiledSearchQuery != "undefined") {
        let interpolateMainSearchQueryObject = JSON.parse(compiledSearchQuery);          
        requestModel.data = interpolateMainSearchQueryObject;
        requestModel.data.Page = pageNum;
      }

      var searchModel = requestModel.data.ClassTypes[0];
      

      requestModel.data.PageSize = this.selectedPageSize;

      let viewId = 0;
      if(this.params != null && typeof this.params != "undefined" && this.params["view"] != null && typeof this.params["view"] != "undefined"){
        viewId = this.params["view"];
      }

      this.metaModelService.getSearchFields(searchModel,false,viewId, false, "").subscribe(data => {        
        if(data != null && typeof data != "undefined" && data.length > 0 && data[0]["LuceneProperties"] != null && typeof data[0]["LuceneProperties"] != "undefined"){
          
          var luceneProperties = data[0]["LuceneProperties"];                            
          
          this.tableData.Columns = [];

          for(var i = 0; i < this.tableFields.length; i++){
            
            let index = luceneProperties.findIndex(x => x.Name == this.tableFields[i]);
            if(index >= 0){

              this.tableData.Columns.push({
                "HeaderText":luceneProperties[index].Translation,
                "IsDateTime": luceneProperties[index].IsDateTime,
                "IsBoolean": luceneProperties[index].IsCheckBox,
                "IsImageControl": luceneProperties[index].IsImageControl,
                "BindingField": luceneProperties[index].Name
              });                           
            }                             


            if(this.helperColumnFlags[luceneProperties[index].Name] == null || typeof this.helperColumnFlags[luceneProperties[index].Name] == "undefined"){
              let isSortable = false;
              isSortable = luceneProperties[index]["IsSortable"];
              this.helperColumnFlags[luceneProperties[index].Name] = {
                "IsSortable": isSortable,
                "ShowSort": false,
                "SortingUsed": false
              }
            }            
          }    


          if(this.currentSortFields != null){

            requestModel.data["SortFields"] = [];

            for (let sortField of this.currentSortFields) {

              let rootclassname = sortField.RootClassName;
      
              if (rootclassname == null || typeof rootclassname == "undefined" || rootclassname == "") {
                rootclassname = requestModel.data.ClassTypes[0];
              }
      
              requestModel.data["SortFields"].push(
                {
                  "FieldName": sortField.FieldName,
                  "Direction": sortField.SortDirection,
                  "RootClassName": rootclassname
                });
            }
          }
          
          if(this.customApiEndpoint !== null && typeof this.customApiEndpoint !== "undefined" && this.customApiEndpoint.length > 0)
          {
            //requestModel.url = "/api/data/list";
            requestModel.url = "/" + this.customApiEndpoint;
          }

            //faking čreva
          this.restProviderService.getDataPOST<any>(requestModel).subscribe(data => {
            this.zone.run(() => {
              this.items = data.Models; 
              this.totalItems = data.TotalCount;     
                            
              let currentPage = data.Page;
              this.pages = [];
              
              if (this.totalItems == 0) {
                this.pages.push({ "Number": 1, "Active": true });
              } else {
      
                let pageNum = (this.totalItems + data.PageSize - 1) / data.PageSize;
                pageNum = Math.floor(pageNum);
      
                let from = 0;
                let to = pageNum;
                this.totalPages = pageNum;
                
                from = currentPage - 2;
                if (from <= 0) {
                  from = 1;
                }
      
                to = from + 5;
      
                if (to > pageNum) {
                  to = pageNum + 1;
                }
      
                this.previousPage = currentPage - 1;
                if (this.previousPage <= 0) {
                  this.previousPage = 0;
                }
      
                this.nextPage = currentPage + 1;
                if (this.nextPage > pageNum) {
                  this.nextPage = 0;
                }
      
                for (let i = from; i < to; i++) {
      
                  let active = false;
                  if (i == currentPage) {
                    active = true;
                  }
      
                  this.pages.push({ "Number": i, "Active": active });
                }
              }
              this.itemsLoaded = true;  
              DynamicLoadingUtil.loader.showLoader = false;
              this.showTableLoader = false;

            });
          
          }, error => {
            this.itemsLoaded = true;
            DynamicLoadingUtil.loader.showLoader = false;
            this.showTableLoader = false;

          });
                          
        }   else{
          this.itemsLoaded = true;
          DynamicLoadingUtil.loader.showLoader = false;
          this.showTableLoader = false;
        }     

      }, error => {        
        this.itemsLoaded = true;
        DynamicLoadingUtil.loader.showLoader = false;
        this.showTableLoader = false;

      });           
    } catch {      
      //console.log("e"); 
      this.itemsLoaded = true;  
      DynamicLoadingUtil.loader.showLoader = false;
      this.showTableLoader = false;
   
    }   
  }
   
   itemClick(dataItem) {
    if(this.customApiEndpoint !== null && typeof this.customApiEndpoint !== "undefined" && this.customApiEndpoint.length > 0)
    {
      this.utility.processNavigationUrl(dataItem.Model.DetailsFormUrl+ "?id=" + dataItem.Model.Id);
    }
    else{
     if(this.redirectUrl != null && typeof this.redirectUrl != "undefined" && this.redirectUrl.length > 0 && dataItem != null && typeof dataItem != "undefined"){
      this.utility.processNavigationUrl(this.redirectUrl+ "?id=" + dataItem.Model.Id);
     }  
    }  
  }
  

  showDataGridColumn(isImageControl, isBoolean) {
    // if it is image control then dont show data grid column
    // or it is boolean
    if (isImageControl == true || isBoolean == true) {
      return false; // means it will hide div
    } else {
      // in every other case show it
      return true;
    }
  }

  resolvePropertyBinding(object, propertyPath) {

    if (typeof propertyPath != "undefined" && propertyPath != null) {
      let split = propertyPath.split(".");
      let obj = object;

      for (let i = 0; i < split.length; i++) {
        if (obj != null && typeof obj != "undefined") {
          obj = obj[split[i]];
        }
      }

      if (obj != null && typeof obj != "undefined") {
        return obj;
      }
    }

    return "";
  }

  showIfNotDate(isDateTime) {
    if (isDateTime == true) {
      return false;
    } else {
      return true;
    }
  }

}
