import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, Validators, NG_VALIDATORS } from '@angular/forms';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';
import { RestProviderService } from 'src/app/services/rest-provider/rest-provider.service';

@Directive({
  selector: '[semrequired]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SemRequiredDirective, multi: true }]
})
export class SemRequiredDirective implements Validator {

  @Input() semrequired: string;
  cntrl: AbstractControl;

  constructor(public dfms: DynamicFieldsManagerService, public restProviderService: RestProviderService) {

    this.dfms._modelMTOValueChanged.subscribe(model => {

      for (var i = 0; i < this.dfms.manyToOne.length; i++) {        
        if (this.semrequired != null && typeof this.semrequired != "undefined" && this.semrequired.includes(this.dfms.manyToOne[i].name)) {

          if(model == null && this.cntrl != null && typeof this.cntrl != "undefined"){
            this.cntrl.setValidators([]); 
            this.cntrl.updateValueAndValidity();
            return;
          }

          let res = this.dfms.getValue(model, this.dfms.manyToOne[i].name);

          if (typeof res != "undefined" && res != null && Object.keys(res).length > 1 && this.cntrl != null && typeof this.cntrl != "undefined") {
            this.cntrl.setValidators([Validators.required]);
          }else{
            this.cntrl.setValidators([]);  
          }
          
          this.cntrl.updateValueAndValidity();

        }
      }

    });    

  }

  validate(control: AbstractControl): ValidationErrors {
    this.cntrl = control;
    for (var i = 0; i < this.dfms.manyToOne.length; i++) {
      if (this.semrequired.includes(this.dfms.manyToOne[i].name)) {

        //this.control.nativeElement.removeAttribute('required');
        //this.control.nativeElement.removeAttribute('ng-reflect-required');

        if (this.dfms.manyToOne[i].required) {
          control.setValidators([Validators.required]);
          return;
        } else {
          return null;
        }

      }
    }

    control.setValidators([Validators.required]);
    return null;
  }
}
