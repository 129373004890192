import { Component, OnInit, Input } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';

@Component({
    selector: 'app-sem-text-input',
    templateUrl: './sem-text-input.component.html',
    styleUrls: ['./sem-text-input.component.css']
})
export class SemTextInputComponent extends SemControlComponent implements OnInit {

    super() { }

    ngOnInit() {
  }

  valueChanged() {
  }

}
