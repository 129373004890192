import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { RequestModel } from '../../../models/request/request-model';
import { LocalStorageService } from '../../../services/local-storage-service/local-storage.service';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { TranslationsProviderService } from '../../../services/translations-provider/translations-provider.service';
import { SemToken } from 'src/app/models/oauth/semToken';
import { jwtDecode } from "jwt-decode";
import { delayWhen, map, retryWhen, shareReplay, tap } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-login-v3',
  templateUrl: './login-v3.component.html',
  styleUrls: ['./login-v3.component.scss']
})
export class LoginV3Component implements OnInit {

  public username: string = "";
  public password: string = "";
  public otp: string = "";
  public imgPath = environment.projIcon;
  public disabled: boolean = false;
  public projName = environment.projectName;
  public showOTP: boolean = false;
  public showAlertMessage: boolean = false;
  public alertMessage: string = "";
  public forgotUsernameMail: string = "";
  public loginViewName: string = "";
  public forgotPasswordMail: string = "";
  public showGreenAlert: boolean = false;
  public newPasswordRepeat: string = "";
  public newPassword: string = "";
  private pwResetToken: string = "";
  public pwChangeDone: boolean = false;


  constructor(private rp: RestProviderService, private httpClient: HttpClient, private router: Router, private localStorageService: LocalStorageService, private translationsService: TranslationsProviderService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.loginViewName = "userLogin";
    let homepage = this.localStorageService.get("homepage_url");

    this.pwResetToken = this.route.snapshot.queryParams['token'];
    if(this.pwResetToken !== null && this.pwResetToken !== undefined && this.pwResetToken.length > 0){
      this.loginViewName = "passwordReset";
    }

    if (homepage) {
      window.location.href = homepage;
    } else {

      this.projName = environment.projectName;


      if (environment.ADLoginEnabled) {
        let options = {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
          withCredentials: true
        };


        this.httpClient.get(environment.wgProfileHost + "/oauth/winlogin", options)
          .subscribe(
            response => {
              this.procesResponse(response);
            },
            error => {
              console.log(error);
            },

            () => {
              //all ok
            }

          );
      }
    }

  }


  procesResponse(response) {       
    let languageCode = this.localStorageService.get("language");
    if (languageCode == null) {
      languageCode = environment.language.toUpperCase();
    }

    localStorage.clear();

    this.localStorageService.add("language", languageCode);

    let token = new SemToken;
    token.accessToken = response["access_token"];
    token.created = new Date();

    let expires = new Date();
    expires.setHours(expires.getHours() + 24);
    token.expires = expires;

    this.localStorageService.add("token", JSON.stringify(token));

    let userData = jwtDecode(token.accessToken);

    this.localStorageService.add("user", userData["userState"]);//response["user"]);

    //this.rp.setCookie("_galis_access_token", response["access_token"], 1, "/");
    this.rp.currentUser = JSON.parse(userData["userState"]);

    let transObj = new RequestModel;
    transObj.data = {
      "LanguageCode": languageCode
    };

    transObj.url = "api/translation/all";

    this.rp.getDataPOST(transObj).subscribe(data => {

      let translations = data["Translations"];
      this.translationsService.translations = translations;
      this.localStorageService.add("translations", JSON.stringify(translations));

      if (typeof response["dashboard_url"] != "undefined" && response["dashboard_url"]) {
        this.localStorageService.add("homepage_url", response["dashboard_url"]);
        window.location.href = response["dashboard_url"];
      } else {
        this.localStorageService.add("homepage_url", "cms/dashboard");
        window.location.href = "cms/dashboard";
      }

    }, error => {
      this.disabled = false;
    });
  }

  showPassword() {
    var x = document.getElementById("Password");
    if (x["type"] === "password") {
      x["type"] = "text";
    } else {
      x["type"] = "password";
    }
  }

  loginClick(otpLogin: boolean = false) {
    this.showAlertMessage = false;
    this.disabled = true;
    this.showGreenAlert = false;
    let body = new URLSearchParams();
    let url = "";

    body.set('username', this.username);
    body.set('grant_type', 'password');

    if (otpLogin) {
      body.set('otp', this.otp);
      url = "/oauth/otptoken";
    } else {
      body.set('password', this.password);
      url = "/oauth/token";
    }    

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    this.httpClient
      .post(environment.wgProfileHost + url, body.toString(), options)
      .subscribe(response => {

        if (response != null) {

          let warning = response["warning"];
          if (warning !== null && warning !== undefined && warning === true) {

            this.showAlertMessage = true;
            this.alertMessage = response["warningMsg"];

          } else {

            if (response["otpRequired"] === true) {
              this.showOTP = true;
              this.otp = "";              
            } else {
              this.showOTP = false;
              this.procesResponse(response);
            }

          }          

          this.disabled = false;
        }        
      },
        error => {
          this.disabled = false;
        });

  }

  otpLogin() {
    this.loginClick(true);
  }

  closeErrorBox() {
    this.showAlertMessage = false;
    this.alertMessage = "";
  }

  newRegistration() {
    
    // TODO: make registration form?

    if(environment.newRegistrationLinkDocument && environment.newRegistrationLinkDocument.length > 0){
      window.open(environment.newRegistrationLinkDocument, '_blank');
    }else{
      // show registration form?
    }

   }


   usernameForgot(){
    this.loginViewName = "forgotUsername";
   }

   passwordForgot(){
    this.loginViewName = "forgotPassword";
   }

   showMainLoginScreen(){
    window.location.href = '/account/login';
   }



   sendMailForgotUsername(){
    this.showGreenAlert = false;
    this.disabled = true;    
    let url = "/api/account/username/forgot/"+this.forgotUsernameMail;    

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    this.httpClient
      .get(environment.wgProfileHost + url, options)
      .subscribe(response => {

        if(response){

          if(response["Error"]){
            this.showAlertMessage = true;
            this.alertMessage = response["Message"];
          }else{            
            this.forgotUsernameMail = "";              
            this.loginViewName = "userLogin";       
          }                   
        }   
        this.disabled = false;     
      },
        error => {
          this.disabled = false;
          this.showAlertMessage = true;
          this.alertMessage = "Napaka";
      });        
   }

   sendMailForgotPassword(){
    this.showGreenAlert = false;
    this.disabled = true;    
    let url = "/api/account/password/reset/"+this.forgotPasswordMail;    

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    this.httpClient
      .get(environment.wgProfileHost + url, options)
      .subscribe(response => {


        if(response){
          
          if(response["Error"]){
            this.showGreenAlert = false;
          }else{            
            this.showGreenAlert = true;
            this.forgotPasswordMail = "";                                      
          }                   

          this.alertMessage = response["Message"]; 
          this.showAlertMessage = true;            
        }   
        this.disabled = false;            
      },
        error => {
          this.disabled = false;
          this.showAlertMessage = true;
          this.alertMessage = "Napaka";
      });        
   }

   sendPasswordResetValues(){
    
    this.pwChangeDone = false;
    this.showGreenAlert = false;
    this.disabled = true;    
    let url = "/api/account/password/reset/token";

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };        

    let body = {
      NewPassword: this.newPassword,
      NewPasswordRepeat: this.newPasswordRepeat,
      Token: this.pwResetToken
    }

    this.httpClient
    .post(environment.wgProfileHost + url, JSON.stringify(body), options)
    .subscribe(response => {


        if(response){
          
          if(response["Error"]){
            this.showGreenAlert = false;                        
          }else{                        
            this.showGreenAlert = true;
            this.newPassword = "";
            this.newPasswordRepeat = "";     
            this.pwChangeDone = true;                               
          } 
          this.showAlertMessage = true;                  
          this.alertMessage = response["Message"];                       
        }   
        this.disabled = false;            
      },
        error => {
          this.disabled = false;
          this.showAlertMessage = true;
          this.alertMessage = "Napaka";
      }); 

   }
}

