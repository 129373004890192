import { Component, Input, OnInit } from '@angular/core';
import { SemNavigationComponent } from '../../../components/common/sem-navigation/sem-navigation.component';
import { SemLeftMenuComponent } from '../sem-left-menu/sem-left-menu.component';

@Component({
  selector: 'sem-left-menu-item',
  templateUrl: './sem-left-menu-item.component.html',
  styleUrls: ['./sem-left-menu-item.component.css']
})
export class SemLeftMenuItemComponent extends SemLeftMenuComponent implements OnInit {

  @Input() node:any;
  @Input() opened:any;

  super() { }

  ngOnInit(): void {
  }

}
