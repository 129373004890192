import { Component, OnInit, Input } from '@angular/core';
import { ValueAccessorBase } from '../../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';
import { RequestModel } from 'src/app/models/request/request-model';


@Component({
  selector: 'sem-last-edited-data',
  templateUrl: './sem-last-edited-data.component.html',
  styleUrls: ['./sem-last-edited-data.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemLastEditedDataComponent, multi: true }
  ]
})
export class SemLastEditedDataComponent extends ValueAccessorBase<string> implements OnInit {

  @Input() settings: any;

  private myTemplate: any = "";
  @Input() url: string;
  searchResults: any[] = [];
  searchTerm: string;

  private searchServiceSubscriber = null;

  super() {

    if (typeof DynamicLoadingUtil.loader != "undefined") {
      DynamicLoadingUtil.loader.showLoader = true;
    }

    if (this.restProviderService.currentUser != "undefined" && this.restProviderService.currentUser != null) {
      if (this.restProviderService.currentUser["Username"] != "anonymous") {
        DynamicLoadingUtil.loader.showLoader = false;
      } else {
        window.location.href = "/account/login?returnUrl=/cms/dashboard";
      }
    }
    else {
      window.location.href = "/account/login?returnUrl=/cms/dashboard";
    }

    //console.log("Last updated control settings: ", this.settings);

  }

  ngOnInit() {

    if (this.settings["PerformDirectAPICall"]) {
      this.restProviderService.getDataGET(this.settings["DirectAPICallUrl"]).subscribe(
        data => {
          //console.log("Retrieved tasks on dashboard: ", data);
          let models = JSON.parse(data);
          for (var i = 0; i < models.length; i++) {
            this.searchResults.push({

              "Id": models[i].Id,
              "Title": models[i].Title,
              "Date": models[i].DueDate

            });
          }
        },
        error => {
          //console.log("Error retrieving tasks", error);
        }
      );
    }
    else if (this.settings["PerformPOSTAPICall"]) {
      var fModel = new RequestModel;
      fModel.data = JSON.parse("{\"Page\":" + 1 + ",\"PageSize\":" + 5 + "}");
      fModel.url = this.settings["DirectAPICallUrl"];

      this.restProviderService.getDataPOST<any>(fModel).subscribe(
        data => {

          this.modifyForFrontEnd(data);

        },
        error => {
          //console.log("Error retrieving tasks", error);
        }
      );
    } else {
      let queryModel = JSON.parse(this.settings.Query.replace(/'/g, '"'));
      this.searchServiceSubscriber = this.searchService.performSearch(queryModel, null, null, null).subscribe(data => {
        this.modifyForFrontEnd(data);
      }, error => {
      });
    }


  }

  modifyForFrontEnd(data:any){

    let propsToBeShown = this.settings["PropertiesToBeShownOnFrontEnd"];
    if (!(typeof propsToBeShown != "undefined" && propsToBeShown && propsToBeShown.length > 0)) {
      propsToBeShown = [{Name:"Title",DataType:"string"}, {Name:"LastChangeDate",DataType:"datetime"}];
    }

    for (var i = 0; i < data["Models"].length; i++) {

      let resItem = {};

      for (var j = 0; j < propsToBeShown.length; j++) {
        resItem[propsToBeShown[j]["Name"]] = this.dfms.getValue(data["Models"][i]["Model"], propsToBeShown[j]["Name"]);
      }

      resItem["OriginalObject"] = data["Models"][i]["Model"];

      this.searchResults.push(resItem);
    }

    this.sidebarMenuOpened = true;
    this.changeDetection.detectChanges();

  }

  isMenuOpened() { }



  navigate(item: any, url: string) {

    if (url.includes("processNavigationUrl")) {
      eval(url);
    } else {
      this.utility.processNavigationUrl(url,item);
    }

  }

  navigateToDetails(dataItem: any) {

    let url = this.settings["Url"];

    if (url.includes("processNavigationUrl")) {
      eval(url);
    } else {
      let detailsUrl = url + dataItem["Id"];
      this.utility.processNavigationUrl(detailsUrl);
    }

  }

  sidebarChanged(event) {
    this.sidebarMenuOpened = event;
  }

}
