import { Component, Input, OnInit } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';

@Component({
  selector: 'sem-side-container',
  templateUrl: './sem-side-container.component.html',
  styleUrls: ['./sem-side-container.component.css']
})
export class SemSideContainerComponent extends SemControlComponent implements OnInit {

  @Input() title: "";

  linkedDocuments: any = [];

  id: number = 0;
  url:string = "";

  super() { }

  ngOnInit(): void {


    this.activatedRoute.queryParams.subscribe(params => {

      if (params != null && typeof params != "undefined") {
        if (params["id"] != null && typeof params["id"] != "undefined") {
          let currentId = parseInt(params["id"]);

          this.restProviderService.getDataGET("api/galisdocument/getlinkeddocuments/" + currentId).subscribe((data: any) => {
            this.linkedDocuments = JSON.parse(data);
          }, error => {
            console.log(error);
          });

        }
      }
    });
  }

  docSelected(id: number, url:string) {
    this.id = id;
    this.url = url;

    document.getElementsByClassName("item-container")[0].classList.add("splitted-view");
  }

  close() {
    this.id = 0;
    this.url = "";
    document.getElementsByClassName("item-container")[0].classList.remove("splitted-view");
  }

}
