import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { RestProviderService } from '../rest-provider/rest-provider.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationsProviderService {
  public translations: string[] = [];
  public labels: any = {};
  constructor(public restProviderService: RestProviderService, public localStorageService: LocalStorageService) {
    this.translations = JSON.parse(this.localStorageService.get("translations"));
    //this.loadLabels();
  }

  loadLabels() {
    let cacheData: string | null = null;
    if (environment.useRouteAndMasterpageCache) {
      cacheData = this.localStorageService.get("field_labels");
    }

    if (cacheData) {
      this.labels = JSON.parse(cacheData);

      if (this.labels.length == 0) {
        this.getLabels();
      }
    } else {
      this.getLabels();
    }
  }

  getLabels() {
    if (this.restProviderService.getToken() != '') {
      this.restProviderService.getDataGET("api/dynamicforms/labels").subscribe((data: any) => {
        this.localStorageService.add("field_labels", data);
        this.labels = JSON.parse(data);
      });
    }
  }

  translate(key: string):string {

    try {
      if (typeof key != "undefined" && key != null) {
        var k = key.replace("Translation.", "");

        if (this.translations[k]) {
          return this.translations[k];
        } else {
          return key;
        }
      }

      return key;
    } catch (e) {
      return key;
    }

  }
}
