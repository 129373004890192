import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SemManyToOneControlComponent } from '../../sem-many-to-one-control/sem-many-to-one-control.component';
import { RestProviderService } from 'src/app/services/rest-provider/rest-provider.service';
import { SearchService } from 'src/app/services/search/search.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';

interface HierarchyObject {
  name: string;
  id?: Number;
  parentId?: Number;
  children?: HierarchyObject[];
}

const TREE_DATA: HierarchyObject[] = [
  {
    name: 'Kovina',
    children: [
      { name: 'Železo' },
      { name: 'Jeklo' },
      { name: 'Baker' },
    ]
  }, {
    name: 'Les',
    children: [
      {
        name: 'Iglavci',
        children: [
          { name: 'Smreka' },
          { name: 'Jelka' },
        ]
      }, {
        name: 'Listavci',
        children: [
          { name: 'Lipa' },
          { name: 'Bukev' },
        ]
      },
    ]
  },
];

@Component({
  selector: 'app-sem-compare-dialog',
  templateUrl: './sem-compare-dialog.component.html',
  styleUrls: ['./sem-compare-dialog.component.css']
})
export class SemCompareDialogComponent implements OnInit {

  treeControl = new NestedTreeControl<HierarchyObject>(node => node.children);
  dataSource = new MatTreeNestedDataSource<HierarchyObject>();
  treeControlRemoved = new NestedTreeControl<HierarchyObject>(node => node.children);
  dataSourceRemoved = new MatTreeNestedDataSource<HierarchyObject>();
  treeControlMod = new NestedTreeControl<HierarchyObject>(node => node.children);
  dataSourceMod = new MatTreeNestedDataSource<HierarchyObject>();

  entityModel: string;
  propName: string;
  linkWithExistingData: string;
  title: string;
  flatTreeData: HierarchyObject[] = [];
  flatTreeDataRemoved: HierarchyObject[] = [];
  flatTreeDataMod: HierarchyObject[] = [];

  loading: boolean = false;

  defaultSearchQueryString: string = "{\"MainQuery\":null,\"FieldQueries\":{},\"FacetQueries\": {},\"RequestedFacets\": null,\"Projection\": [],\"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"*\", \"FieldValue\": \"*\" },\"ClassTypes\": [],\"Page\": \"1\",\"PageSize\": \"500\",\"RepositoryName\": \"lucene\",\"Form\": null,\"UseCache\": false}";
  mainSearchQuery: any;

  models: any[] = [];
  simpleProps: any[] = [];
  selectedPage: number;
  isModified: boolean = false;

  private searchServiceSubscriber: any = null;

  constructor(public dialogRef: MatDialogRef<SemCompareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private restProvider: RestProviderService,
    public searchService: SearchService,
    public translationService: TranslationsProviderService) {

  }

  hasChild = (_: number, node: HierarchyObject) => !!node.children && node.children.length > 0;



  ngOnDestroy(): void {
    if (typeof this.searchServiceSubscriber != "undefined" && this.searchServiceSubscriber != null) {
      this.searchServiceSubscriber.unsubscribe();
    }

  }

  ngOnInit() {
    this.models = this.dialogData["items"];           
    let modified = false;

    this.models.forEach(element => {

      var a = {} as HierarchyObject;
      if (element["NewValue"] && typeof element["NewValue"] === 'object' && (element["Type"] == 1 || element["Type"] == 2)) {
        a.name = element["NewValue"]["Rank"]["TitleWithAgeCategory"];
        let obj = { name: element["NewValue"]["Discipline"]["TitleWithGender"] };
        a.children = [obj];
        modified = true;
      } else if (element["OldValue"] && typeof element["OldValue"] === 'object' && element["Type"] == 3) {
        a.name = element["OldValue"]["Rank"]["TitleWithAgeCategory"] + " ; " + element["OldValue"]["Discipline"]["TitleWithGender"];

        let childs = [];

        if (typeof element["Children"] != "undefined" && element["Children"]) {
          element["Children"].forEach(child => {
            let cChild = { name: this.translationService.translate(child["Name"]) + ": prejšnja vrednost " + child["OldValue"] + ", nova vrednost " + child["NewValue"] };
            childs.push(cChild);
          });
        }

        a.children = childs;
        modified = true;
      } else if (element["OldValue"] && element["NewValue"] && typeof element["OldValue"] !== 'object' && element["Type"] == 3) {
        this.simpleProps.push(element);
      }

      if (element["Type"] == 2) {
        this.flatTreeData.push(a);
      } else if (element["Type"] == 1) {
        this.flatTreeDataRemoved.push(a);
      } else if (element["Type"] == 3) {
        this.flatTreeDataMod.push(a);
      }

      /*if(element.Parent != undefined || element.Parent != null){
        a.parentId = element.Parent.Id;
      }*/

    });

    // if only simple props then modified is false
    this.isModified = modified;

    /*this.flatTreeData.forEach(elItem =>{
      elItem.children = this.flatTreeData.filter(filt => filt.parentId == elItem.id);
    })

    let filteredRoots: HierarchyObject[] = this.flatTreeData.filter(ft => ft.parentId == null || ft.parentId == undefined)
*/

    /*filteredRoots.forEach(elementRoot => {
      
      let childs: HierarchyObject[] = this.flatTreeData.filter(ft => ft.parentId == elementRoot.id);
      if(childs != null && childs != undefined){
        elementRoot.children = childs;
      }

      this.treeData.push(elementRoot);
    });*/
    /*this.treeData = this.flatTreeData;
*/

    this.dataSource.data = this.flatTreeData;
    this.treeControl.dataNodes = this.flatTreeData;
    this.dataSourceRemoved.data = this.flatTreeDataRemoved;
    this.treeControlRemoved.dataNodes = this.flatTreeDataRemoved;
    this.dataSourceMod.data = this.flatTreeDataMod;
    this.treeControlMod.dataNodes = this.flatTreeDataMod;
  }

  ngAfterViewInit() {
    //this.treeControl.expandAll();
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  linkClicked(data) {
  }

  confirm(){
    this.dialogRef.close({"IsModified":this.isModified});
  }

}
