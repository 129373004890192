import { Component, Input, OnInit } from '@angular/core';
import { SemBaseComponent } from '../../../components/common/sem-base/sem-base.component';

@Component({
  selector: 'sem-loader',
  templateUrl: './sem-loader.component.html',
  styleUrls: ['./sem-loader.component.css']
})
export class SemLoaderComponent implements OnInit {

  constructor() { }

  @Input() showLoader: boolean = false;
  @Input() cssClasses: string = "";

  toggle(event) {
  }

  ngOnInit() {
  }

}
