import { Injectable } from '@angular/core';
import { RequestModel } from 'src/app/models/request/request-model';
import { RestProviderService } from '../rest-provider/rest-provider.service';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportService {  

  constructor(private restProvider: RestProviderService) {
  }

  // export data
  private exportData: Observable<any[]>; // store the shared observable

  export(exportType: string, searchQuery: any) {            

    var reqModel = new RequestModel;
    reqModel.url = "api/document/export";
    reqModel.data = {
      "ExportType": exportType,
      "SearchQuery": searchQuery
    };    

    this.exportData = this.restProvider.getDataPOST<any[]>(reqModel).
      pipe(
        map((data: any[]) => {
          return data;
        }), catchError(error => {
          return throwError('Export service error!', error)
        }));

    return this.exportData;

  }

}
