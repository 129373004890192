import { Component, OnInit, Input, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { RestProviderService } from '../../../services/rest-provider/rest-provider.service';
import { unescape } from 'querystring';

@Component({
  selector: 'sem-radio-button',
  templateUrl: './sem-radio-button.component.html',
  styleUrls: ['./sem-radio-button.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemRadioButtonComponent, multi: true }
  ]
})
export class SemRadioButtonComponent extends ValueAccessorBase<any> implements OnInit, AfterContentInit {

  @Input() answers: any;
  @Input() subBindingProperty: any;
  @Input() itemsType: any;

  super(){}

  ngOnInit() {
    this.loadItems();
    this.changeDetection.detectChanges();
  }

  ngAfterContentInit() {

  }

  ngAfterViewChecked() {
    //your code to update the model
    this.changeDetection.detectChanges();
  }

  loadItems() {
    if (this.itemsType.length > 0) {
      this.restProviderService.getDataGET("api/registry/" + this.itemsType + "/dropdown", "application/json").subscribe(data => {
        let result = JSON.parse(data);

        for (var i = 0; i < result.length; i++) {

          this.answers = [...this.answers, result[i]];

        }

        //I know, very bad....
        //setTimeout(function () {
        //}, 3000);
        //

        this.changeDetection.detectChanges();

      }, error => {
        this.changeDetection.detectChanges();
        //console.log("Error retrieving data for dropdown");
        //console.log(error);
      });
    }
  }

  public get parsedAnswers() {
    try {
      if (this.itemsType.length == 0) {
        //if (this.answers.length == 1) {
          return this.answers;
        //}
      }
      return this.answers;
    }
    catch (e) {
      return [];
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.Id === c2.Id : c1 === c2;
  }

  protected valueChanged() {

    if (typeof this.answers != "undefined") {
      for (var i = 0; i < this.answers.length; i++) {
        if (this.answers[i].Id == this.value["Id"]) {
          this.value = this.answers[i];
          break;
        }
      }
    }
  }

  protected valueModified() {
    if (this.itemsType.length == 0) {
      if (typeof this.value["Value"] != "undefined") {
        this.value = this.value["Value"];
      }
    }
  }

}
