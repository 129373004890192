import { NgControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl(name: string) {
    //const action = condition ? 'disable' : 'enable';
    //this.ngControl.control[action]();

    for (var i = 0; i < this.dfms.metaModel.length; i++) {
      if (this.dfms.metaModel[i].W == 0 && this.dfms.metaModel[i].Path == name) {
        this.ngControl.control["disable"]();
      }
    }

  }

  constructor(private ngControl: NgControl, public dfms: DynamicFieldsManagerService) {
  }

}