import { RootModel } from '../root-model';
import { Injectable } from "@angular/core";
import { UserSearchSettingPropertyModel } from './user-search-setting-property-model';

@Injectable()
export class UserSearchSettingModel {

  public User: any;
  public SearchFields: Array<UserSearchSettingPropertyModel>;
  public ModelName: string = "";
  public LayoutName: string = "";
  public Version: string = "";
  public FilterQuery: string = "";
  public EnableFacets: boolean = false;
  public Id: number = 0;
  public View: any = null;
  public QuickViewEnabled: boolean = false;
  public OnlyLastNodeNames: boolean = false;
  public ShowItemNumber: boolean = false;
  public ShowActionsColumn: boolean = true;
  public QuickViewFormName: string = "";
  public SearchPlaceHolderTitle: string = null;
  public DefaultSorter : string = null;
  public CustomFacets: string = null;
  public CustomPageSize: number = 0;
  public FacetsOrder: string = null;
  public ConditionEvaluations: Array<any> = null;
  public SearchViewName: string = "";
  public CanEditView: boolean = false;

  constructor() {
  }

}
