import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SemManyToOneControlComponent } from '../../sem-many-to-one-control/sem-many-to-one-control.component';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { RestProviderService } from '../../../../services/rest-provider/rest-provider.service';
import { SearchService } from 'src/app/services/search/search.service';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';


/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface HierarchyObject {
  name: string;
  id?: Number;
  parentId?: Number;
  children?: HierarchyObject[];
  dirty: boolean;
}

/**
 * @title Tree with nested nodes
 */
@Component({
  selector: 'app-sem-treeview-dialog',
  templateUrl: './sem-treeview-dialog.component.html',
  styleUrls: ['./sem-treeview-dialog.component.css']
})
export class SemTreeviewDialogComponent implements OnInit, AfterViewInit {

  treeControl = new NestedTreeControl<HierarchyObject>(node => node.children);
  dataSource = new MatTreeNestedDataSource<HierarchyObject>();

  entityModel: string;
  propName: string;
  linkWithExistingData: string;
  title: string;
  flatTreeData: HierarchyObject[] = [];
  treeData: HierarchyObject[] = [];

  loading: boolean = false;

  defaultSearchQueryString: string = "{\"MainQuery\":null,\"FieldQueries\":{},\"FacetQueries\": {},\"RequestedFacets\": null,\"Projection\": [],\"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"*\", \"FieldValue\": \"*\" },\"ClassTypes\": [],\"Page\": \"1\",\"PageSize\": \"1000\",\"RepositoryName\": \"lucene\",\"Form\": null,\"UseCache\": false, \"IncludeParents\": false}";
  mainSearchQuery: any;

  models: any[] = [];
  selectedPage: number;

  searchTerm: string = "";

  private searchServiceSubscriber: any = null;

  constructor(public dialogRef: MatDialogRef<SemManyToOneControlComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private restProvider: RestProviderService,
    public searchService: SearchService,
    public translationsService: TranslationsProviderService) {

  }

  hasChild = (_: number, node: HierarchyObject) => !!node.children && node.children.length > 0;



  ngOnDestroy(): void {
    if (typeof this.searchServiceSubscriber != "undefined" && this.searchServiceSubscriber != null) {
      this.searchServiceSubscriber.unsubscribe();
    }
  }

  ngOnInit() {

    this.mainSearchQuery = JSON.parse(this.defaultSearchQueryString);

    // based on display fields set projection on query
    if (this.mainSearchQuery != null && typeof this.mainSearchQuery != "undefined") {

      let displayFieldsForProjection = [];

      /*for (var i = 0; i < this.displayFields.length; i++) {
        displayFieldsForProjection.push(this.displayFields[i].PropertyName);
      }*/

      this.mainSearchQuery["Projection"] = Object.assign([], displayFieldsForProjection);
      // always add "Id" to projection
      this.mainSearchQuery.Projection.push("Id");
      this.mainSearchQuery.Projection.push("SourceRepository");
      this.mainSearchQuery.Projection.push("RepositoryId");
      this.mainSearchQuery.Projection.push("Parent.Id");
      this.mainSearchQuery.Projection.push("Title");
    }

    this.linkWithExistingData = this.translationsService.translations["WebGalis_LinkWithExistingData"];
    this.entityModel = this.dialogData["entityModel"];
    this.propName = this.dialogData["propName"];

  }

  ngAfterViewInit() {
    this.mainSearchQuery["ClassTypes"].push(this.entityModel);
    this.performDataSearch(1, null);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onEnter() {
    this.performDataSearch(1, this.searchTerm);
  }

  performDataSearch(page, searchString) {

    this.models = [];
    this.treeData = [];
    this.flatTreeData = [];

    this.loading = true;

    if (this.dialogData["customApiUrl"]) {

      this.restProvider.getDataGET(this.dialogData["customApiUrl"] + "/" + this.dialogData["entityModel"]).subscribe((data: any) => {
        this.models = JSON.parse(data);

        if (this.models && this.models.length > 0) {
          //Not the best option
          if (searchString && searchString.length > 0) {

            if (typeof this.models[0].Value != "undefined") {
              this.models = this.models.filter(az => az.Value.Title.toLowerCase().indexOf(searchString.toLowerCase()) > -1);

              this.models.sort((a, b) => {
                if (a.Value.Title < b.Value.Title) { return -1; }
                if (a.Value.Title > b.Value.Title) { return 1; }
                return 0;
              });

            } else {
              this.models = this.models.filter(az => az.Title.toLowerCase().indexOf(searchString.toLowerCase()) > -1);

              this.models.sort((a, b)=>{
                if(a.Title < b.Title) { return -1; }
                if(a.Title > b.Title) { return 1; }
                return 0;
            });
            }
          }

          this.loadData(searchString);
        }

      });

    } else {

      this.flatTreeData = [];
      this.selectedPage = page;
      this.models = [];
      this.loading = true;
      this.mainSearchQuery.IncludeParents = true;
      this.mainSearchQuery.TreeQuery.FieldName = "*";

      if (searchString != null) {
        this.mainSearchQuery.IncludeParents = true;
        this.mainSearchQuery.TreeQuery.FieldName = "Title";
        if (searchString.includes("*")) {
          this.mainSearchQuery.TreeQuery.FieldValue = searchString;
        }
        else {
          this.mainSearchQuery.TreeQuery.FieldValue = searchString + "*";
        }
      }

      this.searchServiceSubscriber = this.searchService.performSearch(this.mainSearchQuery, null, null, null).subscribe(data => {


        for (var i = 0; i < data["Models"].length; i++) {
          this.models.push(data["Models"][i]["Model"]);
        }

        this.loadData(searchString);

      }, error => {
        //console.log(error);
        this.loading = false;
      });
    }
  }

  loadData(searchString) {

    this.models.forEach(element => {
      var a = {} as HierarchyObject;
      if (typeof element.Value != "undefined") {
        a.name = element.Value.Title;
        a.id = element.Value.Id;
      } else {
        a.name = element.Title;
        a.id = element.Id;
      }

      //(foo === null || foo === undefined)
      if (element.Parent != undefined || element.Parent != null) {

        a.parentId = element.Parent.Id;
      }
      a.dirty = false;

      this.flatTreeData.push(a);

    });

    this.flatTreeData.forEach(elItem => {
      let filteredArr = this.flatTreeData.filter(filt => filt.parentId == elItem.id);
      elItem.children = filteredArr;
      if (searchString != null) {
        this.markDirty(filteredArr);
      }
    })

    let filteredRoots: HierarchyObject[] = this.flatTreeData.filter(ft => ft.parentId == null || ft.parentId == undefined)

    /*filteredRoots.forEach(elementRoot => {
      
      let childs: HierarchyObject[] = this.flatTreeData.filter(ft => ft.parentId == elementRoot.id);
      if(childs != null && childs != undefined){
        elementRoot.children = childs;
      }

      this.treeData.push(elementRoot);
    });*/
    if (filteredRoots.length > 0) {
      this.treeData = filteredRoots;
    }
    else {
      this.treeData = this.flatTreeData.filter(az => az.dirty == false);
    }

    this.treeData.sort((a, b)=>{
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
  });

    this.dataSource.data = this.treeData;
    this.treeControl.dataNodes = this.treeData;
    this.loading = false;
    this.treeControl.collapseAll();
  }

  markDirty(filteredTreeArray: HierarchyObject[]) {
    filteredTreeArray.forEach(element => {
      var a = this.flatTreeData.filter(ft => ft.id == element.id)[0];
      if (a != undefined) {
        a.dirty = true;
      }
    });
  }


  linkClicked(data) {
    this.loading = true;

    var url = "";

    if (data.id > 0 || data.id != undefined) {
      url = "api/data/relatedentity/" + this.dialogData["formName"] + "/" + this.propName + "/" + this.entityModel + "/" + data.id;
    } else {
      //relatedentity/external/{formInfo}/{propName}/{sourcerepository}/{repositoryid}
      url = "api/data/relatedentity/external/" + this.dialogData["formName"] + "/" + this.dialogData["propName"] + "/" + data["SourceRepository"] + "/" + data["RepositoryId"];
    }

    this.restProvider.getDataGET(url).subscribe(data => {
      let jsonData = JSON.parse(data);
      this.dialogRef.close(jsonData["Model"]);

      this.loading = false;
    }, error => { this.loading = false; });

  }

  searchString() {
    alert("Search Fired!");
  }

}
