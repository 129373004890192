import { Component, OnInit, Input, NgZone, ChangeDetectorRef, Injector, NgModuleRef, Compiler, Renderer2, ComponentFactoryResolver, ElementRef, Output, EventEmitter, HostListener, KeyValueDiffers, AfterViewInit, ViewChild } from '@angular/core';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { FileDataModel } from 'src/app/models/file-upload/file-data';
import { RestProviderService } from 'src/app/services/rest-provider/rest-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { FileUploadService } from 'src/app/services/file-upload-service/file-upload.service';
import { MetaModelService } from 'src/app/services/metamodel/metamodel-provider.service';
import { UserService } from 'src/app/services/user/user-provider.service';
import { SearchService } from 'src/app/services/search/search.service';
import { FormBuilder } from '@angular/forms';
import { FileUtil } from 'src/app/shared/helpers/file-util';
import { RouteNavigationService } from 'src/app/services/dynamic-routes-loader/route-navigation.service';
import { ActionsService } from 'src/app/services/actions-service/actions.service';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { ParameterFormsService } from 'src/app/services/parameter-forms-service/parameter-forms.service';
import { FormService } from '../../../services/form-service/form.service';
import { Annotorious } from '@recogito/annotorious';
import { throws } from 'assert';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SemFileExplorerDialogComponent } from '../dialogs/sem-file-explorer-dialog/sem-file-explorer-dialog.component';
import { NgxPhotoEditorService } from 'ngx-photo-editor';
import { HttpClient } from '@angular/common/http';
import { RequestModel } from '../../../models/request/request-model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sem-file-explorer',
  templateUrl: './sem-file-explorer.component.html',
  styleUrls: ['./sem-file-explorer.component.css']
})
export class SemFileExplorerComponent extends SemControlComponent implements OnInit, AfterViewInit {

  explorer: any = {

    normal: {
      width: 941,
      height: 625,
      positionTop: 50,
      positionLeft: 50,
      marginTop: -312,
      marginLeft: -470
    },

    max: {
      width: 1000,
      height: 700,
      positionTop: 50,
      positionLeft: 50,
      marginTop: -350,
      marginLeft: -500
    },
    maximized: true,
    opened: true
  };

  @Input() files: any[] = [];
  @Input() shownFiles: any[] = [];
  @Input() type: number = 1; //1-images|2-files
  selectedItem: any = null;
  @Input() model: any;
  @Input() dialogRef: MatDialogRef<SemFileExplorerDialogComponent> | null = null;

  sideType: number = 0;

  @Input() hasDeleteRight: boolean = false;
  @Input() hasWriteRight: boolean = false;
  @Input() availableExtensions: any[] = [];
  imagesExtensions: string[] = ["jpg", "jpeg", "png", "gif"];

  txtFileContent: any;
  pdfSrc: any;
  searchText: string = "";
  selectedImageData: SafeUrl = "";
  selectedImageDataString: string = "";
  imgViewType: number = 1;


  @Output() onAddItem = new EventEmitter();
  @Output() onItemDrop = new EventEmitter();
  @Output() onItemDownload = new EventEmitter();
  @Output() onItemDelete = new EventEmitter();

  imageAnnotate: any;
  annotations: any = [];
  @ViewChild('annotat', { static: false }) public annotate: ElementRef;

  constructor(
    public restProviderService: RestProviderService,
    public zone: NgZone,
    public changeDetection: ChangeDetectorRef,
    public _injector: Injector,
    public _m: NgModuleRef<any>,
    public _compiler: Compiler,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public renderer: Renderer2,
    public translationsService: TranslationsProviderService,
    public fileUploadService: FileUploadService,
    public metaModelService: MetaModelService,
    public userService: UserService,
    public searchService: SearchService,
    public componentFactoryResolver: ComponentFactoryResolver,
    public control: ElementRef,
    public formBuilder: FormBuilder,
    public actionsService: ActionsService,
    public dfms: DynamicFieldsManagerService,
    public utility: UtilityService,
    public parameterFormService: ParameterFormsService,
    public formService: FormService,
    private windowRef: WindowRefService,
    private domSanitizer: DomSanitizer,
    public photoEditorService: NgxPhotoEditorService,
    public anglarHttpClient: HttpClient,
    public angLocation:Location) {
    super(
      restProviderService,
      zone,
      changeDetection,
      _injector,
      _m,
      _compiler,
      route,
      router,
      dialog,
      renderer,
      translationsService,
      fileUploadService,
      metaModelService,
      userService,
      searchService,
      componentFactoryResolver,
      control,
      formBuilder,
      actionsService,
      dfms,
      utility,
      parameterFormService,
      formService,
      photoEditorService,
      anglarHttpClient,
      angLocation);
  }

  async ngAfterViewInit() {
    this.open();

    console.log(this.files);

    this.loading = true;

    /////////////////////////////////////////////////////////




    this.shownFiles = [];

    for (var i = 0; i < this.files.length; i++) {
      // somewhere this.files is reset to [] 
      // check 
      if (this.files != null && this.files.length > 0) {
        var fileName = this.files[i].Name.split(".")[0];
        var filenamefull = this.files[i].Name;
        var extension = this.files[i].Name.split(".")[1].toLowerCase();

        if (fileName.length > 10) {
          fileName = fileName.substring(0, 10) + "...";
        }

        let url = "";

        if (typeof this.files[i]["FileID"] != "undefined" && this.files[i]["FileID"]) {

          let model = new RequestModel;
          model.url = "api/media/GetPreview";
          model.data = { Id: this.files[i]["FileID"], DocumentId: this.model["Id"], ReturnFullSize: true, Width: 0, Height: 0 };
          //model.responseType = "application/octet-stream";
          //model.contentType = "application/json";
          this.restProviderService.serializer = null;

          //var result = await this.restProviderService.getDataPOST(model).toPromise();
          var result = await this.restProviderService.imagePostRequest(model).toPromise();

          //var result = this.readWGFile(model,this.files[i].Name);
          //console.log("WG Image result", result);

          this.files[i].File = this.dataURLtoFile("data:text/plain;base64," + result["Base64Img"], this.files[i].Name);
        }

        url = URL.createObjectURL(this.files[i].File);

        this.shownFiles.push({ id: i, path: url, feName: fileName, name: filenamefull, extension: extension, size: FileUtil.getFriendlyFileSize(this.files[i].File.size) });

        if (i == 0) {
          if (this.files != null && this.files.length > 0) {
            this.selectedItem = this.files[0];
            this.getImageUrl();
          }
          this.loading = false;
        }
      }
    }

    this.loading = false;

    ////////////////////////////////////////////////////////




    if (this.type == 2 && this.files?.length > 0) {
      this.selectItem(this.files[0]);
    }

    setTimeout(() => {
      this.onStartAnnotate();
    }, 500);
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  onAddItemClick() {
    this.onAddItem.emit();
  }

  ngOnInit() {

    if (this.type == 2) {
      this.imgViewType = 0;
    }

    this.calcMax();
  }

  setSelected(name) {
    this.selectedItem = this.files.filter(i => i.Name == name)[0];
    this.onRestartAnnotate();
    this.getImageUrl();
  }

  getAnnotationBody(ann) {
    let string = "";

    ann.body?.forEach(element => {
      string += " " + element.value + ";";
    });

    return string.slice(0, -1);
  }

  calcMax() {
    var w = this.windowRef.nativeWindow.screen.width - 250;
    var h = this.windowRef.nativeWindow.screen.height - 250;

    this.explorer.max.width = w;
    this.explorer.max.height = h;

    var ptp = (25 * 100) / this.windowRef.nativeWindow.screen.height;
    var lpp = (50 * 100) / this.windowRef.nativeWindow.screen.width;

    this.explorer.max.positionTop = ptp;
    this.explorer.max.positionLeft = lpp;
    this.explorer.max.marginLeft = 0;
    this.explorer.max.marginTop = 0;
  }

  /*@HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calcMax();
    this.calcPositionStyle();
  }*/

  setExplorerMax() {
    this.calcMax();
    this.explorer.maximized = true;
  }

  setExplorerNormal() {
    this.explorer.maximized = false;
  }

  setExplorerNormalMax() {
    if (this.explorer.maximized) {
      this.setExplorerNormal();
    } else {
      this.setExplorerMax();
    }
  }

  close() {
    this.explorer.maximized = false;
    this.explorer.opened = false;
    this.selectedItem = null;
    this.getImageUrl();
  }

  open() {
    this.explorer.opened = true;
    this.setExplorerMax();
  }

  onRestartAnnotate() {
    if (this.type != 2) {
      /*try {
        document.getElementsByClassName("a9s-annotationlayer")[0].remove();
      } catch (e) { }*/

      let annotations: any = [];

      if (this.selectedItem.Annotations) {
        this.selectedItem.Annotations?.forEach(element => {
          element.target.selector.type = "FragmentSelector";
          let obj = {
            "@context": "http://www.w3.org/ns/anno.jsonld",
            "id": element.id,
            "type": "Annotation",
            "body": element.body,
            "target": {
              "selector": element.target.selector
            }
          };

          annotations.push(obj);
        });
      }

      this.annotations = annotations;
      this.imageAnnotate.clearAnnotations();
      this.imageAnnotate.setAnnotations(annotations);
    }
  }

  onStartAnnotate() {
    if (this.type != 2) {
      try {
        document.getElementsByClassName("a9s-annotationlayer")[0].remove();
      } catch (e) { }

      this.imageAnnotate = new Annotorious({
        image: document.getElementById("annotate"),
        widgets: ['COMMENT']
      });

      this.imageAnnotate.on('createAnnotation', (annotation, overrideId) => {
        this.annotations.push(annotation);
        this.selectedItem["Annotations"] = this.annotations;
      });

      this.imageAnnotate.on('updateAnnotation', (annotation, previous) => {
        let index = this.annotations.findIndex(i => i.id == annotation.id);
        this.annotations[index] = annotation;
        this.selectedItem["Annotations"] = this.annotations;
      });

      this.imageAnnotate.on('deleteAnnotation', (annotation) => {
        this.annotations = this.annotations.filter(i => i.id != annotation.id);
        this.selectedItem["Annotations"] = this.annotations;
      });

      let annotations: any = [];

      if (this.selectedItem.Annotations) {
        this.selectedItem.Annotations?.forEach(element => {
          element.target.selector.type = "FragmentSelector";
          let obj = {
            "@context": "http://www.w3.org/ns/anno.jsonld",
            "id": element.id,
            "type": "Annotation",
            "body": element.body,
            "target": {
              "selector": element.target.selector
            }
          };

          annotations.push(obj);
        });
      }

      this.annotations = annotations;
      this.imageAnnotate.clearAnnotations();
      this.imageAnnotate.setAnnotations(annotations);
    }
  }

  calcPositionStyle() {

    let data = this.explorer.maximized ? this.explorer.max : this.explorer.normal;

    let explorerPositionStyle = {
      'width': data.width + "px",
      'height': data.height + "px",
      'top': data.positionTop + "%",
      'left': data.positionLeft + "%",
      'margin-left': data.marginLeft + 'px',
      'margin-top': data.marginTop + 'px',
      'display': this.explorer.opened ? 'block' : 'none'
    }

    return explorerPositionStyle;
  }

  selectItem(item) {
    this.selectedItem = item;

    if (this.getPreviewType() == "text") {
      this.getTextFileContent();
    }

    if (this.getPreviewType() == "pdf") {

      if (typeof (FileReader) !== 'undefined') {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.pdfSrc = e.target.result;
        };

        reader.readAsArrayBuffer(this.selectedItem.File);
      }
    }
    this.getImageUrl();
  }

  getFrendlyFileSize(size) {
    return FileUtil.getFriendlyFileSize(size);
  }

  getExtension(name) {
    var splits = name.split(".");
    return splits[splits.length - 1].toLowerCase()
  }

  searchCheckShow(item) {

    if (this.searchText.length == 0)
      return true;

    var indexOf = item.Name.toLowerCase().indexOf(this.searchText.toLowerCase());

    if (indexOf !== -1) {
      return true;
    } else {
      return false;
    }
  }

  onDrop(s, t) {
    if (this.onItemDrop != null) {

      let src = {
        id: s.Id,
        order: s.Order,
        name: s.Name
      };

      let tar = {
        id: t.Id,
        order: t.Order,
        name: t.Name
      }
      this.onItemDrop.emit({ src: src, tar: tar })
    }
  }

  checkFile(file: File): boolean {
    if (this.availableExtensions.length > 0) {
      var fileExtentionSplit = file.name.split(".");
      for (var i = 0; i < this.availableExtensions.length; i++) {
        if (fileExtentionSplit[fileExtentionSplit.length - 1].toLowerCase() == this.availableExtensions[i].toLowerCase()) {
          return true;
        }
      }

      return false;
    }
    return true;
  }

  getPreviewType() {
    if (this.selectedItem != null) {

      //image preview check
      var extension = this.getExtension(this.selectedItem.Name);
      if (this.imagesExtensions.indexOf(extension) > -1) {
        return "image";
      }

      if (extension == "pdf") {
        return "pdf"
      }

      return "text";
    }

    return "";
  }

  getImageUrl() {
    try {
      if (this.selectItem != null) {
        this.selectedImageData = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedItem.File));
        this.selectedImageDataString = URL.createObjectURL(this.selectedItem.File);
      } else {
        this.selectedImageData = "";
        this.selectedImageDataString = "";
      }
    } catch (e) {

    }
  }

  getPreviewImageUrl() {
    try {
      if (this.selectItem != null) {
        return URL.createObjectURL(this.selectedItem.File);
      }
    } catch (e) {

    }
  }

  getTextFileContent() {
    var fr = new FileReader();
    var ctrlThis = this;
    fr.onload = function () {
      ctrlThis.txtFileContent = this.result;
    }

    fr.readAsText(this.selectedItem.File);
  }

  onDownload() {
    if (this.onItemDownload != null) {
      this.onItemDownload.emit(this.selectedItem);
    }
  }

  onDelete() {
    if (this.onItemDelete != null) {
      this.onItemDelete.emit(this.selectedItem);
      this.selectedItem = null;
      this.getImageUrl();
    }
  }
}
