import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormService } from '../../services/form-service/form.service';

@Directive({
  selector: '[formLoadEvent]'
})
export class FormLoadEventDirective implements OnInit {

  @Input() formLoadEvent: any;
  @Output() formLoadEventReceived: EventEmitter<any> = new EventEmitter();

  constructor(public formService: FormService) {
  }

  ngOnInit(): void {
    if (this.formLoadEvent) {
      this.formService.preLoadEvent = this.formLoadEvent;
    }
  }

}
