import { Component, OnInit, Input } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { parse } from 'url';
import { BehaviorSubject } from 'rxjs';
import { SemConfirmDialogComponent } from '../dialogs/sem-confirm-dialog/sem-confirm-dialog.component';
import { DialogType } from 'src/app/shared/components/sem-dynamic/sem-dynamic.component';
import { RequestModel } from 'src/app/models/request/request-model';
import { isNumber } from 'lodash';

@Component({
  selector: 'sem-one-to-many-container',
  templateUrl: './sem-one-to-many-container.component.html',
  styleUrls: ['./sem-one-to-many-container.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemOneToManyContainerComponent, multi: true }
  ]
})
export class SemOneToManyContainerComponent extends ValueAccessorBase<any> implements OnInit {

  @Input() bindingPropertyName: any;
  @Input() maxCount: number = -1;
  @Input() sectionTransferUrl: any;
  @Input() sectionContent: any;
  @Input() contentTemplate: any;
  @Input() actions: any = [];
  @Input() overrideActions: any = [];
  @Input() forwardEntity: any;
  @Input() postAddNewSectionLogic: string;
  @Input() hideNewAddSection: boolean = false;
  @Input() autoAddSectionIfEmpty: boolean = false;
  @Input() showAsListing: boolean = false;
  @Input() inlineEdit: string;
  @Input() listingFields: any;
  @Input() projections: any;
  @Input() presentTableLayoutInReadonly: any;
  @Input() statisticsView: boolean = false;

  @Input() showCustomAdditionalDataFields: boolean = false;
  @Input() hideOneToManyItems: boolean = false;
  @Input() propertyNameAdditionalData: string;
  @Input() hideNewAddSectionCondition: string;	
  @Input() hideRepeaterActionsCondition: string;  	
  // JS logic that will be evaluated after remove section event	
  @Input() afterRemoveSectionLogicEvent: string; 
  @Input() customApiAdditionalDataFields: string;
  @Input() additionalFieldHideCondition: string;  

  @Input() addNewTranslation: string | null = null;
  @Input() onSearchNavigateUrl: string = "";

  @Input() containerTitle: string = "";
  @Input() hideSectionWhenNotEditing: boolean = false;
  @Input() addNewButtonOnTop: boolean = false;

  currentNumber: number = 0;
  showDD: boolean = false;
  public templateProvided: boolean = false;
  private sectionAdded: boolean = false;

  //Props for listing grid view
  public gridSection: any = null;
  public inEditMode: boolean = false;


  private additionalFields = [];

  // this property stores values true/false for each additionalfield so we know if we hide the field or not
  private additionalFieldsHiding: any = {};

  selectedIndex: number = 0;

  typeoflisting() {
    return typeof (this.showAsListing);
  }
  typeofinline() {
    return typeof (this.inlineEdit);
  }
  typeofwt() {
    return typeof (this.hideNewAddSection);
  }  super() { }

  ngOnInit() {
    this.getContentTemplate();
  }

  ngAfterContentInit() {

    if (this.presentTableLayoutInReadonly == "true") {
      this.selectedIndex = -1;
    }

    this.restProviderService.modelValueChanged.subscribe(model => {

      this.model = model;

      if (this.model != null
        && typeof this.model != "undefined"
        && this.propertyNameAdditionalData != null
        && typeof this.propertyNameAdditionalData != "undefined"
        && this.propertyNameAdditionalData.length > 0) {
        // need to convert additionalfields json string to json object for mapping features
        // in presubmit event we convert back to string json (stringify)
        if (this.model[this.propertyNameAdditionalData] != null && typeof this.model[this.propertyNameAdditionalData] != "undefined") {
          // only parse if it's not already parsed into object
          // so only if json string
          if (typeof this.model[this.propertyNameAdditionalData] === "string") {
            this.model[this.propertyNameAdditionalData] = JSON.parse(this.model[this.propertyNameAdditionalData]);
          }
        }
      }

      let toAssign = this.dfms.getValue(this.model, this.bindingPropertyName);

      
      if (this.autoAddSectionIfEmpty && !this.showAsListing && !this.autoAddSectionIfEmpty) {
        if (this.sectionAdded == false || model["LOADEDFROMAPI"]) {
          // if empty 
          if (typeof toAssign == "undefined" || toAssign == null || toAssign.length == 0) {
            this.sectionAdded = true;
            this.addSection();
          }
        }
      }

      if (this.showAsListing) {
        if (typeof toAssign != "undefined") {
          if (toAssign.length > 0) {
            this.inEditSection(toAssign.length - 1);
            this.changeDetection.detectChanges();
          }
        }
      }

      if (typeof toAssign != "undefined" && toAssign != null && toAssign.length > 0) {
        this.loadAdditionalFields(toAssign);
      }

      if (typeof (this.inlineEdit) != "undefined" && (this.inlineEdit == "true")) {
        this.gridSection = null;
        this.inEditMode = false;
      }

    });

    this.dfms._modelMTOValueChanged.subscribe(model => {

      if (this.propertyNameAdditionalData != null
        && typeof this.propertyNameAdditionalData != "undefined"
        && this.propertyNameAdditionalData.length > 0) {

        if (this.model[this.propertyNameAdditionalData] != null && typeof this.model[this.propertyNameAdditionalData] != "undefined") {
          // only parse if it's not already parsed into object
          // so only if json string
          if (typeof this.model[this.propertyNameAdditionalData] === "string") {
            this.model[this.propertyNameAdditionalData] = JSON.parse(this.model[this.propertyNameAdditionalData]);
          }
        }

         

      }else{	
        // bind to global model in case we need this in section	
        // for example we have dropdown in section which we want to filter based on some value on form - we cannot acces this value through this.model	
        // baucse each section has it's own model, so we need to go via globalModel	
        this.dfms.globalModel = model;	
      }

      if (typeof (this.inlineEdit) != "undefined" && (this.inlineEdit == "true")) {
        this.gridSection = null;
        this.inEditMode = false;
      }

    });

    if (typeof (this.inlineEdit) != "undefined" && (this.inlineEdit == "true")) {
      this.gridSection = null;
      this.inEditMode = false;
    }

  }

  checkEditRight(){
    return false;
  }

  loadAdditionalFields(listOfAdditionalData) {

    // execute only if ShowCustomAdditionalDataFields true
    if(this.showCustomAdditionalDataFields == true){
        if (listOfAdditionalData != null && typeof listOfAdditionalData != "undefined") {
          let requestModel: RequestModel = new RequestModel();

          let entityId = 0;

          if(this.model !== null && typeof this.model !== undefined 
            && this.model.Id !== null
            && this.model.Id > 0){
              entityId = this.model.Id;
            }

          if(this.customApiAdditionalDataFields){
            requestModel.url = this.customApiAdditionalDataFields;
          }else{
            requestModel.url = "/api/dynamicforms/additionaldatafields";
          }        

          if(entityId > 0){
            requestModel.url +=  "?id=" + entityId;
          }
          
          requestModel.contentType = "application/json"  
          requestModel.responseType = "application/json"  


          try{
            requestModel.data = JSON.stringify(listOfAdditionalData);
          }catch {
            requestModel.data = null;
          }

        this.restProviderService.getDataPOST<any>(requestModel).subscribe(data => {
          this.zone.run(() => {
            if (data != null && typeof data != "undefined" && data.length > 0) {
              this.additionalFields = data;

              // remove properties from model that don't exists in additionalfields anymore
              if (this.model[this.propertyNameAdditionalData] != null && typeof this.model[this.propertyNameAdditionalData] != "undefined") {
                if (this.additionalFields != null && typeof this.additionalFields != "undefined") {

                  for (let propertyBinding in this.model[this.propertyNameAdditionalData]) {

                    let index = this.additionalFields.findIndex(x => x.Binding == propertyBinding);
                    // if property doesnt exists in additionalFields then it was removed from additionalfields and we should also remove from this.model
                    // if not found
                    if (index < 0) {
                      delete this.model[this.propertyNameAdditionalData][propertyBinding];
                    }
                  }
                }
              }
            }
          });
        },
          error => {
          }
        );
      }
    }
  
  }

  fireSectionAddedEvent() {
    this.utility.newSectionAdded.next(this.model);
  }

  resolvePropertyBinding(object, propertyPath) {

    if (typeof propertyPath != "undefined" && propertyPath != null) {
      let split = propertyPath.split(".");
      let obj = object;

      for (let i = 0; i < split.length; i++) {
        if (obj != null && typeof obj != "undefined") {
          obj = obj[split[i]];
        }
      }

      if (obj != null && typeof obj != "undefined" && obj instanceof Array) {

        if (obj.length > 0 && this.inlineEdit == "false") {
          if (!this.hideSectionWhenNotEditing) {
            this.gridSection = obj[obj.length - 1];
            this.inEditMode = true;
          }

          if (typeof this.forwardEntity != "undefined" && this.forwardEntity) {
            obj.forEach(element => {
              element[this.forwardEntity] = _.cloneDeep(this.model[this.forwardEntity]);
            });
          }
        }

        return obj;
      }
    }



    return [];
  }

  modelChanged($event) {
    //console.log("one2m changed model");
  }
  async getContentTemplate() {

    /*

    let templateUrl = "";

    for (var i = 0; i < this.dynamicRoutesLoader.wgRoutes.length; i++) {
      if (this.dynamicRoutesLoader.wgRoutes[i]["PrettyUrl"] == window.location.pathname.substring(1)) {
        templateUrl = this.dynamicRoutesLoader.wgRoutes[i].Url;
        break;
      }
    }

    this.restProviderService.getDataGET(templateUrl).subscribe(async rawTemplate => {
      //console.log("Retrieved dynamic template: ", rawTemplate);
      let startParseIndex = rawTemplate.indexOf("<ng-container class=\"" + this.bindingPropertyName) + this.bindingPropertyName.length+23;
      let endParseIndex = rawTemplate.indexOf("</ng-container>",startParseIndex);
      let contentLength = endParseIndex - startParseIndex;


      this.contentTemplate = rawTemplate.substring(startParseIndex, endParseIndex);

      this.restProviderService.modelValueChanged.subscribe(model => {

        let toAssign = this.dfms.getValue(model, this.bindingPropertyName);
        if (typeof toAssign != "undefined") {
          this.currentNumber = toAssign.length;
          for (var i = 0; i < toAssign.length; i++) {
            toAssign[i]["SerializationObjectFormLink"] = this.model["SerializationObjectFormLink"];
          }
        }
  
      });

    });*/
    /*
        if(this.dynamicRoutesLoader.wgRoutes.length == 0){
          await this.dynamicRoutesLoader.loadConfig(window.location.pathname.substring(1));
        }*/

    let templateUrl = "";

    for (var i = 0; i < this.dynamicRoutesLoader.wgRoutes.length; i++) {
      if (this.dynamicRoutesLoader.wgRoutes[i]["PrettyUrl"] == window.location.pathname.substring(1)) {
        templateUrl = this.dynamicRoutesLoader.wgRoutes[i].Url;
        break;
      }
    }

    for (var i = 0; i < this.dynamicRoutesLoader.loadedRoutes.length; i++) {
      if (this.dynamicRoutesLoader.loadedRoutes[i].Url == templateUrl) {
        this.templateProvided = true;
        this.loadContent(this.dynamicRoutesLoader.loadedRoutes[i].Html);
        break;
      }
    }

    if (!this.templateProvided) {
      this.restProviderService.getDataGET(templateUrl).subscribe(async rawTemplate => {
        //console.log("Retrieved dynamic template: ", rawTemplate);
        this.loadContent(rawTemplate);

      });
    }
  }

  loadContent(rawTemplate) {
    let startParseIndex = rawTemplate.indexOf("<ng-container class=\"" + this.bindingPropertyName) + this.bindingPropertyName.length + 23;
    //let endParseIndex = rawTemplate.indexOf("</ng-container><!--" + this.bindingPropertyName + "-->", startParseIndex);
    //</ng-container></div><span class=\""+ThisSettings.BindingPropertyName + "\"></span>
    let endParseIndex = rawTemplate.indexOf("</ng-container></div><span class=\"" + this.bindingPropertyName + "\"></span>", startParseIndex);
    let contentLength = endParseIndex - startParseIndex;


    this.contentTemplate = rawTemplate.substring(startParseIndex, endParseIndex);
    this.changeDetection.detectChanges();

    this.restProviderService.modelValueChanged.subscribe(model => {

      /*let toAssign = this.dfms.getValue(model, this.bindingPropertyName);
      if (typeof toAssign != "undefined") {
        this.currentNumber = toAssign.length;
        for (var i = 0; i < toAssign.length; i++) {
          toAssign[i]["SerializationObjectFormLink"] = this.model["SerializationObjectFormLink"];
        }
      }*/
      if (typeof this.model != "undefined" && this.model) {
        let forwardEnt = this.model[this.forwardEntity];
        if (typeof forwardEnt != "undefined" && forwardEnt) {
          if (typeof this.model[this.bindingPropertyName] != "undefined") {
            for (var i = 0; i < this.model[this.bindingPropertyName].length; i++) {
              this.model[this.bindingPropertyName][i][this.forwardEntity] = forwardEnt;
            }
          }
        }
      }


    });
  }


  addSection() {

    this.dfms.globalForm.form.markAsDirty();

    /*if(typeof this.showAsListing != "undefined" && this.showAsListing && !this.gridSection){
      this.gridSection = {};
      this.changeDetection.detectChanges();
    }*/

    let toAssign = this.dfms.getValue(this.model, this.bindingPropertyName);    
    if ((typeof this.showAsListing != "undefined" && this.showAsListing && this.gridSection) || (typeof this.inlineEdit != "undefined" && this.inlineEdit)) {

      if (this.inEditMode) {
        this.inEditMode = false;
      }

      if (typeof toAssign == "undefined" || toAssign == null) {
        toAssign = [];
      }

      if (!(Array.isArray(toAssign))) {
        toAssign = [toAssign];
      }

      toAssign.push({});

      //this.gridSection = toAssign[toAssign.length - 1];

      /*this.gridSection = null;
      this.changeDetection.detectChanges();
      this.gridSection = {};*/


      //this.gridSection = toAssign[toAssign.length - 1];

      this.dfms.assign(this.model, this.bindingPropertyName, toAssign, true);
      
      //this.changeDetection.detectChanges();
      this.currentNumber = toAssign.length;
      this.inEditSection(toAssign.length - 1);


    } else {

      if (typeof toAssign == "undefined" || toAssign == null) {
        toAssign = [];
      }

      if (!(Array.isArray(toAssign))) {
        toAssign = [toAssign];
      }

      if (this.bindingPropertyName == "DeductionInformations") {
        let months = 0;
        if (this.model.PenaltyData.DurationYears) {
          months = parseInt(this.model.PenaltyData.DurationYears) * 12;
        }
        if (this.model.PenaltyData.DurationMonths) {
          months = months + parseInt(this.model.PenaltyData.DurationMonths);
        }

        if (this.model.PenaltyData.DurationDays) {
          let p = parseInt(this.model.PenaltyData.DurationDays)
          if (p > 0) {
            months = months + 1;
          }
        }

        let array: any[] = [];
        for (var i = 0; i < months; i++) {
          array.push({});
        }

        //this.dfms.assign(this.model, this.bindingPropertyName, array);
        this.model.DeductionInformations = array;
        this.currentNumber = array.length;

      } else {

        if (this.maxCount == -1 || toAssign.length < this.maxCount) {
          let objToAdd = {};

          if (typeof this.forwardEntity != "undefined" && this.forwardEntity) {
            objToAdd[this.forwardEntity] = _.cloneDeep(this.model[this.forwardEntity]);
          }

          //objToAdd["SerializationObjectFormLink"] = this.model["SerializationObjectFormLink"];
          toAssign.push(objToAdd);

          this.dfms.assign(this.model, this.bindingPropertyName, toAssign);
          this.currentNumber = toAssign.length;

          if (typeof this.showAsListing != "undefined" && this.showAsListing) {
            this.gridSection = objToAdd;
          }
        }
      }
    }


    // if PostAddNewClientLogic exists execute
    if (this.postAddNewSectionLogic != null && typeof this.postAddNewSectionLogic != "undefined") {
      let preResult = function (str) {
        return eval(str);
      }.call(this, this.postAddNewSectionLogic);
    }
  }

  removeSection(section: any) {

    this.dfms.globalForm.form.markAsDirty();

    const dialogRef = this.dialog.open(SemConfirmDialogComponent, { data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let values = this.dfms.getValue(this.model, this.bindingPropertyName);
        for (var i = 0; i < values.length; i++) {
          if (values[i] == section) {
            values.splice(i, 1);
            this.currentNumber = values.length;
            if (this.hideSectionWhenNotEditing) {
              this.gridSection = null;
            }
            break;
          }
        }

        this.fireSectionAddedEvent();
        // execute JS logic after section is removed	
        this.executeAfterRemoveSectionLogic();
      }

    });

  }

  executeAfterRemoveSectionLogic(){	
    if(this.afterRemoveSectionLogicEvent != null && typeof this.afterRemoveSectionLogicEvent != "undefined"	
        && this.afterRemoveSectionLogicEvent.length > 0){        	
          eval(this.afterRemoveSectionLogicEvent);	
        }	
  }

  inEditSection(index) {
    if (this.inEditMode) {
      this.inEditMode = false;
    }
    let toAssign = this.dfms.getValue(this.model, this.bindingPropertyName);
    this.gridSection = null;
    this.changeDetection.detectChanges();
    this.gridSection = toAssign[index];
    this.selectedIndex = index;
    this.inEditMode = true;
    this.changeDetection.detectChanges();
  }

  inRemoveSection(index) {

    const dialogRef = this.dialog.open(SemConfirmDialogComponent, { data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        var actionEval = false;
        let toAssign = [];
        toAssign = this.dfms.getValue(this.model, this.bindingPropertyName);

        if (typeof this.overrideActions != "undefined" && this.overrideActions) {
          if (this.overrideActions.length > 0) {
            for (var i = 0; i < this.overrideActions.length; i++) {
              if (this.overrideActions[i]["Type"] == 1) {
                //var evalVal = eval("function doIt(toAssign){" + this.overrideActions[i]["ActionBody"] + "} doIt(toAssign);");
                eval(this.overrideActions[i]["ActionBody"]);
                actionEval = true;
                break;
              }
            }
          }
        }

        if (!actionEval) {
          toAssign.splice(index, 1);
        }

        this.dfms.assign(this.model, this.bindingPropertyName, toAssign);

        this.gridSection = null;
        /*this.changeDetection.detectChanges();
        this.gridSection = {};*/
        this.changeDetection.detectChanges();

        this.fireSectionAddedEvent();
      }

    });
  }

  getProp(model, path) {
    if (model[this.propertyNameAdditionalData] != null && typeof model[this.propertyNameAdditionalData] != "undefined") {
      let val = model[this.propertyNameAdditionalData][path];
      return val;
    }
    return null;
  }

  setProp(path, value) {
    if (this.model[this.propertyNameAdditionalData] == null || typeof this.model[this.propertyNameAdditionalData] == "undefined") {
      this.model[this.propertyNameAdditionalData] = {};
    }
    this.model[this.propertyNameAdditionalData][path] = value;
  }

  showAddNew(){	
    	//  *ngIf="hideNewAddSection == false && !hideOneToManyItems && !checkForDisabled(bindingPropertyName) && dfms?.isFormReadOnly != true"

    if (this.hideNewAddSection == false && !this.hideOneToManyItems && this.dfms?.isFormReadOnly != true) {	
      	
      if(this.hideNewAddSectionCondition != null && typeof this.hideNewAddSectionCondition != "undefined" && this.hideNewAddSectionCondition.length > 0){	
        var evalResponse = eval(this.hideNewAddSectionCondition);	
        if(evalResponse == true){	
          return false;	
        }	
        return true;	
      }	
      return true;	
    }	
    return false;	
  }

  showRepeaterActions(){	
    if(this.hideRepeaterActionsCondition != null && typeof this.hideRepeaterActionsCondition != "undefined" && this.hideRepeaterActionsCondition.length > 0){	
      var evalResponse = eval(this.hideRepeaterActionsCondition);	
      if(evalResponse == true){	
        return false;	
      }	
      return true;	
    }	
    return true;	
  }  

 additionalFieldVisibility(currentAdditionalField){
    if(this.additionalFieldHideCondition != null){
      
      // create function based on what user wrote in control settings (additionalFieldHideCondition)
      // we will also send some parameters into function
      // model => this.model -> this is global model on the form
      // adField => current additional field that we are checking
      // adFieldsHiding => for description go check definition of property
      // if user defines hideCondition (additionalFieldHideCondition) in control settings it must set values true/false to property "adFieldsHiding" in his code - only way fields will be hidden
      var func = Function("adField", "adFieldsHiding", "model", this.additionalFieldHideCondition);

      // call function      
      func(currentAdditionalField, this.additionalFieldsHiding, this.model);
            
    }else{
      this.additionalFieldsHiding[currentAdditionalField.Binding] = true;
    }

    // functions saves true or false into this object, based on value additional field is then shown/hidden    
    return this.additionalFieldsHiding[currentAdditionalField.Binding];
  }

  searchPageRedirect(section, lf) {
    let value = this.dfms.getValue(section, lf.Binding);

    if(value){
      value = value.toLocaleString('sl-SI');
    }

    //window.location.href = "cms/galisweb/search?view=1&search=" + value + "*";
    this.searchService.splitSearchEnabled = true;

    let bindingProp = this.bindingPropertyName + "." + lf.Binding;


    let e = this.searchService.splitSearchKeywords.filter(i=>i.bindingProperty == bindingProp);

    if(e.length > 0){
      e[0].value = value;
    }else{
      this.searchService.splitSearchKeywords.push({value:value,bindingProperty:bindingProp, name:this.translationsService.translate(lf.Name)});
    }
  }

  checkValueTypeForDecimal(value: any) {
    try {
      if (value && value.length > 0) {
        if (!isNaN(value)) {
          return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
        } else {
          return value;
        }
      }
      return value;
    } catch (e) {
      console.log(e);
      return value;
    }
  }

}
