import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SemManyToOneControlComponent } from '../../sem-many-to-one-control/sem-many-to-one-control.component';
import { RestProviderService } from '../../../../services/rest-provider/rest-provider.service';
import { RequestModel } from '../../../../models/request/request-model';
import { SearchService } from 'src/app/services/search/search.service';
import { SemTextBoxComponent } from '../../sem-text-box/sem-text-box.component';
import { SemControlComponent } from '../../sem-control/sem-control.component';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef, Inject, ViewChild, ContentChild } from '@angular/core';
import * as _ from 'lodash';
import { DynamicFieldsManagerService } from 'src/app/services/dynamic-fields/dynamic-fields-manager.service';
import { ActionsService } from 'src/app/services/actions-service/actions.service';
import { threadId } from 'worker_threads';
import { SemImageViewerComponent } from '../../sem-image-viewer/sem-image-viewer.component';


@Component({
  selector: 'app-sem-dialog',
  templateUrl: './sem-dialog.component.html',
  styleUrls: ['./sem-dialog.component.css']
})
export class SemDialogComponent implements OnInit, AfterViewInit {

  entityModel: string;
  propName: string;
  linkWithExistingData: string;
  title: string;

  loading: boolean = false;
  pageSize: number = 5;
  findTheRecord: string;
  enterRecordId: string;
  resultsFound: string;
  recordName: string;
  idNumber: string;
  dateOfCreaton: string;
  link: string;
  searchFields: any[] = [];
  displayFields: any[] = [];
  additionalSearchFilter;
  vsaPoljaSearchValue: string = null;
  popupSearchValue: string = "";
  tooltipTextPoVseh: string = null;
  repositories: string = null;
  defaultSearchQueryString: string = "{\"MainQuery\":null,\"FieldQueries\":{},\"FacetQueries\": {},\"RequestedFacets\": null,\"Projection\": [],\"TreeQuery\": { \"Type\": \"FieldValue\", \"FieldName\": \"*\", \"FieldValue\": \"*\" },\"ClassTypes\": [],\"Page\": \"1\",\"PageSize\":" + this.pageSize + ",\"RepositoryName\": \"\",\"Form\": null,\"UseCache\": false}";
  mainSearchQuery: any;  

  models: any[] = [];
  modelsToDisplay: any[] = [];
  loaded: boolean = false;

  selectedPage: number;
  searchValues: object = {};
  currentFormModel: any = null;
  errorMessage: any = null;

  // used if searchFields is populated
  private customORQuery: any = null;

  private searchResult: any = null;

  private searchServiceSubscriber: any = null;

  //@ContentChild('nav',{static:true} ) ds!: DragScrollComponent;
  ds: any;

  totalItems: number = 0;
  previousPage = 0;
  nextPage = 0;
  totalPages = 1;
  pages: any[] = [];


  @ViewChild('nav')
  set pane(v: DragScrollComponent) {
    setTimeout(() => { this.ds = v; }, 0);
  }

  constructor(
    public dialogRef: MatDialogRef<SemManyToOneControlComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private restProvider: RestProviderService,
    private changeDet: ChangeDetectorRef,
    public searchService: SearchService,
    public formActionService: ActionsService,
    public translationService: TranslationsProviderService,
    public dfms: DynamicFieldsManagerService) {

  }

  ngOnDestroy(): void {
    if (typeof this.searchServiceSubscriber != "undefined" && this.searchServiceSubscriber != null) {
      this.searchServiceSubscriber.unsubscribe();
    }
  }

  ngOnInit() {

    this.mainSearchQuery = JSON.parse(this.defaultSearchQueryString);

    if (this.dialogData["tooltipTextPoVseh"] != null && typeof this.dialogData["tooltipTextPoVseh"] != "undefined" && this.dialogData["tooltipTextPoVseh"].length > 0) {
      this.tooltipTextPoVseh = this.dialogData["tooltipTextPoVseh"];
    }

    if (this.dialogData["repositories"] != null && typeof this.dialogData["repositories"] != "undefined" && this.dialogData["repositories"].length > 0) {
      this.repositories = this.dialogData["repositories"];
    }

    // fields that are displayed in table
    this.displayFields = this.dialogData["displayFields"];
    if (this.displayFields == null || typeof this.displayFields == "undefined" || this.displayFields.length == 0) {
      // set default
      this.displayFields = [];
      this.displayFields.push({ "Title": this.translationService.translate("Title"), "PropertyName": "Title", "Type": "Text" });
    }

    this.searchFields = this.dialogData["searchFields"];
    this.additionalSearchFilter = this.dialogData["additionalSearchFilter"];
    this.currentFormModel = this.dialogData["model"];

    this.setSearchFields();

    // based on display fields set projection on query
    if (this.mainSearchQuery != null && typeof this.mainSearchQuery != "undefined") {

      let displayFieldsForProjection = [];

      for (var i = 0; i < this.displayFields.length; i++) {
        displayFieldsForProjection.push(this.displayFields[i].PropertyName);
      }

      this.mainSearchQuery["Projection"] = Object.assign([], displayFieldsForProjection);
      // always add "Id" to projection
      this.mainSearchQuery.Projection.push("Id");
      this.mainSearchQuery.Projection.push("SourceRepository");
      this.mainSearchQuery.Projection.push("RepositoryId");
    }

    this.linkWithExistingData = this.translationService.translations["WebGalis_LinkWithExistingData"];

    this.title = this.translationService.translate(this.dialogData["title"]);
    this.entityModel = this.dialogData["entityModel"];
    this.propName = this.dialogData["propName"];

    this.findTheRecord = this.translationService.translations["WebGalis_FindTheRecord"];
    this.enterRecordId = this.translationService.translations["WebGalis_EnterRecordId"];
    //this.resultsFound = TRANSLATIONS["WebGalis_ResultsFound"];
    this.recordName = this.translationService.translations["WebGalis_RecordName"];
    this.idNumber = this.translationService.translations["WebGalis_IDNumber"];
    this.dateOfCreaton = this.translationService.translations["WebGalis_DateOfCreation"];
    this.link = this.translationService.translations["WebGalis_Link"];
  }

  showDataItemTooltiptext(dataItem) {
    
    if (dataItem !== null && dataItem !== undefined
      && dataItem.TooltipText !== null
      && dataItem.TooltipText !== undefined && dataItem.TooltipText.length > 0) {

      return true;

    }

    return false;
  }

  setSearchFields() {
    this.errorMessage = null;
    // if searchFields are present then tree query is changed (not * anymore)
    // it will search for all those fields that are in searchFields (OR between)
    if (this.searchFields != null || typeof this.searchFields != "undefined") {
      if (this.searchFields.length > 0) {

        this.customORQuery = JSON.parse("{\"Type\": \"AND\", \"SubQueries\": []}");

        for (let i = 0; i < this.searchFields.length; i++) {
          let field = null;

          if (this.searchFields[i].Type == 'Date') {

            field = {
              "Type": "FieldRange",
              "FieldName": this.searchFields[i].PropertyName
            }

            if (this.searchValues[this.searchFields[i].PropertyName + "From"] != "undefined" && this.searchValues[this.searchFields[i].PropertyName + "From"] != "" && this.searchValues[this.searchFields[i].PropertyName + "From"] != null) {
              //var date = new Date(this.searchValues[this.searchFields[i].PropertyName + "From"]);

              field["FieldValueFrom"] = this.searchValues[this.searchFields[i].PropertyName + "From"];

              //field["FieldValueFrom"] = new Date(date.setDate(date.getDate() - 1)).toISOString();
              //field["FieldValueTo"] = new Date(date.setDate(date.getDate() + 2)).toISOString();
            } else {
              field["FieldValueFrom"] = "*";
              //field["FieldValueTo"] = "*";
            }

            if (this.searchValues[this.searchFields[i].PropertyName + "To"] != "undefined" && this.searchValues[this.searchFields[i].PropertyName + "To"] != "" && this.searchValues[this.searchFields[i].PropertyName + "To"] != null) {
              field["FieldValueTo"] = this.searchValues[this.searchFields[i].PropertyName + "To"];
            } else {
              field["FieldValueTo"] = "*";
            }

          } else if (this.searchFields[i].Type == 'CB') {

            field = {
              "Type": "FieldValue",
              "FieldName": this.searchFields[i].PropertyName,
              "FieldValue": "*"
            }

            if (this.searchValues[this.searchFields[i].PropertyName] == true) {
              field.FieldValue = 1;
            } else if (this.searchValues[this.searchFields[i].PropertyName] == false) {
              field.FieldValue = 0;
            }

          } else {

            field = {
              "Type": "FieldValue",
              "FieldName": this.searchFields[i].PropertyName,
              "FieldValue": "*"
            }

            if (this.searchValues[this.searchFields[i].PropertyName] != "undefined" && this.searchValues[this.searchFields[i].PropertyName] != null && this.searchValues[this.searchFields[i].PropertyName] != "") {
              field.FieldValue = this.searchValues[this.searchFields[i].PropertyName] + "*";
            }

          }

          this.customORQuery.SubQueries.push(
            field
          );

        }
      }
    }
  }

  ngAfterViewInit(): void {

    if (typeof (this.entityModel) == "undefined" || !this.entityModel) {
      this.entityModel = this.dialogData["entityModel"];
    }

    this.mainSearchQuery["ClassTypes"].push(this.entityModel);
    this.performLinkedDataSearch(1);
  }

  onKeydown(event) {
    event.preventDefault();
    this.selectedPage = 1;
    this.search(1);
  }

  performLinkedDataSearch(page) {
    this.errorMessage = null;
    this.selectedPage = page;
    this.models = [];
    this.loading = true;


    if (this.customORQuery != null) {
      this.mainSearchQuery["TreeQuery"] = this.customORQuery;
    }

    _.templateSettings.interpolate = /\${([\s\S]+?)}/g;

    if (this.additionalSearchFilter != null && typeof this.additionalSearchFilter != "undefined" && this.additionalSearchFilter.length > 0) {
      try {
        if (this.currentFormModel != null && typeof this.currentFormModel != "undefined") {
          // interpolate
          let compiled = _.template(this.additionalSearchFilter);
          let compiledSearchQuery = compiled(this.currentFormModel);
          if (compiledSearchQuery != null && typeof compiledSearchQuery != "undefined") {
            let interpolateMainSearchQueryObject = JSON.parse(compiledSearchQuery);

            let currentTreeQuery = this.mainSearchQuery["TreeQuery"];
            let newMainTreeQuery = { "Type": "AND", "SubQueries": [] };
            newMainTreeQuery.SubQueries.push(interpolateMainSearchQueryObject);
            newMainTreeQuery.SubQueries.push(currentTreeQuery);
            this.mainSearchQuery["TreeQuery"] = newMainTreeQuery;
          }
        }
      } catch {
      }
    }

    if (this.vsaPoljaSearchValue != null && typeof this.vsaPoljaSearchValue != "undefined" && this.vsaPoljaSearchValue.length > 0) {
      let currentTreeQuery = this.mainSearchQuery["TreeQuery"];
      let newMainTreeQuery = { "Type": "AND", "SubQueries": [] };
      newMainTreeQuery.SubQueries.push({
        "Type": "FieldValue",
        "FieldName": "*",
        "FieldValue": this.vsaPoljaSearchValue
      });
      newMainTreeQuery.SubQueries.push(currentTreeQuery);
      this.mainSearchQuery["TreeQuery"] = newMainTreeQuery;
    }

    this.mainSearchQuery["Page"] = page;

    if (this.repositories != null) {
      this.mainSearchQuery.RepositoryName = this.repositories;
    } else {
      // make lucene default if nothing is defined (as it should be)    
      this.mainSearchQuery.RepositoryName = "lucene";
    }

    if (this.dialogData.projection) {

      this.dialogData.projection.forEach(element => {
        this.mainSearchQuery["Projection"].push(element);
      });

    }


    this.searchServiceSubscriber = this.searchService.performSearch(this.mainSearchQuery, null, null, null).subscribe(data => {

      if (data["Models"].length == 0) {
        this.resultsFound = this.translationService.translations["WebGalis_NoResults"];
        this.loaded = false;
      } else {
        this.resultsFound = this.translationService.translations["WebGalis_ResultsFound"];
      }

      //this.numberOfPages = [];
      //var pages = Math.ceil(data["TotalCount"] / 5);
      //this.numberOfPages = Array.apply(null, { length: pages + 1 }).map(Number.call, Number);
      //this.numberOfPages = this.numberOfPages.splice(1, pages + 1);



        this.pages = [];

        // convert to int      
      let totalCountNumber = Math.ceil(data["TotalCount"]);
        let currentPage = page;

        if (totalCountNumber == 0) {
          this.pages.push({ "Number": 1, "Active": true });
        } else {

          var ps: number = +this.pageSize;
          let pageNum = (totalCountNumber + ps - 1) / ps;
          pageNum = Math.floor(pageNum);

          let from = 0;
          let to = pageNum;
          this.totalPages = pageNum;

          from = currentPage - 2;
          if (from <= 0) {
            from = 1;
          }

          to = from + 5;

          if (to > pageNum) {
            to = pageNum + 1;
          }

          this.previousPage = currentPage - 1;
          if (this.previousPage <= 0) {
            this.previousPage = 0;
          }

          this.nextPage = currentPage + 1;
          if (this.nextPage > pageNum) {
            this.nextPage = 0;
          }

          for (let i = from; i < to; i++) {

            let active = false;
            if (i == currentPage) {
              active = true;
            }

            this.pages.push({ "Number": i, "Active": active });
          }
        }

      



      for (var i = 0; i < data["Models"].length; i++) {
        this.models.push(data["Models"][i]["Model"]);
      }

      this.modelsToDisplay = this.models;

      this.loading = false;
      this.loaded = true;

    }, error => {
      //console.log(error);
      this.loading = false;
      this.resultsFound = this.translationService.translations["WebGalis_NoResults"];
    });
  }

  linkClicked(data) {

    this.errorMessage = null;
    this.loading = true;
    this.evalSelectedOption(data);

  }

  formActionResult(data: any, error: any, context: any) {
    if (data) {
      context.dialogRef.close(context.searchResult);
    } else {
      let splited = context.dialogData.onValueSelectValidation.split(":");
      context.errorMessage = context.translationService.translate(splited[2]);
    }
  }

  evalSelectedOption(data) {
    var url = "";

    if (this.propName.length == 0) {
      this.propName = "_";
    }

    if (data["Id"] > 0) {
      url = "api/data/relatedentity/" + this.dialogData["formName"] + "/" + this.propName + "/" + this.entityModel + "/" + data["Id"];
    } else {
      //relatedentity/external/{formInfo}/{propName}/{sourcerepository}/{repositoryid}
      url = "api/data/relatedentity/external/" + this.dialogData["formName"] + "/" + this.dialogData["propName"] + "/" + data["SourceRepository"] + "/" + data["RepositoryId"];
    }

    this.restProvider.getDataGET(url).subscribe(data => {
      let jsonData = JSON.parse(data);
      this.searchResult = jsonData["Model"];

      if (this.dialogData.onValueSelectValidation.length > 0) {
        if (this.dialogData.onValueSelectValidation.includes("formAction")) {
          let splited = this.dialogData.onValueSelectValidation.split(":");
          this.formActionService.executeAction(this.formActionService.getAction(splited[1]), {}, jsonData["Model"], this.formActionResult, null, this, null);
        }
      } else {
        this.dialogRef.close(jsonData["Model"]);
      }

      this.loading = false;
    }, error => { this.loading = false; });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  search(page) {
    this.errorMessage = null;
    this.setSearchFields();
    this.loaded = false;

    if (page == null || typeof page == "undefined") {
      page = 1;
    }

    if (this.popupSearchValue != "" && this.popupSearchValue != "*") {

      // if customORTreeQuery is not null, replace all fieldvalues with current search value
      if (this.customORQuery == null) {
        this.mainSearchQuery["TreeQuery"]["FieldValue"] = this.popupSearchValue + "*";
      } else {
        this.mainSearchQuery["TreeQuery"] = this.handleORTreeQuery(this.customORQuery, this.popupSearchValue);
      }
    }
    else {
      if (this.customORQuery == null) {
        this.mainSearchQuery["TreeQuery"]["FieldValue"] = "*";
      } else {
        this.mainSearchQuery["TreeQuery"] = this.customORQuery;//this.handleORTreeQuery(this.customORQuery, "*");
      }
    }

    this.performLinkedDataSearch(page);
  }

  private handleORTreeQuery(ORTreeQuery: any, popupSearchVal: string) {

    if (ORTreeQuery != null && typeof ORTreeQuery != "undefined") {
      for (let i = 0; i < ORTreeQuery.SubQueries.length; i++) {
        ORTreeQuery.SubQueries[i].FieldValue = popupSearchVal+"*";
      }
    }
    return ORTreeQuery;
  }

  pageChanged(number) {

    this.errorMessage = null;
    this.loaded = false;
    this.selectedPage = number;
    this.search(number);
  }

  toLastPage() {
    this.errorMessage = null;
    this.loaded = false;

    if (this.totalPages < 1) {
      this.totalPages = 1;
    }

    this.selectedPage = this.totalPages;
    this.search(this.selectedPage);
  }

  toFirstPage() {
    this.errorMessage = null;
    this.loaded = false;
    this.selectedPage = 1;
    this.search(1);
  }  

  resolvePropertyBinding(object, propertyPath) {
    return this.dfms.getValue(object, propertyPath);
  }

  getProp(prop) {
    try {
      return this.searchValues[prop];
    } catch (e) {
      return "";
    }
  }

  setProp(prop, value) {
    try {
      this.searchValues[prop] = value;
    } catch (e) {
    }
  }

  private dataURLtoFile(dataurl, filename) {
    this.loading = true;
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    this.loading = false;
    return new File([u8arr], filename, { type: mime });
  }

  imageClick(imageComponent:SemImageViewerComponent){
    let file = this.dataURLtoFile("data:text/plain;base64," + imageComponent.base64, imageComponent.name);
    var l = {file:file,mediaId:imageComponent.mediaId,name:imageComponent.name};
    this.dialogRef.close(l);
  }
}
