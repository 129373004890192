import { RootModel } from '../root-model';

export class NavigationNodeModel extends RootModel {

  public Title: string = "";
  public Icon: string = "";
  public Type: number = 0;
  public Url: string;
  public Selected: boolean = false;
  public Children: NavigationNodeModel[];
  public Id: number = 0;
  public Payload: string = "";
  public Provider: string = "";
  public ClientId: string = "";
  public Version: string = "";
  public HttpRequestType: string = "";
  public BreadCrumbs: any[] = [];

  super() {
  }

}

export class Url {
  Url: string;
  Type: number;
  Class: string;
}
