import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import * as d3 from "d3";
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { SemControlComponent } from '../sem-control/sem-control.component';

@Component({
  selector: 'app-sem-graph-bar',
  templateUrl: './sem-graph-bar.component.html',
  styleUrls: ['./sem-graph-bar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SemGraphBarComponent extends SemControlComponent implements AfterViewInit {

  @ViewChild("containerChart",{ read: ElementRef, static:true }) element: ElementRef;

  private host: any;
  private htmlElement: HTMLElement;
  private canvas: any;
  private margin: any = { top: 20, right: 20, bottom: 30, left: 50 };
  private width: number;
  private height: number;

  private data = [];

  ngAfterViewInit() {
    this.htmlElement = this.element.nativeElement;
    this.host = d3.select(this.htmlElement);
    this.data = this.model;
    this.setup();
    this.buildSVG();
  }

  private setup(): void {
    this.width = 960 - this.margin.left - this.margin.right,
      this.height = 500 - this.margin.top - this.margin.bottom;

  }

  private buildSVG(): void {
    this.host.html("");
    this.canvas = this.host.append("svg").attr("width", 960).attr("height", 500);
    let self = this;

    var g = this.canvas.append("g").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
    var parseTime = d3.timeParse("%d-%b-%y");

    var x = d3.scaleBand().rangeRound([0, this.width]).padding(0.1);
    var y = d3.scaleLinear().rangeRound([this.height, 0]);

    x.domain(this.data.map(function (d) {
      return d.Country;
    }));

    y.domain([0, d3.max(this.data, function (d) {
      return d.Count;
    })]);

    g.append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(x))

    g.append("g")
      .call(d3.axisLeft(y))
      .append("text")
      .attr("fill", "#000")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", "0.71em")
      .attr("text-anchor", "end")
      .text("Number of prisoners");

    g.selectAll(".bar")
      .data(this.data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", function (d) {
        return x(d.Country);
      })
      .attr("y", function (d) {
        return y(d.Count);
      })
      .attr("width", x.bandwidth())
      .attr("height", function (d) {
        return self.height - y(d.Count);
      });

  }

}
