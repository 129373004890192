import { RootModel } from '../root-model';
import { Injectable } from "@angular/core";

@Injectable()
export class PaginationModel {

  public totalCount: number;
  public page: number;

  constructor(totalCount: number, page: number) {
      this.totalCount = totalCount;
      this.page = page;
  }

}
