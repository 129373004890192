export interface ChatItem{
    id:number;
    text:string;
    itemType:ItemType;
    itemFunctionalType:ItemFunctionalType;
    redirectUrl:string | null;
    response:any;
}


export enum ItemType{
    UserInput = 0,
    ResponseMessage = 1
}

export enum ItemFunctionalType{
    Text = 0,
    WgCmdConfirmation = 1,
    RegistryConfirmation = 2,
    OpenRecord = 3,
    SearchResult = 4,
    Error = 99
}