import { Component, OnInit, Input, AfterViewInit, ViewEncapsulation, ViewChild, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { DataGridColumn } from '../../../models/datagrid/data-grid-column';
import { SemBaseComponent } from '../../common/sem-base/sem-base.component';
import { retry } from '../../../../../node_modules/rxjs/operators';
import { UserSearchSettingModel } from '../../../models/search/user-search-setting-model';
import { RequestModel } from '../../../models/request/request-model';
import { DynamicLoadingUtil } from '../../../shared/helpers/dynamic-loading-util';
import { MetaModelService } from '../../../services/metamodel/metamodel-provider.service';
import { UserSearchSettingPropertyModel } from '../../../models/search/user-search-setting-property-model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProjectionFacetWrapperModel } from 'src/app/models/search/facet-projection-wrapper.model';
import { FacetViewModel } from 'src/app/models/search/facet-view.model';
import { PaginationModel } from 'src/app/models/search/pagination.model';
import { DataGridSortingFlag } from '../../../models/datagrid/data-grid-column-sorting-flag';
import { SortFieldModel } from 'src/app/models/search/sort-field.model';
import { SemGridDisplayFieldsDialogComponent } from '../dialogs/sem-grid-display-fields-dialog/sem-grid-display-fields-dialog.component';
import { AdditionalSearchSettingsModel } from 'src/app/models/search/additional-search-settings.model';
import { SemDynamicControlComponent } from '../shared/sem-dynamic-control/sem-dynamic-control.component';
import { SemGenerateDialogComponent } from '../dialogs/sem-generate-dialog/sem-generate-dialog.component';
import { format } from 'url';
import { SemParameterDialogComponent } from '../dialogs/sem-parameter-dialog/sem-parameter-dialog.component';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'sem-data-grid',
  templateUrl: './sem-data-grid.component.html',
  styleUrls: ['./sem-data-grid.component.css']
})
export class SemDataGridComponent extends SemControlComponent implements OnInit, AfterViewInit {

  @Input() columns: Array<DataGridColumn>;
  @Input() bindingPropertyName: any;
  @Input() gridSettings: any;
  @Input() editable: string = "True";

  @ViewChild("dynComp", { read: SemDynamicControlComponent }) dynComp: SemDynamicControlComponent;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.searchService.currentPreviewItemClicked = null;
  }

  private originalGridColumnSettings: any;
  private currentSortFields: SortFieldModel[] = [];

  private previewActions: any = [];

  // user's search settings
  private userGridSettings: UserSearchSettingModel;
  private metamodelGridFields: any = [];

  private gridAddedFields: any = [];
  private gridOriginalFieldsList: any = [];
  private gridAvailibleFields: any = [];
  private gridOriginalAddedFielsList: any;
  private currentMetaModel: any;
  private bulkActionPresent: boolean = false;
  private enableFacetsSubs: any;
  private checkedBulkItems: any[] = [];
  private checkedBulkItemStatus: any = {};
  private searchSettingsSubs: any;
  private checkboxAllItemsChecked: boolean = false;
  private additionalSearchSettings: AdditionalSearchSettingsModel;
  private showPreview: boolean = false;
  private previewModel = null;
  private defaultSorter: SortFieldModel = null;
  private restoreGlobal: boolean = false;
  private facetsCollapsed: boolean = false;
  private viewType: string = "list";

  totalItems: number = 0;
  previousPage = 0;
  nextPage = 0;
  totalPages = 1;
  pages: any[] = [];
  helperColumnFlags: DataGridSortingFlag = {};
  additionalListingFields: any[] = [];
  numberOfPagesValue: any;
  maxNumberOfPages:number = 100;
  uiPages?:string[]=[];

  private metamodelSubs = null;
  private paginationSubs = null;
  private bulkActionPresentSubs = null;
  private searchPerformedSubs = null;
  private additionalSearchSettingsSubs = null;
  private ddShown = false;
  private stateModel = null;
  private gridFieldsRestoreSubs = null;
  private facetsCollapsedSubs = null;
  private currentEnviromentSettings;

  super() {

  }

  ngOnDestroy(): void {
    this.searchService.changePaginationInfo(undefined);
    this.searchService.changeSearchProjectionAndFacets(undefined);

    if (typeof this.metamodelSubs != "undefined" && this.metamodelSubs != null) {
      this.metamodelSubs.unsubscribe();
    }

    if (typeof this.paginationSubs != "undefined" && this.paginationSubs != null) {
      this.paginationSubs.unsubscribe();
    }

    if (typeof this.additionalSearchSettingsSubs != "undefined" && this.additionalSearchSettingsSubs != null) {
      this.additionalSearchSettingsSubs.unsubscribe();
    }

    this.searchService.changeAvailibleFacets(undefined);
    if (typeof this.enableFacetsSubs != "undefined" && this.enableFacetsSubs != null) {
      this.enableFacetsSubs.unsubscribe();
    }

    this.actionsService.setCurrentBulkActionPresent(undefined);
    if (typeof this.bulkActionPresentSubs != "undefined" && this.bulkActionPresentSubs != null) {
      this.bulkActionPresentSubs.unsubscribe();
    }

    this.searchService.setCurrentCheckedBulkDocuments([]);
    if (typeof this.searchPerformedSubs != "undefined" && this.searchPerformedSubs != null) {
      this.searchPerformedSubs.unsubscribe();
    }

    this.searchService.changeCurrentSearchSettings(undefined, undefined);
    if (typeof this.searchSettingsSubs != "undefined" && this.searchSettingsSubs != null) {
      this.searchSettingsSubs.unsubscribe();
    }

    this.searchService.setGridRestoredDefault(undefined);
    if (typeof this.gridFieldsRestoreSubs != "undefined" && this.gridFieldsRestoreSubs != null) {
      this.gridFieldsRestoreSubs.unsubscribe();
    }

    this.utility.setFacetsCollapsed(false);
    if (typeof this.facetsCollapsedSubs != "undefined" && this.facetsCollapsedSubs != null) {
      this.facetsCollapsedSubs.unsubscribe();
    }
  }


  ngOnInit() {

    super.ngOnInit();
    this.currentEnviromentSettings = environment;

    this.loading = true;

    // pageSize is set in SemSearchComponent via settings, if not set via settings then default value is used (5)
    this.numberOfPagesValue = "" + this.searchService.pageSize;





    let nop = environment.maxRecordNumberPerPageListing;
    if(nop){
      this.maxNumberOfPages = nop;
    }

    for(var i=5;i<=this.maxNumberOfPages;i+=5){
      this.uiPages?.push(i.toString());
    }





    // init default values
    this.additionalSearchSettings =
    {
      quickViewEnabled: false,
      onlyLastNodeNames: false,
      showActionsColumn: true,
      showItemNumber: false,
      quickViewFormName: "",
      LayoutName: ""
    };

    var defaultHeaderTextTranslation = this.translationsService.translate('WebGalis_Core_Title');
    // save original grid settings columnns to property (if user restores to default then this will show)
    // original grid columns settings is Title
    this.originalGridColumnSettings = [
      {
        "HeaderText": defaultHeaderTextTranslation,
        "Width": 100,
        "BindingField": "Title"
      }
    ];

    // if no columns in gridsettins (no settings in db) then set gridSettings.columns as default (Title)
    if (this.gridSettings != null) {
      //if (this.gridSettings.Columns == null || this.gridSettings.Columns.length < 0 || (this.gridSettings.Columns[0].BindingField == "Model" && this.gridSettings.Columns[0].HeaderText == "Default")) {
      this.gridSettings.Columns = this.originalGridColumnSettings.map(x => Object.assign({}, x));
      //}
    }

    let viewId = this.params["view"];

    if (typeof viewId == "undefined") {
      viewId = 0;
    }

    this.gridFieldsRestoreSubs = this.searchService.currentgridRestoredDefault.subscribe(value => {

      if (value != null && typeof value != "undefined" && value == true) {
        this.restoreGlobal = true;
        this.handleUserAdditionalGridFields(this.params["model"], viewId, this.currentMetaModel, true);
      }
    });

    this.facetsCollapsedSubs = this.utility.currentFacetsCollapsed.subscribe(value => {

      if (value != null && typeof value != "undefined") {
        this.facetsCollapsed = value;
      }
    });

    this.bulkActionPresentSubs = this.actionsService.currentBulkActionPresent.subscribe(value => {
      if (value != null && typeof value != "undefined") {
        this.bulkActionPresent = value;
      }
    });

    this.metamodelSubs = this.searchService.currentMetaModel.subscribe(value => {

      if (typeof value != "undefined") {
        // get current user search field settings (if they exist)
        this.handleUserAdditionalGridFields(this.params["model"], viewId, value);
        this.currentMetaModel = value;
      }
    });


    this.paginationSubs = this.searchService.currentPagination.subscribe(value => {

      if (value != null && typeof value != "undefined") {

        this.totalItems = value.totalCount;
        this.pages = [];

        // convert to int      
        let totalCountNumber = value.totalCount;
        let currentPage = value.page;

        if (totalCountNumber == 0) {
          this.pages.push({ "Number": 1, "Active": true });
        } else {

          var ps: number = +this.searchService.pageSize;
          this.numberOfPagesValue = "" + ps;
          let pageNum = (totalCountNumber + ps - 1) / ps;
          pageNum = Math.floor(pageNum);

          let from = 0;
          let to = pageNum;
          this.totalPages = pageNum;

          from = currentPage - 2;
          if (from <= 0) {
            from = 1;
          }

          to = from + 5;

          if (to > pageNum) {
            to = pageNum + 1;
          }

          this.previousPage = currentPage - 1;
          if (this.previousPage <= 0) {
            this.previousPage = 0;
          }

          this.nextPage = currentPage + 1;
          if (this.nextPage > pageNum) {
            this.nextPage = 0;
          }

          for (let i = from; i < to; i++) {

            let active = false;
            if (i == currentPage) {
              active = true;
            }

            this.pages.push({ "Number": i, "Active": active });
          }
        }

      }
    });

    this.searchPerformedSubs = this.searchService.currentSearchExecuted.subscribe(value => {

      if (typeof value != "undefined" && value != null) {
        // if search was performed
        // clear all checked document ids for bulk action
        this.checkedBulkItems = [];
        this.checkedBulkItemStatus = {};
      }
    });

    this.enableFacetsSubs = this.searchService.currentEnableFacets.subscribe(enableFacets => {

      if (enableFacets != null && typeof enableFacets != "undefined") {
        // change control settings for data grid
        // this property (from db table) has higher priority than control setting   
        this.gridSettings.ResizeGridForFacetsControl = enableFacets;
      }
    });

    this.searchSettingsSubs = this.searchService.currentUserSearchSettings.subscribe(value => {

      // only if user settings were changed from search control
      if (typeof value != "undefined" && value != null && value["Control"] == "search") {
        this.setNewUserSearchSettingObject(this.params["model"], viewId);
      }
    });


    this.additionalSearchSettingsSubs = this.searchService.currentAdditionalSearchSettings.subscribe(settings => {

      if (settings != null && typeof settings != "undefined") {
        this.additionalSearchSettings = settings
        //console.log("additional search settings:" + this.additionalSearchSettings);
      }
    });

    this.activatedRoute.paramMap.subscribe(data => {
      this.stateModel = window.history.state;
      this.stateModel = this.utility.checkStateForValidity(window.location.href, this.stateModel);
    });
    
  }

  toggleViewType(type) {

    this.loading = true;

    this.userGridSettings.LayoutName = type;
    this.userService.currentLayout = type;

    localStorage.setItem("CURRENT_LAYOUT_" + this.userGridSettings.ModelName, type);

    DynamicLoadingUtil.loader.showLoader = true;

    let reqModel: RequestModel = new RequestModel;

    reqModel.data = {
      "UserSearchSettingData": this.userGridSettings
    };
    reqModel.url = "api/user/settings/updatelayout";

    this.restProviderService.getDataPOST(reqModel).subscribe(data => {

      if(this.viewType.includes('grid') && type.includes('grid')){
        this.viewType = type;
        this.loading = false;
        DynamicLoadingUtil.loader.showLoader = false;
        return;
      }else{
        this.viewType = type;

        let viewId = this.params["view"];

        if (typeof viewId == "undefined") {
          viewId = 0;
        }

        this.handleUserAdditionalGridFields(this.params["model"], viewId, this.currentMetaModel, true);
      }
    },
      error => {
        DynamicLoadingUtil.loader.showLoader = false;
        this.loading = false;
      });
  }

  getGridRowClassList(rowData: any) {
    let classList = ["grid-table-row"];

    if (typeof this.userGridSettings != "undefined" && this.userGridSettings) {
      if (typeof this.userGridSettings.ConditionEvaluations != "undefined" && this.userGridSettings.ConditionEvaluations) {
        for (var i = 0; i < this.userGridSettings.ConditionEvaluations.length; i++) {

          if (this.userGridSettings.ConditionEvaluations[i]["ConditionEval"].length > 0) {
            if (eval(this.userGridSettings.ConditionEvaluations[i]["ConditionEval"])) {
              classList.push(this.userGridSettings.ConditionEvaluations[i]["CssClass"])
            }
          }
        }
      }
    }

    return classList;
  }

  ngAfterViewInit(): void {

  }

  modelChanged(model) {

  }

  calculateItemNumber(index) {
    let indx = this.pages.findIndex(x => x.Active == true);

    if (indx >= 0) {
      let currentPageNum = this.pages[indx].Number;
      if (currentPageNum == 1) {
        return index + 1;
      } else {
        var ps: number = + this.searchService.pageSize;
        return ((currentPageNum - 1) * ps) + (index + 1);
      }
    }

    return 1;
  }

  
  linkClick(event, dataItem, propPath) {
    if (dataItem != null && typeof dataItem != "undefined" && propPath != null && typeof propPath != "undefined") {
      var value = this.resolvePropertyBinding(dataItem, propPath);
      if (!value.match(/^https?:\/\//i)) {
        value = 'http://' + value;
      }
      window.open(value, '_blank');
    }
    event.stopPropagation();
  }

  itemClickNavigate(dataItem) {
    this.searchService.currentPreviewItemClicked = null;
    this.previewClose();
    this.utility.processNavigationUrl(this.params.url + "?id=" + dataItem["Id"]);
  }

  itemNavigate(dataItem) {
    this.searchService.currentPreviewItemClicked = null;
    this.previewClose();
    return (this.params.url + "?id=" + dataItem["Id"]);
  }

  itemClick(dataItem) {

    let parUrl = this.params.url;
    if (typeof parUrl != "undefined" && parUrl && parUrl.length > 0 && (this.additionalSearchSettings == null || typeof this.additionalSearchSettings == "undefined" || !this.additionalSearchSettings.quickViewEnabled)) {
      this.utility.processNavigationUrl(this.params.url + "?id=" + dataItem["Id"]);
    } else if (this.searchService.globalSearch) {

      if (dataItem["Id"] != null && typeof dataItem["Id"] != "undefined"
        && dataItem["DetailsFormUrl"] != null && typeof dataItem["DetailsFormUrl"] != "undefined" && dataItem["DetailsFormUrl"].length > 0) {

        var detailsFormUrl = dataItem["DetailsFormUrl"] + "?id=" + dataItem["Id"]
        this.utility.processNavigationUrl(detailsFormUrl);

      }

    } else {

      let actions = this.getGridFormActions(4);

      if (actions && actions.length > 0) {
        this.performAction(actions[0]["FormAction"], dataItem);
      } else {

        //this.t++;
        this.previewClose();
        // if quick view enabled then we should not navigate to details page    
        if (this.additionalSearchSettings != null && typeof this.additionalSearchSettings != "undefined" && this.additionalSearchSettings.quickViewEnabled) {
          // open quick preview      
          this.searchService.currentPreviewItemClicked = dataItem;

          if (this.previewActions.length == 0) {
            //lets get rid of nino
            //this.restProviderService.getDataGET("api/formactions/load/3434").subscribe(data => {
            var endpoint = "api/formactions/loadbyname/" + this.additionalSearchSettings.quickViewFormName;
            this.restProviderService.getDataGET(endpoint).subscribe(data => {
              this.previewActions = JSON.parse(data);
            }, error => {
              //console.log("Error retrieving form actions");
            });
          }

        }
        /*else {
          if (this.params.url.includes("_ww_")) {
            let fUrl = this.params.url;
            fUrl.split("_ww_").join("?");
            fUrl.split("_qq_").join("&");
            this.utility.processNavigationUrl(fUrl);
          } else {
            this.utility.processNavigationUrl(this.params.url + "?id=" + dataItem["Id"]);
          }*/
        //}
      }
    }
  }

  redirectPreviewToDetails() {
    if (this.searchService.currentPreviewItemClicked != null && typeof this.searchService.currentPreviewItemClicked != "undefined") {
      this.utility.processNavigationUrl(this.params.url + "?id=" + this.searchService.currentPreviewItemClicked["Id"]);
      this.previewClose();
    }
  }

  previewClose() {
    //this.currentItemClicked = null;
    this.searchService.currentPreviewItemClicked = null;
    this.changeDetection.detectChanges();
  }

  pageChanged(pageNum) {
    this.checkboxAllItemsChecked = false;
    this.searchService.changeSearchPage(pageNum);
  }

  toLastPage() {
    this.checkboxAllItemsChecked = false;
    if (this.totalPages < 1) {
      this.totalPages = 1;
    }
    this.searchService.changeSearchPage(this.totalPages);
  }

  toFirstPage() {
    this.checkboxAllItemsChecked = false;
    this.searchService.changeSearchPage(1);
  }

  itemEditClick(dataItem) {
    if (this.params.hasOwnProperty('urlNew')) {
      this.utility.processNavigationUrl(this.params.urlNew + "?id=" + dataItem["Id"]);
    } else if (this.params.hasOwnProperty('url')) {
      this.utility.processNavigationUrl(this.params.url + "?id=" + dataItem["Id"]);
    } else {
      if (dataItem["Id"] != null && typeof dataItem["Id"] != "undefined"
        && dataItem["DetailsFormUrl"] != null && typeof dataItem["DetailsFormUrl"] != "undefined" && dataItem["DetailsFormUrl"].length > 0) {

        var detailsFormUrl = dataItem["DetailsFormUrl"] + "?id=" + dataItem["Id"]
        this.utility.processNavigationUrl(detailsFormUrl);

      }
    }
  }

  addNew() {

    if (typeof this.model[this.bindingPropertyName] == "undefined") {
      this.model[this.bindingPropertyName] = [];
    }

    this.model[this.bindingPropertyName].push({});

  }

  translate(key: string) {
    var k = key.replace("Translation.", "");

    if (this.translationsService.translations[k]) {
      return this.translationsService.translations[k];
    } else {
      return key;
    }
  }

  deleteItem(dataItem) {
    var index = this.model[this.bindingPropertyName].indexOf(dataItem);
    if (index > -1) {
      this.model[this.bindingPropertyName].splice(index, 1);
      this.totalItems = this.totalItems - 1;
    }
  }

  public get itemsLoaded(): boolean {
    try {

      this.userService.currentLayout = this.viewType;

      if (typeof this.model[this.bindingPropertyName] == "undefined") {
        this.loading = false;
        this.setViewType();
        return false;
      }

      if (this.model[this.bindingPropertyName].length > 0) {
        this.loading = false;
        this.setViewType();
        return true;
      }

      this.loading = false;
      this.setViewType();
      return false;

    }
    catch (exc) {
      this.loading = false;
      return false;
    }
  }

  setViewType() {
    if (typeof this.userGridSettings.LayoutName != "undefined" && this.userGridSettings.LayoutName) {
      this.viewType = this.userGridSettings.LayoutName;
    } else {
      this.viewType = "list";
    }
  }

  valueChanged() {
  }

  sortDropDownClick(column, event) {
    event.stopPropagation();
    if (this.helperColumnFlags[column.BindingField]["ShowSort"]) {
      this.helperColumnFlags[column.BindingField]["ShowSort"] = false;
    } else {
      this.helperColumnFlags[column.BindingField]["ShowSort"] = true;
    }
    return false;
  }

  setColumnSorting(column, direction, event) {
    event.stopPropagation();
    this.helperColumnFlags[column.BindingField]["SortingUsed"] = true;
    this.helperColumnFlags[column.BindingField]["SortDirection"] = direction;
    // hide    
    this.helperColumnFlags[column.BindingField]["ShowSort"] = false;


    // add to current sort field if doesn't already exists
    // if exists just update sort direction
    let index = this.currentSortFields.findIndex(x => x.FieldName == column.BindingField);
    if (index >= 0) {
      // if new direction is same as current then ignore it
      if (this.currentSortFields[index].SortDirection != direction) {
        this.currentSortFields[index].SortDirection = direction;
        // update observable
        this.searchService.changeSortFields(this.currentSortFields);
      }
    } else {
      this.currentSortFields = [];
      this.currentSortFields.push(new SortFieldModel(direction, column.BindingField, this.params["model"]));
      this.searchService.changeSortFields(this.currentSortFields);
    }

    return false;
  }

  setNewUserSearchSettingObject(modelName, viewId) {
    this.userService.getUserSearchSettings(modelName, viewId).subscribe(data => {

      console.log(data);

      if (data != null) {
        this.userGridSettings = data;
        if (data != null && typeof data != "undefined" && data["Version"] != null && typeof data["Version"] != "undefined") {
          this.userService.userSearchSettingCurrentVersion = data["Version"];
        }

        if (typeof this.userGridSettings.LayoutName != "undefined" && this.userGridSettings.LayoutName) {
          this.viewType = this.userGridSettings.LayoutName;
        } else {
          this.userGridSettings.LayoutName = "list";
        }
      }

    });
  }

  handleAdditionalListingAction(data) {
    this.additionalListingFields.push(data);
  }

  handleUserAdditionalGridFields(modelName, viewId, metamodelProperties, restore: boolean = false) {

    let layoutName = this.userGridSettings?.LayoutName;
    if(!layoutName){
      layoutName = "init";
    }
    // check if user already has search field settings saved
    this.userService.getUserGridSettings(modelName, viewId, this.viewType, layoutName).subscribe(data => {

      if (data != null) {
        this.userGridSettings = data;

        if (data != null && typeof data != "undefined" && data["Version"] != null && typeof data["Version"] != "undefined") {
          this.userService.userSearchSettingCurrentVersion = data["Version"];
        }


        if (data["View"] != null) {
          if (data["View"]["AdditionalListingActions"] != null) {
            var addListing = JSON.parse(data["View"]["AdditionalListingActions"]);

            for (var i = 0; i < addListing.length; i++) {
              var res = this.actionsService.callAction({ "ActionName": addListing[i]["formAction"] }, {}, null, this.handleAdditionalListingAction.bind(this), this, false, null);
            }

          }
        }
      }

      if (restore == true || this.restoreGlobal == true) {
        this.gridAddedFields = [];
      }

      try {
        //lets get default sorter
        if (this.userGridSettings != null && this.userGridSettings.DefaultSorter != null && this.userGridSettings.DefaultSorter != "undefined") {
          var defSorterJson = JSON.parse(this.userGridSettings.DefaultSorter)
          if (defSorterJson != null) {
            this.defaultSorter = new SortFieldModel(defSorterJson.SortDirection, defSorterJson.FieldName, defSorterJson.RootClassName);
            this.currentSortFields.push(this.defaultSorter);
            this.searchService.changeSortFields(this.currentSortFields);
          }
        }
      }
      catch (e) {

      }

      //this.metaModelService.getSearchFields(modelName).subscribe(data => {

      // only those that are availible in grid
      this.metamodelGridFields = metamodelProperties.filter(x => x.AvailibleInGrid == true);
      let newProjection = [];
      this.helperColumnFlags = [];


      if (typeof this.userGridSettings?.LayoutName != "undefined" && this.userGridSettings?.LayoutName) {
        this.viewType = this.userGridSettings.LayoutName;
      } else if(this.userGridSettings) {
        this.userGridSettings.LayoutName = "list";
      }

      // if user has any saved searchSettings
      if (this.userGridSettings != null && this.userGridSettings.SearchFields != null && this.userGridSettings.SearchFields.length > 0) {

        //clear default grid columns if there are any saved gridSEttings
        this.gridSettings.Columns = [];

        // go through saved fields
        for (let i = 0; i < this.userGridSettings.SearchFields.length; i++) {


          if (this.userGridSettings.LayoutName == "list") {
            if (this.userGridSettings.SearchFields[i].FieldType != 2) {
              continue;
            }
          } else if (this.userGridSettings.LayoutName.includes("grid")) {
            if (this.userGridSettings.SearchFields[i].FieldType != 4) {
              continue;
            }
          }

          // find saved field in metamodelGridFields
          let index = this.metamodelGridFields.findIndex(x => x.Name == this.userGridSettings.SearchFields[i].PropertyPath);

          let headerText = this.userGridSettings.SearchFields[i].PropertyPath;
          let binding = this.userGridSettings.SearchFields[i].PropertyPath;
          let isImageControl = false;
          let isSortable = false;
          let isDateTime = false;
          let isBoolean = false;

          if (index >= 0) {

            headerText = this.metamodelGridFields[index]["Translation"];
            binding = this.metamodelGridFields[index]["Name"];
            isImageControl = this.metamodelGridFields[index]["IsImageControl"];
            isSortable = this.metamodelGridFields[index]["IsSortable"];
            isDateTime = this.metamodelGridFields[index]["IsDateTime"];
            isBoolean = this.metamodelGridFields[index]["IsCheckBox"];

            // also add into addedFields
            this.gridAddedFields.push(this.metamodelGridFields[index]);
          }

          // add into grid column              
          this.gridSettings.Columns.push(
            {
              "HeaderText": headerText,
              "Width": 30,
              "BindingField": binding,
              "IsImageControl": isImageControl,
              "IsDateTime": isDateTime,
              "IsBoolean": isBoolean,
              "Type": this.userGridSettings.SearchFields[i].FieldType
            });

          this.helperColumnFlags[binding] = {
            "IsSortable": isSortable,
            "ShowSort": false,
            "SortingUsed": false
          }

          // add grid fields to projection (so values will be inside search response)
          newProjection.push(binding);
        }
      }

      // set new projection based on user's grid settings and default projection fields
      let projectionFacet = new ProjectionFacetWrapperModel();
      // before adding title and id (if they dont already exists) to current projection, save this projection 
      this.searchService.setCurrentGridDisplayFields(Object.assign([], newProjection));
      projectionFacet.projection = this.handleDefaultProjection(newProjection);
      projectionFacet.facets = this.handleMetamodelFacets(metamodelProperties);
      this.searchService.changeSearchProjectionAndFacets(projectionFacet);


      if (typeof DynamicLoadingUtil.loader != "undefined") {
        DynamicLoadingUtil.loader.showLoader = false;
      }

      // }, error => {
      //   if (typeof DynamicLoadingUtil.loader != "undefined") {
      //     DynamicLoadingUtil.loader.showLoader = false;
      //   }
      // });

      this.loading = false;

    }, error => {
      if (typeof DynamicLoadingUtil.loader != "undefined") {
        DynamicLoadingUtil.loader.showLoader = false;
      }
      this.loading = false;
    });
  }


  private handleMetamodelFacets(luceneProperties): FacetViewModel[] {

    let availibleFacets = [];

    if (luceneProperties != null && typeof luceneProperties != "undefined" && luceneProperties.length > 0) {

      let facets = luceneProperties.filter(x => x.IsFacet == true);

      for (let facet of facets) {

        let facetView = new FacetViewModel();
        facetView.facetTitle = facet.Translation;
        facetView.facetPropertyPath = facet.Name;
        facetView.facetTranslation = facet.FacetTranslation;


        if (facet.IsTaxonomyFacet) {
          facetView.facetType = "taxonomy";
        } else if (facet.IsFacet && facet.IsRangeField) {
          facetView.facetType = "range";
        } else {
          facetView.facetType = "normal";
        }


        availibleFacets.push(facetView);
      }
    }

    return availibleFacets;
  }

  showDataGridColumn(isImageControl, isBoolean) {
    // if it is image control then dont show data grid column
    // or it is boolean
    if (isImageControl == true || isBoolean == true) {
      return false; // means it will hide div
    } else {
      // in every other case show it
      return true;
    }
  }

  showIfNotDate(isDateTime) {
    if (isDateTime == true) {
      return false;
    } else {
      return true;
    }
  }

  modifyGridTableColumns() {
    DynamicLoadingUtil.loader.showLoader = true;
    this.gridOriginalAddedFielsList = this.gridAddedFields.map(x => Object.assign({}, x));

    // set to default
    this.gridAvailibleFields = [];
    this.gridOriginalFieldsList = [];
    // go through all fields
    for (let i = 0; i < this.metamodelGridFields.length; i++) {
      // ignore collection properties in grid (they cannot be shown in grid component)
      // also add only properties that are stored (can retrieve from index)
      // SAŠO ADDED - 20.8. - ignore fields that have no form controls attached (mostly IDs)
      if (!this.metamodelGridFields[i].IsCollectionProperty && this.metamodelGridFields[i].IsRetrievable
        && (this.metamodelGridFields[i].FormControlName != 'undefined' && this.metamodelGridFields[i].FormControlName != null
          && this.metamodelGridFields[i].FormControlName != '')) {
        // check if exists in addedFields
        let index = this.gridAddedFields.findIndex(x => x.Name == this.metamodelGridFields[i].Name);

        if (index < 0) {
          // if doesnt exists add to availible fields        
          this.gridAvailibleFields.push(this.metamodelGridFields[i]);
          this.gridOriginalFieldsList.push(this.metamodelGridFields[i]);
        }
      }
    }
    DynamicLoadingUtil.loader.showLoader = false;


    // open dialog and send needed data to dialog
    this.restoreGlobal = false;
    const dialogRef = this.dialog.open(SemGridDisplayFieldsDialogComponent, {
      data: {
        gridAvailibleFields: this.gridAvailibleFields,
        gridAddedFields: this.gridAddedFields,
        gridOriginalFieldsList: this.gridOriginalFieldsList,
        gridOriginalAddedFielsList: this.gridOriginalAddedFielsList,
        userGridSettings: this.userGridSettings,
        modelName: this.params["model"],
        gridSettings: this.gridSettings,
        viewId: this.params["view"],
        originalGridColumnSettings: this.originalGridColumnSettings,
        helperColumnFlags: this.helperColumnFlags,
        placeHolder: this.searchService.currentPlaceHoldertitle,
        metamodelGridFields: this.metamodelGridFields,
        type: this.viewType
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined") {

        this.gridAvailibleFields = result["gridAvailibleFields"];
        this.gridAddedFields = result["gridAddedFields"];
        this.gridOriginalFieldsList = result["gridOriginalFieldsList"];
        this.gridOriginalAddedFielsList = result["gridOriginalAddedFielsList"];
        this.userGridSettings = result["userGridSettings"];
        this.gridSettings = result["gridSettings"];
        this.originalGridColumnSettings = result["originalGridColumnSettings"];
        this.helperColumnFlags = result["helperColumnFlags"];

        this.searchService.changeCurrentSearchSettings(this.userGridSettings, "grid");

        if (result["newProjection"] != null && typeof result["newProjection"] != "undefined") {
          let newProjection = result["newProjection"];
          let projectionFacet = new ProjectionFacetWrapperModel();
          // before adding title and id (if they dont already exists) to current projection, save this projection 
          this.searchService.setCurrentGridDisplayFields(Object.assign([], newProjection));
          projectionFacet.projection = this.handleDefaultProjection(newProjection);
          projectionFacet.facets = this.handleMetamodelFacets(this.metamodelGridFields);
          this.searchService.changeSearchProjectionAndFacets(projectionFacet);
        }

        if (result["restore"] == true) {
          this.restoreGlobal = true;
          this.searchService.setGridRestoredDefault(true);
        } else {
          this.restoreGlobal = false;
        }
      }
    });

  }

  // this method will add default fields to projection if they aren't already in
  private handleDefaultProjection(projection: any[]): any[] {

    if (projection != null && typeof projection != "undefined") {
      // also always add Id into projection - need this for navigation to edit
      // and Title, but only if they aren't already in
      let index = projection.findIndex(x => x == "Id");
      let titleIndex = projection.findIndex(x => x == "Title");

      if (index < 0) {
        projection.push("Id");

        let isSortable = false;
        // check if id sortable
        if (this.currentMetaModel != null && typeof this.currentMetaModel != "undefined" && this.currentMetaModel.length > 0) {
          let index = this.currentMetaModel.findIndex(x => x.Name == "Id");
          if (index >= 0) {
            isSortable = this.currentMetaModel[index]["IsSortable"];
          }
        }

        this.helperColumnFlags["Id"] = {
          "IsSortable": isSortable,
          "ShowSort": false,
          "SortingUsed": false
        }

      }
      if (titleIndex < 0) {

        let isSortable = false;
        projection.push("Title");

        // check if Title sortable
        if (this.currentMetaModel != null && typeof this.currentMetaModel != "undefined" && this.currentMetaModel.length > 0) {
          let index = this.currentMetaModel.findIndex(x => x.Name == "Title");
          if (index >= 0) {
            isSortable = this.currentMetaModel[index]["IsSortable"];
          }
        }

        this.helperColumnFlags["Title"] = {
          "IsSortable": isSortable,
          "ShowSort": false,
          "SortingUsed": false
        }
      }
    }

    return projection;
  }

  resolvePropertyBinding(object, propertyPath) {

    if (typeof propertyPath != "undefined" && propertyPath != null) {
      let split = propertyPath.split(".");
      let obj = object;

      for (let i = 0; i < split.length; i++) {
        if (obj != null && typeof obj != "undefined") {
          obj = obj[split[i]];
        }
      }

      if (obj != null && typeof obj != "undefined") {
        return obj;
      }
    }

    return "";
  }

 validURL(str) {
    var pattern = new RegExp(/^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/i); 
    return !!pattern.test(str);
  }


  isLink(dataItem, propPath) {

    if (dataItem != null && typeof dataItem != "undefined" && propPath != null && typeof propPath != "undefined") {
      var value = this.resolvePropertyBinding(dataItem, propPath);

      if (value != null && typeof value != "undefined") {
        return this.validURL(value);
      }
    }
    return false;
  }

  checkboxBulkItemClicked(object, allCheckedFunction: boolean = false, allItemsChecked: boolean = false, oneItemClick: boolean = true) {

    if (typeof object != "undefined" && object != null) {
      var documentId = this.resolvePropertyBinding(object, "Id");
      var modelName = this.params["model"];
      var index = this.checkedBulkItems.findIndex(x => x.Id == documentId);

      // check if already in
      if (index >= 0) {

        // if all checked, but this already checked, dont remove
        if (!allCheckedFunction || !allItemsChecked) {
          // user unchecked
          // remove from             
          this.checkedBulkItems.splice(index, 1);
          this.checkedBulkItemStatus[documentId] = false;
        }
      } else {

        if (!allItemsChecked) {
          if (oneItemClick) {
            this.checkedBulkItems.push({ "Id": documentId, "ApplicationModelName": modelName });
            this.checkedBulkItemStatus[documentId] = true;
          }
        } else {
          // user checked
          // add
          this.checkedBulkItems.push({ "Id": documentId, "ApplicationModelName": modelName });
          this.checkedBulkItemStatus[documentId] = true;
        }
      }
      // save current checked bulk items
      this.searchService.setCurrentCheckedBulkDocuments(this.checkedBulkItems);
    }
  }

  checkboxBulkCheckAllItems() {

    // change to reverse value
    this.checkboxAllItemsChecked = !this.checkboxAllItemsChecked;
    var items = this.model[this.bindingPropertyName];

    if (items != null && typeof items != "undefined") {
      for (var i = 0; i < items.length; i++) {
        this.checkboxBulkItemClicked(items[i], true, this.checkboxAllItemsChecked, false);
      }
    }
  }

  changeNumberOfPages(event) {

    DynamicLoadingUtil.loader.showLoader = true;

    this.userService.updateUserSearchSettingsCustomPageSize(this.userGridSettings, event.target.value).subscribe(data => {

      this.userGridSettings = data;
      if (data != null && typeof data != "undefined" && data["Version"] != null && typeof data["Version"] != "undefined") {
        this.userService.userSearchSettingCurrentVersion = data["Version"];
      }

      //this.searchService.changeCurrentSearchSettings(this.userGridSettings, "grid");

      this.searchService.changePageSize(event.target.value);
      this.numberOfPagesValue = event.target.value;
      DynamicLoadingUtil.loader.showLoader = false;

    }, error => {
      DynamicLoadingUtil.loader.showLoader = false;
    });
  }

  actionDD(event) {

    event.preventDefault();

    if (this.previewActions.length > 1) {
      this.ddShown = !this.ddShown;
    } else {
      this.runAction(this.previewActions[0]);
    }
  }

  runAction(action) {
    if (typeof (this.dynComp.cmpRef) != "undefined" && this.dynComp.cmpRef != null) {
      if (typeof (this.dynComp.cmpRef.instance) != "undefined" && this.dynComp.cmpRef.instance != null) {
        this.previewModel = this.dynComp.cmpRef.instance.model;
        this.actionsService.callAction(action, this.previewModel, null, this.actionExecuted.bind(this), this, false, null);
      }
    }
    this.ddShown = false;
  }

  actionExecuted(model, error) {
    this.dynComp.cmpRef.instance.model = model.Model;
    //console.log("Action executed: ", model, error);
  }

  getByType(number) {
    var items = [];
    if (number == true) {
      for (var i = 0; i < this.gridSettings.Columns.length; i++) {
        //if (this.gridSettings.Columns[i]["Type"] == 3) {
        items.push(this.gridSettings.Columns[i]);
        //}
      }
    } else {
      for (var i = 0; i < this.gridSettings.Columns.length; i++) {
        //if (this.gridSettings.Columns[i]["Type"] != 3) {
        items.push(this.gridSettings.Columns[i]);
        //}
      }
    }

    return items;
  }

  checkForm(urlModel) {
    if (window.location.href.indexOf(urlModel) > -1) {
      return true;
    }
    return false;
  }

  performAction(formAction, dataItem) {

    //this.actionsService.callAction(action, dataItem, this.actionExecuted.bind(this), null, this, false, null, action["OnlyClientLogic"]);
    /*eval(action.PreJS);

    //eval(action.NavigationNodeRule.ActionBody);
    // if form actions has parameter form linked then we should first show popup with parameter form
    if (action.ParameterForm != null && typeof action.ParameterForm != "undefined" && action.ParameterForm.Id > 0) {
      const dialogRef = this.dialog.open(SemParameterDialogComponent, {
        data: { parameterFormId: action.ParameterForm.Id }
      });

      dialogRef.afterClosed().subscribe(returnData => {
        if (returnData != null && typeof returnData != "undefined") {
          // only call action if parameter popup was confirmed (not cancled - close button)
          if (returnData.Canceled == false) {
            var inputParametersModel = returnData["Model"];
            this.actionsService.callAction(action, dataItem, null, null, this, false, inputParametersModel, action.OnlyClientLogic, true, action.SkipBulkActionApiMethod);
          }
        }
      });
    } else {
      // just call action without parameters form popup
      //this.actionsService.callAction(action, dataItem, null, null, this, false, null, action.OnlyClientLogic, true, action.SkipBulkActionApiMethod);
    }*/

    //performAction(formAction, dataItem){

    this.actionsService.callAction(formAction, dataItem, null, this.actionExecuted.bind(this), this, false, null, formAction["OnlyClientLogic"]);
    //eval(formAction.PreJS);
  }

  callReport(data) {
    const dialogRef = this.dialog.open(SemGenerateDialogComponent, {
      data: data
    });
  }

  getGridFormActions(type) {
    let formActions = [];

    if (typeof this.userGridSettings != "undefined" && this.userGridSettings) {
      if (typeof this.userGridSettings["FormActions"] != "undefined" && this.userGridSettings["FormActions"]) {
        for (var i = 0; i < this.userGridSettings["FormActions"].length; i++) {
          if (this.userGridSettings["FormActions"][i]["Type"] == type) {
            formActions.push(this.userGridSettings["FormActions"][i]);
          }
        }
      }
    }

    return formActions;
  }

}
