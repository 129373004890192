import { Injectable, Optional } from "../../../../node_modules/@angular/core";
import { Routes } from "../../../../node_modules/@angular/router";
import { SemLoaderComponent } from "../components/sem-loader/sem-loader.component";

@Injectable({
  providedIn: 'root'
})
export class DynamicLoadingUtil {

  routesLoaded: boolean;
  static loadedDynamicRoutes: any[] = [];
  static loader: SemLoaderComponent;
  static breadcrumbs: any = [];
  static currentRoute: any = {};
  static navigationNodes: any = [];

  constructor() {
  }
  
}
