import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SemManyToOneControlComponent } from '../../sem-many-to-one-control/sem-many-to-one-control.component';
import { RestProviderService } from '../../../../services/rest-provider/rest-provider.service';
import { RequestModel } from '../../../../models/request/request-model';
import { SearchService } from 'src/app/services/search/search.service';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';

@Component({
  selector: 'app-sem-confirm-dialog',
  templateUrl: './sem-confirm-dialog.component.html',
  styleUrls: ['./sem-confirm-dialog.component.css']
})
export class SemConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SemConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any, public tps:TranslationsProviderService ) { }

  ngOnInit() {
  }

  cancel(){
    this.dialogRef.close(false);
  }

  confirm(){
    this.dialogRef.close(true);
  }

}
