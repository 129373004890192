import { RootModel } from '../root-model';
import { Injectable } from "@angular/core";
import { UserSearchSettingModel } from './user-search-setting-model';

@Injectable()
export class UserSearchSettingPropertyModel {

  public Id: number = 0;
  public PropertyPath: string = "";
  public UserSearchSetting: UserSearchSettingModel;
  public Version: string = "";
  public FieldType: number;

  constructor() {
  }

}
