import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import * as d3 from "d3";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-sem-graph-pie',
  templateUrl: './sem-graph-pie.component.html',
  styleUrls: ['./sem-graph-pie.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemGraphPieComponent, multi: true }
  ]
})
export class SemGraphPieComponent implements AfterViewInit {

  @ViewChild("containerPieChart",{ read: ElementRef, static:true }) element: ElementRef;

  private host: any;
  private htmlElement: HTMLElement;
  private canvas: any;
  private width: number;
  private height: number;
  private data = 
  [
    {c:"SVN", n:35 }, 
    {c:"BIH", n:20 },
    {c:"AZE", n:15 },
    {c:"HRV", n:30 }]
  private radius = 300;

  ngAfterViewInit() {
    this.htmlElement = this.element.nativeElement;
    this.host = d3.select(this.htmlElement);
    this.setup();
    this.buildSVG();
  }

  private setup(): void {
    this.width = this.radius*2+50;
    this.height = this.radius*2+50;
  }

  private buildSVG(): void {
    this.host.html("");
    this.canvas = this.host.append("svg")
      .attr("width", this.width)
      .attr("height", this.height);

      var color = d3.scaleOrdinal().range(d3.schemeCategory10);
      

      var group = this.canvas.append("g")
      .attr("transform", "translate("+this.radius+","+this.radius+")");

      var arc = d3.arc().innerRadius(0).outerRadius(this.radius);

      var pie = d3.pie<any>().value(v=>v.n);

      var arcs = group.selectAll(".arc")
      .data(pie(this.data))
      .enter()
      .append("g")
      .attr("class", ".arc");

      arcs.append("path")
      .attr("d", arc)
      .attr("stroke", "white")
      .attr("stroke-width", "1px")
      .attr("fill", function(d){ return color(d.data.n)});

      var SUMA = d3.sum<any>(this.data, function(d){return d.n;});

      arcs.append("text")
      .attr("transform", function(d){ return "translate(" + (arc.centroid(d)) + ")"; })
      .attr("font-size", "1.5em")
      .attr("font-family","Segoe UI")
      .text(function(d){return d.data.c +"("+(d.data.n*100.0/(SUMA))+"%)"; })
      
  }
}
