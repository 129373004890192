// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `thead th{        
    border-bottom: 2px solid #e5eaed !important;
    white-space: nowrap;
    padding: 10px 10px 10px 0;
    position: relative;
}

thead th p {
    font-family: 'robotomedium';
    font-size: 20px;
    color: black;
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 10px;
}

tbody td {
    border: 2px solid #e5eaed !important;    
}

.task-opened {
    font-weight:normal;
}

.task-unread{
    font-weight: bold;
}

.task-file{
    cursor: pointer;
    text-decoration: underline;
    color:#1589FF;
}

.task-paging p{
    cursor: pointer;
}

.popuptask .description a, .popuptask .description a:visited {
    color: #0000EE !important;    
    text-decoration: underline !important;
}

.task-table p{
    font-family: 'asapmedium' !important;
    color:black !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/containers/workflow-center/workflow-center.component.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,qCAAqC;AACzC;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B","sourcesContent":["thead th{        \r\n    border-bottom: 2px solid #e5eaed !important;\r\n    white-space: nowrap;\r\n    padding: 10px 10px 10px 0;\r\n    position: relative;\r\n}\r\n\r\nthead th p {\r\n    font-family: 'robotomedium';\r\n    font-size: 20px;\r\n    color: black;\r\n    margin-left: 45px;\r\n    margin-right: 45px;\r\n    margin-top: 10px;\r\n}\r\n\r\ntbody td {\r\n    border: 2px solid #e5eaed !important;    \r\n}\r\n\r\n.task-opened {\r\n    font-weight:normal;\r\n}\r\n\r\n.task-unread{\r\n    font-weight: bold;\r\n}\r\n\r\n.task-file{\r\n    cursor: pointer;\r\n    text-decoration: underline;\r\n    color:#1589FF;\r\n}\r\n\r\n.task-paging p{\r\n    cursor: pointer;\r\n}\r\n\r\n.popuptask .description a, .popuptask .description a:visited {\r\n    color: #0000EE !important;    \r\n    text-decoration: underline !important;\r\n}\r\n\r\n.task-table p{\r\n    font-family: 'asapmedium' !important;\r\n    color:black !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
