import { Component, Input, OnDestroy, OnInit, ɵɵstylePropInterpolateV } from '@angular/core';
import {
  NG_VALUE_ACCESSOR, Validators,
} from '@angular/forms';
import { NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';

interface InputSegment {
  type: Number; //type=1 Input; type = 2 Label;
  segmentValue: string;
  inputSize: Number;
  maxLength: string;
  segmentFormat: string;
  defaultSegment: boolean;
}

@Component({
  selector: 'sem-regex-input',
  templateUrl: './sem-regex-input.component.html',
  styleUrls: ['./sem-regex-input.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemRegexInputComponent, multi: true }
  ]
})
export class SemRegexInputComponent extends ValueAccessorBase<string> implements OnInit, OnDestroy {

  ngOnDestroy() {

    this.dfms._bindValueChange.next({});

    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
  }

  @Input() formatString: any;
  @Input() regEx: any;
  @Input() segmentLeadingZero: boolean;
  @Input() cutSegmentDelimeterWhenEmpty: boolean;
  @Input() defaultSegmentIndex: number;
  @Input() preSegmentsText: string;
  segments: InputSegment[] = [];
  splitters: string[] = ["-", "/", " "];
  sub: Subscription;
  sub2: Subscription;

  super() { }

  ngAfterContentInit() {
    this.sub = this.restProviderService.modelValueChanged.subscribe(model => {

      //Boštjan:30.09.2022, Moved clearing of segments in here as well, cause when navigating to new form, old model still get passed in this event. 
      //TODO:Refactor model value changed event

      //Boštjan: 10.01.2023, we parse model only on saved objects... Need to refactor model value changed
      if (window.location.href.includes("?id=")) {
        this.segments = [];
        this.generateSegments(this.formatString);

        var propValue = model[this.bindingProperty];

        //if (name != null && typeof name != "undefined")
        if (propValue != null && typeof propValue != "undefined") {
          this.applyValueToSegments(propValue);
        }
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();

    //test
    var bbe = this.segmentLeadingZero;
    var oce = this.cutSegmentDelimeterWhenEmpty;
    //end of test

    //we need to clear segments first
    this.segments = [];
    this.generateSegments(this.formatString);

    var seg = this.value;
    var valueToBeParsed = this.model[this.bindingProperty]

    /*this.modelChangeNotifier.subscribe((model)=>{
      //what do we have here
      var propValue = model[this.bindingProperty];

      if(propValue != null && typeof propValue != "undefined"){
        this.applyValueToSegments(propValue);
      }
    });*/

    this.sub2 = this.dfms.bindValueChange.subscribe((val: any) => {
      if (val != null && typeof val != "undefined") {
        if (val.bindingProperty != "undefined" && this.bindingProperty == val.bindingProperty) {
          this.applyValueToSegments(val.value.toString());

          //TODO: Check if this is ok with Tadej. When btn on inventory book item inventory number is clicked, value is not assigned to the model
          this.changeBindingPropertyValue();
        }
      }
    })

  }

  applyValueToSegments(propVal) {
    var propTrimmed = propVal.trim();
    //var valueSegments = this.splitMulti(propTrimmed, this.splitters)
    this.clearValueSegments();

    var valueSegments = this.splitByRegex(propVal, this.regEx);

    if (typeof valueSegments === 'string' && (this.defaultSegmentIndex != null && typeof this.defaultSegmentIndex != "undefined")) {
      this.segments[this.defaultSegmentIndex].segmentValue = this.removeLeadingZeroesAndReturnValue(this.segments[this.defaultSegmentIndex], propVal);
    }
    else {
      for (let z = 0; z < this.segments.length; z++) {
        if (this.segments[z].type == 1) {
          //lets apply segment value (first value segment is whole match)
          this.segments[z].segmentValue = this.removeLeadingZeroesAndReturnValue(this.segments[z], valueSegments[z + 1]);
        }
      }
    }

  }

  clearValueSegments() {
    for (let i = 0; i < this.segments.length; i++) {
      if (this.segments[i].type == 1) {
        this.segments[i].segmentValue = "";
      }
    }
  }

  generateSegments(format) {

    var valueSegments = this.splitMulti(format, this.splitters)
    var splitterSegments = [];
    //now we need to get 
    for (let i = 0; i < format.length; i++) {
      if (format[i] == "-" || format[i] == "/" || format[i] == ' ') {
        splitterSegments.push(format[i])
      }
    }

    //now we create our segments to
    for (let z = 0; z < valueSegments.length; z++) {
      var newSegment = {} as InputSegment;
      newSegment.type = 1;
      newSegment.inputSize = valueSegments[z].length + 1;
      newSegment.maxLength = valueSegments[z].length;
      newSegment.segmentFormat = valueSegments[z];
      this.segments.push(newSegment);
      //we need to add a separator if it exists
      if (splitterSegments.length > z) {
        var sg = {} as InputSegment;
        sg.type = 2;
        sg.segmentValue = splitterSegments[z];
        this.segments.push(sg);
      }
    }

    //set the default segment
    if (this.defaultSegmentIndex != null && typeof this.defaultSegmentIndex != "undefined") {
      this.segments[this.defaultSegmentIndex].defaultSegment = true;
    }

  }

  changeBindingPropertyValue() {
    //we need to get values from properties to binding property
    var val: string = "";
    var i = 0;
    this.segments.forEach(segment => {
      if (segment.segmentValue != null && typeof segment.segmentValue != "undefined") {
        var segVal = this.addLeadingZeroesAndReturnValue(segment, segment.segmentValue);
        val = val.concat(segVal);
      }
      else {
        if (this.cutSegmentDelimeterWhenEmpty) {
          val = val.substring(0, val.length - 1);
        }
      }
      i++;
    });

    this.model[this.bindingProperty] = val;
  }

  inputChange(value, segmentIndex) {
    this.changeBindingPropertyValue();
    var newV = this.model[this.bindingProperty];
  }

  splitMulti(str, tokens) {
    var tempChar = tokens[0]; // We can use the first token as a temporary join character
    for (var i = 1; i < tokens.length; i++) {
      str = str.split(tokens[i]).join(tempChar);
    }
    str = str.split(tempChar);
    return str;
  }

  splitByRegex(str: string, regex) {
    const matches = str.match(regex);
    if (matches) {
      return matches;
    }

    return str;
  }

  keyUp(event, index) {
    //skip backspace
    /*if (event.keyCode != 8) {
      var prePt = '^'.concat(this.regEx).concat('$')
      var pattern = new RegExp(prePt);
      //var newV = this.model[this.bindingProperty];
      //newV = newV.trim();

      var inputVal = "";
      //we need to put current key into segment
      for (let i = 0; i < this.segments.length; i++) {
        if (this.segments[i].segmentValue != null && typeof this.segments[i].segmentValue != "undefined") {
          if (i == index) {
            inputVal = inputVal.concat(this.segments[i].segmentValue).concat(event.key);
          }
          else {
            inputVal = inputVal.concat(this.segments[i].segmentValue);
          }
        }
      }

      var u = pattern.test(inputVal)

      if (!u) {
        return false;
      }
    }*/
  }

  removeLeadingZeroesAndReturnValue(segment: InputSegment, value) {
    if (value != null && value != "undefined") {
      //chek if we only have numbers
      if (/^[n\n]+$/.test(segment.segmentFormat)) {
        return value.replace(/^0+/, '');
      }
      else {
        //we have string lets trim whitespaces
        return value.trim();
      }
    }
    return value;
  }

  addLeadingZeroesAndReturnValue(segment: InputSegment, value) {

    //chek if we only have numbers
    if (/^[n\n]+$/.test(segment.segmentFormat)) {
      if (value != null && value != "undefined") {
        return value.padStart(segment.segmentFormat.length, "0")
      }
    }

    return value;
  }

  cutSegmentDelimeterIfSegmentEmpty() {

  }

}
