import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';

@Component({
  selector: 'sem-search-bar',
  templateUrl: './sem-search-bar.component.html',
  styleUrls: ['./sem-search-bar.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemSearchBarComponent, multi: true }
  ]
})

export class SemSearchBarComponent extends ValueAccessorBase<string> implements OnInit {

  @Input() fields: any;
  @Input() customPlaceholder: string;
  @Input() dashboardUrlSearch: string;
  @Input() dashboardUrlSearchPath: string;  

  searchTerm: string;
  
  super() { }

  ngOnInit() {
    if(this.customPlaceholder == null || typeof this.customPlaceholder == "undefined"){
      this.customPlaceholder = "galis_js_dashboard_whatareyoulookingfor";
    }
  }

  dashboardSearch(event){
    if(this.dashboardUrlSearchPath != null && typeof this.dashboardUrlSearchPath != "undefined"){
      event.preventDefault();
      let urlsearch = this.dashboardUrlSearchPath + "&search=" + event.target.value +"*";
      this.utility.processNavigationUrl(urlsearch);
    }   
  }

}
