import { Component, OnInit, AfterViewInit, ChangeDetectorRef, Injector, Input } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from "@angular/router";
import { filter, window } from "../../../../../node_modules/rxjs/operators";
import { DynamicLoadingUtil } from "../../../shared/helpers/dynamic-loading-util";
import { SemBaseComponent } from "../../../components/common/sem-base/sem-base.component";
import { DynamicFieldsManagerService } from "src/app/services/dynamic-fields/dynamic-fields-manager.service";
import { TranslationsProviderService } from "src/app/services/translations-provider/translations-provider.service";
import { UtilityService } from "src/app/services/utility/utility.service";
import { FormService } from "../../../services/form-service/form.service";


interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

@Component({
  selector: "breadcrumb",
  templateUrl: './sem-breadcrumbs.component.html',
  styleUrls: ['./sem-breadcrumbs.component.css']
})
export class SemBreadcrumbComponent extends SemBaseComponent implements OnInit {

  public breadcrumbs: any[] = [];

  @Input() formTitle: string = "";

  /**
   * @class SemBreadcrumbComponent
   * @constructor
   */
  constructor(
    public injector: Injector,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public changeDet: ChangeDetectorRef,
    public translationsService: TranslationsProviderService,
    public utility: UtilityService,
    public formService:FormService
  ) {
    super(injector, router, activatedRoute, injector.get(DynamicFieldsManagerService), utility,formService);
  }

  ngOnInit() {
  }



  ngAfterViewInit() {
    this.dfms.routesLoaded.subscribe((data: any[]) => {
      if (data && data.length > 0) {
        this.loadBreadcrumb();
      }
    })
  }

  loadBreadcrumb() {
    // route/navigation node we redirected (come) from
    let navigationNodeFrom = JSON.parse(JSON.stringify(DynamicLoadingUtil.currentRoute));
    DynamicLoadingUtil.currentRoute = null;

    let url = this.router.url.substring(1, this.router.url.length);

    // try finding breadcrumb from previourRoute (navigationNodeFrom - route which we redirected from)
    if (navigationNodeFrom != null) {
      if (Object.keys(navigationNodeFrom).length > 1) {
        this.visitNode(navigationNodeFrom);
      }
    }

    if (DynamicLoadingUtil.currentRoute == null) {
      // if could find breadcrumb based on routeFrom - try finding them from all navigationNodes
      for (var i = 0; i < DynamicLoadingUtil.navigationNodes.length; i++) {
        if (DynamicLoadingUtil.currentRoute == null) {
          this.visitNode(DynamicLoadingUtil.navigationNodes[i]);
        } else {
          break;
        }
      }
    }

    // if currenroute not found, try without view in url 
    // since there is possibility of having some node that is not in left-menu (which represent DynamicLoadingUtil.navigationNodes)
    if (DynamicLoadingUtil.currentRoute == null) {
      for (var i = 0; i < DynamicLoadingUtil.navigationNodes.length; i++) {
        if (DynamicLoadingUtil.currentRoute == null) {
          this.visitNode(DynamicLoadingUtil.navigationNodes[i], true);
        }
        else {
          break;
        }
      }
    }

    if (DynamicLoadingUtil.currentRoute == null) {
      if (Object.keys(navigationNodeFrom).length > 1) {
        DynamicLoadingUtil.currentRoute = navigationNodeFrom;
      }
    }

    if (DynamicLoadingUtil.currentRoute && DynamicLoadingUtil.currentRoute["BreadCrumbs"]) {
      if (DynamicLoadingUtil.currentRoute["BreadCrumbs"][0].Url != "cms/dashboard") {
        DynamicLoadingUtil.currentRoute["BreadCrumbs"].unshift({ Title: "galis_js_dashboard", Url: "cms/dashboard" });
      }


      if (typeof DynamicLoadingUtil.currentRoute["BreadCrumbs"] != "undefined" && DynamicLoadingUtil.currentRoute["BreadCrumbs"] != null) {
        this.breadcrumbs = DynamicLoadingUtil.currentRoute["BreadCrumbs"];
      }

      /*if (!url.includes("?model")) {
        this.breadcrumbs.pop();
      }*/

      if (this.breadcrumbs[this.breadcrumbs.length - 1].Title == this.translationsService.translate('AddNew')) {
        this.breadcrumbs.pop();
      }

      this.changeDet.detectChanges();
    }
    else {
      if (this.activatedRoute != null) {
        if (this.activatedRoute.children.length > 0) {
          if (location.pathname.includes(this.activatedRoute.children[0]["url"]["value"][0].path)) {
            this.breadcrumbs.push({ Url: "", Title: this.activatedRoute.children[0].data["value"]["breadcrumb"] });
          }
        }
      }
    }

    if (url.includes("/dashboard")) {
      this.breadcrumbs = [];
      this.formTitle = "";
    }

  }

  visitNode(node, withoutViewUrl: boolean = false) {

    let goToUrl:string = this.router.url.substring(1, this.router.url.length);
    let detailsPage = false;


    if (withoutViewUrl && goToUrl.includes("&view=")) {
      var n = goToUrl.indexOf("&view=");
      goToUrl = goToUrl.substring(0, n);
    }

    if(goToUrl.includes("?id=")){
      goToUrl = goToUrl.split("?id=")[0];
    }

    //let withoutsplit = "/"+goToUrl;
    if (!goToUrl.includes("?model")) {

      goToUrl = goToUrl.split("?")[0];

      /*if(!goToUrl.includes("view=")){  
        detailsPage = true;
      } */
    }

    if (goToUrl == this.router.url /*|| (detailsPage && withoutsplit == this.router.url)*/) {
      DynamicLoadingUtil.currentRoute = node;
    }
    else {
      if (node.Children != null && typeof node.Children != "undefined") {
        for (var i = 0; i < node.Children.length; i++) {
          let nodeChildUrl:string = node.Children[i].Url;

          if (withoutViewUrl) {
            var ind = nodeChildUrl.indexOf("&view=");
            nodeChildUrl = nodeChildUrl.substring(0, ind);
          }

          if (nodeChildUrl == goToUrl || nodeChildUrl.endsWith(goToUrl) || nodeChildUrl.includes(goToUrl+"&")) {
            DynamicLoadingUtil.currentRoute = node.Children[i];
            return;
          } else {
            this.visitNode(node.Children[i]);
          }
        }
      }
    }
  }

  getTranslation(node: string) {
    //console.log("bread trans: " + node);
    if (node.includes(".")) {
      return node.split(".")[1];
    }
    return node;
  }

  translate(key: string) {
    if (typeof key != "undefined" && key != null) {
      let translationKey = null;
      if (key.includes(".")) {
        translationKey = key.split(".")[1];
      } else {
        translationKey = key;
      }

      return this.translationsService.translate(translationKey);
    }
    return "";
  }

  setTitle(title) {
  }

  navigate(url) {
    if (url && url.length > 0) {
      this.utility.processNavigationUrl(url);
    }
  }
}
