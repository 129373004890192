import { RootModel } from '../root-model';

import { NavigationNodeModel } from './navigation-node-model';
import { UserSearchSettingModel } from '../search/user-search-setting-model';
import { Injectable } from "@angular/core";

@Injectable()
export class NavigationRequestModel extends RootModel {

  context: string;
  rootNode: NavigationNodeModel;
  depth: number;
  dynamicFormId: number;
  UserSearchSetting: UserSearchSettingModel;

  super() {
  }
  
}
