import { Component, OnInit } from '@angular/core';
import { SemControlComponent } from '../../sem-control/sem-control.component';

@Component({
  selector: 'sem-last-added-records',
  templateUrl: './sem-last-added-records.component.html',
  styleUrls: ['./sem-last-added-records.component.css']
})
export class SemLastAddedRecordsComponent extends SemControlComponent implements OnInit {

  lastRecordValue:any = null;

  super() { }

  ngOnInit(): void {
    this.loading = true;
    this.restProviderService.getDataGET("api/galisdocument/getlastdocument").subscribe((data:any)=>{
      this.lastRecordValue = JSON.parse(data);
      this.loading = false;
    },error=>{
      console.log("Error retrieving last record",error);
      this.loading = false;
    })
  }

}
