import "@angular/compiler";
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

var html = document.documentElement.innerHTML;
const span = document.createElement('span');
span.innerHTML = environment.projectName;
var projName =  span.textContent || span.innerText;
document.documentElement.innerHTML = html.replace("...",projName);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
