import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RequestModel } from '../../models/request/request-model';
import { HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { RootModel } from '../../models/root-model';
import { map, retry } from 'rxjs/operators';
import { SemToken } from 'src/app/models/oauth/semToken';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { environment } from 'src/environments/environment';
import { lang } from 'moment';
import { head } from 'lodash';
import { Serializer } from '../../models/serializing/serializer';


@Injectable({
  providedIn: 'root'
})

export class RestProviderService {

  // listener for search value change
  _modelValueChanged = new BehaviorSubject({});
  modelValueChanged = this._modelValueChanged.asObservable();
  currentUser: any = null;

  serializer: Serializer;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    //console.log("CURRENT USER:",this.currentUser);
    this.getUser();
  }

  public buildWgApiUrl(url: string): string {

    if (url.startsWith("/")) {
      return environment.wgProfileHost + url;
    } else {
      return environment.wgProfileHost + "/" + url;
    }
  }

  public getUser() {
    if (this.getToken() != "") {
      this.currentUser = JSON.parse(this.localStorageService.get("user"));
    }
  }

  public getToken() {

    let stToken = this.localStorageService.get("token");

    if (stToken != null) {
      let semToken: SemToken = Object.assign(new SemToken, JSON.parse(stToken));

      if (new Date(semToken.expires) > new Date()) {
        return semToken.accessToken;
      } else {
        this.localStorageService.remove("token");
        this.localStorageService.remove("user");
      }

    }

    return '';
  }

  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  public setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }


  imagePostRequest(data: any) {

    var cookie = this.getToken();
    var language = "";

    var storageLanguage = this.localStorageService.get("language");
    if (storageLanguage) {
      language = storageLanguage;
    }

    const options = {
      headers: new HttpHeaders({
        'Authorization': "Bearer " + cookie,
        'Response-Type': "application/json",
        'Accept-Language': language,
        'Cache-Control': 'must-revalidate, post-check=0, pre-check=0',
      }), params: null, responseType: "application/json"
    };

    return this.http.get<any>(this.buildWgApiUrl(data.url+"/"+data.data.Id+"/"+data.data.Width+"/"+data.data.Height),
      { headers: options.headers });
  }


  //TODO: get content type from model ?
  getDataPOST<T>(model: RequestModel): Observable<T> {
    if (this.serializer != null && typeof this.serializer != "undefined" && model.url == "api/navigation/getNodes") {
      return this.http.post<T>(this.buildWgApiUrl(model.url), model.data, this.getHttpOptions("application/json", "application/json")).pipe(map((data: any) => this.serializer.fromJson(data) as T));
    } else if (model.contentType != "" && model.responseType != "") {
      return this.http.post<T>(this.buildWgApiUrl(model.url), model.data, this.getHttpOptions(model.contentType, model.responseType));
    } else {
      return this.http.post<T>(this.buildWgApiUrl(model.url), model.data, this.getHttpOptions("", ""));
    }
  }

  //TODO: get content type from model ?
  getDataDELETE<T>(model: RequestModel): Observable<T> {

    //if (this.serializer != null && typeof this.serializer != "undefined") {
    //  return this.http.delete<T>(model.url, this.getHttpOptions("application/json", "application/json")).pipe(map((data: any) => this.serializer.fromJson(data) as T));
    //} else {
    return this.http.delete<T>(this.buildWgApiUrl(model.url), this.getHttpOptions("application/json", "application/json"))
    //}
  }

  //TODO: get model
  getDataGET(url: string, p_responseType = "text", useCache = false) {

    var cookie = this.getToken();
    var language = "";

    var storageLanguage = this.localStorageService.get("language");
    if (storageLanguage) {
      language = storageLanguage;
    }

    if (useCache) {
      const options = {
        headers: new HttpHeaders({
          'Authorization': "Bearer " + cookie,
          'Response-Type': p_responseType,
          'Accept-Language': language,
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
        }), params: null, responseType: p_responseType as "text"
      };
      return this.http.get(this.buildWgApiUrl(url), options);
    } else {
      const options = {
        headers: new HttpHeaders({
          'Authorization': "Bearer " + cookie,
          'Response-Type': p_responseType,
          'Accept-Language': language,
        }), params: null, responseType: p_responseType as "text"
      };
      return this.http.get(this.buildWgApiUrl(url), options);
    }

  }


  fileDownload(url: string) {

    var cookie = this.getToken();
    var language = "";

    var storageLanguage = this.localStorageService.get("language");
    if (storageLanguage) {
      language = storageLanguage;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + cookie,
      'Accept-Language': language
    });
    const params = null;
    const responseType = 'blob';

    return this.http.get(this.buildWgApiUrl(url), {
      headers,
      params,
      responseType,
      observe: 'events',
      reportProgress: true,
      withCredentials: true
    });

  }

  getData<T>(url: string) {
    var cookie = this.getToken();

    var o = { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + cookie, 'Content-Type': 'application/json' }) };

    return this.http.get<T>(this.buildWgApiUrl(url), o);
  }

  getHttpOptions(contentType: string, responseType: string) {
    var cookie = this.getToken();
    var language = "";

    var storageLanguage = this.localStorageService.get("language");
    if (storageLanguage) {
      language = storageLanguage;
    }

    var httpOptions = {
      headers: new HttpHeaders({
        'Authorization': "Bearer " + cookie,
        'Accept-Language': language.toLowerCase()
      })
    };

    if (contentType != "" && responseType != "") {
      httpOptions = {
        headers: new HttpHeaders({
          'Authorization': "Bearer " + cookie,
          'Content-Type': contentType,
          'Response-Type': responseType,
          'Accept-Language': language.toLowerCase()
        })
      };
    }

    return httpOptions;
  }

}
