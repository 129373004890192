import { Component, OnInit } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';

@Component({
  selector: 'sem-edit-mode-btn',
  templateUrl: './sem-edit-mode-btn.component.html',
  styleUrls: ['./sem-edit-mode-btn.component.css']
})
export class SemEditModeBtnComponent extends SemControlComponent implements OnInit {

  super() { }

  ngOnInit(): void {
  }

  click(): void {
    this.dfms.setFormAsReadOnly();
  }

  show(){
    if(this.dynamicRoutesLoader.loadedRoute?.ExplicitEditMode && window.location.href.includes("id=")){
      return true;
    }

    return false;
  }

}
