import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { SemBaseComponent } from 'src/app/components/common/sem-base/sem-base.component';

@Component({
  selector: 'app-container-wrapper',
  templateUrl: './container-wrapper.component.html',
  styleUrls: ['./container-wrapper.component.css']
})
export class ContainerWrapperComponent extends SemBaseComponent implements OnInit {

  super(){
    
  }

  ngOnInit() {
  }

  onTextChange(value){

  }

  
  sidebarChanged(event) {
    this.sidebarMenuOpened = event;
  }
}
