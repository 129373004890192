import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input } from "@angular/core";
import * as d3 from "d3";
import { GraphSettings } from "src/app/models/graph/graph-settings";
import { SemControlComponent } from "../sem-control/sem-control.component";

@Component({
  selector: 'app-sem-graph',
  templateUrl: './sem-graph.component.html',
  styleUrls: ['./sem-graph.component.css']
})
export class SemGraphComponent extends SemControlComponent implements OnInit {

  @Input() settingsJson: string;
  private Settings:GraphSettings;

  ngOnInit(): void {
    this.Settings = JSON.parse(this.settingsJson);
  }
}
