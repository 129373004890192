import { Directive, OnInit, Input, OnDestroy } from '@angular/core';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Directive({
  selector: '[preFormSubmit]'
})
export class PreFormSubmitDirective implements OnInit, OnDestroy  {

  @Input() preFormSubmitJS: string;
  @Input() openPopupBeforeSaveMessage: string = null;
  @Input() openPopupBeforeSave: string = null;
  @Input() openPopupBeforeSaveCondition: string = null;
  @Input() redirectUrlAfterSubmit: string = null;
  @Input() afterFormSubmitErrorJS: string;

  constructor(private utilityService:UtilityService ) { 

  }
    ngOnDestroy(): void {
        this.utilityService.preFormSubmitJS = null;
        this.utilityService.openPopupBeforeSave = null;
        this.utilityService.openPopupBeforeSaveMessage = null;
        this.utilityService.openPopupBeforeSaveCondition = null;
        this.utilityService.redirectUrlAfterSubmit = null;
        this.utilityService.afterFormSubmitErrorJS = null;
    }

  ngOnInit() {
    this.utilityService.preFormSubmitJS = this.preFormSubmitJS;
    if (this.openPopupBeforeSave == "true") {
      this.utilityService.openPopupBeforeSave = true;
    } else {
      this.utilityService.openPopupBeforeSave = false;
    }

    this.utilityService.afterFormSubmitErrorJS = this.afterFormSubmitErrorJS;
    this.utilityService.redirectUrlAfterSubmit = this.redirectUrlAfterSubmit;
    this.utilityService.openPopupBeforeSaveMessage = this.openPopupBeforeSaveMessage;
    this.utilityService.openPopupBeforeSaveCondition = this.openPopupBeforeSaveCondition;
  }



}
