import { Component, OnInit, Input, AfterViewInit, AfterContentInit } from '@angular/core';
import { SemControlComponent } from '../sem-control/sem-control.component';
import { SemDialogComponent } from '../dialogs/sem-dialog/sem-dialog.component';
import { SemTreeviewDialogComponent } from '../dialogs/sem-treeview-dialog/sem-treeview-dialog.component';
import { keyframes } from '@angular/animations';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SemOneToManyContainerComponent } from '../sem-one-to-many-container/sem-one-to-many-container.component';


@Component({
  selector: 'sem-many-to-one',
  templateUrl: './sem-many-to-one-control.component.html',
  styleUrls: ['./sem-many-to-one-control.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemManyToOneControlComponent, multi: true }
  ]
})

export class SemManyToOneControlComponent extends ValueAccessorBase<any> implements OnInit {

  @Input() entityName: any;
  @Input() modelName: any;
  @Input() dialogTitle: any;
  @Input() entityFormUrl: any;
  @Input() searchFields: string[];
  @Input() displayFields: string[];
  @Input() showControls: boolean = true;
  @Input() entityReadOnly: boolean = true;
  @Input() readOnly: boolean = true;
  @Input() settingsFormName;
  @Input() additionalSearchFilter;
  @Input() dialogType: string;
  @Input() tooltipTextPoVseh: string;
  @Input() repositories: string = null;
  @Input() clearFiles: string = null;
  @Input() customEvalCode: string = "";
  @Input() onValueSelectValidation: string = "";
  @Input() customApiUrl: string = "";
  @Input() parentBindingProperty: string = "";

  linkWithExistingData: string;

  dataIsLinked: boolean = false;
  jsTranslations: any;
  _parent: any;

  super() {



  }

  ngOnInit() {

    this.linkWithExistingData = this.translationsService.translations["WebGalis_LinkWithExistingData"];
    this.jsTranslations = this.translationsService.translations;

    if (typeof this.entityName != "undefined" && this.entityName) {
      this.dfms.manyToOne.push({ name: this.entityName, required: this.isRequired });

      if (this.readOnly) {
        if (this.readOnly == true) {
          this.dfms.manyToOneReadOnly.push(this.entityName);
        }
      }

      try {
        if (typeof this.entityName != "undefined" && this.entityName.length > 0) {
          var props = this.entityName.split(".");

          this.dfms.dynamicFields.push(this.entityName);

          if (props.length == 0) {
            this.model[this.entityName] = {};
          }
          else {
            this.model = this.dfms.assign(this.model, this.entityName, {});
          }

          this.restProviderService.modelValueChanged.subscribe(model => {

            if (this.route.snapshot.queryParams['id']) {

              let bindingProp = this.entityName;

              if (this.parentBindingProperty && this.parentBindingProperty.length > 0) {
                bindingProp = this.parentBindingProperty;
              }

              var res = this.dfms.getValue(model, bindingProp);

              if (typeof res == "undefined") {
                res = this.dfms.getValue(this.model, bindingProp);
              }

              if (typeof res != "undefined" && res != null) {
                if (Array.isArray(res)) {

                  //Is section in o2m
                  let sec = res[this.model["number"]];
                  //Prop in section
                  let secProp = this.dfms.getValue(sec, this.entityName);

                  if (secProp) {
                    if (Object.keys(secProp).length > 1) {
                      this.dataIsLinked = true;
                    }
                  }
                } else {
                  if (Object.keys(res).length > 1) {
                    this.dataIsLinked = true;
                  }
                }
              } else {
              }

              //Init value for custom inputs instead of selecting from popup

              var val = this.dfms.getValue(model, this.entityName);
              if (!val) {
                this.dfms.assign(model, this.entityName, {});
              }


              /*if (typeof res != "undefined" && res != null && Object.keys(res).length > 1) {

                for (var prop in res) {
                  if (Object.prototype.hasOwnProperty.call(res, prop)) {
                    if (res[prop] != null && typeof res[prop] != "undefined") {
                      if (Object.keys(res[prop]).length > 1) {

                        this.dataIsLinked = true;
                      }
                    }
                  } else {
                    this.dataIsLinked = true;
                  }
                }
              } else {
                if (props.length == 0) {
                  this.model[this.entityName] = {};
                }
                else {
                  this.model = this.dfms.assign(this.model, this.entityName, {});
                }
              }*/



            } else {
              this.dataIsLinked = false;
            }

          });

        }
      }
      catch (e) {
        console.log(e);
      }
    }

  }

  ngAfterViewInit() {
    //console.log("FORM", this.form);
    this.updateValidators();
    //this.form.form.get("model.Person.FirstName").disable();
    //this.form.form.controls["model.Person.FirstName"].disable();
    //this.form.form.controls["model.Person.FirstName"].setValidators(null);

    /*    const _form = this;
        this.form.statusChanges.subscribe(data => {
          try {
            //_form.form.form.get("model.Person.FirstName").disable();
            _form.form.form.controls["model.Person.FirstName"].disable();
            //_form.form.form.controls["model.Person.FirstName"].setValidators(null);
          } catch (e) { }
        })*/

    /*for (var i = 0; i < this.dfms.metaModel.length; i++) {
      if (this.dfms.metaModel[i].Path.includes(this.entityName)) {
        this.dfms.metaModel[i].R = 0;
      }
    }*/

  }

  openDialog() {

    this.dfms.globalForm.form.markAsDirty();
    let formname = null;

    if (this.settingsFormName != null && typeof this.settingsFormName != "undefined" && this.settingsFormName.length > 0) {
      formname = this.settingsFormName;
    } else {
      formname = this.model["SerializationObjectFormLink"];
    }

    let dialogRef = null;

    if (typeof (this.dialogType) == "undefined" || this.dialogType == null || this.dialogType.length == 0) {
      dialogRef = this.dialog.open(SemDialogComponent, {
        width: '575px', data:
        {
          entityModel: this.modelName,
          title: this.dialogTitle,
          model: this.model,
          formName: formname,
          propName: this.entityName,
          searchFields: this.searchFields,
          displayFields: this.displayFields,
          additionalSearchFilter: this.additionalSearchFilter,
          tooltipTextPoVseh: this.tooltipTextPoVseh,
          repositories: this.repositories,
          onValueSelectValidation: this.onValueSelectValidation
        }
      });
    }
    else if (this.dialogType === "tree") {
      dialogRef = this.dialog.open(SemTreeviewDialogComponent, { width: '600px', height: '640px', data: { entityModel: this.modelName, title: this.dialogTitle, formName: formname, propName: this.entityName, searchFields: this.searchFields, displayFields: this.displayFields, additionalSearchFilter: this.additionalSearchFilter, customApiUrl: this.customApiUrl } });
    }

    dialogRef.afterClosed().subscribe(result => {

      if (typeof result != "undefined") {
        this.dataIsLinked = true;
        //this.model[this.entityName] = result;

        if (this.entityName.length == 0) {
          const callerFunc = new Function("callerThis", "result", this.customEvalCode);
          callerFunc(this, result);
          //eval(this.customEvalCode).bind(this);
          result["SerializationObjectFormLink"] = this.model["SerializationObjectFormLink"];
          this.model = null;
          result["Id"] = 0;
          this.model = result;
          this.model = this.dfms.setInitValues(this.model);
          this.dfms._modelValueReset.next(this.model);
        } else {
          this.model = this.dfms.assign(this.model, this.entityName, result, true);
          this.model = this.dfms.setInitValues(this.model);
        }

        if (this.entityReadOnly) {
          this.dfms.manyToOneReadOnly.push(this.entityName);
        }
        this.changeDetection.detectChanges();
        this.updateValidators();
        this.dfms._modelMTOValueChanged.next(this.model);
      }
    });

  }

  updateValidators() {

  }

  showLinkDataControls(entityName: string) {

    if (this.readOnly == true) {
      return false;
    }

    // bosko had this on .html we moved this here
    if (this.checkForDisabled(entityName) || this.dfms?.isFormReadOnly === true) {
      return false;
    }

    if (typeof this.readOnlyCondition != "undefined" && this.readOnlyCondition) {
      if (this.readOnlyCondition.length > 0) {
        let resulteval = eval(this.readOnlyCondition);

        // if condition for read only is true we dont show linkdatacontrols	
        if (resulteval == true) {
          return false;
        }
      }
    }
    return true;
  }

  removeLinkedData() {
    // this.model[this.entityName] = {};
    this.dfms.globalForm.form.markAsDirty();
    this.model = this.dfms.assign(this.model, this.entityName, {}, true);
    this.model = this.dfms.setInitValues(this.model);
    this.dataIsLinked = false;
    this.dfms._modelMTOValueChanged.next(null);

    if (this.entityReadOnly) {
      for (var i = 0; i < this.dfms.manyToOneReadOnly.length; i++) {
        if (this.dfms.manyToOneReadOnly[i] == this.entityName) {
          this.dfms.manyToOneReadOnly.splice(i, 1);
          break;
        }
      }
    }

    if (typeof this.clearFiles != "undefined" && this.clearFiles) {
      if (this.clearFiles.length > 0 && this.clearFiles.toLowerCase() == "true") {
        this.fileUploadService._clearFiles.next(null);
      }
    }

  }

  navigateToEntity() {

    if (typeof this.entityFormUrl != "undefined" && this.entityFormUrl != null && this.entityFormUrl.length > 0) {
      let entity = this.dfms.getValue(this.model, this.entityName);

      if (typeof entity != "undefined" && entity != null) {
        if (typeof entity["Id"] != "undefined" && entity["Id"] != 0) {
          this.utility.processNavigationUrl(this.entityFormUrl + "?id=" + entity["Id"]);
        }
      }
    }
  }



}
