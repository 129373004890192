import { RootModel } from '../root-model';
import { Injectable } from "@angular/core";

@Injectable()
export class SortFieldModel {

  public SortDirection: number;
  public FieldName: string;
  public RootClassName: string;

  constructor(SortDirection: number, FieldName: string, RootClassName: string) {
      this.SortDirection = SortDirection;
      this.FieldName = FieldName;
      this.RootClassName = RootClassName;      
  }

}
