import { Injectable, Component } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParameterFormsService{

   // for closing dialog
   private closeDialog = new BehaviorSubject(undefined);
   currentCloseDialog = this.closeDialog.asObservable();

   private parameterFormsModel: any;
 
  constructor() {  
  }

  closeParameterFormDialog(cancled: boolean){
    this.closeDialog.next(cancled);
  }

  setParameterFormModel(model: any) {
    this.parameterFormsModel = model;
  }

  getParameterFormModel(){
    return this.parameterFormsModel;
  }

}
