import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lang } from 'moment';
import { SemToken } from 'src/app/models/oauth/semToken';
import { RequestModel } from 'src/app/models/request/request-model';
import { LocalStorageService } from 'src/app/services/local-storage-service/local-storage.service';
import { RestProviderService } from 'src/app/services/rest-provider/rest-provider.service';
import { TranslationsProviderService } from 'src/app/services/translations-provider/translations-provider.service';
import { environment } from 'src/environments/environment';
import { SemTimePickerComponent } from '../../controls/sem-time-picker/sem-time-picker.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public username: string = "";
  public password: string = "";
  public imgPath = environment.projIcon;

  constructor(private rp: RestProviderService, private httpClient: HttpClient, private router: Router, private localStorageService: LocalStorageService, private translationsService: TranslationsProviderService) { }

  ngOnInit(): void {
    let homepage = this.localStorageService.get("homepage_url");
    if(homepage){
      window.location.href = homepage;
    }
  }

  loginClick() {
    let body = new URLSearchParams();
    body.set('username', this.username);
    body.set('password', this.password);
    body.set('grant_type', 'password');

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    this.httpClient
      .post(environment.wgProfileHost + "/oauth/token", body.toString(), options)
      .subscribe(response => {

        let token = new SemToken;
        token.accessToken = response["access_token"];
        token.created = new Date();

        let expires = new Date();
        expires.setHours(expires.getHours() + 24);
        token.expires = expires;

        this.localStorageService.add("token", JSON.stringify(token));
        this.localStorageService.add("user", response["user"]);

        //this.rp.setCookie("_galis_access_token", response["access_token"], 1, "/");
        this.rp.currentUser = JSON.parse(response["user"]);

        let languageCode = this.localStorageService.get("language");
        if (languageCode == null) {
          languageCode = environment.language.toUpperCase();
          this.localStorageService.add("language",languageCode);
        }

        let transObj = new RequestModel;
        transObj.data = {
          "LanguageCode": languageCode
        };

        transObj.url = "api/translation/all";

        this.rp.getDataPOST(transObj).subscribe(data => {

          let translations = data["Translations"];
          this.translationsService.translations = translations;
          this.localStorageService.add("translations", JSON.stringify(translations));

          if (typeof response["dashboard_url"] != "undefined" && response["dashboard_url"]) {
            this.localStorageService.add("homepage_url", response["dashboard_url"]);
            window.location.href = response["dashboard_url"];
          } else {
            this.localStorageService.add("homepage_url", "cms/dashboard");
            window.location.href = "cms/dashboard";
          }

        }, error => {

        });
      },
        error => {

        });

  }

}
