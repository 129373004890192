// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.museumCodeLetters{
    font-size: 14px;
    font-weight:lighter;
    padding-right: 4px;
}
.museumCodeLetters::after{
    content: " ";
}`, "",{"version":3,"sources":["webpack://./src/app/components/controls/sem-regex-input/sem-regex-input.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,YAAY;AAChB","sourcesContent":[".museumCodeLetters{\r\n    font-size: 14px;\r\n    font-weight:lighter;\r\n    padding-right: 4px;\r\n}\r\n.museumCodeLetters::after{\r\n    content: \" \";\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
