import { NavigationNodeModel } from '../navigation/navigation-node-model'
import { Serializer } from '../../models/serializing/serializer';
import { Navigation } from '../../../../node_modules/@types/selenium-webdriver';

export class NavigationNodeSerializer implements Serializer {

  fromJson(json: any): NavigationNodeModel[] {
    var array = new Array(json.length);

    for (var i = 0; i < json.length; i++) {

      var NavigationNode = new NavigationNodeModel();
      NavigationNode.Title = json[i].Title;
      NavigationNode.Icon = json[i].Icon;
      NavigationNode.Type = json[i].Type;
      NavigationNode.Selected = true;
      NavigationNode.Children = json[i].Children;
      array[i] = NavigationNode;
      NavigationNode.ClientId = json[i].ClientId;
      NavigationNode.Id = json[i].Id;
      NavigationNode.Payload = json[i].Payload;
      NavigationNode.Version = json[i].Version;
      NavigationNode.Provider = json[i].Provider;
      NavigationNode.HttpRequestType = json[i].HttpRequestType;
      NavigationNode.Url = json[i].Url;
      NavigationNode.BreadCrumbs = json[i].BreadCrumbs;

    }

    return array;

  }

  toJson(NavigationNode: NavigationNodeModel): any {
    return {
      title: NavigationNode.Title,
      icon: NavigationNode.Icon,
      type: NavigationNode.Type,
      children: NavigationNode.Children,
      breadcrumbs: NavigationNode.BreadCrumbs
    };
  }

}
