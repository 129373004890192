import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { RequestModel } from 'src/app/models/request/request-model';
import { SemControlComponent } from '../../../sem-control/sem-control.component';

@Component({
  selector: 'sem-graph-pie',
  templateUrl: './sem-line-graph.component.html',
  styleUrls: ['./sem-line-graph.component.css']
})
export class SemLineGraphComponent extends SemControlComponent implements OnInit, AfterViewInit {

  @ViewChild("chartContainer", { read: ElementRef, static: true }) element: ElementRef;

  @Input() settings: any;
  @Input() valuesName: string = "...";

  multi: any[] = [
    {
      "name": this.valuesName,
      "series": [/*
        {
          "name": "2017",
          "value": 30
        },
        {
          "name": "2018",
          "value": 40
        },
        {
          "name": "2019",
          "value": 100
        },
        {
          "name": "2020",
          "value": 60
        },
        {
          "name": "2021",
          "value": 220
        }
      */]
    }
  ];

  view: any[] = [400, 320];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;
  loading: boolean = true;

  results: any = [
    {
      "name": "",
      "series": [
      ]
    }
  ];

  colorScheme = {
    domain: ['#cc922f', '#447695']
  };

  super() {
    //Object.assign(this, { this.multi });
  }

  ngAfterViewInit(): void {
  }
  
  ngOnInit() {
    this.setViewSize();

    if (typeof this.settings["GraphValueTitle"] != "undefined" && this.settings["GraphValueTitle"]) {
      this.results[0].name = this.settings["GraphValueTitle"];
    }

    if (typeof this.settings["ApiMethodType"] == "undefined" || this.settings["ApiMethodType"] == 0 || this.settings["ApiMethodType"] == 2) {
      let model = new RequestModel();
      model.data = {};
      model.url = this.settings["Endpoint"];
      this.restProviderService.getDataPOST(model).subscribe(data => {
        this.results[0].series = data;
        this.multi = this.results;
        this.loading = false;
      });
    } else {
      this.restProviderService.getDataGET(this.settings["Endpoint"]).subscribe((data: any) => {
        this.results[0].series = JSON.parse(data);
        this.multi = this.results;
        this.loading = false;
      });
    }
  }

  /*onResize(event) {
    this.setViewSize();
  }*/

  setViewSize() {
    this.view[0] = this.control.nativeElement.parentElement.clientWidth - 135;
    this.view[1] = this.control.nativeElement.parentElement.clientHeight - 165;
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
