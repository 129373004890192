import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParameterFormsService } from "src/app/services/parameter-forms-service/parameter-forms.service";
import { SearchService } from "src/app/services/search/search.service";
import { TranslationsProviderService } from "src/app/services/translations-provider/translations-provider.service";
import { RequestModel } from 'src/app/models/request/request-model';
import { RestProviderService } from 'src/app/services/rest-provider/rest-provider.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'sem-parameter-dialog',
  templateUrl: './sem-parameter-dialog.component.html',
  styleUrls: ['./sem-parameter-dialog.component.css']
})
export class SemParameterDialogComponent implements OnInit {
  
  constructor(        
    public dialogRef: MatDialogRef<SemParameterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public parameterFormsService: ParameterFormsService,
    public translationsService: TranslationsProviderService,
    public searchService: SearchService,
    private restProvider: RestProviderService) {
  }

  closingParameterFormSubscription: any;
  paramsFormUrl: string;
  isReportInUse: boolean|null = false;
  reportId: any;
  reportParameters: any = [];
  model: any = {};
  customHeaderTitle: string = null;
  customHeaderTitlePresent: boolean = false;
  customCssClass: string = null;

  ngOnDestroy() : void {
    this.parameterFormsService.closeParameterFormDialog(undefined);
    if (typeof this.closingParameterFormSubscription != "undefined" && this.closingParameterFormSubscription != null) {
      this.closingParameterFormSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {    
    this.paramsFormUrl = "/_/params/" + this.dialogData["parameterFormId"];
    this.reportId = this.dialogData["reportId"];
    
    this.customCssClass = this.dialogData["customCssClass"];
    this.customHeaderTitle = this.dialogData["customHeaderTitle"];
    if(this.customHeaderTitle != null && typeof this.customHeaderTitle != "undefined" && this.customHeaderTitle != ""){
      this.customHeaderTitlePresent = true;
    }

    this.isReportInUse = (typeof this.reportId != "undefined" && this.reportId != null) ? true : false; 
    
    this.closingParameterFormSubscription =  this.parameterFormsService.currentCloseDialog.subscribe(value => {
      if(typeof value != "undefined" && value != null){              
        this.dialogRef.close({
          "Canceled": value,
          "Model": this.parameterFormsService.getParameterFormModel()
        })        
      }    
    });

    if(this.isReportInUse)
    {
      let model = new RequestModel;
      model.url = "api/report/parameters";
      model.data = { ReportId: this.reportId };

      this.restProvider.getDataPOST(model).subscribe(data => {
        console.log("Parameter data : ", data);
        this.reportParameters = data;
      }, error => {
        console.log("Error retrieving parameter data", error);
      });
    }
}
  
  confirmParameterDialog() {   
    
    let apiModel = {};
    for (var i = 0; i < Object.keys(this.model).length; i++) {
      apiModel[Object.keys(this.model)[i]] = { "StringValue": this.model[Object.keys(this.model)[i]] };
    }

    var query = {
      "ReportId": this.reportId,
      "Query": {
        "MainQuery": null,
        "FieldQueries": null,
        "TreeQuery": {
          "Type": "FieldValue",
          "FieldName": "Id",
          "FieldValue": "1",
          "Path": null,
          "Exclude": false,
          "Subqueries": null,
          "BoostFactor": null,
          "Negate": false
        },
        "Facets": null,
        "Projection": [],
        "RequestedFacets": null,
        "Page": 1,
        "PageSize": 100,
        "ClassType": null,
        "ClassTypes": [],
        "SortFields": null,
        "UseCache": true,
        "RepositoryName": "lucene",
        "ParseMode": 0,
        "IncludeParents": false
      },
      "ResultType": null,
      "DocumentId": "1",
      "FileName": "report_" + Date.now().toString(),
      "Parameters": apiModel,
      "GlobalConfig": {
        "DateFormat": "dd. MM. yyyy"
      }

    }

    var fModel = new RequestModel;
    fModel.data = query;

    fModel.contentType = "application/json";
    fModel.responseType = "application/octet-stream;base64 ";
    fModel.url = "api/report/generate";
    var datePipe = new DatePipe('en-US');

    this.restProvider.getDataPOST<string>(fModel).subscribe(data => {
      //console.log(data);

      const linkSource = 'data:application/pdf;base64,' + data["Data"];
      const downloadLink = document.createElement("a");
      const fileName = datePipe.transform(Date.now(), "dd_MM_yyyy_hh_mm_ss") + "_report.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      //DynamicLoadingUtil.loader.showLoader = false;

    }, error => {
      //console.log(error);
      //DynamicLoadingUtil.loader.showLoader = false;
    }); 

    this.parameterFormsService.closeParameterFormDialog(false);               
  }

  cancelParameterDialog() {                  
    //this.parameterFormsService.setParameterFormModel({});
    this.parameterFormsService.closeParameterFormDialog(true);
  }
}
