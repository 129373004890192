import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, EMPTY, Subscriber } from 'rxjs';
import { expand } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RequestModel } from '../../models/request/request-model';
import { DynamicLoadingUtil } from '../../shared/helpers/dynamic-loading-util';
import { DynamicFieldsManagerService } from '../dynamic-fields/dynamic-fields-manager.service';
import { RestProviderService } from '../rest-provider/rest-provider.service';
import { FileDataModel } from 'src/app/models/file-upload/file-data';
import { ClientAction } from '../../models/file-upload/file-data';

@Injectable({
  providedIn: null
})
export class FileUploadStandaloneService implements OnDestroy {

  public files: FileDataModel[] = [];
  public filesToRemove: FileDataModel[] = [];
  public imagesPath: string[] = [];
  public filesNumber: number = 0;

  size: number;
  chunkSize: number = 512000;
  progress: number;
  startTime: number;
  speed: number;
  remaining: number;
  response: any;
  status: string;
  options: any;
  headers: any;
  abort: () => void;
  file: FileDataModel;
  model: any;
  uploaded: number;
  startFU: number;
  chunkNumber: number = 0;

  _clearFiles = new BehaviorSubject({});
  clearFiles = this._clearFiles.asObservable();

  g_Observer: Subscriber<number> | null = null;

  constructor(private restProvider: RestProviderService, private http: HttpClient, private loadingUtil: DynamicLoadingUtil, private dfms: DynamicFieldsManagerService, private router: Router) {
  }

  ngOnDestroy(): void {
    if (this.files.length != 0) {
      this.g_Observer?.error();
      this.files = [];
    }
  }

  uploadFiles(model: object): Observable<number> {


    this.model = model;

    if (this.files.length > 0) {
      var filesTemp = this.files.slice();
      let fileSet = new Set();
      for (var i = 0; i < filesTemp.length; i++) {
        fileSet.add(filesTemp[i]);
      }

      this.file = filesTemp[0];
      this.startTime = Date.now();
      this.size = this.file.File.size;
    }

    /*if (this.filesToRemove.length > 0) {
      var res = this.removeFiles();
    }*/

    return this.sendFile().pipe(expand((res: number) => {
      if (res > -1) {
        return this.sendFile(res);
      } else {
        return EMPTY;
      }
    }));

  }

  removeFiles(): Promise<any> {
    var model = new RequestModel;
    model.url = "api/media/remove";

    for (var i = 0; i < this.filesToRemove.length; i++) {
      this.filesToRemove[i]["DocumentId"] = this.model["Id"];
    }

    model.data = this.filesToRemove;

    return this.restProvider.getDataPOST(model).toPromise();
  }

  /**
 * Content upload
 */
  private sendFile(start: number = 0): Observable<number> {
    //return new Promise((resolve, reject) => {
    return new Observable(observer => {

      this.g_Observer = observer;

      if (this.files.length == 0) {
        observer.next(-1);
        return;
      }
      if (this.chunkNumber > environment.maxChunkNumber) {
        observer.error();
        return;
      }

      if (this.file.Action == ClientAction.Linked) {

        let model = new RequestModel;
        model.url = "api/media/linkfile";
        
        model.data = { 
          MediaId: this.file.Id, 
          DocumentId: this.model["Id"], 
          Description:this.file.Description.toString(), 
          FileName:this.file.File.name 
        };

        model.contentType = "application/json";
        model.responseType = "application/json";

        this.restProvider.getDataPOST(model).subscribe(data => {

          if (this.files.length > 0 && (this.files.indexOf(this.file) != (this.files.length - 1))) {
            //console.log("File change ---------------------------------------------------------------------------------------------");
            this.chunkNumber = 0;
            this.file = this.files[this.files.indexOf(this.file) + 1];
            this.size = this.file.File.size;
            this.startTime = Date.now();
            //this.sendFile();
            observer.next(0);
          } else {
            this.chunkNumber = 0;
            this.startFU = 0;
            this.uploaded = 0;
            this.size = 0;
            this.remaining = 0;
            this.files = [];
            this.file = null;
            //console.log("Upload finished");
            observer.next(-1);
          }

        }, error => {
          observer.error();
        })

      } else {

        let end: number = this.chunkSize
          ? start + this.chunkSize
          : this.size;

        end = end > this.size ? this.size : end;
        this.startFU = end;
        const chunk: Blob = this.file.File.slice(start, end);
        const xhr: XMLHttpRequest = XHRFactory.getInstance();
        xhr.open('POST', environment.wgProfileHost + "/api/media", true);
        xhr.responseType = 'json';

        //this.setCommonHeaders(xhr);

        xhr.setRequestHeader(
          'Content-Range',
          `bytes ${start}-${end - 1}/${this.size}`
        );

        xhr.setRequestHeader('Authorization', "Bearer " + this.restProvider.getToken());

        const updateProgress = (pEvent: ProgressEvent) => {
          this.uploaded = pEvent.lengthComputable
            ? start + (end - start) * (pEvent.loaded / pEvent.total)
            : start;
          this.progress = +((this.uploaded / this.size) * 100).toFixed(2);
          const now = new Date().getTime();
          this.speed = Math.round((this.uploaded / (now - this.startTime)) * 1000);
          this.remaining = Math.ceil((this.size - this.uploaded) / this.speed);
          //console.log("Remaining to upload :");
          //console.log(this.remaining);
        };

        const onDataSendError = async () => {
          observer.error();
        };

        xhr.onload = () => {
          if (xhr.status === 200 || xhr.status === 201) {
            this.progress = 100;
            this.response = xhr.response;
            this.status = 'complete';
            XHRFactory.release(xhr);
            //this.options.nextFile();
            if (this.uploaded < this.size && this.file.FileID < 1) {
              //this.sendFile(this.startFU);
              observer.next(this.startFU);
            }
            else if (this.files.length > 0 && (this.files.indexOf(this.file) != (this.files.length - 1))) {
              //console.log("File change ---------------------------------------------------------------------------------------------");
              this.chunkNumber = 0;
              this.files = [...new Map(this.files.map(v => [v.Name, v])).values()];
              this.file = this.files[this.files.indexOf(this.file) + 1];
              this.size = this.file.File.size;
              this.startTime = Date.now();
              //this.sendFile();
              observer.next(0);
            } else {
              this.chunkNumber = 0;
              this.startFU = 0;
              this.uploaded = 0;
              this.size = 0;
              this.remaining = 0;
              this.files = [];
              this.file = null;
              //console.log("Upload finished");
              observer.next(-1);
            }

          } else if (xhr.status && xhr.status < 400) {
            const range = +xhr.getResponseHeader('Range').split('-')[1] + 1;
            //this.retry.reset();
            XHRFactory.release(xhr);
            // send next chunk
            //this.abort = this.sendFile(range);
            observer.next(0);
          } else {
            onDataSendError();
          }
        };

        xhr.onerror = onDataSendError;
        //xhr.onload = onDataSendSuccess;
        xhr.upload.onprogress = updateProgress;

        this.chunkNumber++;

        let modelId = 0;
        modelId = this.model["Id"];


        let formData: FormData = new FormData();
        formData.append('file', chunk, this.file.File.name);
        formData.append('name', this.file.File.name);
        formData.append('parentdbid', modelId.toString());
        formData.append('chunkSize', this.chunkSize.toString());
        formData.append('fileSize', this.file.File.size.toString());
        formData.append('chunkNumber', this.chunkNumber.toString());
        formData.append('category', this.file.Category.toString());
        formData.append('formName', this.model["SerializationObjectFormLink"]);

        if (typeof this.file.Title != "undefined" && this.file.Title != null) {
          formData.append('title', this.file.Title.toString());
        }
        if (typeof this.file.Description != "undefined" && this.file.Description != null) {
          formData.append('description', this.file.Description.toString());
        }
        if (typeof this.file.FileOrder != "undefined" && this.file.FileOrder != null) {
          formData.append('order', this.file.FileOrder.toString());
        }
        if (typeof this.file.FileCategory != "undefined" && this.file.FileCategory != null) {
          formData.append('fileCategory', this.file.FileCategory.toString());
        }
        if (typeof this.file.FileID != "undefined" && this.file.FileID != null) {
          formData.append('fileId', this.file.FileID.toString());
        }
        if (typeof this.file.Annotations != "undefined" && this.file.Annotations != null) {
          formData.append('annotations', JSON.stringify(this.file.Annotations));
        }

        //console.log("Chunk number " + this.chunkNumber + " send");

        xhr.send(formData);
      }

      //return () => {
      //  xhr.abort();
      //};
    });
    //});
  }
}


export let XHRFactory = (() => {
  let stack = Array(1).fill(createXHR());

  function createXHR() {
    return new XMLHttpRequest();
  }

  return {
    release: (xhr) => {
      xhr.onreadystatechange = null;
      xhr.onerror = null;
      xhr.onload = null;
      xhr.upload.onprogress = null;
      stack.push(xhr);
    },
    getInstance() {
      if (!stack.length) {
        return createXHR();
      } else {
        return stack.pop();
      }
    },
    get size() {
      return stack.length;
    },
    set size(s) {
      stack = Array(s).fill(createXHR());
    }
  };
})();
