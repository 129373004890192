import { RootModel } from '../root-model';
import { Injectable } from "@angular/core";
import { FacetGroupModel } from './facet-group.model';

@Injectable()
export class FacetViewModel {

    public facetType: string;
    public facetPropertyPath: string;
    // facet property translation
    public facetTitle: string;
    // facet translation (only for ui in facets component)
    public facetTranslation: string;
    public groupFacets: FacetGroupModel[] = []; 
    public order: number;

  constructor() {      
  }

}
