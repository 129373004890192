import { Injectable, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DynamicFieldsManagerService } from '../dynamic-fields/dynamic-fields-manager.service';
import { DynamicLoadingUtil } from 'src/app/shared/helpers/dynamic-loading-util';
import { RouteNavigationService } from '../dynamic-routes-loader/route-navigation.service';
import { BehaviorSubject } from 'rxjs';
import { ResponseStatus, DialogType } from 'src/app/shared/components/sem-dynamic/sem-dynamic.component';
import { Url } from 'src/app/models/navigation/navigation-node-model';
import { type } from 'os';
import { SemConfirmDialogComponent } from 'src/app/components/controls/dialogs/sem-confirm-dialog/sem-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../search/search.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';

/**
 * Provides various JavaScript utility services that can be used on WG client-side
 */
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  // property for sending selected values between 2 dropdowns
  private dropdownSelectedValue = new BehaviorSubject<any>(undefined);
  currentDropdownSelectedValue = this.dropdownSelectedValue.asObservable();

  public newSectionAdded = new BehaviorSubject<any>(undefined);
  newSectionAddedObsr = this.newSectionAdded.asObservable();

  public reqResponse: boolean = false;
  public reqStatus: ResponseStatus;
  public reqMessage: string;
  public preFormSubmitJS: string = null;
  public openPopupBeforeSaveMessage: string = null;
  public openPopupBeforeSaveCondition: string = null;
  public openPopupBeforeSave: boolean = false;
  public redirectUrlAfterSubmit: string = null;
  public tabInformations: any = null;
  public afterFormSubmitErrorJS: string = null;

  public stateObjectHistory: StateHistory[] = [];

  // listener for expanding/colapsin facets
  private facetsCollapsed = new BehaviorSubject(undefined);
  currentFacetsCollapsed = this.facetsCollapsed.asObservable();

  constructor(public injector: Injector, public router: Router, public activatedRoute: ActivatedRoute, public dfms: DynamicFieldsManagerService, public dialog: MatDialog, public searchService: SearchService, public localStorageService: LocalStorageService, public location:Location) {
  }

  public get dynamicRoutesLoader(): RouteNavigationService { //this creates router property on your service.
    return this.injector.get(RouteNavigationService);
  }

  newDropdownValueSelected(value, selectedDropdownBinding) {
    this.dropdownSelectedValue.next({
      "SelectedValue": value,
      "SelectedDropDownBinding": selectedDropdownBinding
    });
  }

  async processNavigationUrl(url, stateModel = null, passthroughPopup = false, ignoreHistorySearchState = false) {

    //Hack for AZ... it's friday 4 o'clock man....
    //passthroughPopup = true;

    if (this.router.url.includes("list?model=")) {
      this.searchService.historySearchState = {};

      if(ignoreHistorySearchState === false){
        this.searchService.historySearchState["query"] = this.searchService.searchState;
        this.searchService.historySearchState["url"] = window.location.href;
        this.searchService.historySearchState["additionalFields"] = this.searchService.historySearchStateAdditionalFields;
        this.searchService.historySearchState["mainSearchValue"] = this.searchService.historySearchMainSearchFieldValue;
      }      
    }

    if (passthroughPopup) {
      await this.processNavigate(url, stateModel);
    } else if (environment.navigateAwayPopupEnabled) {

      if (typeof url != "undefined" && url != "") {
        if (!this.router.url.includes("/list?") && !this.router.url.includes("dashboard") && !this.router.url.includes("/galisweb/search")) {

          DynamicLoadingUtil.loader.showLoader = false;

          if (this.dfms.wasInEditMode) {
            const dialogRef = this.dialog.open(SemConfirmDialogComponent, { width: '350px', height: '185px', data: { type: DialogType.Confirmation, text: 'WebGalis_NavigateAway' } });
            dialogRef.afterClosed().subscribe(async result => {

              if (result) {
                await this.processNavigate(url, stateModel);
              }

            });
          } else {
            await this.processNavigate(url, stateModel);
          }
        } else {

          await this.processNavigate(url, stateModel);

        }
      }
    }
  }

  updateURLQueryParam(url: string, param: string, newValue: string, baseUrl: string = ''): string {
    // If the URL is partial, prepend the base URL
    const fullUrl = baseUrl ? `${baseUrl}${url}` : url;

    // Parse the URL
    let urlObj = new URL(fullUrl, window.location.origin); // Use window.location.origin as a fallback for relative URLs

    // Modify the specific query parameter
    urlObj.searchParams.set(param, newValue);

    // Return the updated URL as a string
    return urlObj.pathname + urlObj.search + urlObj.hash; // Return the partial URL if base URL was used
  }

  async processNavigate(url, stateModel = null) {
    this.reqResponse = false;

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    if (typeof DynamicLoadingUtil.loader != "undefined") {
      DynamicLoadingUtil.loader.showLoader = true;
    }

    this.router.onSameUrlNavigation = "reload";
    var compExists = false;

    var urlToGenerate = "";
    if (url.includes("?")) {
      urlToGenerate = url.split("?")[0];
    } else {
      urlToGenerate = url;
    }

    for (var i = 0; i < this.router.config.length; i++) {
      if (this.router.config[i]["path"] == urlToGenerate) {
        compExists = true;
        break;
      }
    }

    if (!compExists) {
      await this.dynamicRoutesLoader.loadConfig(urlToGenerate);
    }

    if (stateModel == null) {

      let currentNav = this.router.getCurrentNavigation();
      if (currentNav) {
        currentNav.extras.state = {}; // Resetting the state
      }

      this.location.replaceState(url,'',null);
      
      this.router.navigateByUrl(url, { state: null });
    } else {

      this.router.navigateByUrl(url, { state: stateModel });
    }

    if (typeof DynamicLoadingUtil.loader != "undefined") {
      DynamicLoadingUtil.loader.showLoader = false;
    }
  }

  getStateFromHistory(url: string): StateHistory {
    for (var i = 0; i < this.stateObjectHistory.length; i++) {
      if (this.stateObjectHistory[i].Url == url) {
        if (!this.stateObjectHistory[i].StateObject["type"]) {
          return this.stateObjectHistory[i];
        } else {
          return null;
        }
        break;
      }
    }

    return null;
  }

  modifyStateFromHistory(state: StateHistory): StateHistory {

    let updated: Boolean = false;

    if (typeof state != "undefined" && state) {
      for (var i = 0; i < this.stateObjectHistory.length; i++) {
        if (this.stateObjectHistory[i].Url == state.Url) {
          this.stateObjectHistory[i] = state;
          updated = true;
          break;
        }
      }
    }

    if (!updated) {
      this.stateObjectHistory.push(state);
    }

    return state;
  }

  checkStateForValidity(url: string, stateModel: any): any {
    if ((typeof stateModel == "undefined" || stateModel == null) || (Object.keys(stateModel).length == 1 && Object.keys(stateModel)[0] == "navigationId")) {
      var sm = this.getStateFromHistory(url);

      if (sm != null) {
        stateModel = sm.StateObject;
      }
    } else {
      let stModel = { Url: url, StateObject: stateModel };
      this.modifyStateFromHistory(stModel)
    }

    return stateModel;
  }

  setFacetsCollapsed(value) {
    this.facetsCollapsed.next(value);
  }

  isFormExpicitEditMode() {

    var route = this.getCurrentRoute();

    if (route) {
      return route.ExplicitEditMode;
    } else {
      return false
    }

  }

  getCurrentRoute() {
    let loadedRoutes = this.localStorageService.get("api/navigation/getRoutes");

    if (loadedRoutes != null) {
      let loadedRoutesArr: any[] = JSON.parse(loadedRoutes);

      for (var i = 0; i < loadedRoutesArr.length; i++) {
        var urlToCompare = loadedRoutesArr[i]["PrettyUrl"];

        let url = window.location.pathname;
        if (url.startsWith("/")) {
          url = url.substring(1);
        }

        //if (urlToCompare == url && loadedRoutesArr[i].ExplicitEditMode) {
        if (urlToCompare == url) {
          return loadedRoutesArr[i];
        }
      }
    }

    return null;
  }

}

export interface StateHistory {
  Url: string;
  StateObject: any;
}