import { Injectable } from '@angular/core';
import { RestProviderService } from '../rest-provider/rest-provider.service';
import { DynamicLoadingUtil } from '../../shared/helpers/dynamic-loading-util';
import { RequestModel } from '../../models/request/request-model';
import { Observable, throwError } from 'rxjs';
import { map, filter, switchMap, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MetaModelService {

  observableShare: Observable<any[]>; // store the shared observable
  observableResult: any[]; // store the result of the observable when complete

  constructor(public restProvider: RestProviderService) {   
  }

  getSearchFields(modelName: string, onlyLastNodeNames: boolean = false, viewId: number = 0, globalSearch: boolean = false, globalSearchType: string = "") {

    var metaModelRequest = new RequestModel;
    metaModelRequest.url = "api/metamodel/entities";
    metaModelRequest.data = { "EntityName": modelName, "RepositoryName": "lucene", "OnlyLastNodeNames": onlyLastNodeNames, "SearchViewId": viewId, "GlobalSearch" : globalSearch,
    "GlobalSearchType":globalSearchType};

    /*if (this.observableResult) { // if result has already been found (api has completed) return result
      // create dummy observable and return results
      return new Observable<any[]>(observer => {
        observer.next(this.observableResult);
        observer.complete();
      })
    } else if (this.observableShare) { // else if api is still processing, return shared observable
      return this.observableShare;
    } else { // else api has not started, start api and return shared observable
*/
      this.observableShare = this.restProvider.getDataPOST<any[]>(metaModelRequest).
      pipe(
        map((data: any[]) => {
          this.observableResult = data;
          return data;
        }), catchError(error => {
          return throwError('Metamodel service error!', error)
        }));        

      return this.observableShare;
    //}
  }
  
}
