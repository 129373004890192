import { Component, OnInit } from '@angular/core';
import { ValueAccessorBase } from '../shared/value-accessor/value-accessor-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-sem-graph-histogram',
  templateUrl: './sem-graph-histogram.component.html',
  styleUrls: ['./sem-graph-histogram.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemGraphHistogramComponent, multi: true }
  ]
})
export class SemGraphHistogramComponent extends ValueAccessorBase<any> implements OnInit {


  ngOnInit() {
  }

}
