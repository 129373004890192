import { Component, ViewChild, OnInit, NgModule, Compiler, Input, Injector, QueryList, ViewChildren, inject } from '@angular/core';
import { SemLeftMenuComponent } from '../app/navigation/components/sem-left-menu/sem-left-menu.component';
import { RestProviderService } from './services/rest-provider/rest-provider.service';
import { NavigationNodeSerializer } from './models/serializing/navigation-node-serializer';
import { NavigationRequestModel } from './models/navigation/navigation-request-model';
import { RequestModel } from './models/request/request-model';
import { RouterModule, Routes, Router, RouterLink, ActivatedRoute } from '@angular/router';
import { SemSharedModule } from './shared/modules/sem-shared/sem-shared.module';
import { FormsModule } from '../../node_modules/@angular/forms';
import { HttpClient, HttpClientModule } from '../../node_modules/@angular/common/http';
import { Http } from '../../node_modules/@angular/http';
import { SemDynamicComponent, ResponseStatus } from './shared/components/sem-dynamic/sem-dynamic.component';
import { CommonModule } from '../../node_modules/@angular/common';
import { SemLoaderComponent } from './shared/components/sem-loader/sem-loader.component';
import { DynamicLoadingUtil } from './shared/helpers/dynamic-loading-util';
import { SemBaseComponent } from './components/common/sem-base/sem-base.component';
import { DynamicFieldsManagerService } from './services/dynamic-fields/dynamic-fields-manager.service';
import { UtilityService } from './services/utility/utility.service';
import { FormService } from './services/form-service/form.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})



export class AppComponent extends SemBaseComponent {
  menuOpened: boolean = false;
  @ViewChild(SemLeftMenuComponent, { read: SemLeftMenuComponent, static:true }) leftMenu: SemLeftMenuComponent;
  @Input() rootLoader: any;
  masterPage: string;
  masterPageUrl: string;
  @ViewChildren(SemLoaderComponent) childrenComponent: any;

  
  constructor(private rp: RestProviderService,injector: Injector, activatedRoute: ActivatedRoute) {
    super(injector, injector.get(Router), activatedRoute,injector.get(DynamicFieldsManagerService),injector.get(UtilityService),injector.get(FormService));
  }

  ngOnInit() {
      /*if(this.rp.currentUser==null){
        this.router.navigate(['account/login']);
      }else{
        //this.router.navigate(['cms/dashboard']);
        this.injector.get(UtilityService).processNavigationUrl("cms/dashboard");
      }*/
  }

  ngAfterViewInit() {
    //console.log("LOADER");
    //console.log(this.rootLoader);

    if (typeof this.childrenComponent.first != "undefined") {
      DynamicLoadingUtil.loader = this.childrenComponent.first;
    }

  }

  isMenuOpened() {
    return this.leftMenu.opened;
  }

  sidebarChanged(event) {
    this.sidebarMenuOpened = event;
  }


}
