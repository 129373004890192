import { AfterViewInit, OnInit, Component, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SemControlComponent } from "../../sem-control/sem-control.component";
import { RequestModel } from "src/app/models/request/request-model";

@Component({
  selector: 'sem-table-chart',
  templateUrl: './sem-table-chart.component.html',
  styleUrls: ['./sem-table-chart.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SemTableGridComponent, multi: true }
  ]
})

export class SemTableGridComponent extends SemControlComponent implements OnInit, AfterViewInit {


  @Input() apiUrl: string;

  p: number = 1;
  itemsLoaded: boolean = false;
  tableData: any = [];
  totals: any = [];
  totalCountAll: number = 0;
  pageSize = 10;
  curPage = 1;
  pages: any = [];
  previousPage = 0;
  nextPage = 0;
  @Input() totalSum: string = "";
  footerColSpan: number = 0;
  @Input() hidePagination: any;

  ngAfterViewInit(): void {

  }



  ngOnInit() {

    if (this.apiUrl != null && typeof this.apiUrl != "undefined" && this.apiUrl.length > 0) {

      this.getData(1);
    }
  }

  getData(pageNum) {

    this.tableData = { "Items": [], "Columns": [] };
    this.totals = [];
    this.totalCountAll = 0;


    let requestModel = new RequestModel;
    requestModel.url = this.apiUrl;
    requestModel.data = pageNum;
    requestModel.contentType = "application/json";
    requestModel.responseType = "application/json"


    this.restProviderService.getDataPOST<any>(requestModel).subscribe(data => {
      this.zone.run(() => {
        this.tableData = data;

     
        if (this.tableData != null && this.tableData.Totals != null && this.tableData.Totals.KeyValue != null) {

          let totalKeyValue = this.tableData.Totals.KeyValue;

              this.totalCountAll = totalKeyValue["TotalCount"];
    
              for (var i = 0; i < this.tableData.Columns.length; i++) {
                let binding = this.tableData.Columns[i]["Binding"];
    
                if (totalKeyValue[binding] != null) {
                  this.totals.push(totalKeyValue[binding]);
                }
              }
    
              this.itemsLoaded = true;

              if(this.hidePagination == false || typeof this.hidePagination == "undefined"){
                this.calculatePagination();

                this.footerColSpan = this.tableData.Columns.length;
    
                if (this.totalSum != "False") {
                  this.footerColSpan++;
                }
              }        
        }
      });
    }, error => {
      //console.log("error table chart");
    });
  }

  showPagination()
  {
    if(this.hidePagination == true){
      return false;
    }

    return true;
  }

  calculatePagination() {
    this.pages = [];
    let pageNum = this.numberOfPages();
    let from = 0;
    let to = pageNum;

    from = this.curPage - 2;
    if (from <= 0) {
      from = 1;
    }

    to = from + 5;

    if (to > pageNum) {
      to = pageNum + 1;
    }

    this.previousPage = this.curPage - 1;
    if (this.previousPage <= 0) {
      this.previousPage = 0;
    }

    this.nextPage = this.curPage + 1;
    if (this.nextPage > pageNum) {
      this.nextPage = 0;
    }

    for (let i = from; i < to; i++) {

      let active = false;
      if (i == this.curPage) {
        active = true;
      }

      this.pages.push({ "Number": i, "Active": active });
    }
  }


  pageChanged(pageNum) {
    this.curPage = pageNum;
    this.getData(this.curPage);
  }

  toLastPage() {
    let pageNum = this.numberOfPages();
    this.curPage = pageNum;
    this.getData(pageNum);
  }

  toFirstPage() {
    this.curPage = 1;
    this.getData(1);
  }


  numberOfPages() {
    if (this.tableData != null && typeof this.tableData != "undefined" && this.tableData.Items != null && typeof this.tableData.Items != "undefined") {
      return Math.ceil(this.tableData.TotalCount / this.pageSize);
    }

    return 0;
  };

  resolvePropertyBinding(object, propertyPath) {

    if (typeof propertyPath != "undefined" && propertyPath != null) {
      let split = propertyPath.split(".");
      let obj = object;

      for (let i = 0; i < split.length; i++) {
        if (obj != null && typeof obj != "undefined") {
          obj = obj[split[i]];
        }
      }

      if (obj != null && typeof obj != "undefined") {
        return obj;
      }
    }

    return "";
  }
}
