// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LoginV3Component } from "src/app/components/login-views/login-v3/login-v3.component";

export const environment = {
  production: false,
  language: "sl",
  //wgProfileHost: "https://api-test.360.olympic.si",
  wgProfileHost: "https://api.360.olympic.si",
  //wgProfileHost: "https://demo-api.galisonline.eu",
  //wgProfileHost: "http://localhost:5000",

  //wgPlatformHost: "https://demo-platform.galisonline.eu",
  //wgPlatformHost: "http://localhost:4300",
  wgPlatformHost: "https://platform.360.olympic.si",
  //wgPlatformHost: "https://platform-test.360.olympic.si",


  loginComponent: LoginV3Component,
  //projIcon: "/assets/project-custom/images/ikona-wgp-login.png",
  //sidebarIcon: "/assets/project-custom/images/ikona-wgp-login.png",
  projIcon: "/assets/project-custom/images/oks-logo@3x.png",
  sidebarIcon: "/assets/project-custom/images/oks-logo@3x.png",
  projectCode: "GO",
  projectName: "<span class='span-bold'>ŠportSI 360°<span>",
  //projectName: "<span class='span-bold'>GALIS<span>online",
  locale: 'sl-SI',
  useRouteAndMasterpageCache: true,
  ADLoginEnabled: false,
  dateFormat: 'DD.MM.YYYY',
  newRegistrationLinkDocument: '/assets/sportsi360_obrazec_registracija_v4.pdf',
  navigateAwayPopupEnabled: true,
  useEtag: true,
  maxChunkNumber: 40,
  maxFileSizeInMb: 10,
  maxRecordNumberPerPageListing: 100,
  cacheEnabled: true,
  version:'1.1.0',
  showOnlyUserInHeader:true,
  useOldSearchViewFeature: true // need this for backward compatibility with OKS project, and maybe some others?
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
