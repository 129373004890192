import { RootModel } from '../root-model';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class RequestModel {

  public url: string;
  public data: any;
  public contentType: string = "";
  public responseType: string = "";
  public etag:string|null = null;

  constructor() {
    this.etag = null;
  }
  
}
